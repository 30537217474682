import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { requestAccessToProject, requestMembershipToProject } from 'shared/api/api';
import { Project } from 'shared/model/project.model';

const initialState = {
  requestingAccess: false,
  requestAccessSuccess: false,
  requestAccessProjectId: null as number | null,
  error: null as any
};

export type RequestAccessState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'requestAccess',
  initialState,
  reducers: {
    requestingAccessStart: state => {
      state.requestAccessProjectId = null;
      state.requestingAccess = true;
      state.requestAccessSuccess = false;
      state.error = null;
    },
    requestingAccessFailed: (state, action: PayloadAction<any>) => {
      state.requestingAccess = false;
      state.requestAccessSuccess = false;
      state.error = action.payload;
    },
    requestingAccessSuccess: (state, action: PayloadAction<number>) => {
      state.requestingAccess = false;
      state.requestAccessSuccess = true;
      state.requestAccessProjectId = action.payload;
    },
    reset: state => {
      state.requestingAccess = false;
      state.requestAccessSuccess = false;
      state.requestAccessProjectId = null;
      state.error = null;
    }
  }
});

const { requestingAccessStart, requestingAccessFailed, requestingAccessSuccess } = slice.actions;

export const { reset } = slice.actions;

export const requestAccessForProject =
  (project: Project): AppThunk =>
  async dispatch => {
    try {
      dispatch(requestingAccessStart());
      await requestAccessToProject(project.id);
      dispatch(requestingAccessSuccess(project.id));
    } catch (error) {
      dispatch(requestingAccessFailed(error));
    }
  };

export const requestAccessAsTeamMember =
  (project: Project): AppThunk =>
  async dispatch => {
    try {
      dispatch(requestingAccessStart());
      await requestMembershipToProject(project.id);
      dispatch(requestingAccessSuccess(project.id));
    } catch (error) {
      dispatch(requestingAccessFailed(error));
    }
  };

export default slice.reducer;
