import { IRootState } from 'config/store';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from 'shared/widgets/loader';

const ConfigurationsTabTitle = () => {
  const { t } = useTranslation('translation');
  const configurations = useSelector(({ configuration }: IRootState) => configuration.configurations);
  const loading = useSelector(({ configuration }: IRootState) => configuration.loading);

  const total = configurations ? configurations.length : 0;

  return (
    <div className="df-jb-ac unselectable pr-2">
      {t('configuration.configurations')}
      {loading ? (
        <span className="ml-2">
          <Loader size="sm" inline />
        </span>
      ) : total > 0 ? (
        <Badge pill variant="info" className="ml-2">
          {total}
        </Badge>
      ) : null}
    </div>
  );
};

export default ConfigurationsTabTitle;
