import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { addSatellite, deleteSatellite, editSatellite } from 'shared/api/api';
import { ICreateSatellite, Satellite } from 'shared/model/satellite.model';

const initialState = {
  underEditionSatellite: null as Partial<Satellite> | null,
  selectedSatellite: null as Satellite | null,
  showGlobalBeam: false,
  updating: false,
  updateSuccess: false,
  error: null as any
};

export type SatelliteState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'satellite',
  initialState,
  reducers: {
    setUnderEditionSatellite: (state, action: PayloadAction<Partial<Satellite> | null>) => {
      state.underEditionSatellite = action.payload;
    },
    setSelectedSatellite: (state, action: PayloadAction<Satellite | null>) => {
      state.selectedSatellite = action.payload;
    },
    updateStart: state => {
      state.updating = true;
      state.error = null;
      state.updateSuccess = false;
    },
    updateSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    setShowGlobalBeam: (state, action: PayloadAction<boolean>) => {
      state.showGlobalBeam = action.payload;
    },
    reset: state => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = null;
      state.selectedSatellite = null;
      state.underEditionSatellite = null;
    }
  }
});

export const { reset, setUnderEditionSatellite, setSelectedSatellite, setShowGlobalBeam, updateFailed } = slice.actions;

const { updateStart, updateSuccess } = slice.actions;

export const saveSatellite =
  (projectId: number | string, sat: Partial<Satellite>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      if (sat.id) {
        await editSatellite(projectId, sat);
      } else {
        await addSatellite(projectId, sat as ICreateSatellite);
      }
      dispatch(updateSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeSatellite =
  (projectId: number | string, satId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteSatellite(projectId, satId);
      dispatch(updateSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
