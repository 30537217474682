import classnames from 'classnames';
import { IRootState } from 'config/store';
import { gatewayPicUrl } from 'modules/map/layers/gateway/gateway-layer-def';
import { currentView } from 'modules/map/map';
import { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Gateway } from 'shared/model/gateway.model';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import '../pois/poi-gateway-item.scss';
import CopyGatewayBtn from './copyGatewayBtn';
import DeleteGatewayBtn from './deleteGatewayBtn';
import EditGatewayBtn from './editGatewayBtn';

interface IGatewayItemProps {
  gateway: Gateway;
}

const GatewayItem = (props: IGatewayItemProps) => {
  const { gateway } = props;
  const project = useSelector(({ project }: IRootState) => project.project);

  const hasWriteAccess = projectHasWriteAccess(project);

  const [visible, setVisible] = useState(false);

  const onClick = (e: SyntheticEvent) => {
    currentView?.goTo({
      center: [gateway.longitude, gateway.latitude],
      zoom: 8
    });
  };

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  return (
    <div className="gateway-item hover-white" onClick={onClick} onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div>
        <img src={gatewayPicUrl} height="16" alt="gateway" className="mb-1 mr-1" />
        {gateway.name}
      </div>
      {hasWriteAccess && visible && (
        <div
          className={classnames('d-flex', 'align-items-center', 'animate__animated', {
            animate__fadeIn: visible
          })}
        >
          <EditGatewayBtn gateway={gateway} />
          <CopyGatewayBtn gateway={gateway} />
          <DeleteGatewayBtn gateway={gateway} />
        </div>
      )}
    </div>
  );
};

export default GatewayItem;
