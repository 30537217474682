import classnames from 'classnames';
import './no-result.scss';

interface INoResultProps {
  className?: string;
  size?: 'md' | 'sm';
  children?: React.ReactNode;
}

const NoResult = ({ children, className, size }: INoResultProps) => (
  <div className={classnames('skyT-no-result', className, { sm: size === 'sm' })}>{children ? children : 'No result'}</div>
);

export default NoResult;
