import { IRootState } from 'config/store';
import useBeamLayer from 'modules/map/layers/beams/useBeamLayer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { fetchConfiguration, getMissionsFromConfiguration } from 'shared/reducers/configurationSlice';
import { fetchCoverages } from 'shared/reducers/coverageSlice';
import { showMap } from 'shared/reducers/mapSlice';
import { coverageRoute, coveragesRoute } from 'shared/routes/routes';
import BackButton from 'shared/widgets/buttons/backButton';
import Loader from 'shared/widgets/loader';
import ConfigurationItem from '../../configurationItem';
import CreateAreas from './area/CreateAreas';
import EditArea from './area/EditArea';
import './EditCoverage.scss';
import EditCoverageDetails from './EditCoverageDetails';
import SelectCoverageOrAreaToEdit from './SelectCoverageOrAreaToEdit';

const EditCoverage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const loading = useSelector(({ mission }: IRootState) => mission.loading);
  const loadingMissions = useSelector(({ configuration }: IRootState) => configuration.loadingMissions);
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const loadingCoverages = useSelector(({ coverage }: IRootState) => coverage.loading);
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const coverages = useSelector(({ coverage }: IRootState) => coverage.coverages);
  const areas = useSelector(({ area }: IRootState) => area.areas);
  const area = useSelector(({ area }: IRootState) => area.area);

  const { projectId, satelliteId, configurationId } = useParams<{ projectId: string; satelliteId: string; configurationId: string }>();

  const isCoveragesRoute = useRouteMatch([coveragesRoute.path])?.isExact;

  useBeamLayer();

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
  }, [dispatch, mapIsDisplayed]);

  useEffect(() => {
    dispatch(getMissionsFromConfiguration(projectId, configurationId));
  }, [configurationId, dispatch, projectId]);

  useEffect(() => {
    if (configurationId) {
      dispatch(fetchConfiguration(projectId, satelliteId, configurationId));
      dispatch(fetchCoverages(projectId, satelliteId, configurationId));
    }
  }, [dispatch, configurationId, projectId, satelliteId]);

  useEffect(() => {
    if (isCoveragesRoute && !coverage && coverages && coverages.length > 0) {
      // if no coverage selected, select the first
      const coverageIdToSelect = coverages[0].id;
      const path = coverageRoute.getPath(projectId, satelliteId, configurationId, coverageIdToSelect);
      history.push(path);
    }
  }, [isCoveragesRoute, coverage, coverages, projectId, satelliteId, configurationId, history]);

  const isLoading = loading || loadingMissions !== -1 || loadingCoverages;

  if (!configuration) {
    return null;
  }

  const canCreateArea = !areas.some(item => item.coverageId === coverage?.id);

  return (
    <div className="edit-coverage">
      {isLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <>
          <div className="mb-2 font-weight-bold">
            <ConfigurationItem configuration={configuration} showConfig={false} showExport={false} className="text-primary" />
          </div>
          <div className="mb-2">
            <SelectCoverageOrAreaToEdit />
          </div>
          {area ? (
            <EditArea />
          ) : (
            <>
              {coverage?.missionId ? (
                <>{canCreateArea ? <CreateAreas /> : <EditCoverageDetails />}</>
              ) : (
                <div className="text-center mt-4">
                  <BackButton />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EditCoverage;
