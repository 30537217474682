import Point from '@arcgis/core/geometry/Point';
import Polyline from '@arcgis/core/geometry/Polyline';
import Graphic from '@arcgis/core/Graphic';
import { LineSymbol3D, PictureMarkerSymbol, PointSymbol3D, TextSymbol3DLayer } from '@arcgis/core/symbols';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import { SatelliteModel } from 'shared/model/satellite-model.model';
import { displayOrbitalPosition } from 'shared/utils/string-utils';
import { getAllReadyLoadImage } from 'shared/widgets/images/ImageFromUrl';

const z = 800000;

const picUrl = `/assets/img/sat.svg`;

export const getSatellitePolylineGraphic = (lon: number, isFlat: boolean) => {
  let lonPath1 = [];
  let lonPath2 = [];
  let latPath: any[] = [];
  if (isFlat) {
    lonPath1 = [
      [lon, -90],
      [lon, 90]
    ];
    latPath = [
      [-180, 0],
      [180, 0]
    ];
  } else {
    const lon2 = lon - 180;
    for (let i = -90; i <= 90; i++) {
      lonPath1.push([lon, i, z]);
      lonPath2.push([lon2, i, z]);
    }
    for (let i = -180; i <= 180; i++) {
      latPath.push([i, 0, z]);
    }
  }

  const polyline = new Polyline({
    paths: [lonPath1, lonPath2, latPath]
  });

  const simpleLineSymbol = isFlat
    ? new SimpleLineSymbol({
        color: 'white',
        width: 2
      })
    : new LineSymbol3D({
        symbolLayers: [
          {
            type: 'line',
            size: 2,
            material: {
              color: 'white'
            }
          }
        ]
      });

  return new Graphic({
    geometry: polyline,
    symbol: simpleLineSymbol
  });
};

export const getSatSymbol = (model?: SatelliteModel) => {
  let url = picUrl;
  let width = '30px';
  let height = '60px';
  if (model?.logo) {
    const loadedImage = getAllReadyLoadImage(model.logo);
    if (loadedImage) {
      url = loadedImage.src;
      width = '45px';
      height = '45px';
    }
  }

  return new PictureMarkerSymbol({
    url,
    width,
    height
  });
};

export const getSatPointGraphic = (lon: number, model?: SatelliteModel) => {
  const satPoint = new Point({
    longitude: lon,
    latitude: 0,
    z
  });
  return new Graphic({
    geometry: satPoint,
    symbol: getSatSymbol(model)
  });
};

export const getSatLongitudeLabel = (lon: number, satPoint: Point, isFlat: boolean) => {
  const text = displayOrbitalPosition(lon, 4);
  if (isFlat) {
    return new Graphic({
      geometry: satPoint,
      symbol: new TextSymbol({
        color: 'white',
        haloColor: '#000000',
        haloSize: '1px',
        text,
        xoffset: -10,
        yoffset: -55,
        angle: -90,
        font: {
          size: 12
        }
      })
    });
  }
  const longitudePoint = satPoint.clone();
  longitudePoint.longitude = longitudePoint.longitude + 1;
  longitudePoint.latitude = longitudePoint.latitude - 4;

  return new Graphic({
    geometry: longitudePoint,
    symbol: new PointSymbol3D({
      symbolLayers: [
        new TextSymbol3DLayer({
          size: 12,
          halo: {
            color: '#000000',
            size: '1px'
          },
          text,
          material: {
            color: 'white'
          }
        })
      ]
    })
  });
};

export const getSatEquatorLabel = (satPoint: Point, isFlat: boolean) => {
  if (isFlat) {
    return new Graphic({
      geometry: satPoint,
      symbol: new TextSymbol({
        color: 'white',
        haloColor: '#000000',
        haloSize: '1px',
        text: 'Equator',
        xoffset: 45,
        yoffset: 5,
        font: {
          size: 12
        }
      })
    });
  }
  const equatorPoint = satPoint.clone();
  equatorPoint.longitude = equatorPoint.longitude + 5;
  equatorPoint.latitude = equatorPoint.latitude + 0.7;

  return new Graphic({
    geometry: equatorPoint,
    symbol: new PointSymbol3D({
      verticalOffset: {
        screenLength: 10
      },
      symbolLayers: [
        new TextSymbol3DLayer({
          size: 12,
          halo: {
            color: '#000000',
            size: '1px'
          },
          text: 'Equator',
          material: {
            color: 'white'
          }
        })
      ]
    })
  });
};
