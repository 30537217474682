import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import { createMission, deleteMission, getMissionDetails, getMissions, updateMission } from 'shared/api/api';
import { Mission, MissionDetails } from 'shared/model/mission.model';

const initialState = {
  loading: false,
  loadingDetails: -1,
  updating: false,
  updateSuccess: false,
  bandFilter: undefined as string | undefined,
  missions: [] as MissionDetails[],
  selectedMissions: [] as Mission[] | MissionDetails[],
  error: null as any
};

export type MissionState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'mission',
  initialState,
  reducers: {
    fetchEntitiesStart: state => {
      state.loading = true;
      state.updateSuccess = false;
      state.error = null;
    },
    fetchEntityStart: (state, action: PayloadAction<number>) => {
      state.loadingDetails = action.payload;
      state.updateSuccess = false;
      state.error = null;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchEntityFailed: (state, action: PayloadAction<any>) => {
      state.loadingDetails = -1;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<MissionDetails[]>) => {
      state.loading = false;
      state.missions = action.payload;
    },
    selectMissions: (state, action: PayloadAction<Mission[] | MissionDetails[]>) => {
      state.loadingDetails = -1;
      state.selectedMissions = action.payload;
    },
    updateStart: state => {
      state.loading = false;
      state.updating = true;
      state.updateSuccess = false;
    },
    updateSuccess: (state, action: PayloadAction<Mission>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.selectedMissions = [action.payload];
      const index = state.missions.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        const newMissions = [...state.missions];
        newMissions.splice(index, 1);
        newMissions.push(action.payload as MissionDetails);
        state.missions = newMissions;
      }
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    selectBandFilter: (state, action: PayloadAction<string | undefined>) => {
      state.bandFilter = action.payload;
    },
    reset: state => {
      state.loading = false;
      state.loadingDetails = -1;
      state.updateSuccess = false;
      state.updating = false;
      state.missions = [];
      state.bandFilter = undefined;
      state.selectedMissions = [];
      state.error = null;
    }
  }
});

const {
  fetchEntitiesStart,
  fetchEntityStart,
  fetchEntitiesFailed,
  fetchEntitiesSuccess,
  fetchEntityFailed,
  updateStart,
  updateFailed,
  updateSuccess,
  deleteSuccess
} = slice.actions;

export const { reset, selectMissions, selectBandFilter } = slice.actions;

export const fetchMissions =
  (projectId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Mission[]> = await getMissions(projectId);
      const missionIds = response.data.map(item => item.id);
      const requests = missionIds.map(id => getMissionDetails(projectId, id));
      const responses = await axios.all(requests);
      const data = responses.map(response => response.data);
      dispatch(fetchEntitiesSuccess(data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const fetchMission =
  (projectId: number | string, missionId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntityStart(Number(missionId)));
      const response = await getMissionDetails(projectId, missionId);
      dispatch(selectMissions([response.data]));
    } catch (error) {
      dispatch(fetchEntityFailed(error));
    }
  };

export const saveMission =
  (projectId: number | string, mission: Mission): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = mission.id ? await updateMission(projectId, mission) : await createMission(projectId, mission);
      dispatch(updateSuccess(response.data));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeMission =
  (projectId: number | string, missionId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteMission(projectId, missionId);
      dispatch(deleteSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
