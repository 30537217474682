import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoverageBeamSettings } from 'shared/model/area.model';
import { setCoverageBeamConfig } from 'shared/reducers/coverageSlice';
import { useDebounce } from 'use-debounce';
import BeamSizeSlider from './sliders/BeamSizeSlider';
import GridOrientationSlider from './sliders/GridOrientationSlider';
import HorizontalOffsetSlider from './sliders/HorizontalOffSetSlider';
import VerticalOffsetSlider from './sliders/VerticalOffSetSlider';

const MultiBeamsEditor = () => {
  const dispatch = useDispatch();
  const coverageBeamConfig = useSelector(({ coverage }: IRootState) => coverage.coverageBeamConfig);
  const computingBeams = useSelector(({ coverage }: IRootState) => coverage.computingBeams);
  const [settings, setSettings] = useState<CoverageBeamSettings>(coverageBeamConfig);
  const [debouncedSettings] = useDebounce(settings, 400);

  const onBeamSizeChange = (beamSize: number) => {
    const newSettings = {
      ...settings,
      size: beamSize
    };
    setSettings(newSettings);
  };

  const onGridOrientationChange = (orientation: number) => {
    const newSettings = {
      ...settings,
      gridOrientation: orientation
    };
    setSettings(newSettings);
  };

  const onHorizontalOffsetChange = (xOffset: number) => {
    const newSettings = {
      ...settings,
      xOffset
    };
    setSettings(newSettings);
  };

  const onVerticalOffsetChange = (yOffset: number) => {
    const newSettings = {
      ...settings,
      yOffset
    };
    setSettings(newSettings);
  };

  useEffect(() => {
    dispatch(setCoverageBeamConfig(debouncedSettings));
  }, [debouncedSettings, dispatch]);

  return (
    <div>
      <div className="mb-4">
        <BeamSizeSlider onChange={onBeamSizeChange} defaultValue={coverageBeamConfig.size} disabled={computingBeams} />
      </div>
      <div className="mb-4">
        <GridOrientationSlider
          onChange={onGridOrientationChange}
          defaultValue={coverageBeamConfig.gridOrientation}
          disabled={computingBeams}
        />
      </div>
      <div className="mb-4">
        <HorizontalOffsetSlider onChange={onHorizontalOffsetChange} defaultValue={coverageBeamConfig.xOffset} disabled={computingBeams} />
      </div>
      <div>
        <VerticalOffsetSlider onChange={onVerticalOffsetChange} defaultValue={coverageBeamConfig.yOffset} disabled={computingBeams} />
      </div>
    </div>
  );
};

export default MultiBeamsEditor;
