import { IRootState } from 'config/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { CoverageType } from 'shared/model/coverage.model';
import MultiBeamsEditor from './MultiBeamsEditor';
import RegionalEditor from './RegionalEditor';
import SteerableBeamsEditor from './SteerableBeamsEditor';

const CoverageAreaEditor = () => {
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);

  if (!coverage) {
    return null;
  }

  if (coverage.coverageType === CoverageType.MULTI_BEAM) {
    return <MultiBeamsEditor />;
  } else if (coverage.coverageType === CoverageType.REGIONAL) {
    return <RegionalEditor />;
  } else if (coverage.coverageType === CoverageType.STEERABLE_BEAM) {
    return <SteerableBeamsEditor />;
  }

  return null;
};

export default CoverageAreaEditor;
