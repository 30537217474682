import classNames from 'classnames';
import { IRootState } from 'config/store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserFrequency } from 'shared/model/mission.model';
import DeleteMissionUserFrequency from './form/DeleteMissionUserFrequency';
import './frequency-item.scss';

interface IFrequencyItemProps {
  item: UserFrequency;
  readonly: boolean;
}

const FrequencyItem = (props: IFrequencyItemProps) => {
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);

  const { item, readonly } = props;

  const [visible, setVisible] = useState(false);

  if (selectedMissions.length !== 1) {
    return null;
  }

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  const bigramme = item.name.charAt(0) + item.name.charAt(1);

  return (
    <div className="d-flex mb-2 align-items-center mb-4 flex-nowrap" onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div className="frequency-avatar mr-2">{bigramme}</div>
      <div className="flex-grow-1 ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mission-form-item-name">{item.name}</div>
          <div className="mission-form-item-action">
            {visible && !readonly && (
              <div
                className={classNames('animate__animated text-nowrap', {
                  animate__fadeIn: visible
                })}
              >
                <DeleteMissionUserFrequency item={item} />
              </div>
            )}
          </div>
        </div>
        <hr className="my-1" />
      </div>
    </div>
  );
};

export default FrequencyItem;
