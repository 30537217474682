import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Mission, UserFrequency } from 'shared/model/mission.model';
import { saveMission } from 'shared/reducers/missionSlice';
import { removeItemByName } from 'shared/utils/array-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeleteMissionUserFrequencyProps {
  item: UserFrequency;
}

const DeleteMissionUserFrequency = (props: IDeleteMissionUserFrequencyProps) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ mission }: IRootState) => mission.updateSuccess);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);

  const { item } = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
  }, [updateSuccess]);

  if (selectedMissions.length !== 1) {
    return null;
  }
  const mission = selectedMissions[0];

  const onClose = (confirm: boolean) => {
    if (confirm) {
      const newUserFrequencies = [...mission.userFrequencies];
      removeItemByName(newUserFrequencies, item.name);

      const toSend: Mission = {
        ...mission,
        userFrequencies: newUserFrequencies
      };

      dispatch(saveMission(mission.projectId, toSend));
    } else {
      setShow(false);
    }
  };
  const open = () => setShow(true);

  const name = item.name;
  const missionName = mission.name;

  return (
    <>
      <ConfirmDeleteDialog show={show} dialogContent={t('mission.terminalsDeleteConfirm', { name, missionName })} onClose={onClose} />
      <SkyTButton variant="outline-primary" className="ml-1 xs" onClick={open} size="sm">
        <FontAwesomeIcon icon="times" size="sm" />
      </SkyTButton>
    </>
  );
};

export default DeleteMissionUserFrequency;
