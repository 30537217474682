import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import areaOfInterestReducer from 'shared/reducers/areaOfInterestSlice';
import areaReducer from 'shared/reducers/areaSlice';
import chartReducer from 'shared/reducers/chartSlice';
import configurationReducer from 'shared/reducers/configurationSlice';
import coverageReducer from 'shared/reducers/coverageSlice';
import customerReducer from 'shared/reducers/customerSlice';
import exportReducer from 'shared/reducers/exportSlice';
import gatewayReducer from 'shared/reducers/GatewaySlice';
import mapReducer from 'shared/reducers/mapSlice';
import missionReducer from 'shared/reducers/missionSlice';
import notificationReducer from 'shared/reducers/notificationSlice';
import organizationReducer from 'shared/reducers/organizationSlice';
import poiReducer from 'shared/reducers/poiSlice';
import projectReducer from 'shared/reducers/projectSlice';
import referentialReducer from 'shared/reducers/referentialSlice';
import requestAccessReducer from 'shared/reducers/requestAccessSlice';
import satelliteReducer from 'shared/reducers/satelliteSlice';
import teamMemberReducer from 'shared/reducers/teamMemberSlice';
import axiosUtils from 'shared/utils/axios-utils';
import loggerMiddleware from 'shared/utils/logger-middleware';
import notificationMiddleware from './notification-middleware';

const configureAppStore = (preloadedState?: any) => {
  const defaultMiddleware = [loggerMiddleware];

  const store = configureStore({
    reducer: {
      customer: customerReducer,
      referential: referentialReducer,
      project: projectReducer,
      requestAccess: requestAccessReducer,
      organization: organizationReducer,
      notification: notificationReducer,
      teamMember: teamMemberReducer,
      map: mapReducer,
      mission: missionReducer,
      configuration: configurationReducer,
      coverage: coverageReducer,
      area: areaReducer,
      exporter: exportReducer,
      poi: poiReducer,
      gateway: gatewayReducer,
      satellite: satelliteReducer,
      areaOfInterest: areaOfInterestReducer,
      charts: chartReducer
    },
    middleware: [
      ...defaultMiddleware,
      ...getDefaultMiddleware({
        immutableCheck: false, //can be set to false in development mode in order to avaoid warning message because the state is too large
        serializableCheck: false
      }),
      notificationMiddleware
    ],
    preloadedState
  });

  return store;
};

export const store = configureAppStore();

axiosUtils.setupInterceptors(store);

export type IRootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, Action<string>>;
