import Lottie from 'shared/widgets/lottie/Lottie';
import data from '../lotties/battery.json';

const frameNb = 100;
const maxValue = 100;

interface IGaugeProps {
  value: number;
}

const BatteryIcon = (props: IGaugeProps) => {
  const { value } = props;

  const end = value <= maxValue ? (value * frameNb) / maxValue : frameNb;

  return <Lottie animationData={data} initialSegment={[0, end]} loop={false} />;
};

export default BatteryIcon;
