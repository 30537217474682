export enum SupportLayerSvgIcons {
  AIRPORTS = 'airports',
  GRID = 'grid',
  PORTS = 'ports',
  PRECIPITATION = 'precipitation',
  RAILS = 'rails',
  RIVERS = 'rivers',
  TIMEZONE = 'timeZone',
  SUBMARINE = 'submarine',
  POPULATION = 'population',
  RAIN = 'rain'
}

export enum ISupportLayerType {
  TILE = 'tileLayer',
  WMS = 'wmsLayer',
  IMAGERY = 'imageryLayer',
  FEATURE = 'featureLayer',
  VECTOR_TILE = 'vectorTileLayer',
  MAP_IMAGE = 'mapImageLayer'
}

export interface ISupportLayer {
  id: string;
  name: string;
  url: string;
  type: ISupportLayerType;
  order: number;
  icon?: any;
  svg?: SupportLayerSvgIcons;
  showInLegend?: boolean;
}

export const supportLayers: ISupportLayer[] = [
  {
    id: 'population',
    name: 'Population',
    url: 'https://sedac.ciesin.columbia.edu/arcgis/rest/services/sedac/gpw_v4_population_density_global_2020_cartographic/MapServer',
    type: ISupportLayerType.TILE,
    order: 0,
    svg: SupportLayerSvgIcons.POPULATION
  },
  {
    id: 'precipitations',
    name: 'Precipitation',
    // url: 'https://earthobs3.arcgis.com/arcgis/rest/services/Mean_Precipitation_WorldClim/ImageServer',
    // type: ISupportLayerType.IMAGERY,
    //url: 'https://earthobs3.arcgis.com/arcgis/rest/services/Mean_Precipitation_WorldClim/ImageServer',
    //type: ISupportLayerType.IMAGERY,
    url: 'https://tiles.arcgis.com/tiles/P8Cok4qAP1sTVE59/arcgis/rest/services/TotalAnnualPrecipitation/MapServer',
    type: ISupportLayerType.TILE,
    order: 1,
    svg: SupportLayerSvgIcons.RAIN
  },
  {
    id: 'submarine_fiber',
    name: 'Submarine Fiber Cable',
    // url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Mercator_Submarine_Fiber_Cables/MapServer',
    // type: ISupportLayerType.TILE,
    url: 'https://services.arcgis.com/bDAhvQYMG4WL8O5o/arcgis/rest/services/Global_Submarine_Cable_Map/FeatureServer/1',
    type: ISupportLayerType.FEATURE,
    order: 2,
    svg: SupportLayerSvgIcons.SUBMARINE
  },
  {
    id: 'rails',
    name: 'Rails',
    // url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Mercator_Rails/MapServer',
    // type: ISupportLayerType.TILE,
    url: 'https://services9.arcgis.com/C1LhdW9uSxO25Nh2/arcgis/rest/services/Global_Mercator_Rails/FeatureServer',
    type: ISupportLayerType.FEATURE,
    order: 3,
    svg: SupportLayerSvgIcons.RAILS
  },
  {
    id: 'ports',
    name: 'Ports',
    // url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Mercator_Ports/MapServer',
    //type: ISupportLayerType.TILE,
    url: 'https://wwf-sight-maps.org/arcgis/rest/services/Global/Ports/MapServer',
    type: ISupportLayerType.MAP_IMAGE,
    order: 4,
    svg: SupportLayerSvgIcons.PORTS
  },
  {
    id: 'airports',
    name: 'Airports',
    url: 'https://wwf-sight-maps.org/arcgis/rest/services/Global/Airports/MapServer',
    type: ISupportLayerType.FEATURE,
    // url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Mercator_Airports/MapServer',
    // type: ISupportLayerType.TILE,
    // url: 'https://services2.arcgis.com/jUpNdisbWqRpMo35/arcgis/rest/services/Airports28062017/FeatureServer',
    // type: ISupportLayerType.FEATURE,
    order: 5,
    svg: SupportLayerSvgIcons.AIRPORTS
  },
  {
    id: 'rivers',
    name: 'Rivers',
    // url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Mercator_Rivers/MapServer',
    // type: ISupportLayerType.TILE,
    url: 'https://services9.arcgis.com/C1LhdW9uSxO25Nh2/arcgis/rest/services/Global_Mercator_Rivers/FeatureServer',
    type: ISupportLayerType.FEATURE,
    order: 6,
    svg: SupportLayerSvgIcons.RIVERS
  },
  {
    id: 'timezone',
    name: 'Time Zone',
    //url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Mercator_Time_Zones/MapServer',
    //type: ISupportLayerType.TILE,
    // not working : Failed to create layerview for layer title:'World Time Zones', id:'timezone' of type 'feature'
    url: 'https://sampleserver6.arcgisonline.com/arcgis/rest/services/WorldTimeZones/MapServer/1',
    type: ISupportLayerType.FEATURE,
    order: 7,
    svg: SupportLayerSvgIcons.TIMEZONE
  },
  {
    id: 'coordinates_grid',
    name: 'Coordinates grid',
    // url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Mercator_Longlat/MapServer',
    // type: ISupportLayerType.TILE,
    // not working : message: "The spatial reference of this layer does not meet the requirements of the view"
    // url: 'https://tiles.arcgis.com/tiles/KdvEfCFsjR0kTCmc/arcgis/rest/services/Layers_Equirectangular_Longlat/VectorTileServer',
    // type: ISupportLayerType.VECTORTILE,
    url: 'https://services9.arcgis.com/C1LhdW9uSxO25Nh2/arcgis/rest/services/Graticules_Mercator/FeatureServer',
    type: ISupportLayerType.FEATURE,
    order: 8,
    svg: SupportLayerSvgIcons.GRID
  }
];
