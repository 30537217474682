import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import flatten from 'lodash/flatten';
import { IDatabaseBeam, IUnderEditionDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { createArea, deleteArea, getArea, getAreas, updateArea } from 'shared/api/api';
import { Area } from 'shared/model/area.model';
import { fetchConfiguration } from './configurationSlice';

export type areaMenuType = 'geometry' | 'services' | 'targets';

const initialState = {
  loading: false,
  loadingDetails: -1,
  updating: false,
  multipleUpdating: false,
  updateSuccess: false,
  areas: [] as Area[],
  area: null as Partial<Area> | null,
  currentMenu: 'geometry' as areaMenuType,
  underEditionBeams: [] as IUnderEditionDatabaseBeam[],
  updatingBeamsSelection: false,
  error: null as any
};

export type AreaState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setArea: (state, action: PayloadAction<Partial<Area> | null>) => {
      state.area = action.payload;
    },
    setCurrentMenu: (state, action: PayloadAction<areaMenuType>) => {
      state.currentMenu = action.payload;
    },
    fetchEntitiesStart: state => {
      state.loading = true;
      state.updateSuccess = false;
    },
    fetchEntityStart: (state, action: PayloadAction<any>) => {
      state.loadingDetails = action.payload;
      state.updateSuccess = false;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.loadingDetails = -1;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<Area[]>) => {
      state.loading = false;
      state.areas = action.payload;
    },
    fetchEntitySuccess: (state, action: PayloadAction<Area>) => {
      state.loadingDetails = -1;
      state.area = action.payload;
    },
    updateStart: state => {
      state.loading = false;
      state.updating = true;
      state.updateSuccess = false;
    },
    updateSuccess: (state, action: PayloadAction<Area>) => {
      state.updating = false;
      state.updateSuccess = true;
      if (!state.multipleUpdating) {
        state.area = action.payload;
      }
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
      state.area = null;
    },
    setUnderEditionBeams: (state, action: PayloadAction<IUnderEditionDatabaseBeam[]>) => {
      state.underEditionBeams = action.payload;
      state.updatingBeamsSelection = false;
    },
    setMultipleUpdating: (state, action: PayloadAction<boolean>) => {
      state.multipleUpdating = action.payload;
    },
    setUpdatingBeamsSelection: (state, action: PayloadAction<IUnderEditionDatabaseBeam[]>) => {
      state.underEditionBeams = action.payload;
      state.updatingBeamsSelection = true;
    },
    reset: state => {
      state.loading = false;
      state.loadingDetails = -1;
      state.updateSuccess = false;
      state.multipleUpdating = false;
      state.updating = false;
      state.areas = [];
      state.area = null;
      state.error = null;
      state.underEditionBeams = [];
      state.updatingBeamsSelection = false;
    }
  }
});

const {
  fetchEntitiesStart,
  fetchEntityStart,
  fetchEntitiesFailed,
  fetchEntitiesSuccess,
  updateStart,
  updateFailed,
  updateSuccess,
  deleteSuccess,
  fetchEntitySuccess,
  setMultipleUpdating
} = slice.actions;

export const { reset, setArea, setCurrentMenu, setUnderEditionBeams, setUpdatingBeamsSelection } = slice.actions;

export const fetchAreas =
  (projectId: number | string, satelliteId: number | string, configurationId: number | string, coverageId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Area[]> = await getAreas(projectId, satelliteId, configurationId, coverageId);
      const areas = response.data;
      areas.forEach(area => {
        //@ts-ignore
        area.beamList = convertBeamList(area.beamList);
      });
      dispatch(fetchEntitiesSuccess(areas));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const fetchAreasOfCoverages =
  (projectId: number | string, satelliteId: number | string, configurationId: number | string, coverageIds: number[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const requests = coverageIds.map(id => getAreas(projectId, satelliteId, configurationId, id));
      const responses = await axios.all(requests);
      const data = responses.map(response => {
        const areas = response.data;
        areas.forEach(area => {
          //@ts-ignore
          area.beamList = convertBeamList(area.beamList);
        });

        return areas;
      });

      dispatch(fetchEntitiesSuccess(flatten(data)));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const fetchArea =
  (
    projectId: number | string,
    satelliteId: number | string,
    configurationId: number | string,
    coverageId: number | string,
    areaId: number | string
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntityStart(areaId));
      const response = await getArea(projectId, satelliteId, configurationId, coverageId, areaId);

      const area = response.data;
      //@ts-ignore
      area.beamList = convertBeamList(area.beamList);

      dispatch(fetchEntitySuccess(area));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const saveArea =
  (
    projectId: number | string,
    satelliteId: number | string,
    configurationId: number | string,
    coverageId: number | string,
    area: Partial<Area>
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = area.id
        ? await updateArea(projectId, satelliteId, configurationId, coverageId, area)
        : await createArea(projectId, satelliteId, configurationId, coverageId, area);

      const newArea = response.data;
      //@ts-ignore
      newArea.beamList = convertBeamList(newArea.beamList);

      dispatch(updateSuccess(newArea));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const saveAreas =
  (
    projectId: number | string,
    satelliteId: number | string,
    configurationId: number | string,
    coverageId: number | string,
    areas: Partial<Area>[],
    fetchConfigurationAfterSave = false
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(setMultipleUpdating(true));
      for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        await dispatch(saveArea(projectId, satelliteId, configurationId, coverageId as number, area));
      }

      if (fetchConfigurationAfterSave) {
        // fetch configuration (in order to reload areas)
        await dispatch(fetchConfiguration(projectId, satelliteId, configurationId));
      }

      dispatch(setMultipleUpdating(false));
    } catch (error) {
      dispatch(setMultipleUpdating(false));
    }
  };

export const removeArea =
  (
    projectId: number | string,
    satelliteId: string | number,
    configurationId: string | number,
    coverageId: string | number,
    areaId: string | number
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteArea(projectId, satelliteId, configurationId, coverageId, areaId);
      dispatch(deleteSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;

// json is a string in database convert it into a databaseBeam array.
const convertBeamList = (beamList?: string): IDatabaseBeam[] => {
  if (beamList) {
    return JSON.parse(beamList);
  }
  return [];
};
