import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Organization } from 'shared/model/organization.model';
import { ProjectDetails } from 'shared/model/project.model';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import CreateOrEditOrganization from './createorEditOrganization';

interface IOrganizationItemProps {
  project: ProjectDetails;
  item: Organization;
}

const OrganizationItem = (props: IOrganizationItemProps) => {
  const { project, item } = props;

  const [visible, setVisible] = useState(false);
  const hasWriteAccess = projectHasWriteAccess(project);

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  return (
    <span key={item.id} onMouseEnter={showActions} onMouseLeave={hideActions}>
      {hasWriteAccess && visible ? (
        <CreateOrEditOrganization key={item.id} organization={item} />
      ) : (
        <Button className="organization-btn mr-2 mb-3 readonly">{item.name}</Button>
      )}
    </span>
  );
};

export default OrganizationItem;
