import round from 'lodash/round';

export const isHtml = (s: string) => {
  return /<\/?[a-z][\s\S]*>/i.test(s);
};

export const displayOrbitalPosition = (position: number, round?: number) => {
  const orbitalPos = Math.abs(position);
  const unit = position < 0 ? 'W' : 'E';
  if (round) {
    return roundOrbitalPosition(orbitalPos) + ' ' + unit;
  }
  return orbitalPos + ' ' + unit;
};

export const roundOrbitalPosition = (position: number) => round(position, 4);
