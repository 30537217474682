import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerLock, fetchGatewayModels, fetchReferential, fetchSatelliteModels } from 'shared/reducers/referentialSlice';
import Loader from 'shared/widgets/loader';
import './loadingReferential.scss';

const LoadingReferential = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const loadingReferential = useSelector(({ referential }: IRootState) => referential.loadingReferential);
  const loadingGatewayModels = useSelector(({ referential }: IRootState) => referential.loadingGatewayModels);
  const loadingSatelliteModels = useSelector(({ referential }: IRootState) => referential.loadingSatelliteModels);
  const loadingCustomerLock = useSelector(({ referential }: IRootState) => referential.loadingCustomerLock);
  const error = useSelector(({ referential }: IRootState) => referential.error);

  useEffect(() => {
    dispatch(fetchReferential());
    dispatch(fetchGatewayModels());
    dispatch(fetchSatelliteModels());
    dispatch(fetchCustomerLock());
  }, [dispatch]);

  return (
    <div className="loading-referential">
      {error ? (
        <Alert variant="danger">
          <FontAwesomeIcon icon="exclamation-triangle" className="mr-1" />
          {t('loadingReferentialError')}
        </Alert>
      ) : (
        <>{(loadingReferential || loadingGatewayModels || loadingSatelliteModels || loadingCustomerLock) && <Loader />}</>
      )}
    </div>
  );
};

export default LoadingReferential;
