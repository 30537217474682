import { IRootState } from 'config/store';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import { useDispatch, useSelector } from 'react-redux';
import { qualityProfileType, setQualityProfile } from 'shared/reducers/mapSlice';
import SkyTSelect, { ILabelValueOption } from 'shared/widgets/form/skyTSelect';
import MeetingMode from './MeetingMode';

const qualityOptions: ILabelValueOption<qualityProfileType>[] = [
  {
    label: 'High',
    value: 'high'
  },
  {
    label: 'Medium',
    value: 'medium'
  },
  {
    label: 'Low',
    value: 'low'
  }
];

const Settings = () => {
  const dispatch = useDispatch();
  const qualityProfile = useSelector(({ map }: IRootState) => map.qualityProfile);

  const qualityDefaultValue = qualityOptions.find(option => option.value === qualityProfile);

  const changeQualityPofile = (option: ILabelValueOption<qualityProfileType>) => {
    dispatch(setQualityProfile(option.value));
  };

  return (
    <>
      <Row>
        <Col lg="4" xl="3">
          <Form.Group className="my-5">
            <Form.Label>3D Quality Profile</Form.Label>
            <SkyTSelect options={qualityOptions} defaultValue={qualityDefaultValue} onChange={changeQualityPofile} />
          </Form.Group>
        </Col>
        <Col lg="4" xl="3">
          <MeetingMode />
        </Col>
      </Row>
    </>
  );
};

export default Settings;
