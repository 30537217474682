import classnames from 'classnames';
import lottie, { AnimationConfigWithData } from 'lottie-web';
import { useEffect, useRef } from 'react';
import './Lottie.scss';

interface ILottieProps extends Partial<AnimationConfigWithData> {
  width?: number;
  height?: number;
  speed?: number;
  className?: string;
}

const Lottie = (props: ILottieProps) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();

  const { width = 54, height = 54, speed = 2, className, ...rest } = props;

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        container: element.current,
        ...rest
      });
      lottieInstance.current.setSpeed(speed);
    }
    return () => {
      if (lottieInstance.current) {
        lottieInstance.current.destroy();
      }
    };
  }, [rest, speed]);

  return <div ref={element} className={classnames('skyt-lottie', className)} style={{ width, height }}></div>;
};

export default Lottie;
