import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { removeCoverage } from 'shared/reducers/coverageSlice';
import { configurationsSatelliteRoute } from 'shared/routes/routes';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

const ResetCoverage = () => {
  const dispatch = useDispatch();
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const updating = useSelector(({ coverage }: IRootState) => coverage.updating);
  const history = useHistory();
  const { t } = useTranslation('translation');

  const { projectId, satelliteId, configurationId } = useParams<{ projectId: string; satelliteId: string; configurationId: string }>();

  const [show, setShow] = useState(false);

  const onClose = (confirm: boolean) => {
    if (confirm) {
      if (coverage?.id) {
        dispatch(removeCoverage(projectId, satelliteId, configurationId, coverage.id));
        history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
      }
    } else {
      setShow(false);
    }
  };

  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} onClose={onClose} dialogContent={t('coverage.confirmReset')} waiting={updating} />
      <SkyTButton type="button" variant="outline-info" onClick={open}>
        <span className="mr-2">{t('coverage.reset')}</span>
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default ResetCoverage;
