import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IRootState } from 'config/store';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputError from 'shared/form/InputError';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Project } from 'shared/model/project.model';
import { fetchProjects, saveProject } from 'shared/reducers/projectSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import SkyTToggleSwitch from 'shared/widgets/SkyTToggleSwitch';

interface ICreateOrEditProjectProps {
  project?: Project;
  onClose?: () => void;
}

const CreateOrEditProject = (props: ICreateOrEditProjectProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const refName = useRef<HTMLInputElement>();
  const selectedCustomer = useSelector(({ customer }: IRootState) => customer.customer);
  const updating = useSelector(({ project }: IRootState) => project.updating);
  const updateSuccess = useSelector(({ project }: IRootState) => project.updateSuccess);
  const { project } = props;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: project?.name,
      isConfidential: project?.isConfidential
    }
  });

  const isNew = project ? false : true;
  const [show, setShow] = useState(false);

  const { ref, ...rest } = register('name', { required: true, maxLength: 255 });

  useEffect(() => {
    reset({
      name: project?.name,
      isConfidential: project?.isConfidential
    });
  }, [project, reset]);

  useEffect(() => {
    if (show && updateSuccess) {
      setShow(false);
      reset({
        name: project?.name,
        isConfidential: project?.isConfidential
      });
      if (isNew) {
        if (selectedCustomer) {
          dispatch(fetchProjects(selectedCustomer.id));
        }
      }
    }
  }, [updateSuccess, show, project, reset, selectedCustomer, dispatch, isNew]);

  const handleClose = () => {
    setShow(false);
    reset({
      name: project?.name,
      isConfidential: project?.isConfidential
    });
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      if (refName.current) {
        refName?.current?.focus();
      }
    }, 200);
  };

  const onSubmit = (data: any) => {
    if (isNew) {
      if (selectedCustomer) {
        dispatch(saveProject(selectedCustomer.id, data));
      }
    } else if (project) {
      const toSave = {
        ...project,
        ...data
      };
      dispatch(saveProject(project.idCustomer, toSave));
    }
  };

  const title = isNew ? t('createProjectTitle') : t('editProjectTitle');
  const subTitle = isNew ? t('createProjectSubTitle') : t('editProjectSubTitle');
  const ok = isNew ? t('create') : t('edit');

  return (
    <>
      <Modal className="skyt-modal" show={show} onHide={handleClose}>
        {show && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>
                <h4>{title}</h4>
                <h6>{subTitle}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left">
              <Form.Group as={Row}>
                <Form.Label column sm="5" className={classNames({ 'text-danger': errors.name })}>
                  {t('name')}
                </Form.Label>
                <Col sm="7">
                  <SkyTTextInput
                    placeholder="Project name"
                    disabled={updating}
                    ref={(e: any) => {
                      ref(e);
                      refName.current = e;
                    }}
                    {...rest}
                  />
                  <InputError name="name" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="6">
                  {t('isConfidential')}
                </Form.Label>
                <Col sm="6" className="text-right">
                  <Controller
                    name="isConfidential"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <SkyTToggleSwitch
                        className="mt-1"
                        name={field.name}
                        ref={field.ref}
                        onChange={field.onChange}
                        checked={field.value as boolean}
                        disabled={updating}
                      />
                    )}
                  />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <SkyTButton onClick={handleClose} disabled={updating}>
                {t('cancel')}
              </SkyTButton>
              <SkyTButton type="submit" disabled={updating}>
                {ok}
              </SkyTButton>
            </Modal.Footer>
          </form>
        )}
      </Modal>
      {isNew ? (
        <SkyTButton variant="outline-primary" className="ml-1" onClick={handleShow}>
          <div className="d-flex justify-content-between align-items-center">
            <span>{t('createProjectBtn')}</span>
            <FontAwesomeIcon icon="cog" />
          </div>
        </SkyTButton>
      ) : (
        <SkyTButton variant="outline-info" size="sm" onClick={handleShow} className="mb-1 mr-1">
          <FontAwesomeIcon icon="pencil-alt" />
        </SkyTButton>
      )}
    </>
  );
};

export default CreateOrEditProject;
