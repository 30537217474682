import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { IExportResult } from 'shared/model/results.model';
import { resultsRoute } from 'shared/routes/routes';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import Loader from 'shared/widgets/loader';
import './result-chart-bar.scss';
import ResultChartSelect from './ResultChartSelect';

interface IResultChartBarProps {
  loading: boolean;
  exportResult: IExportResult;
}

const ResultChartBar = (props: IResultChartBarProps) => {
  const history = useHistory();

  const { projectId } = useParams<{ projectId: string }>();

  const { exportResult, loading } = props;

  const title = exportResult.name;

  const onClick = () => {
    const params = new URLSearchParams();
    params.set('category', exportResult.category);
    history.push({
      pathname: resultsRoute.getPath(projectId),
      search: params.toString()
    });
  };

  return (
    <div className="result-chart-bar">
      <div className="d-flex align-items-center">
        <SkyTButton variant="outline-primary" onClick={onClick} className="mr-2">
          <FontAwesomeIcon icon="angle-left" size="lg" />
        </SkyTButton>
        <h3 className="mb-0">{title}</h3>
      </div>
      <div>{loading ? <Loader /> : <ResultChartSelect exportResult={exportResult} />}</div>
    </div>
  );
};

export default ResultChartBar;
