import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import InputError from 'shared/form/InputError';
import SkyTTextInput from 'shared/form/skyTTextInput';
import useAuthentication from 'shared/landing/useAuthentication';

const ContactForm = () => {
  const { userInfo, databaseUser } = useAuthentication();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: userInfo?.given_name,
      lastName: userInfo?.family_name,
      mail: userInfo?.email,
      phone: databaseUser?.phone,
      company: databaseUser?.company
    }
  });

  const onSubmit = (data: any) => {
    // console.log(data);
  };

  return (
    <Row>
      <Col lg="6" xl="4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="firstName">
            <Form.Label className="font-weight-bold">First name</Form.Label>
            <SkyTTextInput {...register('firstName', { required: true, maxLength: 255 })} />
            <InputError name="firstName" errors={errors} maxLength={255} />
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label className="font-weight-bold">Last name</Form.Label>
            <SkyTTextInput {...register('lastName', { required: true, maxLength: 255 })} />
            <InputError name="lastName" errors={errors} maxLength={255} />
          </Form.Group>

          <Form.Group controlId="mail">
            <Form.Label className="font-weight-bold">Email</Form.Label>
            <SkyTTextInput {...register('mail', { required: true, maxLength: 255 })} />
            <InputError name="mail" errors={errors} maxLength={255} />
          </Form.Group>

          <Form.Group controlId="phone">
            <Form.Label className="font-weight-bold">Phone</Form.Label>
            <SkyTTextInput {...register('phone', { required: true, maxLength: 255 })} />
            <InputError name="phone" errors={errors} maxLength={255} />
          </Form.Group>

          <Form.Group controlId="company">
            <Form.Label className="font-weight-bold">Company</Form.Label>
            <SkyTTextInput {...register('company', { required: true, maxLength: 255 })} />
            <InputError name="company" errors={errors} maxLength={255} />
          </Form.Group>

          {/*<Button variant="primary" type="submit" className="mt-4">
            Save
  </Button>*/}
        </form>
      </Col>
    </Row>
  );
};

export default ContactForm;
