import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toggleMenuPanel } from 'shared/reducers/mapSlice';
import {
  areaOfInteretsRoute,
  areaRoute,
  chartResultRoute,
  configurationsRoute,
  configurationsSatelliteRoute,
  configureAreaOfInteretsRoute,
  configureConfigurationSatelliteRoute,
  configureGatewayRoute,
  configureSatelliteGatewaysRoute,
  configureSatellitePositionRoute,
  configureSatelliteReflectorRoute,
  configureSatelliteTypeRoute,
  coverageRoute,
  coveragesRoute,
  editSatelliteRoute,
  exportsRoute,
  exportsSatelliteRoute,
  gatewayRoute,
  gatewaysRoute,
  missionRoute,
  missionsRoute,
  poiRoute,
  poisRoute,
  projectPageRoute,
  resultsRoute,
  satellitesRoute
} from 'shared/routes/routes';
import { ReactComponent as InformationIcon } from './information.svg';
import { ReactComponent as MeetingIcon } from './meeting.svg';
import './menu.scss';
import { ReactComponent as PlanningIcon } from './planning.svg';
import { ReactComponent as ResultsIcon } from './results.svg';
import { ReactComponent as SatelliteIcon } from './satellite.svg';

interface IMenuItem {
  to: string;
  text: string;
  icon?: IconProp;
  svg?: any;
  match: boolean | undefined;
  onMatch?: () => void;
  disabled?: boolean;
}

const Menu = () => {
  const dispatch = useDispatch();

  const matchProjectPage = useRouteMatch(projectPageRoute.path);

  // pay attention to the order of the items
  const matchFullNeedsPage = useRouteMatch([
    areaRoute.path,
    coverageRoute.path,
    coveragesRoute.path,
    configureConfigurationSatelliteRoute.path,
    configurationsSatelliteRoute.path,
    configureGatewayRoute.path,
    editSatelliteRoute.path,
    configureSatelliteTypeRoute.path,
    configureSatellitePositionRoute.path,
    configureSatelliteGatewaysRoute.path,
    configureSatelliteReflectorRoute.path,
    configureAreaOfInteretsRoute.path,
    missionsRoute.path,
    areaOfInteretsRoute.path,
    poiRoute.path,
    poisRoute.path,
    gatewayRoute.path,
    gatewaysRoute.path,
    missionRoute.path,
    satellitesRoute.path,
    exportsSatelliteRoute.path,
    exportsRoute.path,
    configurationsRoute.path
  ]);

  const matchResultsPage = useRouteMatch([chartResultRoute.path, resultsRoute.path]);

  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const project = useSelector(({ project }: IRootState) => project.project);

  const location = useLocation();
  const history = useHistory();

  const projectId = project?.id;

  const items = useMemo(() => {
    if (!projectId) {
      return [];
    }

    const results: IMenuItem[] = [
      {
        to: projectPageRoute.getPath(projectId),
        match: matchProjectPage?.isExact,
        text: 'Project',
        svg: <InformationIcon height="26px" />
      },
      {
        to: matchFullNeedsPage?.isExact ? location.pathname : missionsRoute.getPath(projectId),
        text: 'Needs',
        svg: <SatelliteIcon height="26px" />,
        match: matchFullNeedsPage?.isExact,
        onMatch: () => {
          if (mapIsDisplayed) {
            dispatch(toggleMenuPanel());
          }
        }
      },
      {
        to: resultsRoute.getPath(projectId),
        text: 'Results',
        svg: <ResultsIcon height="21px" />,
        match: matchResultsPage?.isExact,
        onMatch: () => {
          dispatch(toggleMenuPanel());
        },
        disabled: false
      },
      {
        to: '/clone',
        text: 'Clone',
        svg: <MeetingIcon height="26px" />,
        match: false,
        disabled: true
      },
      {
        to: '/calendar',
        text: 'Calendar',
        svg: <PlanningIcon height="24px" />,
        match: false,
        disabled: true
      }
    ];
    return [...results].reverse();
  }, [
    dispatch,
    location.pathname,
    mapIsDisplayed,
    matchFullNeedsPage?.isExact,
    matchProjectPage?.isExact,
    matchResultsPage?.isExact,
    projectId
  ]);

  const goTo = (item: IMenuItem) => () => {
    if (!item.disabled) {
      if (item.match) {
        if (item.onMatch) {
          item.onMatch();
        }
      } else {
        history.push(item.to);
      }
    }
  };

  if (location.pathname === '/' || !project) {
    return null;
  }

  let active = -1;
  let previousActive = -1;
  let nextActive = -1;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (item.match) {
      active = i;
      previousActive = i - 1;
      nextActive = i + 1;
    }
  }

  if (active < 0) {
    return null;
  }

  return (
    <div className="main-menu">
      <div className="main-menu-container">
        <div className={classnames('first', { 'border-radius': active === 0 })}>
          <div></div>
        </div>
        {items.map((item, i) => {
          const isActive = item.match;

          return (
            <div
              className={classnames({
                'item-active': isActive,
                'item-inactive': !isActive,
                'item-previous': i === previousActive,
                'item-next': i === nextActive,
                'map-is-displayed': mapIsDisplayed,
                'item-disabled': item.disabled
              })}
              key={`menu-item_${i}`}
              onClick={goTo(item)}
            >
              <div className="menu-icon-text">
                {isActive ? item.text : item.icon ? <FontAwesomeIcon icon={item.icon} rotation={90} /> : item.svg}
              </div>
            </div>
          );
        })}
        <div className={classnames('last', { 'border-radius': active === items.length - 1 })}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
