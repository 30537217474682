import classNames from 'classnames';
import { IRootState } from 'config/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IExportResult, resultSectionType } from 'shared/model/results.model';
import { selectCharts } from 'shared/reducers/chartSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSelect, { ILabelValueOption } from 'shared/widgets/form/skyTSelect';

interface IResultChartSelectProps {
  exportResult: IExportResult;
}

const ResultChartSelect = (props: IResultChartSelectProps) => {
  const dispatch = useDispatch();
  const charts = useSelector(({ charts }: IRootState) => charts.charts);
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);

  const { exportResult } = props;

  const [section, setSection] = useState<resultSectionType | undefined>();
  const previousSection = usePrevious(section);

  const options = exportResult.graphList.reduce((agg: ILabelValueOption<string>[], current) => {
    if (current.section === section) {
      const found = charts.find(item => item.name === current.name && current.blobPath === item.blobPath);

      if (found) {
        const other = found.blobPath;
        agg.push({
          label: current.name,
          value: current.jsonName,
          other
        });
      }
    }

    return agg;
  }, []);

  useEffect(() => {
    if (exportResult.sections) {
      setSection(exportResult.sections[0]);
    } else {
      setSection(undefined);
    }
  }, [exportResult]);

  useEffect(() => {
    // if only one options select id (case when section filter exist)
    if (options.length > 0 && previousSection !== section) {
      let toSelect = options.find(opt => opt.label === currentChart?.name);
      if (!toSelect) {
        toSelect = options[0];
      }
      const found = charts.find(item => {
        return item.blobPath === toSelect?.other;
      });

      if (found && found.blobPath !== currentChart?.blobPath) {
        // console.log(found.blobPath);
        dispatch(selectCharts(found));
      }
    }
  }, [charts, dispatch, options, previousSection, section, currentChart]);

  const onChange = (o: ILabelValueOption<string>) => {
    const found = charts.find(item => item.blobPath === o.other);
    if (found) {
      //console.log(found.blobPath);
      dispatch(selectCharts(found));
    } else {
      dispatch(selectCharts(null));
    }
  };

  const onSectionClick = (aSection: resultSectionType) => () => {
    setSection(aSection);
  };

  const value = options.find(o => currentChart?.name === o.label);

  return (
    <div className="d-flex align-items-center">
      {exportResult.sections && (
        <div className="result-section-filter">
          {exportResult.sections.map(item => {
            return (
              <div key={item} className={classNames('chart-grid-filter', { active: item === section })} onClick={onSectionClick(item)}>
                {item}
              </div>
            );
          })}
        </div>
      )}
      {options.length > 1 && <SkyTSelect className="result-selector" options={options} onChange={onChange} value={value} />}
    </div>
  );
};

export default ResultChartSelect;
