import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MissionDetails } from 'shared/model/mission.model';
import { someMissionNotComplete } from 'shared/utils/model-utils';
import './missionWarningIcon.scss';

interface IMissionWarningIconProps {
  mission: MissionDetails;
  size?: SizeProp;
}

const MissionWarningIcon = (props: IMissionWarningIconProps) => {
  const { t } = useTranslation('translation');
  const { mission, size = 'sm' } = props;

  const notComplete = someMissionNotComplete([mission]);

  if (notComplete) {
    return (
      <span title={t('mission.notComplete')} className="mission-warning-icon">
        <FontAwesomeIcon icon="exclamation" size={size} color="#f5a030" />
      </span>
    );
  }
  return null;
};

export default MissionWarningIcon;
