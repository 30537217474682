import classnames from 'classnames';
import { ChangeEvent, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Reflector } from 'shared/model/satellite.model';
import './EditFrequencyBandwidth.scss';

interface IEditFrequencyBandwidthProps {
  item: Partial<Reflector>;
  onFwdChange: (value: number) => void;
  onRtnChange: (value: number) => void;
}

const EditFrequencyBandwidth = (props: IEditFrequencyBandwidthProps) => {
  const { t } = useTranslation('translation');

  const { item } = props;

  const defaultFwd = item.fwdBandwidth ? item.fwdBandwidth : 0;
  const defaultRtn = item.rtnBandwidth ? item.rtnBandwidth : 0;

  const [fwd, setFwd] = useState<number>(defaultFwd);
  const [rtn, setRtn] = useState<number>(defaultRtn);

  const onChange = (isFwd: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.length > 0 ? Number(e.target.value) : 0;
    if (isFwd) {
      setFwd(newValue);
      props.onFwdChange(newValue);
    } else {
      setRtn(newValue);
      props.onRtnChange(newValue);
    }
  };

  return (
    <div className="bandwidth-inputs">
      <InputGroup className={classnames('d-flex align-items-center justify-content-between', { 'text-danger': fwd === 0 })}>
        <div className="bandwidth-input-label">{t('mission.fwdBandwidth')}</div>
        <div className="d-flex align-items-center">
          <SkyTTextInput
            className="bandwidth-input"
            size="sm"
            type="number"
            placeholder={t('mission.fwdBandwidth')}
            value={fwd}
            onChange={onChange(true)}
            min={0}
          />
          <span>&nbsp;Mhz</span>
        </div>
      </InputGroup>

      <InputGroup className={classnames('d-flex align-items-center justify-content-between', { 'text-danger': rtn === 0 })}>
        <div className="bandwidth-input-label">{t('mission.rtnBandwidth')}</div>
        <div className="d-flex align-items-center">
          <SkyTTextInput
            className="bandwidth-input"
            size="sm"
            type="number"
            placeholder={t('mission.rtnBandwidth')}
            value={rtn}
            onChange={onChange(false)}
            min={0}
          />
          <span>&nbsp;Mhz</span>
        </div>
      </InputGroup>
    </div>
  );
};

export default EditFrequencyBandwidth;
