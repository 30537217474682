import { IRootState } from 'config/store';
import LoadingReferential from 'modules/login/loadingReferential';
import { useSelector } from 'react-redux';
import Layout from 'shared/layout/layout';
import useAuthentication from './useAuthentication';

const Landing = () => {
  const { userInfo } = useAuthentication();

  const referential = useSelector(({ referential }: IRootState) => referential.referential);

  // if all good show layout
  if (referential) {
    return <Layout />;
  } else if (userInfo) {
    return <LoadingReferential />;
  }
  return null;
};

export default Landing;
