import Polygon from '@arcgis/core/geometry/Polygon';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { MAX_LONGITUDE } from 'config/beam-contants';
import partition from 'lodash/partition';
import { offset, toABPoint, toLonLat } from './beam-utils';
import { deg2rad } from './math-utils';

export const computeReflector = (lat: number, lng: number, majorAxisA: number, minorAxisB: number, satelliteLng: number) => {
  const centerAB = toABPoint(lat, lng, satelliteLng);

  const points: number[][] = [];
  for (let i = 0; i < 360; i++) {
    const angle = deg2rad(i);

    const a = 0.5 * majorAxisA * Math.cos(angle);
    const b = 0.5 * minorAxisB * Math.sin(angle);

    const pointAB = offset(centerAB, a, b);
    const point = toLonLat(pointAB, satelliteLng);
    points.push(point);
  }

  const initialGeometry = new Polygon({
    rings: [points],
    spatialReference: {
      wkid: 4326
    }
  });

  const minLon = points.reduce((min, current) => {
    return current[0] < min[0] ? current : min;
  })[0];
  const maxLon = points.reduce((max, current) => {
    return current[0] > max[0] ? current : max;
  })[0];

  if (Math.abs(minLon - maxLon) < MAX_LONGITUDE) {
    // Beam isn't going through the -180 or 180° longitude axis, nothing to do
    return initialGeometry;
  }

  const twoPoints = partition(points, point => {
    return point[0] < MAX_LONGITUDE && point[0] > 0;
  });

  return new Polygon({
    rings: twoPoints,
    spatialReference: {
      wkid: 4326
    }
  });
};

export const getSelectorSymbol = () => {
  return new SimpleFillSymbol({
    color: [255, 255, 255, 0.2],
    outline: {
      color: 'white'
    }
  });
};

export const getSelectedSelectorSymbol = () => {
  return new SimpleFillSymbol({
    color: [255, 255, 255, 0.3],
    outline: {
      color: 'white',
      style: 'dash'
    }
  });
};

export const createMoveHandleGeom = (lat: number, lng: number) => {
  const radius = 3;
  const points: number[][] = [];
  for (let i = 0; i < 360; i++) {
    const angle = deg2rad(i);
    const longitude = lng + radius * Math.cos(angle);
    const latitude = lat + radius * Math.sin(angle);

    const point = [longitude, latitude];
    points.push(point);
  }
  return new Polygon({
    rings: [points]
  });
};

export const getMoveHandleSelectorSymbol = () => {
  return new SimpleFillSymbol({
    color: [200, 0, 0, 0.5],
    outline: {
      color: 'red',
      style: 'dash'
    }
  });
};
