import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import CreateOrEditProject from 'modules/projects/createorEditProject';
import { useMemo, useState } from 'react';
import Card from 'react-bootstrap/esm/Card';
import { useSelector } from 'react-redux';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import './AddCustomerOrProjectMenu.scss';
import CreateOrEditCustomer from './createorEditCustomer';

const AddCustomerOrProjectMenu = () => {
  const selectedCustomer = useSelector(({ customer }: IRootState) => customer.customer);

  const [show, setShow] = useState(false);

  const showMenu = () => {
    setShow(true);
  };

  const hideMenu = () => {
    setShow(false);
  };

  const items = useMemo(() => {
    const result = [
      {
        key: 'customer',
        component: <CreateOrEditCustomer onClose={hideMenu} />
      }
    ];

    if (selectedCustomer) {
      result.push({
        key: 'project',
        component: <CreateOrEditProject onClose={hideMenu} />
      });
    }

    return result;
  }, [selectedCustomer]);

  return (
    <>
      {show && (
        <Card className="add-customer-or-project-menu" onMouseLeave={hideMenu}>
          <div className="add-items">
            {items.map(item => (
              <div key={item.key} className="add-item">
                {item.component}
              </div>
            ))}
          </div>
          <SkyTButton variant="outline-primary" className="pt-1 pb-0 pr-2" onClick={hideMenu}>
            <FontAwesomeIcon icon="plus" style={{ fontSize: '20px' }} />
          </SkyTButton>
        </Card>
      )}
      <SkyTButton variant="outline-primary" className="ml-1" onClick={showMenu}>
        <FontAwesomeIcon icon="plus" style={{ fontSize: '20px' }} />
      </SkyTButton>
    </>
  );
};

export default AddCustomerOrProjectMenu;
