import classnames from 'classnames';
import { IRootState } from 'config/store';
import React, { SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { ProjectDetails } from 'shared/model/project.model';
import { fetchMission, fetchMissions } from 'shared/reducers/missionSlice';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import CreateOrEditMission from './createorEditMission';
import DeleteMissionBtn from './deleteMissionBtn';
import './missions.scss';
import MissionWarningIcon from './shared/missionWarningIcon';

interface IMissionItemProps {
  projectId: string | number;
  mission: Mission;
}

const MissionItem = (props: IMissionItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector(({ project }: IRootState) => project.project);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const hasWriteAccess = projectHasWriteAccess(project as ProjectDetails);

  const [visible, setVisible] = useState(false);

  const { projectId, mission } = props;

  const selected = selectedMissions.some(selected => selected.id === mission.id);

  const onClick = (e: SyntheticEvent) => {
    if (!selected) {
      dispatch(fetchMission(projectId, mission.id));
      const newHistory = `/project/${projectId}/mission/${mission.id}${history.location.search}`;
      history.push(newHistory);
    }
  };

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  const onEdit = () => {
    dispatch(fetchMissions(projectId));
  };

  return (
    <div
      className={classnames('mission-item hover-white', { selected })}
      onClick={onClick}
      onMouseEnter={showActions}
      onMouseLeave={hideActions}
    >
      <div className="ml-2" style={{ color: mission.color }}>
        {mission.name}
        <MissionWarningIcon mission={mission as MissionDetails} size="xs" />
      </div>
      {visible && hasWriteAccess && (
        <div
          className={classnames('mission-items animate__animated', {
            animate__fadeIn: visible
          })}
        >
          <CreateOrEditMission mission={mission} onCreateOrEdit={onEdit} />
          <DeleteMissionBtn mission={mission} />
        </div>
      )}
    </div>
  );
};

export default MissionItem;
