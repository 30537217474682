import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Mission } from 'shared/model/mission.model';
import { ProjectDetails } from 'shared/model/project.model';
import { createConfigurationAndAddCoverage, fetchConfiguration, updateConfigurationAndCoverages } from 'shared/reducers/configurationSlice';
import { fetchCoverages } from 'shared/reducers/coverageSlice';
import { configurationsSatelliteRoute, coveragesRoute } from 'shared/routes/routes';
import { isNumeric } from 'shared/utils/math-utils';
import { filterMissionsWithSat } from 'shared/utils/model-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';
import './ConfigureSatelliteConfiguration.scss';

const CreateOrEditSatelliteConfiguration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;

  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const loading = useSelector(({ mission }: IRootState) => mission.loading);
  const updating = useSelector(({ configuration }: IRootState) => configuration.updating);
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const coverages = useSelector(({ coverage }: IRootState) => coverage.coverages);
  const loadingCoverages = useSelector(({ coverage }: IRootState) => coverage.loading);

  const { projectId, satelliteId, configurationId } = useParams<{ projectId: string; satelliteId: string; configurationId: string }>();

  const [name, setName] = useState('');
  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);

  const satellite = project ? project.satellites.find(item => item.id === Number(satelliteId)) : undefined;

  const isNew = !isNumeric(configurationId);

  useEffect(() => {
    if (isNumeric(configurationId)) {
      dispatch(fetchConfiguration(projectId, satelliteId, configurationId));
      dispatch(fetchCoverages(projectId, satelliteId, configurationId));
    }
  }, [configurationId, dispatch, projectId, satelliteId]);

  useEffect(() => {
    if (!isNew) {
      const toSelect = missions.filter(item => coverages.some(coverage => coverage.missionId === item.id));
      setSelectedMissions(toSelect);
    }
  }, [coverages, isNew, missions]);

  useEffect(() => {
    if (!isNew && configuration) {
      setName(configuration.name);
    }
  }, [configuration, isNew]);

  if (!satellite) {
    return null;
  }

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onSelectMission = (mission: Mission) => () => {
    const index = selectedMissions.findIndex(item => item.id === mission.id);
    const newSelection = [...selectedMissions];
    if (index === -1) {
      newSelection.push(mission);
    } else {
      newSelection.splice(index, 1);
    }
    setSelectedMissions(newSelection);
  };

  const onCancel = () => {
    history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
  };

  const onSave = async () => {
    if (isNew) {
      const result = await dispatch(createConfigurationAndAddCoverage(projectId, satelliteId, name, selectedMissions));

      // @ts-ignore
      const configurationId = result as number;
      history.push(coveragesRoute.getPath(projectId, satelliteId, configurationId));
    } else if (configuration) {
      const updatedConfiguration = {
        ...configuration,
        name
      };
      await dispatch(updateConfigurationAndCoverages(projectId, satelliteId, updatedConfiguration, coverages, selectedMissions));
      history.push(coveragesRoute.getPath(projectId, satelliteId, configuration.id));
    }
  };

  const canSave = selectedMissions.length > 0 && name.length > 0 && !updating;

  const filteredMissions = filterMissionsWithSat(missions, satellite);

  return (
    <div className="create-edit-satellite-configuration">
      <div className="create-edit-satellite-configuration-content">
        <Form.Group className="mt-2">
          <Form.Label>{t('configuration.labelName')}</Form.Label>
          <SkyTTextInput value={name} onChange={onNameChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t('configuration.selectMissionsLabel')}</Form.Label>
          <div className="missions">
            {loading || loadingCoverages ? (
              <Loader />
            ) : (
              <>
                {filteredMissions.map(item => (
                  <div
                    key={`mission_${item.id}`}
                    className={classnames('mission-item', {
                      selected: selectedMissions.some(anItem => anItem.id === item.id)
                    })}
                    onClick={onSelectMission(item)}
                  >
                    {item.name}
                  </div>
                ))}
              </>
            )}
          </div>
        </Form.Group>
      </div>
      <hr />
      <div className="d-flex justify-content-around">
        <SkyTMapButton variant="outline-info" type="button" onClick={onCancel} disabled={updating}>
          {t('cancel')}
          <FontAwesomeIcon icon="times" className="ml-1" />
        </SkyTMapButton>
        <SkyTMapButton variant="outline-info" type="button" onClick={onSave} disabled={!canSave}>
          {t('done')}
          <FontAwesomeIcon icon="check" className="ml-1" />
        </SkyTMapButton>
      </div>
    </div>
  );
};

export default CreateOrEditSatelliteConfiguration;
