import classnames from 'classnames';
import { IRootState } from 'config/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionSatellite } from 'shared/reducers/satelliteSlice';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';

const SelectReflectorFrequencyBand = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);

  const bands = useMemo(() => {
    if (referential?.satelliteZooming) {
      const result = referential.satelliteZooming.reduce((agg: string[], current) => {
        if (!agg.includes(current.band)) {
          agg.push(current.band);
        }
        return agg;
      }, []);
      result.sort((b1, b2) => b1.localeCompare(b2));
      return result;
    }
    return [];
  }, [referential]);

  if (bands.length === 0) {
    return null;
  }

  const onBandChange = (band: string) => () => {
    if (underEditionSatellite) {
      const underEdition = {
        ...underEditionSatellite,
        reflector: {
          band
        }
      };
      dispatch(setUnderEditionSatellite(underEdition));
    }
  };

  const selectedBand = underEditionSatellite?.reflector?.band;

  return (
    <div className="select-reflector-frequency-band">
      <div className="font-weight-bold">{t('satellite.reflectors.freqBand')}</div>
      <div className="d-flex justify-content-around mt-2">
        {bands.map(band => (
          <SkyTMapButton
            key={band}
            variant="outline-info"
            size="sm"
            className={classnames('reflector-select-config', { selected: selectedBand === band })}
            onClick={onBandChange(band)}
          >
            {band}
          </SkyTMapButton>
        ))}
      </div>
    </div>
  );
};

export default SelectReflectorFrequencyBand;
