import { IRootState } from 'config/store';
import ProjectsList from 'modules/projects/projects-list';
import 'modules/projects/projects.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomer } from 'shared/reducers/customerSlice';
import ImageFromUrl from 'shared/widgets/images/ImageFromUrl';
import Loader from 'shared/widgets/loader';
import './customer-projects-page.scss';

const CustomerProjectsPage = () => {
  const dispatch = useDispatch();

  const customerLock = useSelector(({ referential }: IRootState) => referential.customerLock);
  const lockingCustomer = useSelector(({ referential }: IRootState) => referential.lockingCustomer);
  const selectedCustomer = useSelector(({ customer }: IRootState) => customer.customer);

  useEffect(() => {
    if (customerLock) {
      dispatch(selectCustomer(customerLock));
    }
  }, [customerLock, dispatch]);

  if (!customerLock || selectedCustomer !== customerLock) {
    return null;
  }

  return (
    <div className="projects-page customer-projects-page">
      <div className="customer-logo">
        <ImageFromUrl url={customerLock.iconUrl as string} />
        {!customerLock.isOperator && <span className="customer-name">{customerLock.name}</span>}
      </div>
      {lockingCustomer ? <Loader /> : <ProjectsList />}
    </div>
  );
};

export default CustomerProjectsPage;
