import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import { createExport, getExports } from 'shared/api/api';
import { Export } from 'shared/model/export.model';
import { Mission } from 'shared/model/mission.model';

const initialState = {
  exporting: -1 as number,
  loading: false,
  exportingSuccess: false,
  exports: [] as Export[],
  export: null as Export | null,
  mission: null as Partial<Mission> | null,
  error: null as any
};

export type ExportState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'exporter',
  initialState,
  reducers: {
    fetchEntitiesStart: state => {
      state.loading = true;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<Export[]>) => {
      state.loading = false;
      state.exports = action.payload;
    },
    exportStart: (state, action: PayloadAction<number>) => {
      state.exporting = action.payload;
      state.exportingSuccess = false;
    },
    exportFailed: (state, action: PayloadAction<any>) => {
      state.exporting = -1;
      state.exportingSuccess = false;
      state.error = action.payload;
    },
    exportingSuccess: (state, action: PayloadAction<Export>) => {
      state.exporting = -1;
      state.exportingSuccess = true;
      state.export = action.payload;
    },
    selectExport: (state, action: PayloadAction<Export | null>) => {
      state.export = action.payload;
    },
    selectMission: (state, action: PayloadAction<Partial<Mission> | null>) => {
      state.mission = action.payload;
    },
    reset: state => {
      state.loading = false;
      state.exporting = -1;
      state.exportingSuccess = false;
      state.exports = [];
      state.export = null;
      state.mission = null;
      state.error = null;
    }
  }
});

const { fetchEntitiesStart, fetchEntitiesFailed, fetchEntitiesSuccess, exportStart, exportFailed, exportingSuccess } = slice.actions;

export const { reset, selectExport, selectMission } = slice.actions;

export const fetchExports =
  (projectId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Export[]> = await getExports(projectId);
      dispatch(fetchEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const exportConfiguration =
  (projectId: number | string, configurationId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(exportStart(configurationId));
      const response = await createExport(projectId, configurationId);
      dispatch(exportingSuccess(response.data));
    } catch (error) {
      dispatch(exportFailed(error));
    }
  };

export default slice.reducer;
