import classnames from 'classnames';
import { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { User } from 'shared/model/user.model';
import { getFullName } from 'shared/utils/model-utils';
import CreateOrEditTeamMember from './createOrEditTeamMember';
import DeleteTeamMember from './DeleteTeamMember';
import './teamMembers.scss';
import UserAvatar from './userAvatar';

interface ITeamMemberProps {
  user: User;
  readonly: boolean;
}

const TeamMember = (props: ITeamMemberProps) => {
  const { user, readonly } = props;

  const [visible, setVisible] = useState(false);

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  return (
    <ListGroup.Item className="team-member d-flex align-items-center pr-2" onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div className="mr-2">
        <UserAvatar user={user} />
      </div>
      <div className="content">
        <div>
          <div className="user-name" title={getFullName(user)}>
            {getFullName(user)}
          </div>
          <div className="user-company" title={user.company}>
            {user.company}
          </div>
        </div>
        {visible && !readonly && (
          <div
            className={classnames('animate__animated ml-xl-2 text-nowrap', {
              animate__fadeIn: visible
            })}
          >
            <CreateOrEditTeamMember teamMember={user} />
            <DeleteTeamMember user={user} onModalClose={hideActions} />
          </div>
        )}
      </div>
    </ListGroup.Item>
  );
};

export default TeamMember;
