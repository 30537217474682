import classnames from 'classnames';
import { IRootState } from 'config/store';
import {
  beamCIDef,
  beamEirpDef,
  beamEirpDensityDef,
  beamFreqPlanOccupancyRatioDef,
  beamGTDef
} from 'modules/map/layers/beams/databaseBeam.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BandPerBeamSlider from '../sliders/BandPerBeamSlider';
import CISlider from '../sliders/CISlider';
import EIRPDensitySlider from '../sliders/EIRPDensitySlider';
import EIRPSlider from '../sliders/EIRPSlider';
import FreqPlanOccRatioSlider from '../sliders/FreqPlanOccRatioSlider';
import GTSlider from '../sliders/GTSlider';

const EditTargetPayload = () => {
  const { t } = useTranslation('translation');
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);

  const disabled = underEditionBeams.every(item => !item.selected);

  let defaultForwardBandPerBeam = 100;
  let defaultEirp = beamEirpDef.defaultValue;
  let defaultEirpDensity = beamEirpDensityDef.defaultValue;
  let defaultForwardCI = beamCIDef.defaultValue;
  let defaultReturnBandPerBeam = 0;
  let defaultGT = beamGTDef.defaultValue;
  let defaultReturnCI = beamCIDef.defaultValue;
  let defaultForwardFreqPlanOccRatio = beamFreqPlanOccupancyRatioDef.defaultValue;
  let defaultReturnFreqPlanOccRatio = beamFreqPlanOccupancyRatioDef.defaultValue;

  const beamsToCheck = underEditionBeams.filter(beam => beam.selected);
  if (beamsToCheck.length > 0) {
    const firstBeam = beamsToCheck[0];
    const firstTx = firstBeam.tx;
    if (firstTx) {
      if (beamsToCheck.every(item => item.tx?.b === firstTx.b)) {
        defaultForwardBandPerBeam = firstTx.b;
      }
      if (beamsToCheck.every(item => item.tx?.e === firstTx.e)) {
        defaultEirp = firstTx.e;
      }
      if (beamsToCheck.every(item => item.tx?.c === firstTx.c)) {
        defaultForwardCI = firstTx.c;
      }
      if (beamsToCheck.every(item => item.tx?.d === firstTx.d)) {
        defaultEirpDensity = firstTx.d as number;
      }
      if (beamsToCheck.every(item => item.tx?.r === firstTx.r)) {
        defaultForwardFreqPlanOccRatio = firstTx.r as number;
      }
    }

    const firstRx = firstBeam.rx;
    if (firstRx) {
      if (beamsToCheck.every(item => item.rx?.b === firstRx.b)) {
        defaultReturnBandPerBeam = firstRx.b;
      }
      if (beamsToCheck.every(item => item.rx?.g === firstRx.g)) {
        defaultGT = firstRx.g;
      }
      if (beamsToCheck.every(item => item.rx?.c === firstRx.c)) {
        defaultReturnCI = firstRx.c;
      }
      if (beamsToCheck.every(item => item.rx?.r === firstRx.r)) {
        defaultReturnFreqPlanOccRatio = firstRx.r as number;
      }
    }
  }

  const forwardLinkInError =
    !disabled && (typeof defaultEirpDensity === 'undefined' || typeof defaultForwardFreqPlanOccRatio === 'undefined');
  const returnLinkInError = !disabled && typeof defaultReturnFreqPlanOccRatio === 'undefined';

  return (
    <div className="">
      <div className={classnames('text-center mb-2 font-weight-bold', { 'text-danger': forwardLinkInError })}>
        {t('area.targets.forwardLink')}
      </div>
      <BandPerBeamSlider forward disabled={disabled} defaultValue={defaultForwardBandPerBeam} />
      <EIRPSlider disabled={disabled} defaultValue={defaultEirp} />
      <CISlider forward disabled={disabled} defaultValue={defaultForwardCI} />
      <EIRPDensitySlider disabled={disabled} defaultValue={defaultEirpDensity} />
      <FreqPlanOccRatioSlider disabled={disabled} defaultValue={defaultForwardFreqPlanOccRatio} forward />

      <div className={classnames('text-center my-2 font-weight-bold', { 'text-danger': returnLinkInError })}>
        {t('area.targets.returnLink')}
      </div>
      <BandPerBeamSlider forward={false} disabled={disabled} defaultValue={defaultReturnBandPerBeam} />
      <GTSlider disabled={disabled} defaultValue={defaultGT} />
      <CISlider forward={false} disabled={disabled} defaultValue={defaultReturnCI} />
      <FreqPlanOccRatioSlider disabled={disabled} defaultValue={defaultReturnFreqPlanOccRatio} forward={false} />
    </div>
  );
};

export default EditTargetPayload;
