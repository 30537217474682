export enum CoverageType {
  STEERABLE_BEAM = 'STEERABLE_BEAM',
  MULTI_BEAM = 'MULTI_BEAM',
  GLOBAL = 'GLOBAL',
  REGIONAL = 'REGIONAL'
}

export interface Coverage {
  id: number;
  configurationId: number;
  createdAt: Date;
  missionId: number;
  coverageType?: CoverageType;
  details?: string;
}
