import '@arcgis/core/assets/esri/themes/light/main.css';
import { AuthenticationResult, Configuration, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import 'animate.css/animate.min.css';
import { getClientId, getRedirectUri, getScichartKey, getTenant } from 'config/env';
import 'config/i18n';
import { loadIcons } from 'config/icon-loader';
import { store } from 'config/store';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import App from './App';
import './index.scss';

loadIcons();

const configuration: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${getTenant()}`,
    redirectUri: getRedirectUri(),
    clientId: getClientId()
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

const msalInstance = new PublicClientApplication(configuration);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// init sciChart
SciChartSurface.configure({
  wasmUrl: '/assets/scichart/scichart2d.wasm',
  dataUrl: '/assets/scichart/scichart2d.data'
});
SciChartSurface.setRuntimeLicenseKey(getScichartKey());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App pca={msalInstance} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
