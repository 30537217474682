export const deg2rad = (deg: number) => deg * (Math.PI / 180);

export const rad2deg = (rad: number) => rad * (180 / Math.PI);

export const getMB = (bytes: number) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  return Math.round(bytes / megabyte);
};

export const isNumeric = (str: any) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const round2Decimal = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;
