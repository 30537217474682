import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Satellite } from 'shared/model/satellite.model';
import { fetchProject } from 'shared/reducers/projectSlice';
import { removeSatellite } from 'shared/reducers/satelliteSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeleteSatelliteBtnProps {
  satellite: Satellite;
}

const DeleteSatelliteBtn = (props: IDeleteSatelliteBtnProps) => {
  const { satellite } = props;
  const dispatch = useDispatch();
  const updating = useSelector(({ satellite }: IRootState) => satellite.updating);
  const { projectId } = useParams<{ projectId: string }>();

  const [show, setShow] = useState(false);

  const onClose = async (confirm: boolean) => {
    if (confirm) {
      await dispatch(removeSatellite(projectId, satellite.id));
      setShow(false);
      dispatch(fetchProject(projectId));
    } else {
      setShow(false);
    }
  };
  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} onClose={onClose} waiting={updating} />
      <SkyTButton variant="link" onClick={open} size="sm" className="text-primary">
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default DeleteSatelliteBtn;
