import { ISupportLayer, ISupportLayerType } from './supportLayer';

export const baseLayers: ISupportLayer[] = [
  {
    id: 'World_Boundaries_and_Places',
    name: 'World_Boundaries_and_Places',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer',
    type: ISupportLayerType.TILE,
    order: -1,
    showInLegend: false
  }
];
