import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import moment from 'moment';
import { useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useAuthentication from 'shared/landing/useAuthentication';
import { ProjectDetails } from 'shared/model/project.model';
import { fetchMyNotifications, fetchProjectNotifications } from 'shared/reducers/notificationSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import Loader from 'shared/widgets/loader';
import NoResult from 'shared/widgets/no-result';
import NotificationItem from './notificationItem';
import './notifications.scss';

const style = { height: '435px' };
interface INotificationsProps {
  project: ProjectDetails;
}

const Notifications = (props: INotificationsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notifications = useSelector(({ notification }: IRootState) => notification.notifications);
  const loading = useSelector(({ notification }: IRootState) => notification.loading);
  const { userInfo } = useAuthentication();
  const { project } = props;

  const load = () => {
    if (userInfo && project.id) {
      const isTeamMember = project.teamMembers.some(member => member.mail === userInfo.email);
      if (isTeamMember) {
        dispatch(fetchProjectNotifications(project.id));
      } else {
        const from = moment().subtract(2, 'month');
        dispatch(fetchMyNotifications(from));
      }
    }
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectNotifications = notifications.filter(item => item.projectId === project.id);

  return (
    <div>
      <div className="mb-3 d-flex">
        <h4 className="font-weight-bold">{t('notifications.title')}</h4>
        {notifications.length > 0 && (
          <div className="ml-4">
            <Badge pill variant="info" className="mt-1">
              {notifications.length}
            </Badge>
          </div>
        )}
      </div>
      {loading ? (
        <div className="my-4">
          <Loader />
        </div>
      ) : (
        <div>
          <Scrollbars autoHide style={style} className="notifications skyt-scroll-bar">
            {projectNotifications.map(item => (
              <NotificationItem key={item.id} item={item} onUpdateSuccess={load} />
            ))}
          </Scrollbars>
          {projectNotifications.length === 0 && <NoResult />}
        </div>
      )}
      <div className="text-center">
        <SkyTButton size="sm" variant="outline-info" onClick={load} disabled={loading}>
          <span>{t('translation:notifications.load')}</span>
          <FontAwesomeIcon icon="plus" className="ml-2" />
        </SkyTButton>
      </div>
    </div>
  );
};

export default Notifications;
