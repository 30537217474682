import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IRootState } from 'config/store';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import InputError from 'shared/form/InputError';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Mission } from 'shared/model/mission.model';
import { saveMission } from 'shared/reducers/missionSlice';
import { defaultMissionColors } from 'shared/utils/color-utils';
import { getNextMissionLetter } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';

interface ICreateOrEditMissionProps {
  mission?: Mission;
  onCreateOrEdit: () => void;
}

const CreateOrEditMission = (props: ICreateOrEditMissionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const refName = useRef<HTMLInputElement>();
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const existingMissions = useSelector(({ mission }: IRootState) => mission.missions);
  const updating = useSelector(({ mission }: IRootState) => mission.updating);

  const { mission } = props;

  let missionColors = defaultMissionColors;
  if (referential?.missionColors && referential.missionColors.length > 0) {
    missionColors = referential.missionColors.map(item => item.color);
  }

  const { projectId } = useParams<{ projectId: string }>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      name: mission?.name
    }
  });

  const isNew = mission ? false : true;
  const [show, setShow] = useState(false);

  useEffect(() => {
    reset({
      name: mission?.name
    });
  }, [mission, reset]);

  const { ref, ...rest } = register('name', { required: true, maxLength: 255 });

  const handleClose = () => {
    setShow(false);
    reset({
      name: mission?.name
    });
  };
  const handleShow = (e: SyntheticEvent) => {
    setShow(true);
    setTimeout(() => {
      if (refName.current) {
        refName?.current?.focus();
      }
    }, 200);
  };

  const onSubmit = async (data: any) => {
    let toSend: Mission = { ...mission, ...data };
    if (isNew) {
      const missionLetter = getNextMissionLetter(existingMissions);
      toSend = {
        ...data,
        isCivil: true,
        userTerminals: [],
        userFrequencies: [],
        isFlexible: true,
        services: [],
        missionLetter,
        color: missionColors[existingMissions.length]
      };
    }

    await dispatch(saveMission(projectId, toSend));
    handleClose();
    props.onCreateOrEdit();
  };

  const title = isNew ? t('mission.createTitle') : t('mission.editTitle');
  const subTitle = isNew ? t('mission.createSubTitle') : t('mission.editSubTitle');
  const ok = isNew ? t('create') : t('edit');

  const canAddNewMission = existingMissions.length < missionColors.length;

  if (isNew && !canAddNewMission) {
    return null;
  }

  return (
    <>
      <Modal className="skyt-modal" show={show} onHide={handleClose}>
        {show && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>
                <h4>{title}</h4>
                <h6>{subTitle}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left">
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.name })}>
                  {t('mission.name')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput
                    placeholder="Tape a name here"
                    ref={(e: any) => {
                      ref(e);
                      refName.current = e;
                    }}
                    {...rest}
                    disabled={updating}
                  />
                  <InputError name="name" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <SkyTButton type="button" onClick={handleClose} disabled={updating}>
                {t('cancel')}
              </SkyTButton>
              <SkyTButton type="submit" disabled={updating || !isDirty}>
                {ok}
              </SkyTButton>
            </Modal.Footer>
          </form>
        )}
      </Modal>
      {isNew ? (
        <SkyTButton size="sm" type="button" variant="outline-info" className="ml-1" onClick={handleShow}>
          <span>{t('mission.createBtn')}</span>
          <FontAwesomeIcon icon="plus" className="ml-2" />
        </SkyTButton>
      ) : (
        <SkyTButton size="sm" type="button" variant="link" onClick={handleShow}>
          <FontAwesomeIcon icon="pencil-alt" />
        </SkyTButton>
      )}
    </>
  );
};

export default CreateOrEditMission;
