// import webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Point from '@arcgis/core/geometry/Point';
import { lngLatToXY, webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { IRootState } from 'config/store';
import debounce from 'lodash/debounce';
import { poiMarkerSymbol, tempPoiLayer } from 'modules/map/layers/poi/poi-layer-def';
import { currentView } from 'modules/map/map';
import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { POI } from 'shared/model/poi.model';
import { ProjectDetails } from 'shared/model/project.model';
import { showMap } from 'shared/reducers/mapSlice';
import { savePoi, setUnderEditionFeature } from 'shared/reducers/poiSlice';
import { poisRoute, projectPageRoute } from 'shared/routes/routes';
import { createPoiGraphic } from 'shared/utils/map-utils';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';
import './create-poi-gateway.scss';

let sketchViewModelPoi: SketchViewModel | undefined;

const EditPoi = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');

  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const loading = useSelector(({ project }: IRootState) => project.loading);
  const underEditionFeature = useSelector(({ poi }: IRootState) => poi.underEditionFeature);
  const updating = useSelector(({ poi }: IRootState) => poi.updating);
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);

  const hasWriteAccess = projectHasWriteAccess(project);
  const { projectId, poiId } = useParams<{ projectId: string; poiId: string }>();

  const [name, setName] = useState('');

  const pois = project?.pois ?? [];
  const poi = pois.find(item => item.id === Number(poiId));

  const isNew = poiId === 'new';

  useEffect(() => {
    tempPoiLayer.removeAll();
    arcgisMap?.add(tempPoiLayer);
    if (poi) {
      setName(poi.name);
      currentView?.goTo({
        center: [poi.longitude, poi.latitude],
        zoom: 8
      });
      const graphic = createPoiGraphic(poi.longitude, poi.latitude, poi.name, poi.id, true, true);
      tempPoiLayer.add(graphic);
      dispatch(setUnderEditionFeature(graphic));
      if (currentView) {
        sketchViewModelPoi = new SketchViewModel({
          layer: tempPoiLayer,
          view: currentView,
          defaultUpdateOptions: {
            enableZ: false
          }
        });

        const updateUnderEditionFeature = debounce((e: any) => {
          if (e.state === 'active') {
            const moved = e.graphics[0].clone();
            dispatch(setUnderEditionFeature(moved));
          }
        }, 200);

        sketchViewModelPoi.on('update', updateUnderEditionFeature);
        sketchViewModelPoi.update(graphic);
      }
    } else if (isNew && currentView) {
      sketchViewModelPoi = new SketchViewModel({
        layer: tempPoiLayer,
        view: currentView,
        defaultCreateOptions: {
          hasZ: false
        }
      });

      sketchViewModelPoi.on('create', (event: __esri.SketchViewModelCreateEvent) => {
        if (event.graphic) {
          event.graphic.symbol = poiMarkerSymbol;
          dispatch(setUnderEditionFeature(event.graphic));
        }
      });

      sketchViewModelPoi.on('delete', (event: __esri.SketchViewModelDeleteEvent) => {
        dispatch(setUnderEditionFeature(null));
        setName('');
      });

      sketchViewModelPoi.create('point');
    }
  }, [arcgisMap, poi, isNew, dispatch]);

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
    return () => {
      dispatch(setUnderEditionFeature(null));
      tempPoiLayer.removeAll();
      arcgisMap?.remove(tempPoiLayer);
    };
  }, [dispatch, mapIsDisplayed, arcgisMap]);

  if (!project) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  if (!hasWriteAccess) {
    history.push(projectPageRoute.getPath(projectId));
    return null;
  }

  const onNameChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onClose = () => {
    history.push(poisRoute.getPath(projectId));
  };

  const onSave = async () => {
    const existingFeature = tempPoiLayer.graphics.getItemAt(0);
    if (existingFeature) {
      let geom = existingFeature.geometry as Point;

      if (geom instanceof Point) {
        if (!geom.spatialReference.isGeographic) {
          geom = webMercatorToGeographic(geom) as Point;
        }

        let poi: Partial<POI> = {
          name,
          latitude: geom.latitude,
          longitude: geom.longitude
        };
        if (existingFeature?.attributes?.id) {
          // update poi
          poi.id = existingFeature.attributes.id;
        }
        await dispatch(savePoi(projectId, poi));
        onClose();
      }
    }
  };

  const onLongitudeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newLongitude = Number(e.target.value);

    const feature = tempPoiLayer.graphics.getItemAt(0);
    if (feature) {
      const geom = feature.geometry as Point;
      const pos = lngLatToXY(newLongitude, geom.latitude);
      const newGeom = geom.clone();
      newGeom.x = pos[0];
      feature.geometry = newGeom;
      dispatch(setUnderEditionFeature(feature.clone()));
    }
  };

  const onLatitudeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newLatitude = Number(e.target.value);

    const feature = tempPoiLayer.graphics.getItemAt(0);
    if (feature) {
      const geom = feature.geometry as Point;
      const pos = lngLatToXY(geom.longitude, newLatitude);
      const newGeom = geom.clone();
      newGeom.y = pos[1];
      feature.geometry = newGeom;
      dispatch(setUnderEditionFeature(feature.clone()));
    }
  };

  const canSave = name.length > 0 && underEditionFeature && !updating;

  const point = underEditionFeature ? (underEditionFeature.geometry as Point) : undefined;

  const longitude = point ? point.longitude : 0;
  const latitude = point ? point.latitude : 0;

  return (
    <div className="create-poi">
      <div className="create-poi-title">{t(isNew ? 'pois.createPoiBtn' : 'pois.editPoi')}</div>
      <div className="form">
        <Form.Group className="mt-2 mb-1">
          <Form.Label>{t('pois.labelPoiName')}</Form.Label>
          <SkyTTextInput type="text" onChange={onNameChange} value={name} disabled={updating}></SkyTTextInput>
        </Form.Group>
        {isNew && <div className="mt-4 text-center">{t('pois.createPoiHelp')}</div>}
        {underEditionFeature && (
          <div className="mt-4">
            <Form.Group className="mt-2 mb-1">
              <Form.Label>{t('longitude')}</Form.Label>
              <SkyTTextInput type="number" step={0.1} onChange={onLongitudeChange} value={longitude} disabled={updating}></SkyTTextInput>
            </Form.Group>

            <Form.Group className="mt-2 mb-1">
              <Form.Label>{t('latitude')}</Form.Label>
              <SkyTTextInput type="number" step={0.1} onChange={onLatitudeChange} value={latitude} disabled={updating}></SkyTTextInput>
            </Form.Group>
          </div>
        )}
      </div>
      <div className="actions">
        <SkyTMapButton variant="outline-info" type="button" onClick={onClose}>
          {t('cancel')}
        </SkyTMapButton>
        <SkyTMapButton variant="outline-info" type="button" onClick={onSave} disabled={!canSave}>
          {t('save')}
        </SkyTMapButton>
      </div>
    </div>
  );
};

export default EditPoi;
