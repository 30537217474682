import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AreaOfInterest } from 'shared/model/areaOfInterest.model';
import { removeAreaOfInterest } from 'shared/reducers/areaOfInterestSlice';
import { fetchMissions } from 'shared/reducers/missionSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeleteAreaOfInterestBtnProps {
  areOfInterest: AreaOfInterest;
}

const DeleteAreaOfInterestBtn = (props: IDeleteAreaOfInterestBtnProps) => {
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.updateSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const { areOfInterest } = props;
  const { projectId, missionId } = useParams<{ projectId: string; missionId: string }>();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (previousUpdateSuccess === false && updateSuccess && show) {
      setShow(false);
      dispatch(fetchMissions(projectId));
    }
  }, [updateSuccess, show, dispatch, projectId, previousUpdateSuccess]);

  const onClose = (confirm: boolean) => {
    if (confirm) {
      dispatch(removeAreaOfInterest(projectId, missionId, areOfInterest.id));
    } else {
      setShow(false);
    }
  };
  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} onClose={onClose} />
      <SkyTButton variant="link" onClick={open} size="sm" className="text-primary">
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default DeleteAreaOfInterestBtn;
