import { IRootState } from 'config/store';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';

const LegalInformation = () => {
  const referential = useSelector(({ referential }: IRootState) => referential.referential);

  const notice = referential?.legalNotice?.html;
  const html = notice ? DOMPurify.sanitize(notice) : '';

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};

export default LegalInformation;
