import classnames from 'classnames';
import { IRootState } from 'config/store';
import Notifications from 'modules/notifications/notifications';
import Organizations from 'modules/organizations/organizations';
import TeamMembers from 'modules/teamMembers/teamMembers';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { ProjectDetails } from 'shared/model/project.model';
import { fetchProject } from 'shared/reducers/projectSlice';
import { projectHasWriteAccess, projectIsForbidden } from 'shared/utils/model-utils';
import Loading from 'shared/widgets/loader';
import CreateOrEditProject from './createorEditProject';
import DeleteProjectBtn from './deleteProject';
import ProjectDescription from './projectDescription';
import './projects.scss';

const ProjectPage = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();

  const project = useSelector(({ project }: IRootState) => project.project);
  const loading = useSelector(({ project }: IRootState) => project.loading);

  const hasWriteAccess = projectHasWriteAccess(project);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!project || project.id !== Number(projectId)) {
      dispatch(fetchProject(projectId));
    }
  }, [projectId, project, dispatch]);

  if (loading) {
    return (
      <div className="vcenter-loader">
        <Loading />
      </div>
    );
  }

  if (!project) {
    return null;
  }

  if (projectIsForbidden(project)) {
    return <Redirect to="/" />;
  }

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  const projectDetails = project as ProjectDetails;

  return (
    <div className="project-page">
      <Row>
        <Col lg="4" xl="6">
          <div className="d-xl-flex align-items-center" onMouseEnter={showActions} onMouseLeave={hideActions}>
            <h1 className="display-1">{project.name}</h1>
            {visible && hasWriteAccess && (
              <div
                className={classnames('ml-4 animate__animated ml-xl-4 text-nowrap', {
                  animate__fadeIn: visible
                })}
              >
                <CreateOrEditProject project={project} />
                <DeleteProjectBtn project={project} />
              </div>
            )}
          </div>
          <hr className="title-underline" />
          <div className="mt-2">
            <ProjectDescription project={project} />
          </div>
          <div className="mt-4">
            <Organizations project={projectDetails} />
          </div>
        </Col>
        <Col lg="4" xl="3" className="px-xl-0">
          <TeamMembers project={projectDetails} />
        </Col>
        <Col lg="4" xl="3">
          <Notifications project={projectDetails} />
        </Col>
      </Row>
    </div>
  );
};
export default ProjectPage;
