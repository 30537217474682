import classNames from 'classnames';
import { IRootState } from 'config/store';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from './common-slider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    0: <span className={className}>0</span>,
    500: <span className={className}>500</span>,
    1000: <span className={className}>1000</span>,
    1500: <span className={className}>1500</span>,
    2000: <span className={className}>2000</span>,
    2500: <span className={className}>2500</span>,
    3000: <span className={className}>3000</span>
  };
};

interface ITargetCapacityPerBeamSliderProps {
  defaultValue?: number;
  disabled?: boolean;
}

const TargetCapacityPerBeamSlider = (props: ITargetCapacityPerBeamSliderProps) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const { defaultValue, disabled = false } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue);

  useEffect(() => {
    if (!disabled) {
      setValue(defaultValue);
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (previousDebounceValue !== debounceValue && typeof debounceValue !== 'undefined' && !disabled) {
      const newUnderEditionBeams = underEditionBeams.map(underEditionBeam => {
        if (underEditionBeam.selected) {
          const initialSys = underEditionBeam.sys;

          const sys = {
            ...initialSys,
            c: debounceValue
          };

          return {
            ...underEditionBeam,
            sys
          };
        }
        return { ...underEditionBeam };
      });
      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }
  }, [debounceValue, dispatch, previousDebounceValue, underEditionBeams, disabled]);

  const onChange = (e: number) => {
    setValue(e);
  };

  const marks = getMarks(disabled);

  const showMandatoryError = typeof value === 'undefined' && !disabled;

  return (
    <Form.Group className={classNames('common-slider', { 'text-danger': showMandatoryError })}>
      <Form.Label className="d-flex justify-content-center mb-0">{t('area.targets.targetedCapacityPerBeam')}&nbsp;*</Form.Label>
      <SkyTSlider onChange={onChange} marks={marks} min={0} max={3000} value={value} step={100} disabled={disabled} mandatory />
    </Form.Group>
  );
};

export default TargetCapacityPerBeamSlider;
