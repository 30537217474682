import { IRootState } from 'config/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MissionDetails } from 'shared/model/mission.model';
import { saveMission, selectBandFilter } from 'shared/reducers/missionSlice';
import { getAvailableUserFrequencyBands } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import { ICategoryLabelOptions, ILabelValueOption } from 'shared/widgets/form/skyTSelect';
import './FrequencyBandFilters.scss';

interface IFrequencyBanFiltersProps {
  mission: MissionDetails;
}

const FrequencyBandFilters = (props: IFrequencyBanFiltersProps) => {
  const dispatch = useDispatch();
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const bandFilter = useSelector(({ mission }: IRootState) => mission.bandFilter);
  const { projectId } = useParams<{ projectId: string }>();

  const { mission } = props;

  const usersFrequencyBand = getAvailableUserFrequencyBands(referential, mission.isCivil);

  const categories: string[] = [];
  const availableOptions = usersFrequencyBand.reduce((agg: ICategoryLabelOptions<string>[], current) => {
    const currentCategory = current.category;
    let existingCategory = agg.find(item => item.label === currentCategory);
    if (!existingCategory) {
      categories.push(currentCategory);
      existingCategory = {
        label: currentCategory,
        options: [] as ILabelValueOption<string>[]
      };
      agg.push(existingCategory);
    }
    existingCategory.options.push({
      label: current.name,
      value: current.name
    });
    return agg;
  }, []);

  const firstSelected = mission.userFrequencies.length > 0 ? mission.userFrequencies[0] : null;

  const selectedCategory = firstSelected
    ? availableOptions.find(optionCat => optionCat.options.some(option => option.value === firstSelected.name))
    : null;
  const defaultSelectedFilter = selectedCategory?.label;

  useEffect(() => {
    if (defaultSelectedFilter) {
      dispatch(selectBandFilter(defaultSelectedFilter));
    }
  }, [defaultSelectedFilter, dispatch]);

  const onClick = (cat: string) => async () => {
    // remove not valid frequencies and terminals
    const newValidOptions = availableOptions.find(catOptions => catOptions.label === cat)?.options;
    if (newValidOptions) {
      const newValidFreqs = mission.userFrequencies.filter(freq => newValidOptions.some(opt => opt.value === freq.name));
      const newValidTerminals = mission.userTerminals.filter(term => term.category === cat);

      const newMission: MissionDetails = {
        ...mission,
        userFrequencies: newValidFreqs,
        userTerminals: newValidTerminals
      };

      dispatch(saveMission(projectId, newMission));
      dispatch(selectBandFilter(cat));
    }
  };

  return (
    <div className="frequency-band-filters">
      {categories.map(cat => (
        <SkyTButton key={cat} variant="outline-info" active={bandFilter === cat} onClick={onClick(cat)}>
          {cat}
        </SkyTButton>
      ))}
    </div>
  );
};
export default FrequencyBandFilters;
