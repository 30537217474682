import { exportResultType } from 'shared/model/results.model';
import { ReactComponent as BarChartIcon } from './icons/BAR_CHART.svg';
import { ReactComponent as CurveLineIcon } from './icons/CURVE_LINE.svg';
import { ReactComponent as MapIcon } from './icons/HEAT_MAP.svg';
import { ReactComponent as PieIcon } from './icons/PIE_CHART.svg';

interface IExportResultTypeIconProps {
  type: exportResultType;
}

const ExportResultTypeIcon = (props: IExportResultTypeIconProps) => {
  const { type } = props;

  switch (type) {
    case 'BAR_CHART':
      return <BarChartIcon />;
    case 'CURVE_LINE':
      return <CurveLineIcon />;
    case 'HEAT_MAP':
    case 'BEAM_MAP':
    case 'BOOLEAN_BEAM_MAP':
      return <MapIcon />;
    case 'OVERVIEW':
      return <PieIcon />;
    default:
      return <BarChartIcon />;
  }
};

export default ExportResultTypeIcon;
