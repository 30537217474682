import { IRootState } from 'config/store';
import { ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GatewayPolarizationType } from 'shared/model/gateway.model';
import { setSelectedGatewayPolarization } from 'shared/reducers/GatewaySlice';
import SkyTToggleButton from 'shared/widgets/buttons/skyTToggleButton';

const polarizationTypes = [
  {
    label: 'gateways.monopolar',
    value: 0
  },
  {
    label: 'gateways.bipolar',
    value: 1
  }
];

const GatewayPolarization = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const selectedPolorization = useSelector(({ gateway }: IRootState) => gateway.selectedPolorization);

  const onPolarizationChange = (value: GatewayPolarizationType) => {
    dispatch(setSelectedGatewayPolarization(value));
  };

  return (
    <ToggleButtonGroup
      size="sm"
      name="polarization"
      type="radio"
      value={selectedPolorization}
      onChange={onPolarizationChange}
      className="gateway-polarization"
    >
      {polarizationTypes.map(item => {
        return (
          <SkyTToggleButton size="sm" key={item.value} id={item.value.toString()} variant="outline-info" value={item.value}>
            {t(item.label)}
          </SkyTToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default GatewayPolarization;
