import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IExportResult } from 'shared/model/results.model';
import { chartResultRoute } from 'shared/routes/routes';
import ChartCard from '../commons/ChartCard';
import ExportResultTypeIcon from '../commons/ExportResultTypeIcon';

interface IResultChartCardProps {
  result: IExportResult;
  exportId: string | number;
}

const ResultChartCard = (props: IResultChartCardProps) => {
  const history = useHistory();
  const location = useLocation();

  const { result, exportId } = props;

  const { projectId } = useParams<{ projectId: string }>();

  const onClick = () => {
    const pathname = chartResultRoute.getPath(projectId, exportId);

    const params = new URLSearchParams(location.search);
    params.set('name', result.name);
    params.set('type', result.resultType);

    history.push({
      pathname,
      search: params.toString()
    });
  };

  return <ChartCard title={result.name} icon={<ExportResultTypeIcon type={result.resultType} />} onClick={onClick} />;
};

export default ResultChartCard;
