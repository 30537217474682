import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Area } from 'shared/model/area.model';
import { Coverage, CoverageType } from 'shared/model/coverage.model';
import { MissionDetails } from 'shared/model/mission.model';
import { ProjectDetails } from 'shared/model/project.model';
import { Satellite } from 'shared/model/satellite.model';
import { makeBeamFromDatabase } from 'shared/utils/beam-utils';
import { getMissionFromArea } from 'shared/utils/model-utils';
import { beamIsSelected, getBeamId, getMissionSymbol, getSelectedSymbol, saveMissionSymbol, toBeamAttribute } from '../beam-layer-def';
import { IUnderEditionDatabaseBeam } from '../databaseBeam.model';

interface IUseEditMultiBeamProps {
  layer: GraphicsLayer;
}

const useEditMultiBeam = (props: IUseEditMultiBeamProps) => {
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const area = useSelector(({ area }: IRootState) => area.area);

  const { layer } = props;

  const isMultiBeamCoverageType = coverage?.coverageType === CoverageType.MULTI_BEAM;
  const satellite = project.satellites.find(sat => sat.id === configuration?.satelliteId);

  useEffect(() => {
    if (configuration && coverage && area && satellite && isMultiBeamCoverageType) {
      const mission = getMissionFromArea(area as Area, missions, coverage as Coverage);
      if (mission) {
        updateBeams(layer, coverage as Coverage, area as Area, satellite, mission, underEditionBeams);
        setTimeout(() => {
          currentView?.goTo(layer.graphics);
        }, 200);
      }
    }
  }, [area, configuration, coverage, isMultiBeamCoverageType, layer, missions, satellite, underEditionBeams]);
  return layer;
};

const updateBeams = (
  layer: GraphicsLayer,
  coverage: Coverage,
  area: Area,
  satellite: Satellite,
  mission: MissionDetails,
  underEditionBeams: IUnderEditionDatabaseBeam[]
) => {
  const toRemove = layer.graphics.filter(g => g.getAttribute('areaId') !== area.id);
  layer.removeMany(toRemove.toArray());

  underEditionBeams.forEach(beam => {
    const beamId = getBeamId(coverage.coverageType as CoverageType, area.id, beam.i);

    const existingGraphics = layer.graphics.find(item => item.getAttribute('ObjectID') === beamId);
    let draw = existingGraphics ? false : true;
    if (existingGraphics) {
      draw = existingGraphics.getAttribute('s') !== beam.s;
    }

    if (draw) {
      const polygon = makeBeamFromDatabase(beam, satellite.orbitalPosition);
      const attributes = {
        ...toBeamAttribute(beam),
        ObjectID: beamId,
        name: beam.i.toString(),
        type: coverage.coverageType,
        missionId: mission.id,
        coverageId: coverage.id,
        areaId: area.id,
        areaOfInterestId: area.areaOfInterestId
      };
      if (existingGraphics) {
        attributes.selected = beamIsSelected(existingGraphics).toString();
        layer.remove(existingGraphics);
      }
      const geometry = webMercatorUtils.geographicToWebMercator(polygon);
      const graphic = new Graphic({
        geometry,
        attributes,
        symbol: attributes.selected ? getSelectedSymbol() : getMissionSymbol(mission)
      });
      saveMissionSymbol(graphic, mission);
      layer.graphics.add(graphic);
    } else if (existingGraphics) {
      const selected = beamIsSelected(existingGraphics);

      const attributes = {
        ...existingGraphics.attributes,
        ...toBeamAttribute(beam),
        selected
      };
      existingGraphics.attributes = attributes;
      const symbol = selected ? getSelectedSymbol() : getMissionSymbol(mission);
      existingGraphics.symbol = symbol;
    }
  });
};

export default useEditMultiBeam;
