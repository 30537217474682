import { IRootState } from 'config/store';
import BeamLegend from 'modules/map/layers/beams/beam-legend';
import { BeamCriterion } from 'modules/map/layers/beams/databaseBeam.model';
import { ChangeEvent, useEffect } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setBeamCriterion } from 'shared/reducers/mapSlice';
import './beamCriteria.scss';

const BeamCriteria = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const currentCriterion = useSelector(({ map }: IRootState) => map.beamCriterion);

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (currentCriterion !== e.target.value) {
      dispatch(setBeamCriterion(e.target.value as BeamCriterion | null));
    } else {
      dispatch(setBeamCriterion(null));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setBeamCriterion(null));
    };
  }, [dispatch]);

  return (
    <div className="beam-criteria">
      <h5 className="font-weight-bold">Target's criteria</h5>
      {currentCriterion && <BeamLegend criterion={currentCriterion} />}
      <div className="mb-2">
        <div className="font-weight-bold">{t('beam.system')}</div>
        <div className="my-2">
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.TARGETED_CAPACITY}
            checked={currentCriterion === BeamCriterion.TARGETED_CAPACITY}
            onChange={onSelect}
          >
            {t('beam.targetedCapacity')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.RATIO}
            checked={currentCriterion === BeamCriterion.RATIO}
            onChange={onSelect}
          >
            {t('beam.ratio')}
          </ToggleButton>
        </div>
      </div>
      <div className="mb-2">
        <div className="font-weight-bold">{t('beam.payload') + ' ' + t('beam.forward')}</div>
        <div className="my-2 small">
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.BAND_FORWARD}
            checked={currentCriterion === BeamCriterion.BAND_FORWARD}
            onChange={onSelect}
          >
            {t('beam.bandForward')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.EIRP}
            checked={currentCriterion === BeamCriterion.EIRP}
            onChange={onSelect}
          >
            {t('beam.eirp')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.CI_FORWARD}
            checked={currentCriterion === BeamCriterion.CI_FORWARD}
            onChange={onSelect}
          >
            {t('beam.ci')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.EIRP_DENSITY}
            checked={currentCriterion === BeamCriterion.EIRP_DENSITY}
            onChange={onSelect}
          >
            {t('beam.eirpDensity')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.FREQ_PLANOCC_RATIO_FORWARD}
            checked={currentCriterion === BeamCriterion.FREQ_PLANOCC_RATIO_FORWARD}
            onChange={onSelect}
          >
            {t('beam.freqPlanOccRatio')}
          </ToggleButton>
        </div>
      </div>
      <div>
        <div className="font-weight-bold">{t('beam.payload') + ' ' + t('beam.return')}</div>
        <div className="my-2">
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.BAND_RETURN}
            checked={currentCriterion === BeamCriterion.BAND_RETURN}
            onChange={onSelect}
          >
            {t('beam.bandReturn')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.GT}
            checked={currentCriterion === BeamCriterion.GT}
            onChange={onSelect}
          >
            {t('beam.gt')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.CI_RETURN}
            checked={currentCriterion === BeamCriterion.CI_RETURN}
            onChange={onSelect}
          >
            {t('beam.ci')}
          </ToggleButton>
          <ToggleButton
            type="checkbox"
            variant="outline-primary"
            size="sm"
            className="mr-2"
            value={BeamCriterion.FREQ_PLANOCC_RATIO_RETURN}
            checked={currentCriterion === BeamCriterion.FREQ_PLANOCC_RATIO_RETURN}
            onChange={onSelect}
          >
            {t('beam.freqPlanOccRatio')}
          </ToggleButton>
        </div>
      </div>
    </div>
  );
};

export default BeamCriteria;
