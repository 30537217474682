import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISupportLayer, SupportLayerSvgIcons } from 'config/supportLayer';
import { ReactComponent as AirportsIcon } from './icons/airports.svg';
import { ReactComponent as GridIcon } from './icons/grid.svg';
import { ReactComponent as PopulationIcon } from './icons/population.svg';
import { ReactComponent as PortsIcon } from './icons/port.svg';
import { ReactComponent as PrecipitationIcon } from './icons/precipitation.svg';
import { ReactComponent as RailsIcon } from './icons/rails.svg';
import { ReactComponent as RainIcon } from './icons/rain.svg';
import { ReactComponent as RiversIcon } from './icons/rivers.svg';
import { ReactComponent as SubmarineFiberCableIcon } from './icons/submarineFiberCable.svg';
import { ReactComponent as TimeZoneIcon } from './icons/timeZone.svg';

interface ISupportLayerIconProps {
  def: ISupportLayer;
  className?: string;
}

const SupportLayerIcon = (props: ISupportLayerIconProps) => {
  const {
    def: { icon, svg },
    className
  } = props;

  if (icon) {
    return <FontAwesomeIcon icon={icon} className={className} />;
  } else if (svg) {
    switch (svg) {
      case SupportLayerSvgIcons.RAILS:
        return <RailsIcon className={className} />;
      case SupportLayerSvgIcons.AIRPORTS:
        return <AirportsIcon className={className} />;
      case SupportLayerSvgIcons.GRID:
        return <GridIcon className={className} />;
      case SupportLayerSvgIcons.PORTS:
        return <PortsIcon className={className} />;
      case SupportLayerSvgIcons.PRECIPITATION:
        return <PrecipitationIcon className={className} />;
      case SupportLayerSvgIcons.TIMEZONE:
        return <TimeZoneIcon className={className} />;
      case SupportLayerSvgIcons.RIVERS:
        return <RiversIcon className={className} />;
      case SupportLayerSvgIcons.SUBMARINE:
        return <SubmarineFiberCableIcon className={className} />;
      case SupportLayerSvgIcons.POPULATION:
        return <PopulationIcon className={className} />;
      case SupportLayerSvgIcons.RAIN:
        return <RainIcon className={className} />;
    }
  }
  return null;
};

export default SupportLayerIcon;
