import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { MissionService } from 'shared/model/mission.model';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    20: <span className={className}>20%</span>,
    40: <span className={className}>40%</span>,
    60: <span className={className}>60%</span>,
    80: <span className={className}>80%</span>
  };
};

interface IServiceSliderProps {
  service: MissionService;
  onChange: (value: number) => void;
  defaultValue?: number;
  disabled?: boolean;
}

const ServiceSlider = (props: IServiceSliderProps) => {
  const { service, defaultValue = 0, disabled = false } = props;

  const [value, setValue] = useState(defaultValue);

  const onChange = (e: number) => {
    props.onChange(e);
    setValue(e);
  };

  const marks = getMarks(disabled);

  return (
    <Form.Group className="common-slider">
      <Form.Label className="d-flex justify-content-center mb-0">{service.name}</Form.Label>
      <SkyTSlider onChange={onChange} marks={marks} min={0} max={100} value={value} step={1} disabled={disabled} />
    </Form.Group>
  );
};

export default ServiceSlider;
