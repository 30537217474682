import { IRootState } from 'config/store';
import debounce from 'lodash/debounce';
import { getBeamI } from 'modules/map/layers/beams/beam-layer-def';
import { getDefaultDatabaseBeam, IUnderEditionDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { createRandomBeamPositions } from 'modules/map/layers/beams/steerable/useCreateSteerableBeam';
import { editBeamsLayer } from 'modules/map/layers/beams/useEditBeamLayer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Area } from 'shared/model/area.model';
import { Coverage, CoverageType } from 'shared/model/coverage.model';
import { ProjectDetails } from 'shared/model/project.model';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { getMissionFromArea } from 'shared/utils/model-utils';
import RegionalEditor from '../../coverageArea/RegionalEditor';
import BeamNbSlider from '../../coverageArea/sliders/BeamNbSlider';
import BeamSizeSlider from '../../coverageArea/sliders/BeamSizeSlider';
import DeleteAreaBeamBtn from './DeleteAreaBeamBtn';
import './EditGeometryArea.scss';
import SelectAllBeamsButton from './selectAllBeamsButton';

const EditGeometryArea = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const coverages = useSelector(({ coverage }: IRootState) => coverage.coverages);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const area = useSelector(({ area }: IRootState) => area.area);

  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const [beamNb, setBeamNb] = useState<number>(underEditionBeams.length);

  const { satelliteId } = useParams<{ satelliteId: string }>();

  const satellite = project.satellites.find(sat => sat.id === Number(satelliteId));

  const coverage = coverages.find(item => item.id === area?.coverageId);

  useEffect(() => {
    if (underEditionBeams.length) {
      setBeamNb(underEditionBeams.length);
    }
  }, [underEditionBeams.length]);

  const onBeamSizeChange = debounce((beamSize: number) => {
    let atLeastOneChange = false;
    const newUnderEdition = underEditionBeams.map(item => {
      if (item.selected && item.s !== beamSize) {
        atLeastOneChange = true;
        return {
          ...item,
          s: beamSize
        };
      }
      return {
        ...item
      };
    });
    if (atLeastOneChange) {
      dispatch(setUnderEditionBeams(newUnderEdition));
    }
  }, 400);

  const onBeamNbChange = (nb: number) => {
    setBeamNb(nb);
    const newUnderEditionBeams = [...underEditionBeams];
    const nbOfBeamsToAdd = nb - newUnderEditionBeams.length;
    const nbOfBeamsToRemove = newUnderEditionBeams.length - nb;

    newUnderEditionBeams.sort((b1, b2) => {
      const i1 = b1.i;
      const i2 = b2.i;
      return i1.localeCompare(i2);
    });

    if (nbOfBeamsToAdd > 0 && satellite) {
      const defaultSize = 1;
      const sv = [...newUnderEditionBeams[0].sv];

      const positions = createRandomBeamPositions(nbOfBeamsToAdd, defaultSize, satellite.orbitalPosition, editBeamsLayer.graphics.length);

      const mission = getMissionFromArea(area as Area, missions, coverage as Coverage);

      if (!mission) {
        console.error('Cannot find current Mission');
        return;
      }

      positions.forEach(pos => {
        const i = getBeamI(mission, pos.i.toString());

        const defaultDatabaseBeam = getDefaultDatabaseBeam(mission);

        const newDatabaseBeam: IUnderEditionDatabaseBeam = {
          ...defaultDatabaseBeam,
          i,
          lt: pos.lat,
          lg: pos.lon,
          selected: false,
          s: defaultSize,
          sv
        };
        newUnderEditionBeams.push(newDatabaseBeam);
      });
    } else if (nbOfBeamsToRemove > 0) {
      for (let i = 0; i < nbOfBeamsToRemove; i++) {
        newUnderEditionBeams.pop();
      }
    }
    dispatch(setUnderEditionBeams(newUnderEditionBeams));
  };

  if (!coverage) {
    return null;
  }

  if (coverage.coverageType === CoverageType.REGIONAL) {
    return <RegionalEditor />;
  }

  if (underEditionBeams.length === 0) {
    return null;
  }

  const canCustomizeBeam = referential?.beamCustomisation?.isEnabled ? true : false;

  const selectedBeams = underEditionBeams.filter(beam => beam.selected);
  const disable = selectedBeams.length === 0;

  let defaultBeamSize = 1;
  if (selectedBeams.length > 0) {
    const firstBeams = selectedBeams[0];
    if (selectedBeams.every(item => item.s === firstBeams.s)) {
      defaultBeamSize = firstBeams.s;
    }
  }

  return (
    <div className="edit-geometry-area">
      {canCustomizeBeam ? (
        <>
          <div className="flex-grow-1 d-flex flex-column justify-content-center">
            <BeamSizeSlider onChange={onBeamSizeChange} defaultValue={defaultBeamSize} disabled={disable} />
            {coverage.coverageType === CoverageType.STEERABLE_BEAM && <BeamNbSlider onChange={onBeamNbChange} defaultValue={beamNb} />}
          </div>
          <SelectAllBeamsButton />
          <div className="mt-2 text-center">
            <DeleteAreaBeamBtn />
          </div>
        </>
      ) : (
        <div className="flex-grow-1 d-flex flex-column justify-content-center text-center">{t('area.geometry.customizationDisabled')}</div>
      )}
    </div>
  );
};

export default EditGeometryArea;
