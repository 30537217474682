import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { forwardRef } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import './skyTTextInput.scss';

interface ISkyTTextInputProps extends FormControlProps {
  name?: string;
  placeholder?: string;
  rightIcon?: IconProp;
  dark?: boolean;
  type?: 'search' | 'text' | 'number';
  autocomplete?: 'off' | 'onn';
  rows?: number;
  min?: number | string;
  max?: number | string;
  step?: number;
}

const SkyTTextInput = forwardRef((props: ISkyTTextInputProps, ref) => {
  const { className, rightIcon, autocomplete = 'off', type = 'search', dark = false, min, max, step, ...rest } = props;
  return (
    <div className={classnames('skyt-text-input', { 'right-icon': rightIcon }, className)}>
      {rightIcon && (
        <span>
          <FontAwesomeIcon icon={rightIcon} />
        </span>
      )}

      <Form.Control
        className={classnames({ dark })}
        type={type}
        ref={ref}
        min={min}
        max={max}
        step={step}
        {...rest}
        autoComplete={autocomplete}
      />
    </div>
  );
});

export default SkyTTextInput;
