import { graphicToDatabaseBeam, IUnderEditionDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { Area } from 'shared/model/area.model';

export const convertUnderEditionBeamsToAreas = (editBeamLayer: __esri.GraphicsLayer, underEditionBeams: IUnderEditionDatabaseBeam[]) => {
  return editBeamLayer.graphics.reduce((agg: Partial<Area>[], feature) => {
    const att = feature.attributes;

    const coverageId = att.coverageId;
    const areaId = att.areaId;
    const areaOfInterestId = att.areaOfInterestId;
    const dbBeam = graphicToDatabaseBeam(feature);
    const underEditionBeam = underEditionBeams.find(item => item.i === dbBeam.i);
    if (underEditionBeam) {
      dbBeam.sv = underEditionBeam.sv;
      dbBeam.sys = underEditionBeam.sys;
      dbBeam.tx = underEditionBeam.tx;
      dbBeam.rx = underEditionBeam.rx;

      let area = agg.find(area => area.coverageId === coverageId && area.areaOfInterestId === areaOfInterestId);
      if (!area) {
        area = {
          id: areaId,
          coverageId,
          areaOfInterestId,
          beamList: []
        } as Partial<Area>;
        agg.push(area);
      }
      area.beamList?.push(dbBeam);
    }
    return agg;
  }, []);
};
