import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';

interface ISkyTAccordionToggleProps {
  eventKey: string;
  title: React.ReactNode;
  disabled?: boolean;
}

const SkyTAccordionToggle = (props: ISkyTAccordionToggleProps) => {
  const { eventKey, title, disabled } = props;

  const currentEventKey = useContext(AccordionContext);

  const toggleEventKey = !disabled ? eventKey : eventKey + '-disabled';

  const isCurrentTab = currentEventKey === toggleEventKey && !disabled;

  return (
    <Accordion.Toggle as={Card.Header} eventKey={toggleEventKey}>
      <div className={classnames('tab-title', { 'active-tab': isCurrentTab, 'disabled-tab': disabled })}>
        <div className="flex-grow-1">{title}</div>
        <div>
          <FontAwesomeIcon icon={isCurrentTab ? 'chevron-up' : 'chevron-down'} />
        </div>
      </div>
    </Accordion.Toggle>
  );
};

export default SkyTAccordionToggle;
