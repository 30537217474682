import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CoverageType } from 'shared/model/coverage.model';
import { ProjectDetails } from 'shared/model/project.model';
import { getGeometriesOfAreaOfInterest } from 'shared/utils/model-utils';
import { getMissionSymbol, getRegionalBeamI } from '../beam-layer-def';

export let sketchViewModelSteerableBeams: SketchViewModel | undefined;

interface IuseCreateRegionalBeamProps {
  layer: GraphicsLayer;
}

const useCreateRegionalBeam = (props: IuseCreateRegionalBeamProps) => {
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);

  const { layer } = props;

  const satellite = project.satellites.find(sat => sat.id === configuration?.satelliteId);

  const mission = missions.find(item => item.id === coverage?.missionId);
  const coverageId = coverage?.id;

  const isRegionalCoverageType = coverage?.coverageType === CoverageType.REGIONAL;

  useEffect(() => {
    if (satellite && mission && missions.length > 0 && isRegionalCoverageType) {
      layer.removeAll();
      const beams = mission.areaOfInterests.map(area => {
        const polygons = getGeometriesOfAreaOfInterest(area);
        const polygon = polygons.length > 1 ? geometryEngine.union(polygons) : polygons[0];

        const i = getRegionalBeamI(mission, missions, area);
        const attributes = {
          i,
          name: area.name,
          type: CoverageType.REGIONAL,
          missionId: mission.id,
          coverageId: coverageId,
          areaOfInterestId: area.id
        };

        return new Graphic({
          geometry: polygon,
          attributes,
          symbol: getMissionSymbol(mission)
        });
      });
      layer.addMany(beams);
      setTimeout(() => {
        currentView?.goTo(beams);
      }, 200);
    }
  }, [coverageId, isRegionalCoverageType, layer, mission, satellite, missions]);
};

export default useCreateRegionalBeam;
