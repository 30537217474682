interface IImageFromBase64Props {
  data: string;
  contentType?: string;
  height?: string;
  width?: string;
  alt?: string;
}

const ImageFromBase64 = (props: IImageFromBase64Props) => {
  const { data, contentType = 'image/jpeg', alt = '', height, width } = props;

  return <img src={`data:${contentType};base64,${data}`} alt={alt} height={height} width={width} />;
};

export default ImageFromBase64;
