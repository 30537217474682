import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import ArcGisMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import LayerList from '@arcgis/core/widgets/LayerList';
import Legend from '@arcgis/core/widgets/Legend';
import classNames from 'classnames';
import { showMapLayers } from 'config/env';
import { IRootState } from 'config/store';
import { isArray } from 'lodash';
import { areaOfInterestslayer, getGraphicsFromAreaOfInterest } from 'modules/map/layers/areaOfInterets/areaOfInterestLayer-def';
import { addAndOrderLayer } from 'modules/map/layers/layerOrder';
import { popupConfg, supportBaseLayers } from 'modules/map/map';
import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapMenu from 'shared/layout/mapmenu/mapMenu';
import { setMap } from 'shared/reducers/mapSlice';
import { hexToRgb } from 'shared/utils/color-utils';
import { resultLayerId, verticalLegend } from './resultConstants';
import './ResultMap.scss';

let currentView = null as MapView | SceneView | null;
let mapView = null as MapView | null;
let sceneView = null as SceneView | null;

interface IResultMapProps {
  onMapCreation: (map: ArcGisMap, view: MapView | SceneView) => void;
  showLegend?: boolean;
  children?: React.ReactNode;
}

const ResultMap: FC<IResultMapProps> = props => {
  const dispatch = useDispatch();
  const mapRef = useRef<HTMLDivElement>(null);
  const flat = useSelector(({ map }: IRootState) => map.flat);
  const baseLayer = useSelector(({ map }: IRootState) => map.baseLayer);
  const currentExport = useSelector(({ exporter }: IRootState) => exporter.export);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const qualityProfile = useSelector(({ map }: IRootState) => map.qualityProfile);
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);
  const currentMission = useSelector(({ exporter }: IRootState) => exporter.mission);

  const { onMapCreation, showLegend = false } = props;

  const missionsToUse = missions.filter(
    item => currentMission?.id === item.id && currentExport?.missions.some(mission => mission.id === item.id)
  );

  useEffect(() => {
    return () => {
      currentView = null;
      mapView = null;
      sceneView = null;
      dispatch(setMap(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (mapRef.current) {
      let map = arcgisMap;
      if (!map) {
        map = new ArcGisMap({
          basemap: baseLayer,
          ground: 'world-elevation',
          layers: []
        });
        dispatch(setMap(map));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, arcgisMap]);

  useEffect(() => {
    if (arcgisMap && mapRef.current) {
      let activeViewpoint = null;
      if (currentView) {
        activeViewpoint = currentView.viewpoint.clone();
        currentView.set({ container: null });
      }
      if (flat) {
        if (!mapView) {
          mapView = new MapView({
            map: arcgisMap,
            container: mapRef.current,
            center: [1, 1],
            spatialReference: SpatialReference.WebMercator,
            constraints: {
              minZoom: 3
            },
            popup: popupConfg
          });
          if (showMapLayers()) {
            const layerList = new LayerList({
              view: mapView
            });
            mapView.ui.add(layerList, 'top-right');
          }
          if (showLegend) {
            const found = mapView.ui.find('result-legend');
            if (!found) {
              mapView.ui.add(
                new Legend({
                  id: 'result-legend',
                  view: mapView
                }),
                'bottom-right'
              );
            }
          }
        } else if (activeViewpoint) {
          mapView.viewpoint = activeViewpoint;
          mapView.container = mapRef.current;
        }
        currentView = mapView;
      } else {
        if (!sceneView) {
          sceneView = new SceneView({
            container: mapRef.current,
            map: arcgisMap,
            qualityProfile,
            popup: popupConfg
          });
        } else if (activeViewpoint) {
          sceneView.viewpoint = activeViewpoint;
          sceneView.container = mapRef.current;
        }
        currentView = sceneView;
      }
    }
  }, [arcgisMap, flat, qualityProfile, showLegend]);

  useEffect(() => {
    if (arcgisMap && currentView) {
      arcgisMap.remove(arcgisMap.findLayerById(resultLayerId));
      onMapCreation(arcgisMap, currentView);
    }
  }, [arcgisMap, currentChart, onMapCreation]);

  useEffect(() => {
    if (arcgisMap && currentView) {
      areaOfInterestslayer.removeAll();
      addAndOrderLayer(arcgisMap, areaOfInterestslayer);

      let toAdd: Graphic[] = [];
      missionsToUse.forEach(mission => {
        const color = hexToRgb(mission.color);

        mission.areaOfInterests.forEach(areaOfInterest => {
          let confArray = JSON.parse(areaOfInterest.latLngs);
          if (!isArray(confArray)) {
            confArray = [confArray];
          }
          const graphics = getGraphicsFromAreaOfInterest(areaOfInterest, color, false, true);
          toAdd = toAdd.concat(graphics);
        });
        areaOfInterestslayer.addMany(toAdd);
      });
    }
  }, [arcgisMap, missionsToUse]);

  useEffect(() => {
    if (arcgisMap) {
      arcgisMap.set('basemap', baseLayer);
      if (baseLayer === 'satellite') {
        arcgisMap.addMany(supportBaseLayers, 0);
        if (currentView instanceof MapView) {
          currentView.constraints.minZoom = 3;
        }
      } else {
        arcgisMap.removeMany(supportBaseLayers);
        if (currentView instanceof MapView) {
          currentView.constraints.minZoom = 2;
        }
      }
    }
  }, [baseLayer, arcgisMap]);

  const onCompassClick = () => {
    if (arcgisMap && currentView) {
      const layer = arcgisMap.findLayerById(resultLayerId);
      if (layer instanceof FeatureLayer) {
        currentView.goTo(layer.source);
      }
    }
  };

  return (
    <div className={classNames('result-map-wrapper', { verticalLegend })}>
      <div className="skyt-result-map" ref={mapRef}></div>
      <div className="skyt-result-child">{props.children}</div>
      <MapMenu onCompassClick={onCompassClick} />
    </div>
  );
};

export default ResultMap;
