export const APP_VERSION = 'VITE_VERSION';
export const LIGHT_VERSION = 'VITE_LIGHT_VERSION';
export const API_URL = 'VITE_API_URL';
export const ESRI_API_KEY = 'VITE_ESRI_API_KEY';
export const CLIENT_ID = 'VITE_CLIENT_ID';
export const REDIRECT_URI = 'VITE_REDIRECT_URI';
export const TENANT = 'VITE_TENANT';
export const DEBUG = 'VITE_DEBUG';
export const SHOW_MAP_LAYERS = 'VITE_SHOW_MAP_LAYERS';
export const SCI_CHART_LICENSE = 'VITE_SCI_CHART_LICENSE';

interface IApplicationEnv {
  apiUrl: string;
  env: 'development' | 'production' | 'test';
  version: string;
  esriApiKey: string;
  clientId: string;
  redirectUri: string;
  tenant: string;
  debug: boolean;
  showMapLayers: boolean;
  scichartKey: string;
}

declare global {
  interface Window {
    env: IApplicationEnv;
  }
}

export const getEnv = (): IApplicationEnv => ({
  version: import.meta.env[APP_VERSION] as string,
  env: import.meta.env.NODE_ENV,
  apiUrl: import.meta.env[API_URL] as string,
  esriApiKey: import.meta.env[ESRI_API_KEY] as string,
  clientId: import.meta.env[CLIENT_ID] as string,
  redirectUri: import.meta.env[REDIRECT_URI] as string,
  tenant: import.meta.env[TENANT] as string,
  debug: import.meta.env[DEBUG] === 'true',
  showMapLayers: import.meta.env[SHOW_MAP_LAYERS] === 'true',
  scichartKey: import.meta.env[SCI_CHART_LICENSE] as string
});

export const getEnvApiUrl = (): string => getEnv().apiUrl;
export const isProd = () => getEnv().env === 'production';
export const isDev = () => getEnv().env === 'development';
export const isTest = () => getEnv().env === 'test';
export const getEsriAPIKey = () => getEnv().esriApiKey;
export const getClientId = () => getEnv().clientId;
export const getTenant = () => getEnv().tenant;
export const getRedirectUri = () => (getEnv().redirectUri ? getEnv().redirectUri : undefined);
export const isDebug = () => getEnv().debug;
export const showMapLayers = () => getEnv().showMapLayers;
export const getScichartKey = () => getEnv().scichartKey;

if (!isProd()) {
  window.env = getEnv();
}
