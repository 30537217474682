import { IRootState } from 'config/store';
import ProjectsPage from 'modules/projects/projectsPage';
import { useSelector } from 'react-redux';
import CustomerProjectsPage from './CustomerProjectsPage';

const Home = () => {
  const customerLock = useSelector(({ referential }: IRootState) => referential.customerLock);

  if (customerLock) {
    return <CustomerProjectsPage />;
  }
  return <ProjectsPage />;
};

export default Home;
