import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { createOrganization, deleteOrganization, updateOrganization } from 'shared/api/api';
import { Organization } from 'shared/model/organization.model';
import { fetchProject } from './projectSlice';

const initialState = {
  updating: false,
  updateSuccess: false,
  error: null as any
};

export type OrganizationState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    updateStart: state => {
      state.updating = true;
      state.updateSuccess = false;
    },
    updateSuccess: (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    reset: state => {
      state.updateSuccess = false;
      state.updating = false;
      state.error = null;
    }
  }
});

const { updateStart, updateFailed, updateSuccess, deleteSuccess } = slice.actions;

export const { reset } = slice.actions;

export const saveOrganization =
  (projectId: number | string, organization: Organization): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = organization.id
        ? await updateOrganization(projectId, organization)
        : await createOrganization(projectId, organization);
      dispatch(updateSuccess(response.data));
      dispatch(fetchProject(projectId));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeOrganization =
  (projectId: string | number, organizationId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteOrganization(projectId, organizationId);
      dispatch(deleteSuccess());
      dispatch(fetchProject(projectId));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
