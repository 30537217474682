/* eslint-disable no-case-declarations */

import { toast } from 'react-toastify';
import isFailedActionType from 'shared/utils/promise-utils';

interface IApiDataError {
  code: string;
  details: string;
  message: string;
}

interface IApiDataErrorProps {
  data: IApiDataError;
}

const ApiDataError = (props: IApiDataErrorProps) => {
  const { data } = props;

  return (
    <div>
      <div>{data.message}</div>
      {/*<div>{data.code}</div>
      <div>{data.details}</div>*/}
    </div>
  );
};

const notificationMiddleware = () => (next: any) => (action: any) => {
  if (isFailedActionType(action.type)) {
    if (action?.payload?.response?.data) {
      const data = action?.payload?.response?.data;
      toast.error(<ApiDataError data={data} />);
    } else {
      toast.error('Unknown error!');
    }
  }

  return next(action);
};

export default notificationMiddleware;
