import { IRootState } from 'config/store';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProjectDetails } from 'shared/model/project.model';
import Loader from 'shared/widgets/loader';

const SatellitesTabTitle = () => {
  const { t } = useTranslation('translation');
  const project = useSelector(({ project }: IRootState) => project.project);
  const loading = useSelector(({ project }: IRootState) => project.loading);

  const projectDetail = project as ProjectDetails;

  const total = projectDetail ? projectDetail.satellites.length : 0;

  return (
    <div className="df-jb-ac unselectable pr-2">
      {t('satellite.satellites')}
      {loading ? (
        <span className="ml-2">
          <Loader size="sm" inline />
        </span>
      ) : (
        <Badge pill variant="info" className="ml-2">
          {total}
        </Badge>
      )}
    </div>
  );
};

export default SatellitesTabTitle;
