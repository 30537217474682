import { IRootState } from 'config/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AreaOfInterest } from 'shared/model/areaOfInterest.model';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { selectMissions } from 'shared/reducers/missionSlice';
import { allAreaOfInteretsRoute, areaOfInteretsRoute, configureAreaOfInteretsRoute } from 'shared/routes/routes';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';
import NoResult from 'shared/widgets/no-result';
import AreaOfInteretItem from './areaOfInteret-item';
import './areaOfInterets.scss';

interface IAreaOfInteretsMissionItemProps {
  mission: Mission;
}

const AreaOfInteretsMissionItem = (props: IAreaOfInteretsMissionItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');

  const project = useSelector(({ project }: IRootState) => project.project);
  const loadingDetails = useSelector(({ mission }: IRootState) => mission.loadingDetails);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const { projectId } = useParams<{ projectId: string }>();

  const hasWriteAccess = projectHasWriteAccess(project);

  const mission = props.mission as MissionDetails;

  const isCurrent = selectedMissions.some(selected => selected.id === mission.id);

  const areaOfInterests: AreaOfInterest[] = mission?.areaOfInterests ? mission.areaOfInterests : [];

  const onSelectMission = () => {
    if (isCurrent) {
      dispatch(selectMissions([]));
      const newHistory = allAreaOfInteretsRoute.getPath(projectId);
      history.push(newHistory);
    } else {
      dispatch(selectMissions([mission]));
      const newHistory = areaOfInteretsRoute.getPath(projectId, mission.id);
      history.push(newHistory);
    }
  };

  const onCreate = () => {
    const newHistory = configureAreaOfInteretsRoute.getPath(projectId, mission.id, 'new');
    history.push(newHistory);
  };

  return (
    <div className="area-of-interet-mission-item pl-2 hover-white">
      {loadingDetails === mission.id ? (
        <Loader size="sm" />
      ) : (
        <>
          <div style={{ color: mission.color }} onClick={onSelectMission}>
            {mission.name}
          </div>
          {isCurrent && (
            <div>
              <div>
                {areaOfInterests.map(area => (
                  <AreaOfInteretItem key={`area_${area.id}`} area={area} />
                ))}
                {areaOfInterests.length === 0 && <NoResult>{t('areaOfInteret.noArea')}</NoResult>}
              </div>
              {hasWriteAccess && (
                <div className="text-right px-2 pt-2">
                  <SkyTMapButton size="sm" type="button" variant="outline-info" onClick={onCreate}>
                    {t('areaOfInteret.addArea')}
                  </SkyTMapButton>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AreaOfInteretsMissionItem;
