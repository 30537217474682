import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { beamNumberLabelClass } from 'modules/map/layers/beams/beam-layer-def';
import { NO_DATA, resultLayerId } from 'modules/results/commons/resultConstants';
import numeral from 'numeral';
import { IBooleanBeamMapModel } from 'shared/model/results.model';
import { createBeamSymbol, makeBeam, toABPoint } from 'shared/utils/beam-utils';

const compliant = 'Compliant';
const notCompliant = 'Not compliant';

const getFieldTemplate = (label: string, value: string) =>
  `<div class='field mb-1'><div class='label'>${label}</div><div class='value'>{${value}}</div></div>`;

const initBooleanBeamMap = (model: IBooleanBeamMapModel, satelliteLng: number, missionLetter?: string) => {
  const graphics = model.values.reduce((agg: Graphic[], item) => {
    if (missionLetter && item.i.startsWith(missionLetter)) {
      const center = toABPoint(item.lt, item.lg, satelliteLng);
      const polygon = makeBeam(center, item.s, satelliteLng);

      const calculatedValue = item.calculatedValue ? numeral(item.calculatedValue).format('0,0.[000]') + '&nbsp;' + model.unit : NO_DATA;
      const targetValue = item.targetValue ? numeral(item.targetValue).format('0,0.[000]') + '&nbsp;' + model.unit : NO_DATA;

      const attributes = {
        i: item.i,
        name: item.i,
        s: item.s + '°',
        lt: item.lt,
        lg: item.lg,
        isValid: item.isValid,
        status: item.isValid ? compliant : notCompliant,
        calculatedLabel: model.calculatedLabel,
        calculatedValue,
        targetLabel: model.targetLabel,
        targetValue
      };

      const g = new Graphic({
        geometry: polygon,
        attributes
      });
      agg.push(g);
    }
    return agg;
  }, []);

  const renderer = new UniqueValueRenderer({
    field: 'isValid',
    legendOptions: {
      title: 'Compliance'
    },
    uniqueValueInfos: [
      {
        label: compliant,
        value: 'true',
        symbol: createBeamSymbol('#5f7526', 0.7)
      },
      {
        label: notCompliant,
        value: 'false',
        symbol: createBeamSymbol('#aa0000', 0.7)
      }
    ]
  });

  const popupContent =
    '<div class="beam-popup text-primary">' +
    getFieldTemplate('Status', 'status') +
    getFieldTemplate('{targetLabel}', 'targetValue') +
    getFieldTemplate('{calculatedLabel}', 'calculatedValue') +
    getFieldTemplate('Size', 's') +
    getFieldTemplate('Latitude', 'lt') +
    getFieldTemplate('Longitude', 'lg') +
    '</div>';

  const layer = new FeatureLayer({
    id: resultLayerId,
    spatialReference: SpatialReference.WGS84,
    title: resultLayerId,
    renderer,
    labelingInfo: [beamNumberLabelClass],
    source: graphics,
    geometryType: 'polygon',
    objectIdField: 'id',
    opacity: 0.5,
    fields: [
      {
        name: 'i',
        type: 'string'
      },
      {
        name: 'id',
        type: 'string'
      },
      {
        name: 'name',
        type: 'string'
      },
      {
        name: 'isValid',
        type: 'string'
      },
      {
        name: 'status',
        type: 'string'
      },
      {
        name: 's',
        type: 'string'
      },
      {
        name: 'lt',
        type: 'string'
      },
      {
        name: 'lg',
        type: 'string'
      },
      {
        name: 'calculatedLabel',
        type: 'string'
      },
      {
        name: 'calculatedValue',
        type: 'string'
      },
      {
        name: 'targetLabel',
        type: 'string'
      },
      {
        name: 'targetValue',
        type: 'string'
      }
    ],
    popupTemplate: {
      title: '{name}',
      content: popupContent,
      outFields: ['*']
    }
  });
  return layer;
};

export default initBooleanBeamMap;
