import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Customer } from 'shared/model/customer.model';
import { fetchCustomers, fetchEntitiesSuccess, rotateCustomers, selectCustomer } from 'shared/reducers/customerSlice';
import { sortByName } from 'shared/utils/array-utils';
import Loader from 'shared/widgets/loader';
import AddCustomerOrProjectMenu from './addCustomerOrProjectMenu';
import './customers-list.scss';

const slideDuration = 150;

const CustomerList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const loading = useSelector(({ customer }: IRootState) => customer.loading);
  const customers = useSelector(({ customer }: IRootState) => customer.customers);
  const selectedCustomer = useSelector(({ customer }: IRootState) => customer.customer);

  const [filter, setFilter] = useState<string>('');
  const [slide, setSlide] = useState<'' | 'left' | 'right'>('');

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const select = (selection: Customer | null) => () => {
    dispatch(selectCustomer(selection));
  };

  const onPrevious = () => {
    setSlide('right');
    setTimeout(() => {
      dispatch(rotateCustomers(true));
      setSlide('');
    }, slideDuration);
  };

  const onNext = () => {
    setSlide('left');
    setTimeout(() => {
      dispatch(rotateCustomers(false));
      setSlide('');
    }, slideDuration);
  };

  const resetRotation = () => {
    const orderedCustomers = [...customers];
    orderedCustomers.sort(sortByName());
    dispatch(fetchEntitiesSuccess(orderedCustomers));
  };

  const filterCustomer = (e: ChangeEvent<HTMLInputElement>) => {
    resetRotation();
    setFilter(e.target.value.toLowerCase());
  };

  const filteredList = filter.length > 0 ? customers.filter(item => item.name.toLowerCase().includes(filter)) : customers;

  const lastItem = filteredList.length > 0 ? filteredList[filteredList.length - 1] : null;

  const minItems = 3;

  return (
    <div className="customers">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="customers-list">
            {filteredList.length > minItems && (
              <div className="previous" onClick={onPrevious}>
                <FontAwesomeIcon icon="angle-left" />
              </div>
            )}
            <div className="list-item">
              <div onClick={select(null)} className={classnames('unselectable', { selected: selectedCustomer === null })}>
                Favorites
              </div>
              {lastItem && (
                <div
                  className={classnames('last-but-not-least', 'unselectable', {
                    invisible: slide === '' || slide === 'left',
                    'slide-right-customer': slide === 'right'
                  })}
                >
                  {lastItem.name}
                </div>
              )}
              {filteredList.map((item, i) => (
                <div
                  key={`customer_${item.id}`}
                  onClick={select(item)}
                  className={classnames('unselectable', {
                    'first-customer': i === 0,
                    'last-customer': i === filteredList.length - 1,
                    selected: item.id === selectedCustomer?.id,
                    'slide-right-customer': slide === 'right',
                    'slide-left-customer': slide === 'left'
                  })}
                >
                  {item.name}
                </div>
              ))}
            </div>
            {filteredList.length > minItems && (
              <div className="next" onClick={onNext}>
                <FontAwesomeIcon icon="angle-right" />
              </div>
            )}
          </div>
          <div className="customers-actions">
            <SkyTTextInput type="text" placeholder={t('search')} rightIcon="search" onChange={filterCustomer} />
            <AddCustomerOrProjectMenu />
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerList;
