import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useAuthentication from 'shared/landing/useAuthentication';
import './private-route.scss';

export const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { isAuthorized, isAuthenticated } = useAuthentication();
  const { t } = useTranslation('translation');

  const checkedComponent = (props: any) =>
    isAuthorized ? (
      // @ts-ignore
      <Component {...props} />
    ) : (
      <div className="not-authorized">
        <Row className="justify-content-center align-items-center">
          <Col lg="6" xl="4">
            <Alert variant="danger" className="text-center">
              {t('notAuthorized')}
            </Alert>
          </Col>
        </Row>
      </div>
    );

  const renderRedirect = (props: any) => {
    return isAuthenticated ? (
      checkedComponent(props)
    ) : (
      <Redirect
        to={{
          pathname: '/',
          search: props.location.search,
          state: { from: props.location }
        }}
      />
    );
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect} />;
};

export default PrivateRoute;
