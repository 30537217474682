import classNames from 'classnames';
import { resultClassBreakInfosColors } from 'modules/results/commons/resultConstants';
import ColorGradient from 'shared/widgets/legend/ColorGradient';
import './beam-legend.scss';
import { BeamCriterion, getBeamCriterionDef } from './databaseBeam.model';

interface IBeamLegendProps {
  criterion: BeamCriterion;
  className?: string;
}

const BeamLegend = (props: IBeamLegendProps) => {
  const { criterion, className = '' } = props;

  const def = getBeamCriterionDef(criterion);

  return (
    <div className={classNames('beam-legend', className)}>
      <ColorGradient className="beam-legend-line" colors={resultClassBreakInfosColors} />
      <div className="beam-legend-desc">
        <div className="border-left border-primary pl-1">
          {def.min} {def.unit}
        </div>
        <div className="border-right border-primary pr-1">
          {def.max} {def.unit}
        </div>
      </div>
    </div>
  );
};

export default BeamLegend;
