import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Configuration } from 'shared/model/configuration.model';
import { ProjectDetails } from 'shared/model/project.model';
import { fetchEntitySuccess as selectConfiguration, getMissionsFromConfiguration } from 'shared/reducers/configurationSlice';
import { fetchCoverages } from 'shared/reducers/coverageSlice';
import { exportConfiguration } from 'shared/reducers/exportSlice';
import { setMapMenuPanel } from 'shared/reducers/mapSlice';
import { selectMissions } from 'shared/reducers/missionSlice';
import { configureConfigurationSatelliteRoute, coveragesRoute } from 'shared/routes/routes';
import { usePrevious } from 'shared/utils/hook-utils';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import Loader from 'shared/widgets/loader';
import './configuration.scss';
import DeleteConfiguration from './deleteConfigurationBtn';

interface IConfigurationItemProps {
  configuration: Configuration;
  className?: string;
  showEdit?: boolean;
  showConfig?: boolean;
  showDelete?: boolean;
  showExport?: boolean;
}

const ConfigurationItem = (props: IConfigurationItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingMissions = useSelector(({ configuration }: IRootState) => configuration.loadingMissions);
  const updating = useSelector(({ configuration }: IRootState) => configuration.updating);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const selectedConfiguration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const exporting = useSelector(({ exporter }: IRootState) => exporter.exporting);

  const previousLoadingMissions = usePrevious(loadingMissions);
  const project = useSelector(({ project }: IRootState) => project.project);
  const hasWriteAccess = projectHasWriteAccess(project as ProjectDetails);

  const { projectId } = useParams<{ projectId: string }>();
  const { configuration, showEdit = true, showConfig = true, showDelete = true, showExport = true, className } = props;

  const [visible, setVisible] = useState(false);

  const isLoading = loadingMissions === configuration.id;
  const isExporting = exporting !== -1;

  const onSelect = () => {
    if (selectedConfiguration?.id !== configuration.id) {
      dispatch(selectConfiguration(configuration));
      dispatch(getMissionsFromConfiguration(projectId, configuration.id));
      dispatch(fetchCoverages(projectId, configuration.satelliteId, configuration.id));
    }
    dispatch(setMapMenuPanel('missions'));
  };

  const onConfigure = (e: SyntheticEvent) => {
    dispatch(setMapMenuPanel(null));
    history.push(coveragesRoute.getPath(projectId, configuration.satelliteId, configuration.id));
    e.preventDefault();
  };
  const onEdit = (e: SyntheticEvent) => {
    dispatch(setMapMenuPanel(null));
    history.push(configureConfigurationSatelliteRoute.getPath(projectId, configuration.satelliteId, configuration.id));
    e.preventDefault();
  };

  const onExport = (e: SyntheticEvent) => {
    dispatch(setMapMenuPanel(null));
    dispatch(exportConfiguration(projectId, configuration.id));
    e.preventDefault();
  };

  useEffect(() => {
    if (previousLoadingMissions === configuration.id && loadingMissions === -1) {
      dispatch(setMapMenuPanel('missions'));
      dispatch(selectMissions(missions));
    }
  }, [configuration.id, dispatch, loadingMissions, missions, previousLoadingMissions]);

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  const disabled = updating || isExporting;

  return (
    <div className="configuration-title" onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div
        className={classnames('flex-grow-1', className, { 'text-info': selectedConfiguration?.id === configuration.id && !className })}
        onClick={onSelect}
      >
        {configuration.name}
      </div>
      {isLoading && (
        <div>
          <Loader size="sm" />
        </div>
      )}
      {hasWriteAccess && visible && !isLoading && (
        <div
          className={classnames('animate__animated', {
            animate__fadeIn: visible
          })}
        >
          {showConfig && (
            <SkyTButton variant="outline" size="sm" className={classnames('text-primary')} onClick={onConfigure} disabled={disabled}>
              <FontAwesomeIcon icon="cog" />
            </SkyTButton>
          )}
          {showEdit && (
            <SkyTButton variant="outline" size="sm" className={classnames('text-primary')} onClick={onEdit} disabled={disabled}>
              <FontAwesomeIcon icon="pencil-alt" />
            </SkyTButton>
          )}
          {showDelete && <DeleteConfiguration configuration={configuration} />}
          {showExport && (
            <SkyTButton variant="outline" size="sm" className={classnames('text-primary')} onClick={onExport} disabled={disabled}>
              <FontAwesomeIcon icon="upload" />
            </SkyTButton>
          )}
        </div>
      )}
    </div>
  );
};

export default ConfigurationItem;
