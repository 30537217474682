import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionSatellite } from 'shared/reducers/satelliteSlice';
import { layerInMap } from 'shared/utils/map-utils';
import { getSatellitePolylineGraphic, getSatEquatorLabel, getSatLongitudeLabel, getSatPointGraphic } from './satelliteUtils';

let sationaryHandle: any;

const tempSatelliteLayer = new GraphicsLayer({
  title: 'temp-satellites'
});

const useTempSatelliteLayer = () => {
  const dispatch = useDispatch();
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const isFlat = useSelector(({ map }: IRootState) => map.flat);
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);
  const satelliteModels = useSelector(({ referential }: IRootState) => referential.satelliteModels);

  useEffect(() => {
    return () => {
      tempSatelliteLayer.removeAll();
      if (arcgisMap) {
        arcgisMap.remove(tempSatelliteLayer);
      }
      if (sationaryHandle) {
        sationaryHandle.remove();
      }
    };
  }, [arcgisMap, dispatch]);

  useEffect(() => {
    if (arcgisMap && currentView && underEditionSatellite) {
      if (!layerInMap(arcgisMap, tempSatelliteLayer.id)) {
        arcgisMap.add(tempSatelliteLayer);
      }
      if (sationaryHandle) {
        sationaryHandle.remove();
      }
      sationaryHandle = currentView.watch('center', e => {
        if (e.longitude !== underEditionSatellite?.orbitalPosition) {
          dispatch(
            setUnderEditionSatellite({
              ...underEditionSatellite,
              orbitalPosition: e.longitude
            })
          );
        }
      });
    }
  }, [arcgisMap, dispatch, underEditionSatellite]);

  useEffect(() => {
    tempSatelliteLayer.removeAll();
    const satModel = satelliteModels.find(model => model.id === underEditionSatellite?.modelId);
    const longitude = underEditionSatellite?.orbitalPosition ? underEditionSatellite?.orbitalPosition : 0;

    const graphics: Graphic[] = [];

    const linesGraphics = getSatellitePolylineGraphic(longitude, isFlat);
    graphics.push(linesGraphics);

    const satGraphic = getSatPointGraphic(longitude, satModel);
    graphics.push(satGraphic);

    const longitudeLabel = getSatLongitudeLabel(longitude, satGraphic.geometry as Point, isFlat);
    graphics.push(longitudeLabel);

    const equatorLabel = getSatEquatorLabel(satGraphic.geometry as Point, isFlat);
    graphics.push(equatorLabel);

    tempSatelliteLayer.addMany(graphics);
  }, [underEditionSatellite?.orbitalPosition, satelliteModels, underEditionSatellite?.modelId, isFlat]);
};

export default useTempSatelliteLayer;
