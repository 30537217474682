import { IRootState } from 'config/store';
import { IRxDatabaseBeam, ITxDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from './common-slider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    0: <span className={className}>0</span>,
    100: <span className={className}>100</span>,
    200: <span className={className}>200</span>,
    300: <span className={className}>300</span>,
    400: <span className={className}>400</span>,
    500: <span className={className}>500</span>
  };
};

interface IBandPerBeamSliderProps {
  forward: boolean;
  defaultValue?: number;
  disabled?: boolean;
}

const BandPerBeamSlider = (props: IBandPerBeamSliderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const { defaultValue = 0, disabled = false, forward } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue);

  useEffect(() => {
    if (!disabled) {
      if (defaultValue) {
        setValue(defaultValue);
      } else {
        setValue(0);
      }
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (
      previousDebounceValue !== debounceValue &&
      typeof previousDebounceValue !== 'undefined' &&
      typeof debounceValue !== 'undefined' &&
      !disabled
    ) {
      const newUnderEditionBeams = underEditionBeams.map(underEditionBeam => {
        if (underEditionBeam.selected) {
          const initialTx = underEditionBeam.tx;
          const initialRx = underEditionBeam.rx;

          if (forward) {
            const tx: ITxDatabaseBeam = {
              ...initialTx,
              b: debounceValue
            };
            return {
              ...underEditionBeam,
              tx
            };
          } else {
            const rx: IRxDatabaseBeam = {
              ...initialRx,
              b: debounceValue
            };
            return {
              ...underEditionBeam,
              rx
            };
          }
        }
        return { ...underEditionBeam };
      });
      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }
  }, [debounceValue, dispatch, forward, underEditionBeams, previousDebounceValue, disabled]);

  const onChange = (e: number) => {
    setValue(e);
  };

  const marks = getMarks(disabled);

  return (
    <Form.Group className="common-slider">
      <Form.Label className="d-flex justify-content-center mb-0">
        {t(forward ? 'area.targets.bandForwardPerBeam' : 'area.targets.bandReturnPerBeam')}
      </Form.Label>
      <SkyTSlider onChange={onChange} marks={marks} min={0} max={500} value={value} step={1} disabled={disabled} />
    </Form.Group>
  );
};

export default BandPerBeamSlider;
