import classnames from 'classnames';
import { FC } from 'react';
import { ToggleButton, ToggleButtonProps } from 'react-bootstrap';
import './skyTButton.scss';

const SkyTToggleButton: FC<ToggleButtonProps> = props => {
  const { children, className, ...rest } = props;

  return (
    <ToggleButton className={classnames('skyt-button skyt-toggle-button', className)} {...rest}>
      {children}
    </ToggleButton>
  );
};

export default SkyTToggleButton;
