import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IRootState } from 'config/store';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { searchUserByString } from 'shared/api/api';
import InputError from 'shared/form/InputError';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { SearchedUser, User } from 'shared/model/user.model';
import { fetchProject } from 'shared/reducers/projectSlice';
import { reset as resetTeamMembers, saveTeamMember } from 'shared/reducers/teamMemberSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import SkyTToggleButton from 'shared/widgets/buttons/skyTToggleButton';
import SkyTAsyncSelect from 'shared/widgets/form/skyTAsyncSelect';

interface ICreateOrEditTeamMemberProps {
  teamMember?: User;
}

const CreateOrEditTeamMember = (props: ICreateOrEditTeamMemberProps) => {
  const dispatch = useDispatch();
  const { teamMember } = props;
  const { t } = useTranslation('translation');
  const refFirstName = useRef<HTMLInputElement>();

  const { projectId } = useParams<{ projectId: string }>();

  const updating = useSelector(({ teamMember }: IRootState) => teamMember.updating);
  const updateSuccess = useSelector(({ teamMember }: IRootState) => teamMember.updateSuccess);

  const isNew = teamMember ? false : true;
  const [show, setShow] = useState(false);

  const [searchEnable, setSearchEnable] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: teamMember?.firstName,
      lastName: teamMember?.lastName,
      mail: teamMember?.mail,
      phone: teamMember?.phone,
      company: teamMember?.company
    }
  });

  useEffect(() => {
    reset({
      firstName: teamMember?.firstName,
      lastName: teamMember?.lastName,
      mail: teamMember?.mail,
      phone: teamMember?.phone,
      company: teamMember?.company
    });
  }, [teamMember, reset]);

  useEffect(() => {
    if (show && updateSuccess) {
      setShow(false);
      reset({
        firstName: teamMember?.firstName,
        lastName: teamMember?.lastName,
        mail: teamMember?.mail,
        phone: teamMember?.phone,
        company: teamMember?.company
      });
      dispatch(resetTeamMembers());
      dispatch(fetchProject(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projectId, reset, show, updateSuccess]);

  const handleClose = () => {
    setShow(false);
    setSearchEnable(true);
    reset({
      firstName: teamMember?.firstName,
      lastName: teamMember?.lastName,
      mail: teamMember?.mail,
      phone: teamMember?.phone,
      company: teamMember?.company
    });
  };
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      if (refFirstName.current) {
        refFirstName.current?.focus();
      }
    }, 200);
  };

  const onSubmit = (data: any) => {
    if (isNew) {
      dispatch(saveTeamMember(projectId, data));
    } else {
      const toSave = {
        ...teamMember,
        ...data
      };
      dispatch(saveTeamMember(projectId, toSave));
    }
  };

  const title = isNew ? t('teamMember.addTitle') : t('teamMember.editTitle');
  const subTitle = isNew ? t('teamMember.addSubTitle') : t('teamMember.editSubTitle');
  let okLabel = t('edit');
  if (isNew) {
    if (searchEnable) {
      okLabel = t('add');
    } else {
      okLabel = t('create');
    }
  }

  const searchByName = debounce((inputValue: string, callback) => {
    searchUserByString(inputValue, 20)
      .then(response => response.data.map(item => ({ label: `${item.firstName} ${item.lastName}`, value: item.mail, ot: item })))
      .then(options => callback(options));
  }, 400);

  const onLastNameChange = (value: any) => {
    const person = value.ot as SearchedUser;
    setValue('lastName', person.lastName);
    setValue('firstName', person.firstName);
    setValue('mail', person.mail);
    setValue('phone', person.phone);
    setValue('company', person.company);
  };

  const formatSearch = (option: any, labelMeta: any) => {
    if (labelMeta.context === 'menu') {
      const ot = option.ot as SearchedUser;
      return (
        <div>
          <div>{option.label}</div>
          {ot.mail && <div className="text-muted small">{ot.mail}</div>}
          {ot.company && <div className="text-muted small">{ot.company}</div>}
        </div>
      );
    }

    return (
      <div>
        {option.ot.lastName} {option.ot.firstName}
      </div>
    );
  };

  const enableSearch = () => {
    setSearchEnable(true);
  };

  const disableSearch = () => {
    setSearchEnable(false);
  };

  return (
    <>
      <Modal className="skyt-modal" backdrop="static" show={show} onHide={handleClose}>
        {show && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>
                <h4>{title}</h4>
                <h6>{subTitle}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left pt-1 ">
              {isNew && (
                <ButtonGroup className="mb-2 w-100">
                  <SkyTToggleButton
                    className="add-create-toggle-btn"
                    type="checkbox"
                    variant="outline-primary"
                    checked={searchEnable}
                    value="1"
                    onClick={enableSearch}
                  >
                    {t('teamMember.addExistingMember')}
                  </SkyTToggleButton>
                  <SkyTToggleButton
                    className="add-create-toggle-btn"
                    type="checkbox"
                    variant="outline-primary"
                    checked={!searchEnable}
                    value="2"
                    onClick={disableSearch}
                  >
                    {t('teamMember.createMember')}
                  </SkyTToggleButton>
                </ButtonGroup>
              )}
              {searchEnable && (
                <Form.Group as={Row}>
                  <Form.Label column sm="4" className={classNames({ 'text-danger': errors.lastName })}>
                    {t('search')}
                  </Form.Label>
                  <Col sm="8">
                    <SkyTAsyncSelect
                      placeholder={t('project.searchUserPlaceholder')}
                      loadOptions={searchByName}
                      onChange={onLastNameChange}
                      formatOptionLabel={formatSearch}
                      disabled={updating}
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.lastName })}>
                  {t('lastName')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput
                    placeholder="Last Name"
                    disabled={updating}
                    {...register('lastName', {
                      required: true,
                      maxLength: 255
                    })}
                  />
                  <InputError name="lastName" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.firstName })}>
                  {t('firstName')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput
                    placeholder="First name"
                    disabled={updating}
                    {...register('firstName', {
                      required: true,
                      maxLength: 255
                    })}
                  />
                  <InputError name="firstName" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.mail })}>
                  {t('mail')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput
                    placeholder="Email"
                    disabled={updating}
                    {...register('mail', {
                      required: true,
                      maxLength: 255,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Entered value does not match email format'
                      }
                    })}
                  />
                  <InputError name="mail" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.phone })}>
                  {t('phone')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput
                    placeholder="Phone number"
                    disabled={updating}
                    {...register('phone', {
                      required: true,
                      maxLength: 255
                    })}
                  />
                  <InputError name="phone" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.company })}>
                  {t('teamMember.company')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput placeholder="Company" disabled={updating} {...register('company', { required: true, maxLength: 255 })} />
                  <InputError name="company" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <SkyTButton onClick={handleClose} disabled={updating}>
                {t('cancel')}
              </SkyTButton>
              <SkyTButton type="submit" disabled={updating}>
                {okLabel}
              </SkyTButton>
            </Modal.Footer>
          </form>
        )}
      </Modal>
      {isNew ? (
        <SkyTButton size="sm" variant="outline-info" onClick={handleShow}>
          <span>{t('project.addMember')}</span>
          <FontAwesomeIcon icon="plus" className="ml-2" />
        </SkyTButton>
      ) : (
        <SkyTButton size="sm" variant="outline-info" onClick={handleShow}>
          <FontAwesomeIcon icon="pencil-alt" className="mr-1" />
        </SkyTButton>
      )}
    </>
  );
};

export default CreateOrEditTeamMember;
