import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IRootState } from 'config/store';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputError from 'shared/form/InputError';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Customer } from 'shared/model/customer.model';
import { saveCustomer } from 'shared/reducers/customerSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import SkyTToggleSwitch from 'shared/widgets/SkyTToggleSwitch';

interface ICreateOrEditCustomerProps {
  customer?: Customer;
  onClose?: () => void;
}

const CreateOrEditCustomer = (props: ICreateOrEditCustomerProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const refName = useRef<HTMLInputElement>();
  const updating = useSelector(({ customer }: IRootState) => customer.updating);
  const updateSuccess = useSelector(({ customer }: IRootState) => customer.updateSuccess);

  const { customer, onClose } = props;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: customer?.name,
      isOperator: customer?.isOperator
    }
  });

  const isNew = customer ? false : true;
  const [show, setShow] = useState(false);
  const previousShow = usePrevious(show);

  const { ref, ...rest } = register('name', { required: true, maxLength: 255 });

  useEffect(() => {
    reset({
      name: customer?.name,
      isOperator: customer?.isOperator
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    setShow(false);
    reset({
      name: customer?.name,
      isOperator: customer?.isOperator
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  useEffect(() => {
    if (previousShow && !show) {
      if (onClose) {
        onClose();
      }
    }
  }, [show, previousShow, onClose]);

  const handleClose = () => {
    setShow(false);
    reset({
      name: customer?.name,
      isOperator: customer?.isOperator
    });
  };

  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      if (refName.current) {
        refName?.current?.focus();
      }
    }, 200);
  };

  const onSubmit = (data: any) => {
    let toSave = {
      ...data
    };
    if (!isNew) {
      toSave = {
        ...customer,
        ...data
      };
    }
    dispatch(saveCustomer(toSave));
  };

  const title = isNew ? t('createCustomerTitle') : t('editCustomerTitle');
  const subTitle = isNew ? t('createCustomerTitle') : t('editCustomerTitle');
  const ok = isNew ? t('add') : t('apply');

  return (
    <>
      <Modal className="skyt-modal" show={show} onHide={handleClose}>
        {show && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>
                <h4>{title}</h4>
                <h6>{subTitle}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left">
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.name })}>
                  {t('name')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput
                    placeholder="Tape a name here"
                    disabled={updating}
                    ref={(e: any) => {
                      ref(e);
                      refName.current = e;
                    }}
                    {...rest}
                  />
                  <InputError name="name" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Operator
                </Form.Label>
                <Col sm="8" className="text-right">
                  <Controller
                    name="isOperator"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <SkyTToggleSwitch
                        className="mt-1"
                        name={field.name}
                        ref={field.ref}
                        onChange={field.onChange}
                        checked={field.value as boolean}
                        disabled={updating}
                      />
                    )}
                  />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <SkyTButton onClick={handleClose} disabled={updating}>
                {t('cancel')}
              </SkyTButton>
              <SkyTButton type="submit" disabled={updating}>
                {ok}
              </SkyTButton>
            </Modal.Footer>
          </form>
        )}
      </Modal>
      {isNew ? (
        <SkyTButton variant="outline-primary" className="ml-1" onClick={handleShow}>
          <div className="d-flex justify-content-between align-items-center">
            <span>{t('createCustomerBtn')}</span>
            <FontAwesomeIcon icon={['far', 'user']} />
          </div>
        </SkyTButton>
      ) : (
        <SkyTButton size="sm" onClick={handleShow}>
          <FontAwesomeIcon icon="pencil-alt" className="mr-1" />
          <span>{t('edit')}</span>
        </SkyTButton>
      )}
    </>
  );
};

export default CreateOrEditCustomer;
