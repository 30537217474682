import { IRootState } from 'config/store';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MissionDetails } from 'shared/model/mission.model';
import Loader from 'shared/widgets/loader';

const AreaOfInterestTabTitle = () => {
  const { t } = useTranslation('translation');
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const loadingDetails = useSelector(({ mission }: IRootState) => mission.loadingDetails);

  let count = 0;
  missions.forEach(item => {
    const missionDetails = item as MissionDetails;
    if (missionDetails.areaOfInterests) {
      count += missionDetails.areaOfInterests.length;
    }
  });

  return (
    <div className="df-jb-ac unselectable pr-2">
      {t('areaOfInteret.areasOfInterets')}
      {loadingDetails !== -1 ? (
        <span className="ml-2">
          <Loader size="sm" inline />
        </span>
      ) : (
        <Badge pill variant="info" className="ml-2">
          {count}
        </Badge>
      )}
    </div>
  );
};
export default AreaOfInterestTabTitle;
