import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { addTeamMember, deleteTeamMember, editTeamMember } from 'shared/api/api';
import { User } from 'shared/model/user.model';
import { fetchProject } from './projectSlice';

const initialState = {
  updating: false,
  updateSuccess: false,
  error: null as any
};

export type TeamMemberState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'teamMember',
  initialState,
  reducers: {
    updateStart: state => {
      state.updating = true;
      state.updateSuccess = false;
      state.error = null;
    },
    updateSuccess: (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    reset: state => {
      state.updateSuccess = false;
      state.updating = false;
      state.error = null;
    }
  }
});

const { updateStart, updateFailed, updateSuccess, deleteSuccess } = slice.actions;

export const { reset } = slice.actions;

export const saveTeamMember =
  (projectId: number | string, teamMember: User): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = teamMember.id ? await editTeamMember(projectId, teamMember) : await addTeamMember(projectId, teamMember);
      dispatch(updateSuccess(response.data));
      dispatch(fetchProject(projectId));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeTeamMember =
  (projectId: string | number, teamMemberId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteTeamMember(projectId, teamMemberId);
      dispatch(fetchProject(projectId));
      dispatch(deleteSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
