import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { tempGatewayLayer } from 'modules/map/layers/gateway/gateway-layer-def';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { setGatewayModel, setSelectedGatewayBandwidth, setSelectedGatewayPolarization } from 'shared/reducers/GatewaySlice';
import { showMap } from 'shared/reducers/mapSlice';
import { gatewaysRoute } from 'shared/routes/routes';
import { layerInMap } from 'shared/utils/map-utils';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import NoResult from 'shared/widgets/no-result';
import '../pois/poi-gateway-item.scss';
import './configure-gateway.scss';
import GatewayModelItem from './gatewayModelItem';

const ConfigureGateway = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const location = useLocation();
  const project = useSelector(({ project }: IRootState) => project.project);
  const gatewayModels = useSelector(({ referential }: IRootState) => referential.gatewayModels);
  const underEditionFeature = useSelector(({ gateway }: IRootState) => gateway.underEditionFeature);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);

  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
  }, [dispatch, mapIsDisplayed]);

  useEffect(() => {
    if (arcgisMap) {
      if (!layerInMap(arcgisMap, tempGatewayLayer.id)) {
        arcgisMap.add(tempGatewayLayer);
      }
    }
    return () => {
      if (arcgisMap && layerInMap(arcgisMap, tempGatewayLayer.id)) {
        arcgisMap.remove(tempGatewayLayer);
      }
    };
  }, [arcgisMap]);

  useEffect(() => {
    if (underEditionFeature) {
      tempGatewayLayer.removeAll();
      tempGatewayLayer.add(underEditionFeature);
      const modelId = underEditionFeature.getAttribute('modelId');
      if (modelId) {
        const model = gatewayModels.find(item => item.id === modelId);
        if (model) {
          dispatch(setGatewayModel(model));
        }
      }
      const bandwidthsStr = underEditionFeature.getAttribute('bandwidths');
      if (bandwidthsStr && bandwidthsStr.length > 0) {
        const bandwidths = JSON.parse(bandwidthsStr);
        if (isArray(bandwidths) && bandwidths.length > 0) {
          dispatch(setSelectedGatewayBandwidth(bandwidths[0]));
        }
      }
      const polarization = underEditionFeature.getAttribute('polarization');
      if (!isNil(polarization)) {
        dispatch(setSelectedGatewayPolarization(polarization));
      }
    } else {
      const newHistory = gatewaysRoute.getPath(projectId);
      history.push(newHistory);
    }
  }, [underEditionFeature, history, location.pathname, projectId, gatewayModels, dispatch]);

  if (!project) {
    return null;
  }

  const onCancel = () => {
    const newHistory = gatewaysRoute.getPath(projectId);
    history.push(newHistory);
  };

  const hasWriteAccess = projectHasWriteAccess(project);
  if (!hasWriteAccess) {
    onCancel();
  }

  return (
    <div className="configure-gateway">
      <div className="configure-gateway-title">
        <div>{t('gateways.configureGateway')}</div>
        <SkyTButton variant="link" className="text-primary" onClick={onCancel}>
          <FontAwesomeIcon icon="times" />
        </SkyTButton>
      </div>
      <div className="px-1">
        {gatewayModels.map(item => (
          <GatewayModelItem key={item.id} item={item} />
        ))}
        {gatewayModels.length === 0 && <NoResult />}
      </div>
    </div>
  );
};

export default ConfigureGateway;
