import { Badge, ListGroup } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { ProjectDetails } from 'shared/model/project.model';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import NoResult from 'shared/widgets/no-result';
import CreateOrEditTeamMember from './createOrEditTeamMember';
import JoinAsTeamMember from './joinAsTeamMermber';
import TeamMember from './teamMember';
import './teamMembers.scss';

const style = { height: '435px' };

interface ITeamMembersProps {
  project: ProjectDetails;
}

const TeamMembers = (props: ITeamMembersProps) => {
  const { t } = useTranslation('translation');
  const { project } = props;

  const writeAccess = projectHasWriteAccess(project);

  const members = project?.teamMembers ?? [];

  return (
    <div>
      <div className="mb-3 d-flex">
        <h4 className="font-weight-bold">{t('project.teamMembers')}</h4>
        {members.length > 0 && (
          <div className="ml-4">
            <Badge pill variant="info" className="mt-1">
              {members.length}
            </Badge>
          </div>
        )}
      </div>
      <Scrollbars autoHide style={style} className="team-members skyt-scroll-bar">
        <ListGroup variant="flush">
          {members.map(item => (
            <TeamMember key={item.id} user={item} readonly={!writeAccess} />
          ))}
        </ListGroup>
      </Scrollbars>
      {(project.teamMembers === undefined || project.teamMembers.length === 0) && <NoResult />}
      <div className="text-center">{writeAccess ? <CreateOrEditTeamMember /> : <JoinAsTeamMember project={project} />}</div>
    </div>
  );
};

export default TeamMembers;
