import classNames from 'classnames';
import { IRootState } from 'config/store';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MissionDetails, MissionService } from 'shared/model/mission.model';
import { setUnderEditionBeams, setUpdatingBeamsSelection } from 'shared/reducers/areaSlice';
import './EditServicesArea.scss';
import { refreshSliderDelay } from './sliders/common-slider';
import ServiceSlider from './sliders/ServiceSlider';

const EditServicesArea = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions) as MissionDetails[];
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);

  const services = selectedMissions.reduce((agg: MissionService[], current) => {
    const toAdd = current.services.filter(service => !agg.some(item => item.name === service.name));
    return agg.concat(toAdd);
  }, []);

  useEffect(() => {
    // select all beams
    const newUnderEdition = underEditionBeams.map(item => {
      return {
        ...item,
        selected: true
      };
    });
    dispatch(setUpdatingBeamsSelection(newUnderEdition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (service: MissionService) =>
    debounce((percent: number) => {
      const newUnderEditionBeams = cloneDeep(underEditionBeams);

      newUnderEditionBeams.forEach(underEditionBeam => {
        let serviceFound = underEditionBeam.sv.find(item => item.n === service.name);
        if (!serviceFound) {
          serviceFound = {
            n: service.name,
            v: 0
          };
          underEditionBeam.sv.push(serviceFound);
        }
        serviceFound.v = percent;
      });

      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }, refreshSliderDelay);

  // compute the mean percent
  const percent = useMemo(() => {
    let total = 0;
    if (underEditionBeams.length === 0) {
      return 0;
    }
    underEditionBeams.forEach(dbBeam => {
      dbBeam.sv.forEach(sv => (total += sv.v));
    });
    return total / underEditionBeams.length;
  }, [underEditionBeams]);

  const remaining = 100 - percent;

  const disabled = underEditionBeams.every(item => !item.selected);

  return (
    <div className="edit-services-area">
      <div className={classNames('mb-2 total-title', { error: remaining < 0 })}>
        {t('area.services.total', { percent })} ({remaining} %)
      </div>
      <div className="mb-4 flex-grow-1 d-flex flex-column justify-content-center">
        {services.map(service => {
          // for each beams under edition find the first one with a service
          const defaultDbBeam = underEditionBeams.find(dbBeam => dbBeam.sv.some(item => item.n === service.name));

          const defaultService = defaultDbBeam ? defaultDbBeam.sv.find(item => item.n === service.name) : undefined;
          const defaultValue = defaultService ? defaultService.v : 0;

          return (
            <ServiceSlider
              key={`service_${service.name}`}
              service={service}
              onChange={onChange(service)}
              defaultValue={defaultValue}
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EditServicesArea;
