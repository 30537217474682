import { IRootState } from 'config/store';
import React from 'react';
import { useSelector } from 'react-redux';
import NoResult from 'shared/widgets/no-result';
import SatelliteModelItem from '../satelliteModelItem';
import './configure-satellite.scss';

const ConfigureSatelliteType = () => {
  const satelliteModels = useSelector(({ referential }: IRootState) => referential.satelliteModels);

  return (
    <div>
      {satelliteModels.map(item => (
        <SatelliteModelItem key={item.id} item={item} />
      ))}
      {satelliteModels.length === 0 && <NoResult />}
    </div>
  );
};

export default ConfigureSatelliteType;
