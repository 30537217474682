import Graphic from '@arcgis/core/Graphic';
import { IRootState } from 'config/store';
import { tempAreaOfInterestsLayer } from 'modules/map/layers/areaOfInterets/areaOfInterestLayer-def';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AreaOfInterestType } from 'shared/model/areaOfInterest.model';
import { setTypeOfArea, setUnderEditionFeatures } from 'shared/reducers/areaOfInterestSlice';
import DrawPolygon from 'shared/widgets/map/draw/DrawPolygon';
import EditPolygon from 'shared/widgets/map/draw/EditPolygon';
import AreaTypeIcon from '../icons/AreaTypeIcon';

const DrawPolygonAreaBtn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const underEditionFeatures = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.underEditionFeatures);

  const onCancel = () => {
    dispatch(setTypeOfArea(null));
  };

  const onComplete = (g: Graphic) => {
    dispatch(setUnderEditionFeatures([g]));
  };

  return (
    <div className="mb-2">
      <div className="d-flex align-items-center mb-2">
        <div className="mr-2">
          <AreaTypeIcon type={AreaOfInterestType.POLYGON} />
        </div>
        <div className="mt-1">{t('areaOfInteret.drawPolygon')}</div>
      </div>
      {underEditionFeatures.length > 0 ? (
        <EditPolygon layer={tempAreaOfInterestsLayer} graphics={underEditionFeatures} onCancel={onCancel} onComplete={onComplete} />
      ) : (
        <DrawPolygon layer={tempAreaOfInterestsLayer} onCancel={onCancel} onComplete={onComplete} />
      )}
    </div>
  );
};

export default DrawPolygonAreaBtn;
