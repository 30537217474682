import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Area } from 'shared/model/area.model';
import { Coverage, CoverageType } from 'shared/model/coverage.model';
import { MissionDetails } from 'shared/model/mission.model';
import { getGeometriesOfAreaOfInterest } from 'shared/utils/model-utils';
import { getBeamId, getMissionSymbol, getRegionalBeamI, saveMissionSymbol, toBeamAttribute } from '../beam-layer-def';
import { IUnderEditionDatabaseBeam } from '../databaseBeam.model';

interface IUseEditRegionalBeamProps {
  layer: GraphicsLayer;
}

const useEditRegionalBeam = (props: IUseEditRegionalBeamProps) => {
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const area = useSelector(({ area }: IRootState) => area.area);

  const { layer } = props;

  const isRegionalCoverageType = coverage?.coverageType === CoverageType.REGIONAL;

  useEffect(() => {
    if (configuration && coverage && area && isRegionalCoverageType) {
      updateBeams(layer, coverage as Coverage, area as Area, missions, underEditionBeams);
      setTimeout(() => {
        currentView?.goTo(layer.graphics);
      }, 200);
    }
  }, [area, configuration, coverage, isRegionalCoverageType, layer, missions, underEditionBeams]);
  return layer;
};

const updateBeams = (
  layer: GraphicsLayer,
  coverage: Coverage,
  area: Area,
  missions: MissionDetails[],
  underEditionBeams: IUnderEditionDatabaseBeam[]
) => {
  const toRemove = layer.graphics.filter(g => g.getAttribute('areaId') !== area.id);
  layer.removeMany(toRemove.toArray());

  missions.forEach(mission => {
    const areaOfInterests = mission.areaOfInterests.find(item => item.id === area.areaOfInterestId);
    if (areaOfInterests) {
      const currentI = getRegionalBeamI(mission, missions, areaOfInterests);
      const underEditionBeam = underEditionBeams.find(item => item.i === currentI);
      if (underEditionBeam) {
        const beamId = getBeamId(coverage.coverageType as CoverageType, area.id, underEditionBeam.i);
        const existingGraphics = layer.graphics.find(item => item.getAttribute('ObjectID') === beamId);
        if (existingGraphics) {
          const attributes = {
            ...existingGraphics.attributes,
            ...toBeamAttribute(underEditionBeam)
          };
          existingGraphics.attributes = attributes;
        } else {
          const polygons = getGeometriesOfAreaOfInterest(areaOfInterests);
          const polygon = polygons.length > 1 ? geometryEngine.union(polygons) : polygons[0];

          const attributes = {
            ...toBeamAttribute(underEditionBeam),
            ObjectID: getBeamId(CoverageType.REGIONAL, area.id, areaOfInterests.id),
            name: areaOfInterests.name,
            type: coverage.coverageType,
            missionId: mission.id,
            coverageId: coverage.id,
            areaOfInterestId: area.areaOfInterestId,
            areaId: area.id
          };

          const graphic = new Graphic({
            geometry: polygon,
            attributes,
            symbol: getMissionSymbol(mission)
          });
          saveMissionSymbol(graphic, mission);
          layer.add(graphic);
        }
      }
    }
  });
};

export default useEditRegionalBeam;
