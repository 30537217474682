import classnames from 'classnames';
import { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import './skyTButton.scss';

const SkyTButton: FC<ButtonProps> = props => {
  const { children, className, ...rest } = props;

  return (
    <Button className={classnames('skyt-button', className)} {...rest}>
      {children}
    </Button>
  );
};

export default SkyTButton;
