import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useContext, useEffect, useMemo } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ProjectDetails } from 'shared/model/project.model';
import { showMap } from 'shared/reducers/mapSlice';
import { gatewayRoute, gatewaysRoute } from 'shared/routes/routes';
import { sortByName } from 'shared/utils/array-utils';
import { projectHasWriteAccess, someMissionNotComplete } from 'shared/utils/model-utils';
import SkyTAccordionTab from 'shared/widgets/accordion/skyTAccordionTab';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';
import GatewayItem from './GatewayItem';
import GatewaysTabTitle from './GatewaysTabTitle';

export const gatewayKey = 'gateways';

const GatewaysTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const { projectId } = useParams<{ projectId: string }>();
  const project = useSelector(({ project }: IRootState) => project.project);
  const loading = useSelector(({ project }: IRootState) => project.loading);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const hasWriteAccess = projectHasWriteAccess(project);
  const currentEventKey = useContext(AccordionContext);

  const isActive = currentEventKey === gatewayKey;
  const isDisabled = someMissionNotComplete(missions);

  const projectDetail = project as ProjectDetails;

  useEffect(() => {
    if (isActive) {
      if (!mapIsDisplayed) {
        dispatch(showMap());
      }
      const newHistory = gatewaysRoute.getPath(projectId);
      history.push(newHistory);
    }
  }, [isActive, dispatch, mapIsDisplayed, projectId, history]);

  const title = <GatewaysTabTitle />;

  const sorted = useMemo(() => {
    const result = projectDetail ? [...projectDetail.gateways] : [];
    result.sort(sortByName());
    return result;
  }, [projectDetail]);

  const onCreate = () => {
    history.push(gatewayRoute.getPath(projectId, 'new'));
  };

  return (
    <SkyTAccordionTab eventKey={gatewayKey} title={title} disabled={isDisabled}>
      {isActive && (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Scrollbars autoHide autoHeight autoHeightMax={160} className="skyt-scroll-bar mb-2">
                {sorted.map(item => (
                  <GatewayItem key={item.id} gateway={item} />
                ))}
              </Scrollbars>

              {hasWriteAccess && (
                <div className="text-right">
                  <SkyTMapButton className="add-poi-btn" variant="outline-info" type="button" size="sm" onClick={onCreate}>
                    {t('gateways.createGatewayBtn')}
                    <FontAwesomeIcon icon="plus" className="ml-1" />
                  </SkyTMapButton>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </SkyTAccordionTab>
  );
};

export default GatewaysTab;
