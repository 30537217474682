import Graphic from '@arcgis/core/Graphic';
import { IRootState } from 'config/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProjectDetails } from 'shared/model/project.model';
import { createPoiGraphic } from 'shared/utils/map-utils';
import { addAndOrderLayer } from '../layerOrder';
import { poiLayer } from './poi-layer-def';

const usePoiLayer = () => {
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const project = useSelector(({ project }: IRootState) => project.project);

  useEffect(() => {
    if (arcgisMap) {
      addAndOrderLayer(arcgisMap, poiLayer);
    }
  }, [arcgisMap]);

  const projectDetails = project as ProjectDetails;
  useEffect(() => {
    let addFeatures: Graphic[] = [];
    if (projectDetails?.pois.length > 0) {
      addFeatures = projectDetails.pois.map(item => createPoiGraphic(item.longitude, item.latitude, item.name, item.id));
    }
    poiLayer.queryFeatures().then(result => {
      poiLayer.applyEdits({
        addFeatures,
        deleteFeatures: result.features
      });
    });
  }, [projectDetails?.pois]);
};

export default usePoiLayer;
