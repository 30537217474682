export enum NotificationType {
  REQUESTASK = 'ACCESS_REQUEST_ASK',
  REQUESTACCEPT = 'ACCESS_REQUEST_ACCEPT',
  REQUESTDENY = 'ACCESS_REQUEST_DENY',
  MEMBERSHIPASK = 'ACCESS_MEMBERSHIP_ASK',
  MEMBERSHIPACCEPT = 'ACCESS_MEMBERSHIP_ACCEPT',
  MEMBERSHIPDENY = 'ACCESS_MEMBERSHIP_DENY',
  GRAPHRESULT = 'GRAPH_RESULT'
}

export interface Notification {
  id: number;
  initiatorFullName?: string;
  type: NotificationType;
  date: Date;
  objectId?: number;
  concernedUserFullName?: string;
  projectId?: number;
  message?: string;
  title?: string;
}
