// import webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Satellite } from 'shared/model/satellite.model';
import { editSatelliteRoute } from 'shared/routes/routes';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';

interface IConfigureSatelliteBtnProps {
  satellite: Satellite;
}

const ConfigureSatelliteBtn = (props: IConfigureSatelliteBtnProps) => {
  const history = useHistory();

  const project = useSelector(({ project }: IRootState) => project.project);
  const hasWriteAccess = projectHasWriteAccess(project);

  const { satellite } = props;

  const enableEdtion = () => {
    if (project) {
      history.push(editSatelliteRoute.getPath(project.id, satellite.id));
      // TODO dispatch(setUnderEditionSatellite(satellite));
    }
  };

  if (!hasWriteAccess) {
    return null;
  }

  return (
    <SkyTButton variant="link" onClick={enableEdtion} size="sm" className="text-primary">
      <FontAwesomeIcon icon="pencil-alt" />
    </SkyTButton>
  );
};

export default ConfigureSatelliteBtn;
