import { IRootState } from 'config/store';
import React, { useContext, useEffect } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { showMap } from 'shared/reducers/mapSlice';
import { exportsRoute } from 'shared/routes/routes';
import { someMissionNotComplete } from 'shared/utils/model-utils';
import SkyTAccordionTab from 'shared/widgets/accordion/skyTAccordionTab';
import { confSatItemPrefix } from '../configurations/configurationSatellliteItem';
import ExportsTabTitle from './exportsTabTitle';
import ExportTabContent from './ExportTabContent';

export const exportKey = 'exports';

const ExportsTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentEventKey = useContext(AccordionContext);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);

  const { projectId, satelliteId } = useParams<{ projectId: string; satelliteId: string }>();

  const isActive = currentEventKey === exportKey;
  const isDisabled = someMissionNotComplete(missions);

  const defaultActiveKey = satelliteId ? confSatItemPrefix + '_' + satelliteId : undefined;

  useEffect(() => {
    if (isActive) {
      if (!mapIsDisplayed) {
        dispatch(showMap());
      }

      if (projectId && !defaultActiveKey) {
        const newHistory = exportsRoute.getPath(projectId);
        history.push(newHistory);
      }
    }
  }, [defaultActiveKey, dispatch, history, isActive, mapIsDisplayed, projectId]);

  const title = <ExportsTabTitle />;

  return (
    <SkyTAccordionTab eventKey={exportKey} title={title} bodyClassName="p-0" disabled={isDisabled}>
      {isActive && <ExportTabContent />}
    </SkyTAccordionTab>
  );
};

export default ExportsTab;
