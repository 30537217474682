import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import { IRootState } from 'config/store';
import isNumber from 'lodash/isNumber';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProjectDetails } from 'shared/model/project.model';
import { makeGlobalAreaBeam } from 'shared/utils/beam-utils';
import { hexToRgb } from 'shared/utils/color-utils';
import { computeReflector, getSelectorSymbol } from 'shared/utils/reflector-util';
import { addAndOrderLayer } from '../layerOrder';
import { getSatellitePolylineGraphic, getSatEquatorLabel, getSatLongitudeLabel, getSatPointGraphic } from './satelliteUtils';

export const satelliteLayerId = 'satellites';

export const satelliteLayer = new GraphicsLayer({
  id: satelliteLayerId,
  title: 'Satellites',
  elevationInfo: {
    mode: 'relative-to-ground'
  }
});

const useSatelliteLayer = () => {
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const isFlat = useSelector(({ map }: IRootState) => map.flat);
  const project = useSelector(({ project }: IRootState) => project.project);
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const selectedSatellite = useSelector(({ satellite }: IRootState) => satellite.selectedSatellite);
  const showGlobalBeam = useSelector(({ satellite }: IRootState) => satellite.showGlobalBeam);
  const satelliteModels = useSelector(({ referential }: IRootState) => referential.satelliteModels);

  useEffect(() => {
    if (arcgisMap) {
      addAndOrderLayer(arcgisMap, satelliteLayer);
    }
  }, [arcgisMap]);

  const projectDetails = project as ProjectDetails;

  useEffect(() => {
    satelliteLayer.removeAll();

    const satelliteId = configuration ? configuration.satelliteId : selectedSatellite ? selectedSatellite.id : undefined;

    if (projectDetails?.satellites?.length > 0 && satelliteId) {
      // show only sat orbit with configuration (for light version)
      const sats = projectDetails.satellites.filter(sat => sat.id === satelliteId);
      const graphics: Graphic[] = [];
      sats.forEach(sat => {
        const satModel = satelliteModels.find(model => model.id === sat.modelId);
        const lon = sat.orbitalPosition;

        const linesGraphics = getSatellitePolylineGraphic(lon, isFlat);
        graphics.push(linesGraphics);

        const satGraphic = getSatPointGraphic(lon, satModel);
        graphics.push(satGraphic);

        const longitudeLabel = getSatLongitudeLabel(lon, satGraphic.geometry as Point, isFlat);
        graphics.push(longitudeLabel);

        const equatorLabel = getSatEquatorLabel(satGraphic.geometry as Point, isFlat);
        graphics.push(equatorLabel);

        if (sat.reflector && isNumber(sat.reflector.centerLat)) {
          const reflector = sat.reflector;
          const reflectorGeom = computeReflector(
            reflector.centerLat as number,
            reflector.centerLng as number,
            reflector.majorAxisA as number,
            reflector.majorAxisB as number,
            sat.orbitalPosition
          );
          const reflectorGraphic = new Graphic({
            geometry: reflectorGeom,
            symbol: getSelectorSymbol()
          });
          graphics.push(reflectorGraphic);
        }

        if (showGlobalBeam) {
          const globalBeamGeom = makeGlobalAreaBeam(sat.orbitalPosition);
          const globalBeam = new Graphic({
            geometry: globalBeamGeom,
            symbol: new SimpleLineSymbol({
              color: hexToRgb('#ffffff'),
              width: 2,
              style: 'dash'
            })
          });
          graphics.push(globalBeam);
        }
      });

      satelliteLayer.addMany(graphics);
    }
  }, [projectDetails?.satellites, configuration, selectedSatellite, satelliteModels, isFlat, showGlobalBeam]);
};

export default useSatelliteLayer;
