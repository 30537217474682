import classnames from 'classnames';
import { IRootState } from 'config/store';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Mission } from 'shared/model/mission.model';
import { sortByName } from 'shared/utils/array-utils';
import SkyTBreadcrumb from 'shared/widgets/breadcrumb';
import Loader from 'shared/widgets/loader';
import NoResult from 'shared/widgets/no-result';
import ExportMissionItem from './ExportMissionItem';

const ResultsMenu = () => {
  const showMenuPanel = useSelector(({ map }: IRootState) => map.showMenuPanel);
  const exportItems = useSelector(({ exporter }: IRootState) => exporter.exports);

  const loading = useSelector(({ exporter }: IRootState) => exporter.loading);

  const uniqMissions = exportItems.reduce((agg: Partial<Mission>[], current) => {
    current.missions.forEach(mission => {
      if (!agg.some(item => item.id === mission.id)) {
        agg.push(mission);
      }
    });
    return agg;
  }, []);

  const sortedMissions = uniqMissions.sort(sortByName());

  return (
    <div className={classnames('results-menu map-is-displayed', { 'hide-content': !showMenuPanel })}>
      <div className="needs-menu-content">
        <SkyTBreadcrumb />
        <div>
          {loading ? (
            <div className="vcenter-loader">
              <Loader />
            </div>
          ) : (
            <Scrollbars autoHide autoHeight autoHeightMax={495} className="skyt-scroll-bar">
              {sortedMissions.map(mission => (
                <ExportMissionItem key={`mission_${mission.id}`} mission={mission} />
              ))}
              {sortedMissions.length === 0 && (
                <div className="vcenter-loader">
                  <NoResult />
                </div>
              )}
            </Scrollbars>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsMenu;
