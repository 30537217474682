import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useRouteMatch } from 'react-router-dom';
import { chartResultRoute, resultsRoute } from 'shared/routes/routes';
import ResultChart from '../charts/ResultChart';
import ChartsGrid from '../grid/ChartsGrid';

const style = {
  height: 'calc(100vh - 115px)'
};

const ResultsContent = () => {
  const mathResultsPage = useRouteMatch(resultsRoute.path)?.isExact;
  const matchChartResultRoute = useRouteMatch(chartResultRoute.path)?.isExact;

  return (
    <div className="results-content">
      {mathResultsPage && (
        <Scrollbars autoHide style={style} className="skyt-scroll-bar">
          <ChartsGrid />
        </Scrollbars>
      )}
      {matchChartResultRoute && <ResultChart />}
    </div>
  );
};

export default ResultsContent;
