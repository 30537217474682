import classnames from 'classnames';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Satellite } from 'shared/model/satellite.model';
import { setSelectedSatellite } from 'shared/reducers/satelliteSlice';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import ConfigureSatelliteBtn from './configureSatelliteBtn';
import DeleteSatelliteBtn from './deleteSatelliteBtn';
import './satellite-item.scss';
import SatelliteCardTitle from './satelliteCardTitle';

interface ISatelliteItemProps {
  item: Satellite;
}

const SatelliteItem = (props: ISatelliteItemProps) => {
  const dispatch = useDispatch();
  const project = useSelector(({ project }: IRootState) => project.project);
  const selectedSatellite = useSelector(({ satellite }: IRootState) => satellite.selectedSatellite);
  const hasWriteAccess = projectHasWriteAccess(project);
  const { item } = props;

  const [visible, setVisible] = useState(false);

  const selected = selectedSatellite?.id === item.id;

  const onClick = (e: SyntheticEvent) => {
    if (!selected) {
      currentView?.goTo({
        center: [item.orbitalPosition, 0],
        zoom: 3
      });
      dispatch(setSelectedSatellite(item));
    }
  };

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  return (
    <div
      className={classnames('satellite-item hover-white unselectable', { selected })}
      onClick={onClick}
      onMouseEnter={showActions}
      onMouseLeave={hideActions}
    >
      <SatelliteCardTitle item={item} />
      {visible && hasWriteAccess && (
        <div
          className={classnames('animate__animated', {
            animate__fadeIn: visible
          })}
        >
          <ConfigureSatelliteBtn satellite={item} />
          <DeleteSatelliteBtn satellite={item} />
        </div>
      )}
    </div>
  );
};

export default SatelliteItem;
