import { IRootState } from 'config/store';
import { useContext, useEffect } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { showMap } from 'shared/reducers/mapSlice';
import { allAreaOfInteretsRoute, areaOfInteretsRoute } from 'shared/routes/routes';
import { sortByName } from 'shared/utils/array-utils';
import { someMissionNotComplete } from 'shared/utils/model-utils';
import SkyTAccordionTab from 'shared/widgets/accordion/skyTAccordionTab';
import './areaOfInterets.scss';
import AreaOfInteretsMissionItem from './areaOfInteretsMission-item';
import AreaOfInterestTabTitle from './areaOfInteretsTabTitle';

export const areaOfInterestKey = 'area-of-interets';

const AreaOfInteretsTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const currentEventKey = useContext(AccordionContext);

  const { projectId, missionId } = useParams<{ projectId: string; missionId: string }>();

  const isCurrentTab = currentEventKey === areaOfInterestKey;
  const isDisabled = someMissionNotComplete(missions);

  useEffect(() => {
    if (isCurrentTab) {
      if (!mapIsDisplayed) {
        dispatch(showMap());
      }
      const newHistory = missionId ? areaOfInteretsRoute.getPath(projectId, missionId) : allAreaOfInteretsRoute.getPath(projectId);
      history.push(newHistory);
    }
  }, [isCurrentTab, dispatch, projectId, history, mapIsDisplayed, missionId]);

  const title = <AreaOfInterestTabTitle />;

  const orderedMission = [...missions].sort(sortByName());

  return (
    <SkyTAccordionTab title={title} eventKey={areaOfInterestKey} className="area-of-interets" disabled={isDisabled}>
      <div className="pr-1">{isCurrentTab && orderedMission.map(item => <AreaOfInteretsMissionItem key={item.id} mission={item} />)}</div>
    </SkyTAccordionTab>
  );
};

export default AreaOfInteretsTab;
