import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { saveMission } from 'shared/reducers/missionSlice';
import { sortByLabel, sortByName } from 'shared/utils/array-utils';
import { getAvailableServices, projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTSelect, { ILabelValueOption } from 'shared/widgets/form/skyTSelect';
import NoResult from 'shared/widgets/no-result';
import MissionServicesWarningIcon from '../shared/missionFrequencyBandWarningIcon copy';
import MissionServiceItem from './missionServiceItem';

const SelectMissionServices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const project = useSelector(({ project }: IRootState) => project.project);
  const hasWriteAccess = projectHasWriteAccess(project);
  const [value, setValue] = useState<any>();

  if (selectedMissions.length !== 1) {
    return null;
  }
  const mission = selectedMissions[0];

  const services = getAvailableServices(referential).filter(item => !mission.services.some(ut => ut.name === item.name));

  const servicesOptions = services.reduce((agg: any[], current) => {
    const currentCategory = current.category;
    let existingCategory = agg.find(item => item.label === currentCategory);
    if (!existingCategory) {
      existingCategory = {
        label: currentCategory,
        options: []
      };
      agg.push(existingCategory);
    }
    existingCategory.options.push({ label: current.name, value: current.name });

    return agg;
  }, []);

  servicesOptions.sort(sortByLabel());

  const onChange = (selection: ILabelValueOption<string>) => {
    const selected = services.find(item => item.name === selection.value);

    const newServices = [...mission.services];
    if (selected) {
      newServices.push(selected);

      const toSend: Mission = {
        ...mission,
        services: newServices
      };
      dispatch(saveMission(mission.projectId, toSend));
      setValue(null);
    }
  };

  const ordered = [...mission.services].sort(sortByName());

  return (
    <Form.Group className="mb-4">
      <Form.Label className="mb-4">
        {t('translation:mission.services')}
        <Badge pill variant="info" className="ml-2">
          {mission.services.length}
        </Badge>
        <MissionServicesWarningIcon mission={mission as MissionDetails} />
      </Form.Label>
      {mission.services.length === 0 ? (
        <> {!hasWriteAccess && <NoResult />}</>
      ) : (
        <Scrollbars autoHeight autoHeightMax={200} className="skyt-scroll-bar mb-2">
          {ordered.map(item => (
            <MissionServiceItem item={item} key={item.name} readonly={!hasWriteAccess} />
          ))}
        </Scrollbars>
      )}
      {hasWriteAccess && servicesOptions.length > 0 && (
        <SkyTSelect
          value={value}
          options={servicesOptions}
          placeholder={t('translation:mission.servicesPlaceholder')}
          onChange={onChange}
        />
      )}
    </Form.Group>
  );
};
export default SelectMissionServices;
