export enum AreaOfInterestType {
  COUNTRY = 'COUNTRY',
  POLYGON = 'POLYGON',
  FREEDRAW = 'FREEDRAW',
  GLOBAL = 'GLOBAL'
}

export interface AreaOfInterest {
  id: number;
  missionId: number;
  name: string;
  latLngs: string;
  type: AreaOfInterestType;
}
