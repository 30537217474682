export const resultMapColors = ['#1B1866', '#53B8D9', '#51A293', '#F5A030', '#B42573'];
export const resultClassBreakInfosColors = [
  '#1b1866',
  '#19366d',
  '#1a5b73',
  '#1c7a6f',
  '#1d804f',
  '#1e872b',
  '#3b8d1f',
  '#699420',
  '#9a9821',
  '#a16e22',
  '#a74023',
  '#ae243b',
  '#b42573'
];

export const NO_DATA = 'NO DATA';

export const verticalLegend = false;

export const resultLayerId = 'resultLayer';
