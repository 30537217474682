import { IRootState } from 'config/store';
import { beamGTDef, IRxDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from './common-slider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    '-15': <span className={className}>-15</span>,
    '-10': <span className={className}>-10</span>,
    '-5': <span className={className}>-5</span>,
    0: <span className={className}>0</span>,
    5: <span className={className}>5</span>,
    10: <span className={className}>10</span>,
    15: <span className={className}>15</span>,
    20: <span className={className}>20</span>,
    25: <span className={className}>25</span>
  };
};

interface IGTSliderProps {
  defaultValue?: number;
  disabled?: boolean;
}

const GTSlider = (props: IGTSliderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const { defaultValue = 0, disabled = false } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue);

  useEffect(() => {
    if (!disabled) {
      if (defaultValue) {
        setValue(defaultValue);
      } else {
        setValue(0);
      }
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (
      previousDebounceValue !== debounceValue &&
      typeof previousDebounceValue !== 'undefined' &&
      typeof debounceValue !== 'undefined' &&
      !disabled
    ) {
      const newUnderEditionBeams = underEditionBeams.map(underEditionBeam => {
        if (underEditionBeam.selected) {
          const initialRx = underEditionBeam.rx;

          const rx: IRxDatabaseBeam = {
            ...initialRx,
            g: debounceValue
          };
          return {
            ...underEditionBeam,
            rx
          };
        }
        return { ...underEditionBeam };
      });
      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }
  }, [debounceValue, disabled, dispatch, previousDebounceValue, underEditionBeams]);

  const onChange = (e: number) => {
    setValue(e);
  };

  const marks = getMarks(disabled);

  return (
    <Form.Group className="common-slider">
      <Form.Label className="d-flex justify-content-center mb-0">{t('area.targets.GT')}</Form.Label>
      <SkyTSlider onChange={onChange} marks={marks} min={beamGTDef.min} max={beamGTDef.max} value={value} step={1} disabled={disabled} />
    </Form.Group>
  );
};

export default GTSlider;
