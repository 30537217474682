import React from 'react';
import { useTranslation } from 'react-i18next';
import './RegionalEditor.scss';

const RegionalEditor = () => {
  const { t } = useTranslation('translation');

  return (
    <div className="regional-editor">
      <div className="mb-4">{t('coverage.REGIONAL.description1')}</div>
      <div>{t('coverage.REGIONAL.description2')}</div>
    </div>
  );
};

export default RegionalEditor;
