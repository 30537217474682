import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { currentView } from 'modules/map/map';
import SkyTButton from '../buttons/skyTButton';
import './mapToolBar.scss';
import CompassTool from './tools/CompassTool';

const MapToolBar = () => {
  const onZoomIn = () => {
    if (currentView) {
      currentView.goTo({
        zoom: currentView.zoom + 1
      });
    }
  };

  const onZoomOut = () => {
    if (currentView) {
      currentView.goTo({
        zoom: currentView.zoom - 1
      });
    }
  };

  return (
    <div className={classnames('map-tool-bar flat')}>
      <div>
        <SkyTButton variant="info" className="map-tool-bar-button mr-2" onClick={onZoomIn}>
          <FontAwesomeIcon icon="plus" />
        </SkyTButton>
        <SkyTButton variant="info" className="map-tool-bar-button mr-2 btn-circle" onClick={onZoomOut}>
          <FontAwesomeIcon icon="minus" />
        </SkyTButton>
        <CompassTool />
      </div>
    </div>
  );
};

export default MapToolBar;
