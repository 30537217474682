import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Routes from 'shared/routes/routes';
import Header from './header/header';
import './layout.scss';
import Menu from './menu/menu';

const scrollBarStyle = {
  height: 'calc(100vh - 115px)'
};

const Layout = () => (
  <>
    <Header />
    <Menu />
    <Scrollbars style={scrollBarStyle} autoHide className="skyt-scroll-bar">
      <div className="route-layout">
        <Routes />
      </div>
    </Scrollbars>
  </>
);

export default Layout;
