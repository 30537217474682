import { IRootState } from 'config/store';
import useBeamLayer from 'modules/map/layers/beams/useBeamLayer';
import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProjectDetails } from 'shared/model/project.model';
import { reset as resetConfiguration } from 'shared/reducers/configurationSlice';
import ConfigurationSatelliteItem, { confSatItemPrefix } from './configurationSatellliteItem';

const ConfigurationTabContent = () => {
  const dispatch = useDispatch();
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;

  const { satelliteId } = useParams<{ satelliteId: string }>();

  useBeamLayer();

  const defaultActiveKey = satelliteId ? confSatItemPrefix + '_' + satelliteId : undefined;

  useEffect(() => {
    return () => {
      dispatch(resetConfiguration());
    };
  }, [dispatch]);

  return (
    <div>
      <Accordion defaultActiveKey={defaultActiveKey}>
        {project?.satellites.map(item => (
          <div key={`sat_${item.id}`}>
            <ConfigurationSatelliteItem satellite={item} />
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default ConfigurationTabContent;
