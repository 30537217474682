import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Project } from 'shared/model/project.model';
import { removeProject } from 'shared/reducers/projectSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeleteProjectBtnProps {
  project: Project;
}

const DeleteProjectBtn = (props: IDeleteProjectBtnProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { project } = props;

  const [show, setShow] = useState(false);

  const onClose = async (confirm: boolean) => {
    if (confirm) {
      await dispatch(removeProject(project));
      history.push('/');
    } else {
      setShow(false);
    }
  };

  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} onClose={onClose} />
      <SkyTButton variant="outline-info" className="mb-1" onClick={open} size="sm">
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default DeleteProjectBtn;
