import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { fetchExports, reset } from 'shared/reducers/exportSlice';
import { fetchProject } from 'shared/reducers/projectSlice';
import { projectIsForbidden } from 'shared/utils/model-utils';
import Loader from 'shared/widgets/loader';
import ResultsContent from './commons/ResultsContent';
import ResultsMenu from './menu/ResultsMenu';
import './ResultsPage.scss';

const ResultsPage = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();

  const project = useSelector(({ project }: IRootState) => project.project);
  const loading = useSelector(({ project }: IRootState) => project.loading);

  useEffect(() => {
    if (!project || project.id !== Number(projectId)) {
      dispatch(fetchProject(projectId));
    }
  }, [projectId, project, dispatch]);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchExports(projectId));
    }
    return () => {
      dispatch(reset());
    };
  }, [projectId, dispatch]);

  if (!project) {
    return null;
  }

  if (projectIsForbidden(project)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="results-page">
      {loading ? (
        <div className="vcenter-loader">
          <Loader />
        </div>
      ) : (
        <>
          <ResultsMenu />
          <ResultsContent />
        </>
      )}
    </div>
  );
};

export default ResultsPage;
