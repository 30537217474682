import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoverageBeamSettings } from 'shared/model/area.model';
import { setCoverageBeamConfig } from 'shared/reducers/coverageSlice';
import { useDebounce } from 'use-debounce';
import BeamNbSlider from './sliders/BeamNbSlider';
import BeamSizeSlider from './sliders/BeamSizeSlider';

const SteerableBeamsEditor = () => {
  const dispatch = useDispatch();
  const coverageBeamConfig = useSelector(({ coverage }: IRootState) => coverage.coverageBeamConfig);
  const [settings, setSettings] = useState<CoverageBeamSettings>(coverageBeamConfig);
  const [debouncedSettings] = useDebounce(settings, 400);

  const onBeamSizeChange = (beamSize: number) => {
    const newSettings = {
      ...settings,
      size: beamSize
    };
    setSettings(newSettings);
  };

  const onBeamNbChange = (nb: number) => {
    const newSettings = {
      ...settings,
      steerableBeamNumber: nb
    };
    setSettings(newSettings);
  };

  useEffect(() => {
    dispatch(setCoverageBeamConfig(debouncedSettings));
  }, [debouncedSettings, dispatch]);

  return (
    <div>
      <div className="mb-4">
        <BeamSizeSlider onChange={onBeamSizeChange} defaultValue={coverageBeamConfig.size} />
      </div>
      <div className="mb-4">
        <BeamNbSlider onChange={onBeamNbChange} defaultValue={coverageBeamConfig.steerableBeamNumber} />
      </div>
    </div>
  );
};

export default SteerableBeamsEditor;
