import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, NotificationType } from 'shared/model/notification.model';
import { updateAcceptAccessRequest } from 'shared/reducers/notificationSlice';
import { isPendingAccessRequest, isPendingMemberShipRequest } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import GraphResultNotification from './GraphResultNotification';
import './notifications.scss';

interface INotificationProps {
  item: Notification;
  onUpdateSuccess: () => void;
}

const NotificationItem = (props: INotificationProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updating = useSelector(({ notification }: IRootState) => notification.updating);
  const updatingRequestId = useSelector(({ notification }: IRootState) => notification.requestId);
  const updateSuccess = useSelector(({ notification }: IRootState) => notification.updateSuccess);

  const { item, onUpdateSuccess } = props;

  const fromNow = moment(item.date).fromNow();
  const name = item.concernedUserFullName;

  const onAccept = async () => {
    dispatch(updateAcceptAccessRequest(item.id, true));
  };

  const onDeny = () => {
    dispatch(updateAcceptAccessRequest(item.id, false));
  };

  useEffect(() => {
    if (updateSuccess) {
      onUpdateSuccess();
    }
  }, [onUpdateSuccess, updateSuccess]);

  const disabled = item.id === updatingRequestId && updating;

  if (item.type === NotificationType.GRAPHRESULT) {
    return <GraphResultNotification item={item} />;
  }

  return (
    <div key={item.id} className="notification">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="comment">
          <FontAwesomeIcon icon={['far', 'comment']} className="mr-2" />
          <span>{t('translation:notifications.requests')}</span>
        </div>
        <div className="text-nowrap">
          <span>{fromNow}</span>
          <FontAwesomeIcon icon={['far', 'clock']} className="ml-2" />
        </div>
      </div>
      <div>{t(`translation:notifications.${item.type}`, { name })}</div>
      {(isPendingAccessRequest(item) || isPendingMemberShipRequest(item)) && (
        <div className="d-flex justify-content-center my-2">
          <SkyTButton size="sm" className="mx-2" onClick={onDeny} disabled={disabled}>
            <FontAwesomeIcon icon="times" className="mr-2" />
            {t('translation:notifications.deny')}
          </SkyTButton>
          <SkyTButton size="sm" className="mx-2" onClick={onAccept} disabled={disabled}>
            <FontAwesomeIcon icon="check" className="mr-2" />
            {t('translation:notifications.accept')}
          </SkyTButton>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
