import classnames from 'classnames';
import { IRootState } from 'config/store';
import { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFavoritesProjects, fetchProjects, reset } from 'shared/reducers/projectSlice';
import Loader from 'shared/widgets/loader';
import NoResult from 'shared/widgets/no-result';
import ProjectCard from './project-card';
import './project-list.scss';

const scrollBarStyle = {
  height: 'calc(100vh - 21rem)'
};

const ProjectsList = () => {
  const dispatch = useDispatch();
  const projects = useSelector(({ project }: IRootState) => project.projects);
  const loading = useSelector(({ project }: IRootState) => project.loading);
  const selectedCustomer = useSelector(({ customer }: IRootState) => customer.customer);
  const customerLock = useSelector(({ referential }: IRootState) => referential.customerLock);

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(fetchProjects(selectedCustomer.id));
    } else {
      dispatch(reset());
      dispatch(fetchFavoritesProjects());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  return (
    <Scrollbars autoHide style={scrollBarStyle} className="skyt-scroll-bar">
      <div className={classnames('projects-list', { meetingMode: customerLock })}>
        {loading ? (
          <div className="text-center vcenter-loader-5">
            <Loader />
          </div>
        ) : (
          <>
            {projects.map(item => (
              <ProjectCard key={item.id} project={item} />
            ))}
            {projects.length === 0 && <NoResult />}
          </>
        )}
      </div>
    </Scrollbars>
  );
};

export default ProjectsList;
