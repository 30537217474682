import { IRootState } from 'config/store';
import { useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchCustomers } from 'shared/reducers/customerSlice';
import { updateLockCustomer } from 'shared/reducers/referentialSlice';
import { sortByLabel } from 'shared/utils/array-utils';
import SkyTSelect, { ILabelValueOption } from 'shared/widgets/form/skyTSelect';
import Loader from 'shared/widgets/loader';

const MeetingMode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const loading = useSelector(({ customer }: IRootState) => customer.loading);
  const customers = useSelector(({ customer }: IRootState) => customer.customers);
  const customerLock = useSelector(({ referential }: IRootState) => referential.customerLock);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const options = useMemo(() => {
    const emptyOption: ILabelValueOption<number> = {
      label: t('meetingMode.noCustomer'),
      value: -1
    };

    const result: ILabelValueOption<number>[] = customers.map(c => ({ label: c.name, value: c.id }));
    result.sort(sortByLabel());

    result.unshift(emptyOption);
    return result;
  }, [customers, t]);

  const selectedCustomer = options.find(o => o.value === customerLock?.id);
  const defaultValue = selectedCustomer ? selectedCustomer : options.find(o => o.value === -1);

  const changeCustomer = async (option: ILabelValueOption<number>) => {
    // @ts-ignore
    await dispatch(updateLockCustomer(option.value));
    history.push('/');
  };

  return (
    <Form.Group className="my-5">
      <Form.Label>{t('profil.meetingMode')}</Form.Label>
      {loading ? <Loader /> : <SkyTSelect options={options} defaultValue={defaultValue} onChange={changeCustomer} />}
    </Form.Group>
  );
};

export default MeetingMode;
