import Graphic from '@arcgis/core/Graphic';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { createGateway, deleteGateway, updateGateway } from 'shared/api/api';
import { GatewayModel } from 'shared/model/gateway-model.model';
import { Gateway, GatewayBandWidth, GatewayPolarizationType } from 'shared/model/gateway.model';
import { fetchProject } from './projectSlice';

const initialState = {
  underEditionFeature: null as Graphic | null,
  selectedGatewayModel: null as GatewayModel | null,
  selectedGatewayBandwidth: null as GatewayBandWidth | null,
  selectedPolorization: null as GatewayPolarizationType | null,
  updating: false,
  updateSuccess: false,
  error: null as any
};

export type GatewayState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'gateway',
  initialState,
  reducers: {
    setUnderEditionFeature: (state, action: PayloadAction<Graphic | null>) => {
      state.underEditionFeature = action.payload;
    },
    setGatewayModel: (state, action: PayloadAction<GatewayModel | null>) => {
      state.selectedGatewayModel = action.payload;
    },
    setSelectedGatewayBandwidth: (state, action: PayloadAction<GatewayBandWidth | null>) => {
      state.selectedGatewayBandwidth = action.payload;
    },
    setSelectedGatewayPolarization: (state, action: PayloadAction<GatewayPolarizationType | null>) => {
      state.selectedPolorization = action.payload;
    },
    updateStart: state => {
      state.updating = true;
      state.error = null;
      state.updateSuccess = false;
    },
    updateSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
      state.underEditionFeature = null;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    reset: state => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = null;
      state.selectedGatewayModel = null;
      state.selectedGatewayBandwidth = null;
      state.selectedPolorization = null;
    }
  }
});

export const { reset, setUnderEditionFeature, setGatewayModel, setSelectedGatewayBandwidth, setSelectedGatewayPolarization } =
  slice.actions;

const { updateStart, updateSuccess, updateFailed } = slice.actions;

export const saveGateway =
  (projectId: number | string, gateway: Partial<Gateway>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      gateway.id ? await updateGateway(projectId, gateway) : await createGateway(projectId, gateway);
      dispatch(updateSuccess());
      await dispatch(fetchProject(projectId));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeGateway =
  (projectId: number | string, gatewayId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteGateway(projectId, gatewayId);
      dispatch(updateSuccess());
      dispatch(fetchProject(projectId));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
