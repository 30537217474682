import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { IRootState } from 'config/store';
import { cloneDeep } from 'lodash';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CoverageType } from 'shared/model/coverage.model';
import { ProjectDetails } from 'shared/model/project.model';
import { setUnderEditionBeams, setUpdatingBeamsSelection } from 'shared/reducers/areaSlice';
import { setShowBeamLayer } from 'shared/reducers/mapSlice';
import { setShowGlobalBeam } from 'shared/reducers/satelliteSlice';
import { computeBeamAtLonLat } from 'shared/utils/beam-utils';
import { beamIsComplete } from 'shared/utils/model-utils';
import { beamIsSelected, editBeamLayerId, getMissionSymbolFromGraphic, getSelectedSymbol } from './beam-layer-def';
import './beams.scss';
import useEditMultiBeam from './multibeams/useEditMultiBeam';
import useEditRegionalBeam from './regional/useEditRegionalBeam';
import { IS_DIRTY_ATT } from './steerable/useCreateSteerableBeam';
import useEditSteerableBeam from './steerable/useEditSteerableBeam';

let sketchViewModelBeams: SketchViewModel | undefined;
let updateOnMoveHandle: IHandle | undefined;

export const editBeamsLayer = new GraphicsLayer({
  id: editBeamLayerId,
  title: editBeamLayerId
});

let clickHandle: IHandle | undefined;

interface IUseEditBeamLayerProps {
  canMoveBeam: boolean;
}

const useEditBeamLayer = (props: IUseEditBeamLayerProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const showBeamLayer = useSelector(({ map }: IRootState) => map.showBeamLayer);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);

  const satellite = project.satellites.find(sat => sat.id === configuration?.satelliteId);
  const orbitalPosition = satellite?.orbitalPosition;

  const isSteerableCoverateType = coverage?.coverageType === CoverageType.STEERABLE_BEAM;

  const { canMoveBeam } = props;

  useEditMultiBeam({ layer: editBeamsLayer });
  useEditRegionalBeam({ layer: editBeamsLayer });
  useEditSteerableBeam({ layer: editBeamsLayer });

  useEffect(() => {
    dispatch(setShowGlobalBeam(isSteerableCoverateType));
  }, [dispatch, isSteerableCoverateType]);

  useEffect(() => {
    editBeamsLayer.removeAll();
    return () => {
      editBeamsLayer.removeAll();
      if (sketchViewModelBeams) {
        sketchViewModelBeams.destroy();
        sketchViewModelBeams = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (arcgisMap && selectedMissions.length > 0) {
      arcgisMap.add(editBeamsLayer);
      if (canMoveBeam && currentView) {
        sketchViewModelBeams = new SketchViewModel({
          layer: editBeamsLayer,
          view: currentView,
          defaultUpdateOptions: {
            tool: 'move',
            multipleSelectionEnabled: false
          }
        });
      } else if (sketchViewModelBeams) {
        sketchViewModelBeams?.destroy();
        sketchViewModelBeams = undefined;
      }
      dispatch(setShowBeamLayer(false));
    }

    return () => {
      if (arcgisMap) {
        arcgisMap.remove(editBeamsLayer);
      }
      dispatch(setShowBeamLayer(true));
    };
  }, [arcgisMap, dispatch, selectedMissions.length, orbitalPosition, canMoveBeam]);

  useEffect(() => {
    if (arcgisMap && selectedMissions.length > 0 && currentView) {
      const queryFeatures = async (screenPoint: __esri.ViewClickEvent) => {
        if (currentView) {
          const point = currentView.toMap(screenPoint);
          const featuresToChange = editBeamsLayer.graphics.filter(item => geometryEngine.intersects(point, item.geometry));

          if (featuresToChange.length === 0) {
            return;
          }

          const idsSelected: string[] = [];
          const idsNotSelected: string[] = [];
          featuresToChange.forEach(feature => {
            const isSelected = beamIsSelected(feature);
            const newSelected = !isSelected;
            feature.setAttribute('selected', newSelected);
            const id = feature.getAttribute('name');
            if (newSelected) {
              idsSelected.push(id);
            } else {
              idsNotSelected.push(id);
            }
          });

          if (sketchViewModelBeams && canMoveBeam) {
            const nbSelected = editBeamsLayer.graphics.filter(item => beamIsSelected(item)).length;
            if (idsSelected.length > 0 && nbSelected === 1) {
              if (updateOnMoveHandle) {
                updateOnMoveHandle.remove();
              }
              const updateBeam = (e: any) => {
                const toolType = e.toolEventInfo?.type;
                if (e.graphics.length !== 1) {
                  return;
                }
                const g = e.graphics[0] as Graphic;

                const selected = g.getAttribute('selected');
                const isDirty = g.getAttribute(IS_DIRTY_ATT);
                if (!selected) {
                  sketchViewModelBeams?.cancel();
                  return;
                }
                if (e.state === 'complete') {
                  if (selected && isDirty) {
                    const newBeams = cloneDeep(underEditionBeams);
                    const toUpdateBeam = newBeams.find(beam => beam.i === g.getAttribute('name'));
                    if (toUpdateBeam) {
                      const geom = g.geometry as Polygon;
                      const centroid = geom.centroid;
                      toUpdateBeam.lt = centroid.latitude;
                      toUpdateBeam.lg = centroid.longitude;
                    }
                    dispatch(setUnderEditionBeams(newBeams));
                  }
                } else if (toolType === 'move-stop' && orbitalPosition) {
                  const size = g.getAttribute('s');
                  // change geom
                  const geom = g.geometry as Polygon;
                  const centroid = geom.centroid;
                  const computedGeom = computeBeamAtLonLat(centroid.latitude, centroid.longitude, orbitalPosition, size);
                  g.geometry = webMercatorUtils.geographicToWebMercator(computedGeom) as Polygon;
                  // sketchViewModelBeams?.update(g);
                  g.setAttribute(IS_DIRTY_ATT, true);
                  sketchViewModelBeams?.complete();
                }
              };
              updateOnMoveHandle = sketchViewModelBeams.on('update', updateBeam);
              sketchViewModelBeams.update(featuresToChange.toArray());
            } else {
              sketchViewModelBeams.cancel();
            }
          }

          const newUnderEditionBeam = underEditionBeams.map(item => {
            const selected = idsSelected.some(id => id === item.i.toString());
            const notSelected = idsNotSelected.some(id => id === item.i.toString());
            if (selected) {
              return {
                ...item,
                selected
              };
            } else if (notSelected) {
              return {
                ...item,
                selected: false
              };
            } else {
              return {
                ...item
              };
            }
          });
          dispatch(setUpdatingBeamsSelection(newUnderEditionBeam));
        }
      };
      if (clickHandle) {
        clickHandle.remove();
      }
      clickHandle = currentView.on('click', queryFeatures);

      underEditionBeams.forEach(beam => {
        const existingGraphics = editBeamsLayer.graphics.find(g => g.getAttribute('name') === beam.i.toString());
        if (existingGraphics) {
          const isComplete = beamIsComplete(beam);

          existingGraphics.setAttribute('selected', beam.selected);
          existingGraphics.symbol = beam.selected ? getSelectedSymbol() : getMissionSymbolFromGraphic(existingGraphics, !isComplete);
        }
      });
      const graphicsToRemove: Graphic[] = [];
      editBeamsLayer.graphics.forEach(g => {
        const name = g.getAttribute('name');
        const found = underEditionBeams.find(beam => beam.i.toString() === name);
        if (!found) {
          graphicsToRemove.push(g);
        }
      });
      if (graphicsToRemove.length > 0) {
        editBeamsLayer.graphics.removeMany(graphicsToRemove);
      }
      if (showBeamLayer) {
        dispatch(setShowBeamLayer(false));
      }
    }
  }, [arcgisMap, selectedMissions, t, dispatch, underEditionBeams, showBeamLayer, orbitalPosition, canMoveBeam]);

  return editBeamsLayer;
};

export default useEditBeamLayer;
