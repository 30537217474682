import Graphic from '@arcgis/core/Graphic';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { createPoi, deletePoi, updatePoi } from 'shared/api/api';
import { POI } from 'shared/model/poi.model';
import { fetchProject } from './projectSlice';

const initialState = {
  underEditionFeature: null as Graphic | null,
  updating: false,
  updateSuccess: false,
  error: null as any
};

export type PoiState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'poiAndGateway',
  initialState,
  reducers: {
    setUnderEditionFeature: (state, action: PayloadAction<Graphic | null>) => {
      state.underEditionFeature = action.payload;
    },
    updateStart: state => {
      state.updating = true;
      state.error = null;
      state.updateSuccess = false;
    },
    updateSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    reset: state => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = null;
    }
  }
});

export const { reset, setUnderEditionFeature } = slice.actions;

const { updateStart, updateSuccess, updateFailed } = slice.actions;

export const savePoi =
  (projectId: number | string, poi: Partial<POI>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      poi.id ? await updatePoi(projectId, poi) : await createPoi(projectId, poi);
      dispatch(updateSuccess());
      await dispatch(fetchProject(projectId));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removePoi =
  (projectId: number | string, poiId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deletePoi(projectId, poiId);
      dispatch(updateSuccess());
      dispatch(fetchProject(projectId));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
