import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Configuration } from 'shared/model/configuration.model';
import { fetchConfigurations, removeConfiguration } from 'shared/reducers/configurationSlice';
import { setMapMenuPanel } from 'shared/reducers/mapSlice';
import { configurationsSatelliteRoute } from 'shared/routes/routes';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeleteConfigurationProps {
  configuration: Configuration;
}

const DeleteConfiguration = (props: IDeleteConfigurationProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const updating = useSelector(({ configuration }: IRootState) => configuration.updating);

  const { projectId, satelliteId } = useParams<{ projectId: string; satelliteId: string }>();

  const onClose = (confirm: boolean) => {
    if (confirm) {
      dispatch(removeConfiguration(projectId, satelliteId, props.configuration.id));
      dispatch(setMapMenuPanel(null));
      setShow(false);
      setTimeout(() => {
        dispatch(fetchConfigurations(projectId, satelliteId));
        history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
      }, 300);
    } else {
      setShow(false);
    }
  };

  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} onClose={onClose} waiting={updating} />
      <SkyTButton variant="outline" size="sm" className="text-primary" onClick={open} disabled={updating}>
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default DeleteConfiguration;
