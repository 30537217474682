import Graphic from '@arcgis/core/Graphic';
import { IRootState } from 'config/store';
import isArray from 'lodash/isArray';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { MissionDetails } from 'shared/model/mission.model';
import {
  areaRoute,
  configurationsSatelliteRoute,
  configureConfigurationSatelliteRoute,
  coverageRoute,
  coveragesRoute
} from 'shared/routes/routes';
import { hexToRgb } from 'shared/utils/color-utils';
import { hasAnyBeamLayer } from 'shared/utils/map-utils';
import { currentView } from '../../map';
import { addAndOrderLayer } from '../layerOrder';
import { areaOfInterestslayer, getGraphicsFromAreaOfInterest } from './areaOfInterestLayer-def';

const useAreaOfInteretLayer = () => {
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const currentArea = useSelector(({ area }: IRootState) => area.area);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const configurationMissions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);

  const showOnlyConfigurationAreas = useRouteMatch([
    configureConfigurationSatelliteRoute.path,
    configurationsSatelliteRoute.path,
    coveragesRoute.path,
    coverageRoute.path,
    areaRoute.path
  ])?.isExact;

  let missionsToUse = missions;
  if (showOnlyConfigurationAreas && configurationMissions.length > 0) {
    missionsToUse = configurationMissions;
  }

  useEffect(() => {
    if (arcgisMap) {
      addAndOrderLayer(arcgisMap, areaOfInterestslayer);
    }
  }, [arcgisMap]);

  useEffect(() => {
    areaOfInterestslayer.removeAll();

    const missionsWithAreaOfInterets = missionsToUse.filter(item => {
      const missionDetails = item as MissionDetails;
      if (missionDetails.areaOfInterests) {
        return missionDetails.areaOfInterests.length > 0;
      }
      return false;
    }) as MissionDetails[];

    let areaOfInterests: Graphic[] = [];
    let gotoTarget: Graphic[] = [];

    missionsWithAreaOfInterets.forEach(missionDetails => {
      const color = hexToRgb(missionDetails.color);

      missionDetails.areaOfInterests.forEach(areaOfInterest => {
        let confArray = JSON.parse(areaOfInterest.latLngs);
        if (!isArray(confArray)) {
          confArray = [confArray];
        }
        const graphics = getGraphicsFromAreaOfInterest(areaOfInterest, color);
        areaOfInterests = areaOfInterests.concat(graphics);
        if (currentArea) {
          if (currentArea.areaOfInterestId === areaOfInterest.id) {
            gotoTarget = gotoTarget.concat(graphics);
          }
        } else {
          gotoTarget = gotoTarget.concat(graphics);
        }
      });
    });
    areaOfInterestslayer.addMany(areaOfInterests);
    if (currentView && areaOfInterests.length > 0 && !hasAnyBeamLayer(arcgisMap)) {
      setTimeout(() => {
        currentView?.goTo(gotoTarget);
      }, 200);
    }
  }, [arcgisMap, currentArea, missionsToUse]);
};

export default useAreaOfInteretLayer;
