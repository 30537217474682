import Lottie from 'shared/widgets/lottie/Lottie';
import gauge from '../lotties/gauge.json';

const frameNb = 160;
const maxValue = 16000;

interface IGaugeProps {
  value: number;
}

const GaugeIcon = (props: IGaugeProps) => {
  const { value } = props;

  const end = value <= maxValue ? (value * frameNb) / maxValue : frameNb;

  return <Lottie animationData={gauge} initialSegment={[0, end]} loop={false} />;
};

export default GaugeIcon;
