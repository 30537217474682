import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import useEditBeamLayer from 'modules/map/layers/beams/useEditBeamLayer';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { saveAreas, setArea, setUpdatingBeamsSelection } from 'shared/reducers/areaSlice';
import { setCoverage } from 'shared/reducers/coverageSlice';
import { configurationsSatelliteRoute } from 'shared/routes/routes';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';
import AreaMenuSelector from './AreaMenuSelector';
import { convertUnderEditionBeamsToAreas } from './editAreaUtils';
import EditGeometryArea from './EditGeometryArea';
import EditServicesArea from './EditServicesArea';
import EditTargetsArea from './targets/EditTargetsArea';

const contentScrollBarStyle = {
  height: '285px'
};
let tryRedirect = false;

const EditArea = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const area = useSelector(({ area }: IRootState) => area.area);
  const updatingBeamsSelection = useSelector(({ area }: IRootState) => area.updatingBeamsSelection);
  const updating = useSelector(({ configuration }: IRootState) => configuration.updating);
  const multipleUpdating = useSelector(({ area }: IRootState) => area.multipleUpdating);
  const wasMultipleUpdating = usePrevious(multipleUpdating);
  const currentMenu = useSelector(({ area }: IRootState) => area.currentMenu);
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);

  const { projectId, satelliteId, configurationId, coverageId } = useParams<{
    projectId: string;
    satelliteId: string;
    configurationId: string;
    coverageId: string;
  }>();

  const canCustomizeBeam = referential?.beamCustomisation?.isEnabled ? true : false;
  const canMoveBeam = canCustomizeBeam && currentMenu === 'geometry';

  const editBeamLayer = useEditBeamLayer({
    canMoveBeam
  });

  const [canSave, setCanSave] = useState(true);

  useEffect(() => {
    if (area?.beamList) {
      const underEditionBeams = area.beamList.map(beam => ({
        ...beam,
        selected: false
      }));
      dispatch(setUpdatingBeamsSelection(underEditionBeams));
    }
  }, [area, dispatch]);

  useEffect(() => {
    const servicesOver100 = underEditionBeams.some(underEditionBeam => {
      const services = underEditionBeam.sv;
      const total = services.reduce((agg, current) => agg + current.v, 0);
      return total > 100;
    });

    const missingMandatoringField = underEditionBeams.some(underEditionBeams => {
      const ts = underEditionBeams.tx;
      const rx = underEditionBeams.rx;
      const sys = underEditionBeams.sys;

      if (!ts || !rx || !sys) {
        return true;
      }

      return (
        typeof ts.d === 'undefined' ||
        typeof ts.r === 'undefined' ||
        typeof rx.r === 'undefined' ||
        typeof sys.c === 'undefined' ||
        typeof sys.r === 'undefined'
      );
    });

    const allGood = !servicesOver100 && !missingMandatoringField;
    if (allGood && !updatingBeamsSelection && underEditionBeams.length > 0) {
      const areas = convertUnderEditionBeamsToAreas(editBeamLayer, underEditionBeams);
      dispatch(saveAreas(projectId, satelliteId, configurationId, coverageId, areas));
    }

    setCanSave(allGood);
  }, [
    area?.coverageId,
    configurationId,
    dispatch,
    editBeamLayer,
    projectId,
    satelliteId,
    underEditionBeams,
    coverageId,
    updatingBeamsSelection
  ]);

  const onDone = () => {
    dispatch(setCoverage(null));
    dispatch(setArea(null));
    history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
  };

  useEffect(() => {
    if (multipleUpdating === false && wasMultipleUpdating === true && tryRedirect) {
      dispatch(setArea(null));
      history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
      tryRedirect = false;
    }
  }, [history, multipleUpdating, projectId, satelliteId, wasMultipleUpdating, dispatch]);

  return (
    <>
      <hr />
      <div className="mb-2">
        <AreaMenuSelector />
      </div>
      <Scrollbars autoHide style={contentScrollBarStyle} className="skyt-scroll-bar">
        {currentMenu === 'geometry' ? (
          <EditGeometryArea />
        ) : currentMenu === 'services' ? (
          <EditServicesArea />
        ) : currentMenu === 'targets' ? (
          <EditTargetsArea />
        ) : null}
      </Scrollbars>
      <hr />
      <div className="d-flex justify-content-around pt-2">
        <SkyTMapButton variant={canSave ? 'outline-info' : 'outline-danger'} type="button" onClick={onDone} disabled={updating || !canSave}>
          {updating ? (
            <Loader />
          ) : (
            <>
              {t('done')}
              <FontAwesomeIcon icon="check" className="ml-1" />
            </>
          )}
        </SkyTMapButton>
      </div>
    </>
  );
};

export default EditArea;
