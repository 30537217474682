import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import React, { useContext, useEffect } from 'react';
import { Accordion, AccordionContext, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Satellite } from 'shared/model/satellite.model';
import { fetchConfigurations } from 'shared/reducers/configurationSlice';
import { setMapMenuPanel } from 'shared/reducers/mapSlice';
import { configurationsSatelliteRoute, configureConfigurationSatelliteRoute, exportsSatelliteRoute } from 'shared/routes/routes';
import { sortByName } from 'shared/utils/array-utils';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';
import NoResult from 'shared/widgets/no-result';
import SatelliteCardTitle from '../satellites/satelliteCardTitle';
import ConfigurationItem from './configurationItem';
import './configurationSatellliteItem.scss';

export const confSatItemPrefix = 'confSatItem';

interface IConfigurationSatelliteItemProps {
  satellite: Satellite;
  exportTab?: boolean;
}

const ConfigurationSatelliteItem = (props: IConfigurationSatelliteItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');

  const project = useSelector(({ project }: IRootState) => project.project);
  const configurations = useSelector(({ configuration }: IRootState) => configuration.configurations);
  const loading = useSelector(({ configuration }: IRootState) => configuration.loading);
  const { projectId } = useParams<{ projectId: string }>();

  const hasWriteAccess = projectHasWriteAccess(project);

  const { satellite, exportTab = false } = props;

  const eventKey = `${confSatItemPrefix}_${satellite.id}`;
  const currentEventKey = useContext(AccordionContext);

  const isCurrentTab = currentEventKey === eventKey;

  useEffect(() => {
    if (isCurrentTab) {
      if (exportTab) {
        const newHistory = exportsSatelliteRoute.getPath(projectId, satellite.id);
        history.push(newHistory);
      } else {
        const newHistory = configurationsSatelliteRoute.getPath(projectId, satellite.id);
        history.push(newHistory);
      }

      dispatch(fetchConfigurations(projectId, satellite.id));
    }
  }, [dispatch, history, isCurrentTab, projectId, satellite.id, exportTab]);

  const onAddConfiguration = () => {
    dispatch(setMapMenuPanel(null));
    history.push(configureConfigurationSatelliteRoute.getPath(projectId, satellite.id, 'new'));
  };

  const sortedConfigurations = [...configurations].sort(sortByName());

  return (
    <div className="configuration-satellite-item hover-white unselectable">
      <Accordion.Toggle
        className={classnames('configuration-satellite-item-title', { 'is-open': isCurrentTab })}
        as={'div'}
        eventKey={eventKey}
      >
        <SatelliteCardTitle item={satellite} />
        <div>
          <FontAwesomeIcon icon={isCurrentTab ? 'chevron-up' : 'chevron-down'} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          {loading ? (
            <Loader size="sm" />
          ) : (
            <div>
              <div>
                {sortedConfigurations.length === 0 && (
                  <NoResult size="sm" className="p-0">
                    {t('configuration.noConfig')}
                  </NoResult>
                )}
                {sortedConfigurations.map(item => (
                  <ConfigurationItem
                    key={`configuration_${item.id}`}
                    configuration={item}
                    showConfig={!exportTab}
                    showDelete={!exportTab}
                    showEdit={!exportTab}
                    showExport={exportTab}
                  />
                ))}
              </div>
              {hasWriteAccess && !exportTab && (
                <div className="text-right mt-2">
                  <SkyTMapButton size="sm" type="button" variant="outline-info" onClick={onAddConfiguration}>
                    {t('configuration.addConfig')}
                  </SkyTMapButton>
                </div>
              )}
            </div>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </div>
  );
};

export default ConfigurationSatelliteItem;
