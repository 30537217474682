import classNames from 'classnames';
import { IRootState } from 'config/store';
import { ISupportLayer } from 'config/supportLayer';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import { useSelector } from 'react-redux';
import { createSupportLayer } from 'shared/utils/map-utils';
import SupportLayerIcon from './supportLayerIcon';

interface ISupportLayerItemProps {
  item: ISupportLayer;
  onClick: () => void;
}

const SupportLayerItem = (props: ISupportLayerItemProps) => {
  const { item, onClick } = props;

  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);

  const activeLayer = arcgisMap?.layers.find(aLayer => aLayer.id === item.id);

  const toggle = () => {
    if (arcgisMap) {
      if (activeLayer) {
        arcgisMap.layers.remove(activeLayer);
      } else {
        const layer = createSupportLayer(item);
        if (layer) {
          arcgisMap.layers.add(layer, item.order);
        }
      }
    }
    onClick();
  };

  return (
    <ListGroup.Item onClick={toggle} className="layer-support-item d-flex">
      <div>
        <SupportLayerIcon def={item} className={classNames({ 'icon-active': activeLayer, 'icon-inactive': !activeLayer })} />
      </div>
      <div className="ml-2">{item.name}</div>
    </ListGroup.Item>
  );
};

export default SupportLayerItem;
