import Lottie from 'shared/widgets/lottie/Lottie';
import data from '../lotties/wave.json';

const frameNb = 100;
const maxValue = 100;

interface IWaveIconProps {
  value: number;
}

const WaveIcon = (props: IWaveIconProps) => {
  const { value } = props;

  const end = value <= maxValue ? (value * frameNb) / maxValue : frameNb;

  return <Lottie animationData={data} initialSegment={[0, end]} loop={false} />;
};

export default WaveIcon;
