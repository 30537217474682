import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import AreaTypeIcon from 'modules/needs/areaOfInterets/icons/AreaTypeIcon';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchAreasOfCoverages, setArea, setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { setCoverage } from 'shared/reducers/coverageSlice';
import { setMapMenuPanel } from 'shared/reducers/mapSlice';
import { selectMissions } from 'shared/reducers/missionSlice';
import { areaRoute, configureConfigurationSatelliteRoute, coverageRoute } from 'shared/routes/routes';
import { sortByLabel, sortByName } from 'shared/utils/array-utils';
import { getAreaOfInterestOfArea } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import SkyTSelect, { ILabelValueOption } from 'shared/widgets/form/skyTSelect';
import './select-coverage.scss';

const missionItem = 'missionItem';
const areaItem = 'areaItem';

const SelectCoverageOrAreaToEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const coverages = useSelector(({ coverage }: IRootState) => coverage.coverages);
  const areas = useSelector(({ area }: IRootState) => area.areas);
  const area = useSelector(({ area }: IRootState) => area.area);
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);

  const { projectId, satelliteId, configurationId, coverageId, areaId } =
    useParams<{ projectId: string; satelliteId: string; configurationId: string; coverageId: string; areaId: string }>();

  useEffect(() => {
    if (coverageId) {
      if (!coverage || coverage.id !== Number(coverageId)) {
        const newCoverage = coverages.find(item => item.id === Number(coverageId));
        dispatch(setCoverage({ ...newCoverage }));
      }
    }
    if (areaId) {
      if (!area || area.id !== Number(areaId)) {
        const newArea = areas.find(item => item.id === Number(areaId));
        dispatch(setArea({ ...newArea }));
      }
    }
  }, [area, areaId, areas, coverage, coverageId, coverages, dispatch]);

  useEffect(() => {
    const coverageIds = coverages.map(item => item.id);
    dispatch(fetchAreasOfCoverages(projectId, satelliteId, configurationId, coverageIds));
  }, [configurationId, coverages, dispatch, projectId, satelliteId]);

  useEffect(() => {
    if (coverage?.id === Number(coverageId)) {
      const currentMission = missions.find(item => item.id === coverage.missionId);
      if (currentMission) {
        dispatch(selectMissions([currentMission]));
      }
    }
  }, [coverage, coverageId, dispatch, missions]);

  const onChange = (option: ILabelValueOption<number>) => {
    dispatch(setUnderEditionBeams([]));
    if (option.other === missionItem) {
      dispatch(setArea(null));
      const newCoverage = coverages.find(item => item.missionId === option.value);
      if (newCoverage) {
        history.push(coverageRoute.getPath(projectId, satelliteId, configurationId, newCoverage.id));
      }
    } else if (option.other === areaItem) {
      const currentArea = areas.find(item => item.id === option.value);
      if (currentArea) {
        const newCoverage = coverages.find(item => item.id === currentArea.coverageId);
        if (newCoverage) {
          history.push(areaRoute.getPath(projectId, satelliteId, configurationId, newCoverage.id, currentArea.id));
        }
      }
    }
  };

  const formatOptionLabel = (data: ILabelValueOption<number>) => {
    if (data.other === missionItem) {
      const mission = missions.find(item => item.id === data.value);

      if (!mission) {
        return null;
      }
      return (
        <div className="mb-1" style={{ color: mission.color }}>
          {mission.name}
        </div>
      );
    }

    const area = areas.find(item => item.id === data.value);
    if (!area) {
      return null;
    }
    const areaOfInterest = getAreaOfInterestOfArea(area, missions);
    if (!areaOfInterest) {
      return null;
    }

    return (
      <div className="select-coverage-item">
        <div className="ml-2">
          <div key={`area_${area.id}`}>
            <AreaTypeIcon type={areaOfInterest.type} className="mr-2" />
            <span className="text-primary">{areaOfInterest.name}</span>
          </div>
        </div>
      </div>
    );
  };

  const options = useMemo(() => {
    const orderedMissions = [...missions].sort(sortByName());
    const result = orderedMissions.reduce((agg: ILabelValueOption<number>[], mission) => {
      if (coverages.some(coverage => coverage.missionId === mission.id)) {
        const missionOption = {
          label: mission.name,
          value: mission.id,
          other: missionItem
        };
        agg.push(missionOption);

        const areaOptions = areas.reduce((acc: ILabelValueOption<number>[], area) => {
          const areaOfInterest = getAreaOfInterestOfArea(area, [mission]);
          if (areaOfInterest) {
            acc.push({ label: areaOfInterest.name, value: area.id, other: areaItem });
          }

          return acc;
        }, []);
        areaOptions.sort(sortByLabel());
        areaOptions.forEach(areaOption => agg.push(areaOption));
      }

      return agg;
    }, []);
    return result;
  }, [missions, coverages, areas]);

  let selected = null;
  if (area) {
    selected = options.find(item => item.other === areaItem && item.value === area.id);
  } else if (coverage) {
    selected = options.find(item => item.other === missionItem && item.value === coverage.missionId);
  }

  const onEditConfiguration = () => {
    dispatch(setMapMenuPanel(null));
    history.push(configureConfigurationSatelliteRoute.getPath(projectId, satelliteId, configurationId));
  };

  return (
    <>
      <SkyTSelect
        isSearchable={false}
        formatOptionLabel={formatOptionLabel}
        className="select-area"
        options={options}
        value={selected}
        onChange={onChange}
      />
      {options.length === 0 && (
        <>
          <hr className="my-4" />
          <div className="text-center">
            <SkyTButton variant="outline" className="text-primary" onClick={onEditConfiguration}>
              <FontAwesomeIcon icon="pencil-alt" className="mr-2" />
              <span>{t('coverage.editConfig')}</span>
            </SkyTButton>
          </div>
        </>
      )}
    </>
  );
};

export default SelectCoverageOrAreaToEdit;
