import Polygon from '@arcgis/core/geometry/Polygon';
import { isNumber } from 'lodash';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import { IUnderEditionDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { Area } from 'shared/model/area.model';
import { AreaOfInterest } from 'shared/model/areaOfInterest.model';
import { Coverage } from 'shared/model/coverage.model';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { Notification, NotificationType } from 'shared/model/notification.model';
import { Project, ProjectDetails } from 'shared/model/project.model';
import { ReferentialData } from 'shared/model/referential.model';
import { RightsEnum } from 'shared/model/RightsEnum';
import { SatelliteModel } from 'shared/model/satellite-model.model';
import { Satellite } from 'shared/model/satellite.model';
import { User } from 'shared/model/user.model';

export const projectIsForbidden = (project: Project): boolean => project?.rights === RightsEnum.FORBIDDEN;
export const projectHasWriteAccess = (project?: Project | ProjectDetails | null): boolean => project?.rights === RightsEnum.WRITE;
export const projectHasReadAccess = (project: Project): boolean => project?.rights === RightsEnum.READ;

export const isPendingAccessRequest = (notification: Notification) => notification.type === NotificationType.REQUESTASK;
export const isAcceptedAccessRequest = (notification: Notification) => notification.type === NotificationType.REQUESTACCEPT;
export const isDeniedAccessRequest = (notification: Notification) => notification.type === NotificationType.REQUESTDENY;

export const isPendingMemberShipRequest = (notification: Notification) => notification.type === NotificationType.MEMBERSHIPASK;
export const isAcceptedMemberShipRequest = (notification: Notification) => notification.type === NotificationType.MEMBERSHIPACCEPT;
export const isDeniedMemberShipRequest = (notification: Notification) => notification.type === NotificationType.MEMBERSHIPDENY;

export const getFullName = (user: User) => user.firstName + ' ' + user.lastName;

export const getMissionFromArea = (area: Area, missions: MissionDetails[], coverage: Coverage) => {
  return missions.find(mission => coverage.missionId === mission.id && area.coverageId === coverage.id);
};

export const getMissionFromAreas = (areas: Area[], missions: MissionDetails[], coverage: Coverage) => {
  return missions.filter(mission => coverage.missionId === mission.id && areas.some(area => area.coverageId === coverage.id));
};

export const getAvailableUserTerminals = (referential: ReferentialData | null, civil?: boolean) => {
  if (!referential) {
    return [];
  }
  if (isBoolean(civil)) {
    return referential.userTerminals.filter(item => item.isCivil === civil);
  }
  return referential.userTerminals;
};

export const getAvailableGatewaysFrequencyBands = (referential: ReferentialData | null, civil?: boolean) => {
  if (!referential) {
    return [];
  }
  if (isBoolean(civil)) {
    return referential.gatewaysFrequencyBand.filter(item => item.isCivil === civil);
  }
  return referential.gatewaysFrequencyBand;
};

export const getAvailableUserFrequencyBands = (referential: ReferentialData | null, civil?: boolean) => {
  if (!referential) {
    return [];
  }
  if (isBoolean(civil)) {
    return referential.usersFrequencyBand.filter(item => item.isCivil === civil);
  }
  return referential.usersFrequencyBand;
};

export const getAvailableServices = (referential: ReferentialData | null) => {
  if (!referential) {
    return [];
  }
  return referential.services;
};

export const getAreaOfInterestOfArea = (area: Area, missions: MissionDetails[]) => {
  for (let i = 0; i < missions.length; i++) {
    const mission = missions[i];
    for (let j = 0; j < mission.areaOfInterests.length; j++) {
      let areaOfInterest = mission.areaOfInterests[j];
      if (areaOfInterest.id === area.areaOfInterestId) {
        return areaOfInterest;
      }
    }
  }

  return null;
};

export const getGeometriesOfAreaOfInterest = (areaOfInterest: AreaOfInterest): Polygon[] => {
  let conf = JSON.parse(areaOfInterest.latLngs);

  // take into account area with multiple geom
  if (!isArray(conf)) {
    conf = [conf];
  }

  return conf.map((item: any) => {
    if (item.hasZ) {
      item.hasZ = false;
      item.hasM = false;
    }

    const poly = new Polygon(item);
    poly.set('areaOfInterestId', areaOfInterest.id);
    return poly;
  });
};

export const allFrequencyBandComplete = (satellite: Satellite) => {
  if (satellite.reflector && satellite.reflector.fwdBandwidth && satellite.reflector.rtnBandwidth) {
    return satellite.reflector.fwdBandwidth > 0 && satellite.reflector.rtnBandwidth > 0;
  }
  return true;
};

export const getMissionTotalTerminalsRepartition = (mission: MissionDetails) => {
  return mission.userTerminals.reduce((agg, current) => agg + current.repartitionPercentage, 0);
};

export const someMissionNotComplete = (missions: MissionDetails[]) => {
  if (missions.length === 0) {
    return true;
  }

  return missions.some(mission => {
    const total = mission.userTerminals.reduce((sum: number, current) => sum + current.repartitionPercentage, 0);

    if (total !== 100) {
      return true;
    }

    const allFreqBandComplete = mission.userFrequencies.length > 0;
    if (!allFreqBandComplete) {
      return true;
    }

    if (mission.services.length === 0) {
      return true;
    }

    return false;
  });
};

export const canConfigureReflector = (sat: Partial<Satellite> | null, models: SatelliteModel[]) => {
  if (sat && isNumber(sat.modelId) && models) {
    const model = models.find(item => item.id === sat.modelId);
    if (model) {
      return model.useReflector;
    }
  }
  return false;
};

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const getNextMissionLetter = (missions: Mission[]) => {
  if (missions.length === 0) {
    return 'A';
  }

  const sortedMissions = [...missions];
  sortedMissions.sort((m1, m2) => m2.missionLetter.localeCompare(m1.missionLetter));

  const lastMission = sortedMissions[0];
  const lastIndex = alphabet.indexOf(lastMission.missionLetter);
  const nextIndex = lastIndex + 1;

  return alphabet.charAt(nextIndex);
};

export const filterMissionsWithSat = (missions: MissionDetails[], satellite: Satellite) => {
  return missions.filter(mission => {
    const band = satellite.reflector?.band;
    if (!band) {
      return false;
    }

    return mission.userFrequencies.some(freq => freq.name.includes(band));
  });
};

export const beamIsComplete = (beam: IUnderEditionDatabaseBeam) => {
  if (beam.tx && beam.rx) {
    const tx = beam.tx;
    const rx = beam.rx;
    return tx.d && tx.r && rx.r;
  }
  return false;
};
