import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import DOMPurify from 'dompurify';
import { getCustomerLock, getGatewayModels, getReferential, getSatelliteModels, lockCustomer, unlockCustomer } from 'shared/api/api';
import { Customer } from 'shared/model/customer.model';
import { GatewayModel } from 'shared/model/gateway-model.model';
import { customerLockStub, ReferentialData } from 'shared/model/referential.model';
import { SatelliteModel } from 'shared/model/satellite-model.model';

const initialState = {
  loadingReferential: false,
  loadingGatewayModels: false,
  loadingSatelliteModels: false,
  loadingCustomerLock: false,
  lockingCustomer: false,
  customerLock: null as Customer | null,
  referential: null as ReferentialData | null,
  gatewayModels: [] as GatewayModel[],
  satelliteModels: [] as SatelliteModel[],
  error: null as any
};

export type ReferentialState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'referential',
  initialState,
  reducers: {
    fetchReferentialStart: state => {
      state.loadingReferential = true;
    },
    fetchReferentialFailed: (state, action: PayloadAction<any>) => {
      state.loadingReferential = false;
      state.error = action.payload;
    },
    fetchReferentialSuccess: (state, action: PayloadAction<ReferentialData>) => {
      state.loadingReferential = false;
      const data = action.payload;

      // stub
      if (!data.legalNotice) {
        data.legalNotice = {
          html: '<h5> This is the legal notice of the app </h5>'
        };
      }
      if (!data.customerLock && false) {
        data.customerLock = customerLockStub;
      }
      if (data.legalNotice.html) {
        data.legalNotice.html = DOMPurify.sanitize(data.legalNotice.html);
      }

      state.referential = data;
    },
    fetchGatewayModelsStart: state => {
      state.loadingGatewayModels = true;
    },
    fetchGatewayModelsFailed: (state, action: PayloadAction<any>) => {
      state.loadingGatewayModels = false;
      state.error = action.payload;
    },
    fetchGatewayModelsSuccess: (state, action: PayloadAction<GatewayModel[]>) => {
      state.loadingGatewayModels = false;
      state.gatewayModels = action.payload;
    },
    fetchSatelliteModelsStart: state => {
      state.loadingSatelliteModels = true;
    },
    fetchSatelliteModelsFailed: (state, action: PayloadAction<any>) => {
      state.loadingSatelliteModels = false;
      state.error = action.payload;
    },
    fetchSatelliteModelsSuccess: (state, action: PayloadAction<SatelliteModel[]>) => {
      state.loadingSatelliteModels = false;
      state.satelliteModels = action.payload;
    },
    fetchCustomerLockStart: state => {
      state.loadingCustomerLock = true;
      state.customerLock = null;
    },
    fetchCustomerLockSuccess: (state, action: PayloadAction<Customer | null>) => {
      state.loadingCustomerLock = false;
      state.customerLock = action.payload;
    },
    fetchCustomerLockFailed: (state, action: PayloadAction<any>) => {
      state.loadingCustomerLock = false;
      state.error = action.payload;
    },
    lockCustomerStart: state => {
      state.lockingCustomer = true;
    },
    lockCustomerFailed: (state, action: PayloadAction<any>) => {
      state.lockingCustomer = false;
      state.error = action.payload;
    },
    lockCustomerSuccess: (state, action: PayloadAction<Customer | null>) => {
      state.lockingCustomer = false;
      state.customerLock = action.payload;
    },
    reset: state => {
      state.loadingReferential = false;
      state.loadingGatewayModels = false;
      state.loadingSatelliteModels = false;
      state.lockingCustomer = false;
      state.referential = null;
      state.gatewayModels = [];
      state.satelliteModels = [];
      state.error = null;
      state.customerLock = null;
    }
  }
});

const {
  fetchReferentialStart,
  fetchReferentialFailed,
  fetchReferentialSuccess,
  fetchGatewayModelsStart,
  fetchGatewayModelsFailed,
  fetchGatewayModelsSuccess,
  fetchSatelliteModelsStart,
  fetchSatelliteModelsFailed,
  fetchSatelliteModelsSuccess,
  lockCustomerStart,
  lockCustomerFailed,
  lockCustomerSuccess,
  fetchCustomerLockStart,
  fetchCustomerLockSuccess,
  fetchCustomerLockFailed
} = slice.actions;

export const { reset } = slice.actions;

export const fetchReferential = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchReferentialStart());
    const response: AxiosResponse<ReferentialData> = await getReferential();
    dispatch(fetchReferentialSuccess(response.data));
  } catch (error) {
    dispatch(fetchReferentialFailed(error));
  }
};

export const fetchGatewayModels = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchGatewayModelsStart());
    const response: AxiosResponse<GatewayModel[]> = await getGatewayModels();
    dispatch(fetchGatewayModelsSuccess(response.data));
  } catch (error) {
    dispatch(fetchGatewayModelsFailed(error));
  }
};

export const fetchSatelliteModels = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchSatelliteModelsStart());
    const response: AxiosResponse<SatelliteModel[]> = await getSatelliteModels();
    dispatch(fetchSatelliteModelsSuccess(response.data));
  } catch (error) {
    dispatch(fetchSatelliteModelsFailed(error));
  }
};

export const fetchCustomerLock = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchCustomerLockStart());
    const response: AxiosResponse<Customer> = await getCustomerLock();
    const data = response.data ? response.data : null;
    dispatch(fetchCustomerLockSuccess(data));
  } catch (error) {
    dispatch(fetchCustomerLockFailed(error));
  }
};

export const updateLockCustomer =
  (customerId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(lockCustomerStart());
      const response = customerId === -1 ? await unlockCustomer() : await lockCustomer(customerId);
      const data = response.data ? response.data : null;
      dispatch(lockCustomerSuccess(data));
    } catch (error) {
      dispatch(lockCustomerFailed(error));
    }
  };

export default slice.reducer;
