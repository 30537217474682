import esriConfig from '@arcgis/core/config';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import ArcGisMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import LayerList from '@arcgis/core/widgets/LayerList';
import { baseLayers } from 'config/baseLayer';
import { getEsriAPIKey, showMapLayers } from 'config/env';
import { IRootState } from 'config/store';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMap } from 'shared/reducers/mapSlice';
import { createSupportLayer } from 'shared/utils/map-utils';
import MapToolBar from 'shared/widgets/map/mapToolBar';
import useAreaOfInteretLayer from './layers/areaOfInterets/useAreaOfInteretLayer';
import useGatewayLayer from './layers/gateway/useGatewayLayer';
import usePoiLayer from './layers/poi/usePoiLayer';
import useSatelliteLayer from './layers/satellite/useSatelliteLayer';
import './map.scss';

esriConfig.assetsPath = '/assets';
esriConfig.apiKey = getEsriAPIKey();

export const popupConfg: __esri.PopupProperties = {
  dockEnabled: false,
  dockOptions: {
    buttonEnabled: false
  }
};

export let currentView = null as MapView | SceneView | null;
let mapView = null as MapView | null;
let sceneView = null as SceneView | null;

// see https://gitlab.thalesdigital.io/replat-tas-oen-skyt/front-office/-/issues/94
export const supportBaseLayers = baseLayers.reduce((agg: __esri.Layer[], current) => {
  const layer = createSupportLayer(current);
  if (layer) {
    agg.push(layer);
  }
  return agg;
}, []);

const resetViews = () => {
  currentView = null;
  mapView = null;
  sceneView = null;
};

const Map = () => {
  const dispatch = useDispatch();
  const mapRef = useRef<HTMLDivElement>(null);
  const flat = useSelector(({ map }: IRootState) => map.flat);
  const baseLayer = useSelector(({ map }: IRootState) => map.baseLayer);
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const qualityProfile = useSelector(({ map }: IRootState) => map.qualityProfile);

  useAreaOfInteretLayer();
  useGatewayLayer();
  usePoiLayer();
  useSatelliteLayer();

  useEffect(() => {
    if (mapRef.current) {
      let map = arcgisMap;
      if (!map) {
        map = new ArcGisMap({
          basemap: baseLayer,
          ground: 'world-elevation',
          layers: []
        });
        dispatch(setMap(map));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, arcgisMap]);

  useEffect(() => {
    if (arcgisMap && mapRef.current) {
      let activeViewpoint = null;
      if (currentView) {
        activeViewpoint = currentView.viewpoint.clone();
        currentView.set({ container: null });
      }
      if (flat) {
        if (!mapView) {
          mapView = new MapView({
            map: arcgisMap,
            container: mapRef.current,
            center: [1, 1],
            spatialReference: SpatialReference.WebMercator,
            constraints: {
              minZoom: 3
            },
            popup: popupConfg
          });
          if (showMapLayers()) {
            const layerList = new LayerList({
              view: mapView
            });
            mapView.ui.add(layerList, 'top-right');
          }
        } else if (activeViewpoint) {
          mapView.viewpoint = activeViewpoint;
          mapView.container = mapRef.current;
        }
        currentView = mapView;
      } else {
        if (!sceneView) {
          sceneView = new SceneView({
            container: mapRef.current,
            map: arcgisMap,
            qualityProfile,
            camera: {
              position: {
                x: 1, //Longitude
                y: 1, //Latitude
                z: 25000000 //Meters
              },
              tilt: 0
            },
            popup: popupConfg
          });
        } else if (activeViewpoint) {
          sceneView.viewpoint = activeViewpoint;
          sceneView.container = mapRef.current;
        }
        currentView = sceneView;
      }
    }
  }, [flat, arcgisMap, qualityProfile]);

  useEffect(() => {
    if (arcgisMap) {
      arcgisMap.set('basemap', baseLayer);
      if (baseLayer === 'satellite') {
        arcgisMap.addMany(supportBaseLayers, 0);
        if (currentView instanceof MapView) {
          currentView.constraints.minZoom = 3;
        }
      } else {
        arcgisMap.removeMany(supportBaseLayers);
        if (currentView instanceof MapView) {
          currentView.constraints.minZoom = 2;
        }
      }
    }
  }, [baseLayer, arcgisMap]);

  useEffect(() => {
    return () => {
      resetViews();
    };
  }, []);

  return (
    <>
      <div className="skyt-map" ref={mapRef}></div>
      <MapToolBar />
    </>
  );
};

export default Map;
