import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Notification } from 'shared/model/notification.model';
import './notifications.scss';

interface INotificationProps {
  item: Notification;
}

const GraphResultNotification = (props: INotificationProps) => {
  const { t } = useTranslation('translation');

  const { item } = props;

  const date = moment(item.date);

  const fromNow = date.fromNow();
  const dateToolTip = date.format('DD/MM/YYYY HH:mm');

  const renderTooltip = (props: any) => <Tooltip {...props}>{item.message}</Tooltip>;

  return (
    <div key={item.id} className="notification">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="comment">
          <FontAwesomeIcon icon="signal" className="mr-2" />
          <span>{t('notifications.results')}</span>
        </div>
        <div className="text-nowrap" title={dateToolTip}>
          <span>{fromNow}</span>
          <FontAwesomeIcon icon={['far', 'clock']} className="ml-2" />
        </div>
      </div>
      <OverlayTrigger placement="bottom" overlay={renderTooltip}>
        <div>{item.title}</div>
      </OverlayTrigger>
    </div>
  );
};

export default GraphResultNotification;
