// i => id
// lt => lat
// lg => long
// s => size
// sv => services
// n => name
// value => v
// sys => system
// c => capacity
// r => ratio
// tx => "payloadTarget>forward"
// rx => "payloadTarget>return"
// b => band
// e => eirp
// c => c/i
// g => g/t
// r => freq plan occupancy ratio
// d => eirp density

import Polygon from '@arcgis/core/geometry/Polygon';
import Graphic from '@arcgis/core/Graphic';
import { resultClassBreakInfosColors } from 'modules/results/commons/resultConstants';
import { CoverageType } from 'shared/model/coverage.model';
import { Mission } from 'shared/model/mission.model';
import { createBeamSymbol } from 'shared/utils/beam-utils';

export interface ISvDatabaseBeam {
  n: string;
  v: number;
}

export interface ISysDatabaseBeam {
  c: number;
  r: number;
}

export interface ITxDatabaseBeam {
  b: number;
  e: number;
  c: number;
  d: number;
  r: number;
}

export interface IRxDatabaseBeam {
  b: number;
  g: number;
  c: number;
  r: number;
}

export interface IDatabaseBeam {
  i: string;
  lt: number;
  lg: number;
  s: number;
  sv: ISvDatabaseBeam[];
  sys: ISysDatabaseBeam;
  tx: ITxDatabaseBeam;
  rx: IRxDatabaseBeam;
}

export interface IUnderEditionDatabaseBeam extends IDatabaseBeam {
  selected: boolean;
}

export interface IFeatureBeam {
  id: string;
  i: string;
  lt: number;
  lg: number;
  s: number;
  name: string;
  type: CoverageType;
  missionId: number;
  sysc: number | null;
  sysr: number | null;
  txb: number | null;
  txe: number | null;
  txc: number | null;
  txd: number | null;
  txr: number | null;
  rxc: number | null;
  rxg: number | null;
  rxb: number | null;
  rxr: number | null;
  selected?: string;
}

export enum BeamCriterion {
  TARGETED_CAPACITY = 'sysc',
  RATIO = 'sysr',
  BAND_FORWARD = 'txb',
  EIRP = 'txe',
  CI_FORWARD = 'txc',
  CI_RETURN = 'rxc',
  GT = 'rxg',
  BAND_RETURN = 'rxb',
  EIRP_DENSITY = 'txd',
  FREQ_PLANOCC_RATIO_FORWARD = 'txr',
  FREQ_PLANOCC_RATIO_RETURN = 'rxr'
}

export const getBeamCriterionDef = (criterion: BeamCriterion) => {
  switch (criterion) {
    case BeamCriterion.TARGETED_CAPACITY:
      return beamCapacityDef;
    case BeamCriterion.RATIO:
      return beamRatioDef;
    case BeamCriterion.BAND_FORWARD:
    case BeamCriterion.BAND_RETURN:
      return beamBandDef;
    case BeamCriterion.CI_FORWARD:
    case BeamCriterion.CI_RETURN:
      return beamCIDef;
    case BeamCriterion.GT:
      return beamGTDef;
    case BeamCriterion.EIRP:
      return beamEirpDef;
    case BeamCriterion.EIRP_DENSITY:
      return beamEirpDensityDef;
    case BeamCriterion.FREQ_PLANOCC_RATIO_FORWARD:
    case BeamCriterion.FREQ_PLANOCC_RATIO_RETURN:
      return beamFreqPlanOccupancyRatioDef;
  }
};

// see https://www.colorhexa.com/510e73-to-dfb0f7
// inital colors. Now we use resultColors
let colors: string[] = [
  '#11998e',
  '#14a08d',
  '#18a78b',
  '#1bae8a',
  '#1eb688',
  '#21bd87',
  '#25c485',
  '#28cb84',
  '#2bd283',
  '#2eda81',
  '#31e180',
  '#35e87e',
  '#38ef7d'
];
colors = resultClassBreakInfosColors;

const beamCapacityDef = {
  min: 0,
  max: 3000,
  unit: 'Mbps',
  classBreakInfos: [
    {
      minValue: 1,
      maxValue: 500,
      symbol: createBeamSymbol(colors[0]),
      label: '0 - 500 Mbps'
    },
    {
      minValue: 501,
      maxValue: 1000,
      symbol: createBeamSymbol(colors[2]),
      label: '500 - 1000 Mbps'
    },
    {
      minValue: 1001,
      maxValue: 1500,
      symbol: createBeamSymbol(colors[5]),
      label: '1000 - 1500 Mbps'
    },
    {
      minValue: 1501,
      maxValue: 2000,
      symbol: createBeamSymbol(colors[7]),
      label: '1500 - 2000 Mbps'
    },
    {
      minValue: 2001,
      maxValue: 2500,
      symbol: createBeamSymbol(colors[9]),
      label: '2000 - 2500 Mbps'
    },
    {
      minValue: 2501,
      maxValue: 3000,
      symbol: createBeamSymbol(colors[11]),
      label: '2500 - 3000 Mbps'
    }
  ]
};

export const beamRatioDef = {
  min: 1,
  max: 10,
  unit: '',
  classBreakInfos: [
    {
      minValue: 1,
      maxValue: 2,
      symbol: createBeamSymbol(colors[1]),
      label: '1 - 2'
    },
    {
      minValue: 2.1,
      maxValue: 3,
      symbol: createBeamSymbol(colors[2]),
      label: '2 - 3'
    },
    {
      minValue: 3.1,
      maxValue: 4,
      symbol: createBeamSymbol(colors[3]),
      label: '3 - 4'
    },
    {
      minValue: 4.1,
      maxValue: 5,
      symbol: createBeamSymbol(colors[4]),
      label: '4 - 5'
    },
    {
      minValue: 5.1,
      maxValue: 6,
      symbol: createBeamSymbol(colors[5]),
      label: '5 - 6'
    },
    {
      minValue: 6.1,
      maxValue: 7,
      symbol: createBeamSymbol(colors[6]),
      label: '6 - 7'
    },
    {
      minValue: 7,
      maxValue: 8,
      symbol: createBeamSymbol(colors[7]),
      label: '7 - 8'
    },
    {
      minValue: 8.1,
      maxValue: 9,
      symbol: createBeamSymbol(colors[8]),
      label: '8 - 9'
    },
    {
      minValue: 9,
      maxValue: 10,
      symbol: createBeamSymbol(colors[11]),
      label: '9 - 10'
    }
  ]
};
export const beamBandDef = {
  min: 0,
  max: 500,
  unit: 'Mhz',
  classBreakInfos: [
    {
      minValue: 1,
      maxValue: 100,
      symbol: createBeamSymbol(colors[0]),
      label: '0 - 100 Mbps'
    },
    {
      minValue: 101,
      maxValue: 200,
      symbol: createBeamSymbol(colors[2]),
      label: '100 - 200 Mbps'
    },
    {
      minValue: 201,
      maxValue: 300,
      symbol: createBeamSymbol(colors[5]),
      label: '200 - 300 Mbps'
    },
    {
      minValue: 301,
      maxValue: 400,
      symbol: createBeamSymbol(colors[8]),
      label: '300 - 400 Mbps'
    },
    {
      minValue: 401,
      maxValue: 500,
      symbol: createBeamSymbol(colors[11]),
      label: '400 - 500 Mbps'
    }
  ]
};
export const beamEirpDef = {
  min: 50,
  max: 80,
  defaultValue: 65,
  unit: 'dBW',
  classBreakInfos: [
    {
      minValue: 50,
      maxValue: 54,
      symbol: createBeamSymbol(colors[0]),
      label: '50 - 54 dBW'
    },
    {
      minValue: 55,
      maxValue: 59,
      symbol: createBeamSymbol(colors[2]),
      label: '55 - 59 dBW'
    },
    {
      minValue: 60,
      maxValue: 64,
      symbol: createBeamSymbol(colors[4]),
      label: '60 - 64 dBW'
    },
    {
      minValue: 65,
      maxValue: 69,
      symbol: createBeamSymbol(colors[6]),
      label: '65 - 69 dBW'
    },
    {
      minValue: 70,
      maxValue: 74,
      symbol: createBeamSymbol(colors[8]),
      label: '70 - 74 dBW'
    },
    {
      minValue: 75,
      maxValue: 80,
      symbol: createBeamSymbol(colors[11]),
      label: '75 - 80 dBW'
    }
  ]
};

export const beamEirpDensityDef = {
  min: -50,
  max: -20,
  defaultValue: -34,
  unit: 'dBW/Hz',
  classBreakInfos: [
    {
      minValue: -50,
      maxValue: -46,
      symbol: createBeamSymbol(colors[0]),
      label: '-50 - -46 dBW/Hz'
    },
    {
      minValue: -45,
      maxValue: -41,
      symbol: createBeamSymbol(colors[2]),
      label: '-45 - -41 dBW/Hz'
    },
    {
      minValue: -40,
      maxValue: -36,
      symbol: createBeamSymbol(colors[4]),
      label: '-40 - -36 dBW/Hz'
    },
    {
      minValue: -35,
      maxValue: -31,
      symbol: createBeamSymbol(colors[6]),
      label: '-35 - -31 dBW/Hz'
    },
    {
      minValue: -30,
      maxValue: -26,
      symbol: createBeamSymbol(colors[8]),
      label: '-30 - -26 dBW/Hz'
    },
    {
      minValue: -25,
      maxValue: -20,
      symbol: createBeamSymbol(colors[11]),
      label: '-25 - -20 dBW/Hz'
    }
  ]
};

export const beamFreqPlanOccupancyRatioDef = {
  min: 0,
  max: 100,
  defaultValue: 100,
  unit: '%',
  classBreakInfos: [
    {
      minValue: 0,
      maxValue: 10,
      symbol: createBeamSymbol(colors[0]),
      label: '0 - 10 %'
    },
    {
      minValue: 11,
      maxValue: 20,
      symbol: createBeamSymbol(colors[1]),
      label: '11 - 20 %'
    },
    {
      minValue: 21,
      maxValue: 30,
      symbol: createBeamSymbol(colors[2]),
      label: '21 - 30 %'
    },
    {
      minValue: 31,
      maxValue: 40,
      symbol: createBeamSymbol(colors[3]),
      label: '31 - 40 %'
    },
    {
      minValue: 41,
      maxValue: 50,
      symbol: createBeamSymbol(colors[4]),
      label: '41 - 50 %'
    },
    {
      minValue: 51,
      maxValue: 60,
      symbol: createBeamSymbol(colors[5]),
      label: '51 - 60 %'
    },
    {
      minValue: 61,
      maxValue: 70,
      symbol: createBeamSymbol(colors[6]),
      label: '61 - 70 %'
    },
    {
      minValue: 71,
      maxValue: 80,
      symbol: createBeamSymbol(colors[7]),
      label: '71 - 80 %'
    },
    {
      minValue: 81,
      maxValue: 90,
      symbol: createBeamSymbol(colors[8]),
      label: '81 - 90 %'
    },
    {
      minValue: 90,
      maxValue: 100,
      symbol: createBeamSymbol(colors[11]),
      label: '90 - 100 %'
    }
  ]
};

export const beamCIDef = {
  min: 0,
  max: 30,
  defaultValue: 15,
  unit: 'dB',
  classBreakInfos: [
    {
      minValue: 0,
      maxValue: 4,
      symbol: createBeamSymbol(colors[0]),
      label: '0 - 4 dB'
    },
    {
      minValue: 5,
      maxValue: 9,
      symbol: createBeamSymbol(colors[2]),
      label: '5 - 9 dB'
    },
    {
      minValue: 10,
      maxValue: 14,
      symbol: createBeamSymbol(colors[4]),
      label: '10 - 14 dB'
    },
    {
      minValue: 15,
      maxValue: 19,
      symbol: createBeamSymbol(colors[6]),
      label: '15 - 19 dB'
    },
    {
      minValue: 20,
      maxValue: 24,
      symbol: createBeamSymbol(colors[8]),
      label: '20 - 24 dB'
    },
    {
      minValue: 25,
      maxValue: 30,
      symbol: createBeamSymbol(colors[11]),
      label: '25 - 30 dB'
    }
  ]
};

export const beamGTDef = {
  min: -15,
  max: 25,
  defaultValue: 12,
  unit: 'dB/K',
  classBreakInfos: [
    {
      minValue: -15,
      maxValue: -11,
      symbol: createBeamSymbol(colors[0]),
      label: '-15 - -11 dB/K'
    },
    {
      minValue: -10,
      maxValue: -6,
      symbol: createBeamSymbol(colors[2]),
      label: '-10 - -11 dB/K'
    },
    {
      minValue: -5,
      maxValue: 0,
      symbol: createBeamSymbol(colors[4]),
      label: '-5 - 0 dB/K'
    },
    {
      minValue: 1,
      maxValue: 5,
      symbol: createBeamSymbol(colors[5]),
      label: '1 - 5 dB/K'
    },
    {
      minValue: 6,
      maxValue: 10,
      symbol: createBeamSymbol(colors[6]),
      label: '6 - 10 dB/K'
    },
    {
      minValue: 11,
      maxValue: 15,
      symbol: createBeamSymbol(colors[8]),
      label: '11 - 15 dB/K'
    },
    {
      minValue: 16,
      maxValue: 20,
      symbol: createBeamSymbol(colors[10]),
      label: '16 - 20 dB/K'
    },
    {
      minValue: 21,
      maxValue: 25,
      symbol: createBeamSymbol(colors[11]),
      label: '21 - 25 dB/K'
    }
  ]
};

export const defaultTx: ITxDatabaseBeam = {
  b: 100,
  c: beamCIDef.defaultValue,
  e: beamEirpDef.defaultValue,
  d: beamEirpDensityDef.defaultValue,
  r: beamFreqPlanOccupancyRatioDef.defaultValue
};

export const getDefaultRx = (mission?: Mission): IRxDatabaseBeam => {
  const b = getDefaultBandForwardPerBeam(mission);
  return {
    b,
    c: beamCIDef.defaultValue,
    g: beamGTDef.defaultValue,
    r: beamFreqPlanOccupancyRatioDef.defaultValue
  };
};

const hasAreoService = (mission?: Mission) =>
  mission ? mission.services.some(item => item.category.toLowerCase().includes('aero')) : false;

const getDefaultBandForwardPerBeam = (mission?: Mission) => {
  if (hasAreoService(mission)) {
    return 100;
  }
  return 33;
};

const getDefaultTargetCapacityPerBeam = (mission?: Mission) => {
  if (hasAreoService(mission)) {
    return 200;
  }
  return 300;
};

const getDefaultRatio = (mission?: Mission) => {
  if (hasAreoService(mission)) {
    return 1;
  }
  return 3;
};

export const getDefaultSys = (mission?: Mission): ISysDatabaseBeam => {
  const c = getDefaultTargetCapacityPerBeam(mission);
  const r = getDefaultRatio(mission);
  return {
    c,
    r
  };
};

export const getDefaultDatabaseBeam = (mission?: Mission): IDatabaseBeam => {
  const sys = getDefaultSys(mission);
  return {
    i: '0',
    lt: 0,
    lg: 0,
    s: 1,
    sv: [] as ISvDatabaseBeam[],
    sys,
    tx: defaultTx,
    rx: getDefaultRx(mission)
  };
};

export const graphicToDatabaseBeam = (graphic: Graphic, mission?: Mission): IDatabaseBeam => {
  const polygon = graphic.geometry as Polygon;
  const center = polygon.centroid;

  const att = graphic.attributes;

  const s = att.s;
  const i = att.i;

  return {
    ...getDefaultDatabaseBeam(mission),
    i,
    lg: center.longitude,
    lt: center.latitude,
    s
  };
};
