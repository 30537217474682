import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { beamNumberLabelClass } from 'modules/map/layers/beams/beam-layer-def';
import { resultClassBreakInfosColors, resultLayerId } from 'modules/results/commons/resultConstants';
import { IBeamMapModel } from 'shared/model/results.model';
import { createBeamSymbol, makeBeam, toABPoint } from 'shared/utils/beam-utils';
import { round2Decimal } from 'shared/utils/math-utils';

const defaultSym = new SimpleFillSymbol({
  outline: {
    color: 'white',
    width: '1px'
  }
});

const initBeamMap = (model: IBeamMapModel, satelliteLng: number, missionLetter?: string) => {
  const graphics = model.values.reduce((agg: Graphic[], item) => {
    if (missionLetter && item.i.startsWith(missionLetter)) {
      const center = toABPoint(item.lt, item.lg, satelliteLng);
      const polygon = makeBeam(center, item.s, satelliteLng);

      const attributes = {
        i: item.i,
        name: item.i,
        label: model.name,
        val: item.value,
        unit: model.unit
      };

      const g = new Graphic({
        geometry: polygon,
        attributes
      });
      agg.push(g);
    }

    return agg;
  }, []);

  const step = (model.maxValue - model.minValue) / resultClassBreakInfosColors.length;
  const classBreakInfos = resultClassBreakInfosColors.map((color, i, arr) => {
    const isLastIndex = arr.length - 1 === i;

    const minValue = i === 0 ? Math.floor(model.minValue) : round2Decimal(model.minValue + i * step);
    const maxValue =
      i === 0 ? round2Decimal(model.minValue + 1 * step) : isLastIndex ? Math.ceil(minValue + step) : round2Decimal(minValue + step);

    return {
      minValue,
      maxValue,
      symbol: createBeamSymbol(color, 0.7)
    };
  });

  const renderer = new ClassBreaksRenderer({
    field: 'val',
    defaultSymbol: defaultSym,
    classBreakInfos
  });

  const layer = new FeatureLayer({
    id: resultLayerId,
    spatialReference: SpatialReference.WGS84,
    title: resultLayerId,
    renderer,
    source: graphics,
    labelingInfo: [beamNumberLabelClass],
    geometryType: 'polygon',
    objectIdField: 'id',
    opacity: 0.7,
    fields: [
      {
        name: 'id',
        type: 'string'
      },
      {
        name: 'name',
        type: 'string'
      },
      {
        name: 'label',
        type: 'string'
      },
      {
        name: 'val',
        type: 'double'
      },
      {
        name: 'max',
        type: 'double'
      },
      {
        name: 'unit',
        type: 'string'
      }
    ],
    popupTemplate: {
      title: '{name}',
      content: '<div><div>{label}<div><div>{val} {unit}</div></div>',
      outFields: ['*']
    }
  });
  return layer;
};

export default initBeamMap;
