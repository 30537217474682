import classmames from 'classnames';
import { IRootState } from 'config/store';
import { useSelector } from 'react-redux';
import { Satellite } from 'shared/model/satellite.model';
import ImageFromUrl from 'shared/widgets/images/ImageFromUrl';
interface ISatelliteItemProps {
  item: Satellite;
  className?: string;
}

const SatelliteCardTitle = (props: ISatelliteItemProps) => {
  const satelliteModels = useSelector(({ referential }: IRootState) => referential.satelliteModels);

  const { item, className } = props;

  const itemModel = satelliteModels.find(model => model.id === item.modelId);

  return (
    <div className={classmames('d-flex align-items-center text-primary', className)}>
      <div>{itemModel?.logo && <ImageFromUrl url={itemModel.logo} title={itemModel.name} height="24px" className="mr-2" />}</div>
      <div>{item.name}</div>
    </div>
  );
};

export default SatelliteCardTitle;
