import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Area } from 'shared/model/area.model';
import { Coverage, CoverageType } from 'shared/model/coverage.model';
import { MissionDetails } from 'shared/model/mission.model';
import { ProjectDetails } from 'shared/model/project.model';
import { Satellite } from 'shared/model/satellite.model';
import { fetchAreasOfCoverages } from 'shared/reducers/areaSlice';
import { setShowGlobalBeam } from 'shared/reducers/satelliteSlice';
import { makeBeamFromDatabase } from 'shared/utils/beam-utils';
import { cleanFeatureLayerAndRemove } from 'shared/utils/map-utils';
import { getGeometriesOfAreaOfInterest, getMissionFromArea } from 'shared/utils/model-utils';
import { beamNumberLabelClass, getBeamId, getBeamsLayer, getRegionalBeamI, toBeamAttribute } from './beam-layer-def';
import './beams.scss';

let beamLayer: FeatureLayer | null = null;

let refreshing = false;

const useBeamLayer = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const showBeamLayer = useSelector(({ map }: IRootState) => map.showBeamLayer);
  const showBeamNumber = useSelector(({ map }: IRootState) => map.showBeamNumber);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const loadingConfiguration = useSelector(({ configuration }: IRootState) => configuration.loading);
  const loadingMissions = useSelector(({ configuration }: IRootState) => configuration.loadingMissions);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const coverages = useSelector(({ coverage }: IRootState) => coverage.coverages);
  const loadingCoverage = useSelector(({ coverage }: IRootState) => coverage.loading);
  const areas = useSelector(({ area }: IRootState) => area.areas);
  const area = useSelector(({ area }: IRootState) => area.area);
  const loadingArea = useSelector(({ area }: IRootState) => area.loading);
  const beamCriterion = useSelector(({ map }: IRootState) => map.beamCriterion);

  let selectedAreas = areas;
  if (area?.beamList) {
    selectedAreas = [area] as Area[];
  }

  const { satelliteId } = useParams<{ satelliteId: string }>();

  const isLoading = loadingCoverage || loadingArea || loadingMissions !== -1 || loadingConfiguration;

  let satellite: Satellite | undefined;
  if (project && configuration && satelliteId) {
    satellite = project.satellites.find(sat => sat.id === configuration.satelliteId && sat.id === Number(satelliteId));
  }

  useEffect(() => {
    return () => {
      if (beamLayer && arcgisMap) {
        refreshing = true;
        cleanFeatureLayerAndRemove(beamLayer, arcgisMap).then(() => {
          refreshing = false;
        });
        dispatch(setShowGlobalBeam(false));
      }
    };
  }, [arcgisMap, dispatch]);

  useEffect(() => {
    if (
      project &&
      configuration &&
      coverages &&
      !loadingCoverage &&
      loadingMissions === -1 &&
      !loadingConfiguration &&
      missions.length > 0
    ) {
      if (arcgisMap && beamLayer) {
        cleanFeatureLayerAndRemove(beamLayer, arcgisMap);
        beamLayer = null;
      }

      const filteredCoverages = coverages
        .filter(coverage => coverage.configurationId === configuration.id && missions.some(mission => mission.id === coverage.missionId))
        .map(c => c.id);
      dispatch(fetchAreasOfCoverages(project.id, configuration.satelliteId, configuration.id, filteredCoverages));
    }
  }, [arcgisMap, configuration, coverages, dispatch, project, missions, loadingCoverage, loadingMissions, loadingConfiguration]);

  useEffect(() => {
    if (beamLayer) {
      if (showBeamNumber) {
        beamLayer.labelingInfo = [beamNumberLabelClass];
      } else {
        beamLayer.labelingInfo = [];
      }
    }
  }, [showBeamNumber]);

  // when no configuration
  useEffect(() => {
    if (beamLayer && arcgisMap && !configuration) {
      cleanFeatureLayerAndRemove(beamLayer, arcgisMap);
    }
  }, [configuration, arcgisMap]);

  useEffect(() => {
    if (!refreshing && arcgisMap && satellite && !isLoading) {
      if (beamLayer) {
        arcgisMap.remove(beamLayer);
        beamLayer = null;
      }
      if (!showBeamLayer) {
        return;
      }
      refreshing = true;

      const graphics = selectedAreas.reduce((agg: Graphic[], currentArea) => {
        const coverage = coverages.find(item => item.id === currentArea.coverageId);
        if (coverage) {
          dispatch(setShowGlobalBeam(coverage.coverageType === CoverageType.STEERABLE_BEAM));
          const beams = getBeams(coverage, currentArea, satellite as Satellite, selectedMissions as MissionDetails[]);
          beams.forEach(beam => agg.push(beam));
        }
        return agg;
      }, []);

      if (selectedMissions.length > 0) {
        beamLayer = getBeamsLayer(selectedMissions, beamCriterion, t, graphics);

        arcgisMap.add(beamLayer);
        if (graphics.length > 0) {
          currentView?.goTo(graphics);
        }
      }
      refreshing = false;
    }
  }, [arcgisMap, beamCriterion, coverages, dispatch, isLoading, satellite, selectedAreas, selectedMissions, showBeamLayer, t]);
};

const getBeams = (coverage: Coverage, area: Area, satellite: Satellite, missions: MissionDetails[]): Graphic[] => {
  let result: Graphic[] = [];

  if (!area || !satellite) {
    return result;
  }

  const mission = getMissionFromArea(area, missions, coverage);
  if (mission) {
    switch (coverage.coverageType) {
      case CoverageType.STEERABLE_BEAM:
        if (area.beamList) {
          const graphics = area.beamList.map((beam, i) => {
            const polygon = makeBeamFromDatabase(beam, satellite.orbitalPosition);

            const name = beam.i;

            const attributes = {
              ...toBeamAttribute(beam),
              ObjectID: getBeamId(CoverageType.STEERABLE_BEAM, area.id, beam.i),
              name,
              type: coverage.coverageType,
              missionId: mission.id
            };

            return new Graphic({
              geometry: polygon,
              attributes
            });
          });

          result = result.concat(graphics);
        }
        break;
      case CoverageType.MULTI_BEAM:
        if (area.beamList) {
          const graphics = area.beamList.map((beam, i) => {
            const polygon = makeBeamFromDatabase(beam, satellite.orbitalPosition);

            const name = beam.i;

            const attributes = {
              ...toBeamAttribute(beam),
              name,
              type: coverage.coverageType,
              missionId: mission.id,
              ObjectID: getBeamId(CoverageType.MULTI_BEAM, area.id, beam.i)
            };

            return new Graphic({
              geometry: webMercatorUtils.geographicToWebMercator(polygon),
              attributes
            });
          });
          result = result.concat(graphics);
        }
        break;
      case CoverageType.REGIONAL:
        missions.forEach(mission => {
          const areaOfInterests = mission.areaOfInterests.find(item => item.id === area.areaOfInterestId);
          if (areaOfInterests) {
            const polygons = getGeometriesOfAreaOfInterest(areaOfInterests);
            const polygon = polygons.length > 1 ? geometryEngine.union(polygons) : polygons[0];

            const i = getRegionalBeamI(mission, missions, areaOfInterests);

            const attributes = {
              ObjectID: getBeamId(CoverageType.REGIONAL, area.id, areaOfInterests.id),
              type: coverage.coverageType,
              missionId: mission.id,
              i
            };

            result.push(
              new Graphic({
                geometry: polygon,
                attributes
              })
            );
          }
        });
        break;
      default:
        break;
    }
  }
  return result;
};

export default useBeamLayer;
