import classNames from 'classnames';
import { IRootState } from 'config/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { resultCategoryType } from 'shared/model/results.model';
import Loader from 'shared/widgets/loader';
import ResultChartCard from './ResultChartCard';
import './charts-grid.scss';

const ChartsGrid = () => {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const exportItem = useSelector(({ exporter }: IRootState) => exporter.export);
  const loading = useSelector(({ exporter }: IRootState) => exporter.loading);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const category = params.has('category') ? (params.get('category') as resultCategoryType) : 'Overview';

  if (loading) {
    return <Loader />;
  }
  if (!exportItem?.exportResult?.results || exportItem.exportResult.results.length === 0) {
    return null;
  }

  const onFilter = (category: resultCategoryType) => () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('category', category);

    history.push({
      search: searchParams.toString()
    });
  };

  const results = exportItem.exportResult.results.filter(result => result.category === category);

  return (
    <>
      <div className="charts-grid-filter">
        <div className={classNames('chart-grid-filter', { active: category === 'Overview' })} onClick={onFilter('Overview')}>
          <div>{t('results.charts.overview')}</div>
          {category === 'Overview' && <hr className="underline" />}
        </div>
        <div className={classNames('chart-grid-filter', { active: category === 'Detailed' })} onClick={onFilter('Detailed')}>
          <div>{t('results.charts.detailed')}</div>
          {category === 'Detailed' && <hr className="underline" />}
        </div>
      </div>
      <div className="charts-grid">
        {results.map((item, i) => (
          <ResultChartCard result={item} exportId={exportItem.id} key={`result_card_${item.name}_${i}`} />
        ))}
      </div>
    </>
  );
};

export default ChartsGrid;
