import classNames from 'classnames';
import { IRootState } from 'config/store';
import { beamRatioDef } from 'modules/map/layers/beams/databaseBeam.model';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from './common-slider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    1: <span className={className}>1</span>,
    2: <span className={className}>2</span>,
    3: <span className={className}>3</span>,
    4: <span className={className}>4</span>,
    5: <span className={className}>5</span>,
    6: <span className={className}>6</span>,
    7: <span className={className}>7</span>,
    8: <span className={className}>8</span>,
    9: <span className={className}>9</span>,
    10: <span className={className}>10</span>
  };
};

interface IFwdRtnRatioSliderProps {
  defaultValue?: number;
  disabled?: boolean;
}

const FwdRtnRatioSlider = (props: IFwdRtnRatioSliderProps) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const { defaultValue, disabled = false } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue);

  useEffect(() => {
    if (!disabled) {
      setValue(defaultValue);
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (previousDebounceValue !== debounceValue && typeof debounceValue !== 'undefined' && !disabled) {
      const newUnderEditionBeams = underEditionBeams.map(underEditionBeam => {
        if (underEditionBeam.selected) {
          const initialSys = underEditionBeam.sys;
          const sys = {
            ...initialSys,
            r: debounceValue
          };

          return {
            ...underEditionBeam,
            sys
          };
        }
        return { ...underEditionBeam };
      });
      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }
  }, [debounceValue, disabled, dispatch, previousDebounceValue, underEditionBeams]);

  const onChange = (e: number) => {
    setValue(e);
  };

  const marks = getMarks(disabled);

  const showMandatoryError = typeof value === 'undefined' && !disabled;

  return (
    <Form.Group className={classNames('common-slider', { 'text-danger': showMandatoryError })}>
      <Form.Label className="d-flex justify-content-center mb-0">{t('area.targets.fwdRtnRatio')}&nbsp;*</Form.Label>
      <SkyTSlider
        onChange={onChange}
        marks={marks}
        min={beamRatioDef.min}
        max={beamRatioDef.max}
        value={value}
        step={1}
        disabled={disabled}
        mandatory
      />
    </Form.Group>
  );
};

export default FwdRtnRatioSlider;
