import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { IRootState } from 'config/store';
import isNumber from 'lodash/isNumber';
import { currentView } from 'modules/map/map';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowGlobalBeam, setUnderEditionSatellite } from 'shared/reducers/satelliteSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import { layerInMap } from 'shared/utils/map-utils';
import {
  computeReflector,
  createMoveHandleGeom,
  getMoveHandleSelectorSymbol,
  getSelectedSelectorSymbol
} from 'shared/utils/reflector-util';
import { satelliteLayer } from './useSatelliteLayer';

let updateHandle: IHandle | undefined;
let sketchViewModel: SketchViewModel | undefined;
let reflectorGraphic: Graphic | undefined;

const defaultNoCenter = new Point({
  latitude: 0,
  longitude: 0
});

export const satelliteReflectorLayer = new GraphicsLayer({
  title: 'satellite-reflector'
});

const useSatelliteReflectorLayer = () => {
  const dispatch = useDispatch();
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);

  const defaultCenter = underEditionSatellite?.orbitalPosition
    ? new Point({ latitude: 0, longitude: underEditionSatellite.orbitalPosition })
    : defaultNoCenter;

  const [center, setCenter] = useState<Point>(defaultCenter);
  const previousCenter = usePrevious(center);

  const reflector = underEditionSatellite?.reflector;

  const majorAxisA = reflector?.majorAxisA;
  const previousMajorAxisA = usePrevious(majorAxisA);

  const majorAxisB = reflector?.majorAxisB;

  const centerLat = reflector?.centerLat ? reflector.centerLat : 0;
  const centerLng = reflector?.centerLng
    ? reflector.centerLng
    : underEditionSatellite?.orbitalPosition
    ? underEditionSatellite.orbitalPosition
    : 0;

  const orbitalPosition = underEditionSatellite?.orbitalPosition ? underEditionSatellite.orbitalPosition : 0;

  useEffect(() => {
    return () => {
      satelliteReflectorLayer.removeAll();
      if (arcgisMap) {
        arcgisMap.remove(satelliteReflectorLayer);
      }
      if (sketchViewModel) {
        sketchViewModel.destroy();
        sketchViewModel = undefined;
      }
      dispatch(setShowGlobalBeam(false));
    };
  }, [arcgisMap, dispatch]);

  useEffect(() => {
    if (arcgisMap && currentView && underEditionSatellite) {
      if (!layerInMap(arcgisMap, satelliteReflectorLayer.id)) {
        arcgisMap.add(satelliteReflectorLayer);
        dispatch(setShowGlobalBeam(true));
        if (underEditionSatellite.reflector) {
          const reflector = underEditionSatellite.reflector;
          const latitude = reflector.centerLat ? reflector.centerLat : 0;
          const longitude = reflector.centerLng ? reflector.centerLng : underEditionSatellite.orbitalPosition;
          const newCenter = new Point({ latitude, longitude });
          setCenter(newCenter);
        }
      }
    }
  }, [arcgisMap, dispatch, underEditionSatellite]);

  useEffect(() => {
    if ((previousCenter !== center || previousMajorAxisA !== majorAxisA) && majorAxisA && majorAxisB) {
      if (reflectorGraphic) {
        satelliteLayer.remove(reflectorGraphic);
      }

      const reflectorGeom = computeReflector(center.latitude, center.longitude, majorAxisA, majorAxisB, orbitalPosition);
      reflectorGraphic = new Graphic({
        geometry: webMercatorUtils.geographicToWebMercator(reflectorGeom),
        symbol: getSelectedSelectorSymbol()
      });
      satelliteLayer.add(reflectorGraphic);

      const underEdition = {
        ...underEditionSatellite,
        reflector: {
          ...underEditionSatellite?.reflector,
          centerLat: center.latitude,
          centerLng: center.longitude
        }
      };
      dispatch(setUnderEditionSatellite(underEdition));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, previousCenter, orbitalPosition, previousMajorAxisA, majorAxisA, majorAxisB]);

  useEffect(() => {
    satelliteReflectorLayer.removeAll();
    if (isNumber(orbitalPosition) && currentView) {
      if (updateHandle) {
        updateHandle.remove();
      }

      if (!majorAxisB) {
        return;
      }

      const polygon = createMoveHandleGeom(centerLat, centerLng);

      const geometry = webMercatorUtils.geographicToWebMercator(polygon);
      const handleGraphic = new Graphic({
        geometry,
        symbol: getMoveHandleSelectorSymbol()
      });
      satelliteReflectorLayer.add(handleGraphic);

      const handleModification = (e: __esri.SketchViewModelUpdateEvent) => {
        const toolType = e.toolEventInfo?.type;
        if (toolType === 'move-stop' && e.graphics.length === 1) {
          const graphic = e.graphics[0];
          if (graphic.geometry instanceof Polygon) {
            const centroid = graphic.geometry.centroid;
            setCenter(centroid);
          }
        } else if (e.state === 'complete') {
          sketchViewModel?.update(handleGraphic);
        }
      };
      sketchViewModel = new SketchViewModel({
        layer: satelliteReflectorLayer,
        view: currentView,
        polygonSymbol: getSelectedSelectorSymbol(),
        updateOnGraphicClick: false,
        defaultUpdateOptions: {
          tool: 'move',
          multipleSelectionEnabled: false
          // toggleToolOnClick: false
        }
      });
      sketchViewModel.update(handleGraphic);
      updateHandle = sketchViewModel.on('update', handleModification);
    }
  }, [orbitalPosition, centerLat, centerLng, majorAxisB]);
};

export default useSatelliteReflectorLayer;
