import numeral from 'numeral';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import './beam-popup.scss';
import {
  beamBandDef,
  beamCIDef,
  beamEirpDef,
  beamEirpDensityDef,
  beamFreqPlanOccupancyRatioDef,
  beamGTDef,
  IFeatureBeam
} from './databaseBeam.model';

interface IBeamPopupProps {
  beam: IFeatureBeam;
}

const BeamPopup = (props: IBeamPopupProps) => {
  const { t } = useTranslation('translation');
  const { beam } = props;

  return (
    <div className="text-primary beam-popup">
      <div className="mb-3">
        <h5>Geometry</h5>
        <Row noGutters className="mb-1">
          <Col>Long: {formatValue(beam.lg, <sup>&deg;</sup>)}</Col>
          <Col>Latitude: {formatValue(beam.lt, <sup>&deg;</sup>)}</Col>
        </Row>
        <div>Size: {formatValue(beam.s, <sup>&deg;</sup>)}</div>
      </div>

      <div className="mb-3">
        <h5>{t('beam.system')}</h5>
        <div className="field">
          <div className="label">{t('beam.targetedCapacity')}</div>
          <div className="value">{formatValue(beam.sysc, ' Mbps')}</div>
        </div>
        <div className="field">
          <div className="label">{t('beam.ratio')}</div>
          <div className="value">{formatValue(beam.sysr)}</div>
        </div>
      </div>
      <div className="mb-0">
        <div className="mb-3">
          <h6>{t('beam.payload') + ' ' + t('beam.forward')}</h6>
          <div className="field">
            <div className="label">{t('beam.bandForward')}</div>
            <div className="value">{formatValue(beam.txb, beamBandDef.unit)}</div>
          </div>
          <div className="field">
            <div className="label">{t('beam.eirp')}</div>
            <div className="value">{formatValue(beam.txe, beamEirpDef.unit)}</div>
          </div>
          <div className="field">
            <div className="label">{t('beam.ci')}</div>
            <div className="value">{formatValue(beam.txc, beamCIDef.unit)}</div>
          </div>
          <div className="field">
            <div className="label">{t('beam.eirpDensity')}</div>
            <div className="value">{formatValue(beam.txd, beamEirpDensityDef.unit)}</div>
          </div>
          <div className="field">
            <div className="label">{t('beam.freqPlanOccRatio')}</div>
            <div className="value">{formatValue(beam.txr, beamFreqPlanOccupancyRatioDef.unit)}</div>
          </div>
        </div>
        <div className="mb-0">
          <h6>{t('beam.payload') + ' ' + t('beam.return')}</h6>
          <div className="field">
            <div className="label">{t('beam.bandReturn')}</div>
            <div className="value">{formatValue(beam.rxb, beamBandDef.unit)}</div>
          </div>
          <div className="field">
            <div className="label">{t('beam.gt')}</div>
            <div className="value">{formatValue(beam.rxg, beamGTDef.unit)}</div>
          </div>
          <div className="field">
            <div className="label">{t('beam.ci')}</div>
            <div className="value">{formatValue(beam.rxc, beamCIDef.unit)}</div>
          </div>
          <div className="field">
            <div className="label">{t('beam.freqPlanOccRatio')}</div>
            <div className="value">{formatValue(beam.rxr, beamFreqPlanOccupancyRatioDef.unit)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getBeamPopupContent = (beam: IFeatureBeam) => ReactDOMServer.renderToString(<BeamPopup beam={beam} />);

export const formatValue = (value: number | null, unit?: any) => {
  if (value) {
    return (
      <>
        <span>{numeral(value).format('0,0.[0000]')}</span>
        {unit && <span>&nbsp;{unit}</span>}
      </>
    );
  }
  return '-';
};
