import classnames from 'classnames';
import { SyntheticEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SkyTButton from '../buttons/skyTButton';

interface IConfirmDeleteDialogProps {
  show: boolean;
  className?: string;
  dialogTitle: string | React.ReactNode;
  dialogContent: string | React.ReactNode;
  dialogCancel?: string | React.ReactNode;
  dialogConfirm?: string | React.ReactNode;
  disabled?: boolean;
  onClose: (confirm: boolean) => void;
}

const ConfirmDialog = (props: IConfirmDeleteDialogProps) => {
  const { t } = useTranslation('translation');

  const { show, className, disabled = false, dialogTitle, dialogContent, dialogCancel = t('cancel'), dialogConfirm = t('confirm') } = props;

  const confirmDelete = (e?: SyntheticEvent) => {
    e?.stopPropagation();
    props.onClose(true);
  };

  const handleCancel = (e?: SyntheticEvent) => {
    e?.stopPropagation();
    props.onClose(false);
  };

  return (
    <Modal show={show} onHide={handleCancel} className={classnames('skyt-modal', className)}>
      <Modal.Header className="justify-content-center">{dialogTitle}</Modal.Header>
      <Modal.Body>{dialogContent}</Modal.Body>
      <Modal.Footer>
        <SkyTButton onClick={handleCancel} disabled={disabled}>
          {dialogCancel}
        </SkyTButton>
        <SkyTButton onClick={confirmDelete} disabled={disabled}>
          {dialogConfirm}
        </SkyTButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
