import classnames from 'classnames';
import { IRootState } from 'config/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';

interface ISelectReflectorZoomFactorProps {
  onChange: (zooming: number) => void;
}

const SelectReflectorZoomFactor = (props: ISelectReflectorZoomFactorProps) => {
  const { t } = useTranslation('translation');

  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);

  const zoomings = useMemo(() => {
    if (referential?.satelliteZooming) {
      const currentBand = underEditionSatellite?.reflector?.band;
      if (!currentBand) {
        return [] as number[];
      }

      const result = referential.satelliteZooming.reduce((agg: number[], current) => {
        if (!agg.includes(current.zooming) && current.band === currentBand) {
          agg.push(current.zooming);
        }
        return agg;
      }, []);

      result.sort((z1, z2) => z1 - z2);

      return result;
    }
    return [];
  }, [referential, underEditionSatellite]);

  const onClick = (zooming: number) => () => {
    props.onChange(zooming);
  };

  return (
    <div>
      <div className="font-weight-bold">{t('satellite.reflectors.zoomingFactor')}</div>
      <div className="d-flex justify-content-around mt-2">
        {zoomings.map(zooming => (
          <SkyTMapButton
            variant="outline-info"
            size="sm"
            className={classnames('reflector-select-config', { selected: underEditionSatellite?.reflector?.zooming === zooming })}
            key={`zooming_${zooming}`}
            onClick={onClick(zooming)}
          >
            {zooming}
          </SkyTMapButton>
        ))}
        {zoomings.length === 0 && <div>{t('satellite.reflectors.selectFrequency')}</div>}
      </div>
    </div>
  );
};

export default SelectReflectorZoomFactor;
