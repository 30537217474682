import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import React, { useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  areaOfInteretsRoute,
  chartResultRoute,
  configureSatelliteGatewaysRoute,
  configureSatellitePositionRoute,
  configureSatelliteReflectorRoute,
  configureSatelliteTypeRoute,
  editSatelliteRoute,
  gatewaysRoute,
  missionRoute,
  missionsRoute,
  poisRoute,
  projectPageRoute,
  resultsRoute,
  satellitesRoute
} from 'shared/routes/routes';
import './breadcrumb.scss';

interface IItemDef {
  key: React.Key;
  content: any;
  active: boolean;
  to?: any;
}

const SkyTBreadcrumb = () => {
  const { t } = useTranslation('translation');
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const selectedProject = useSelector(({ project }: IRootState) => project.project);

  const isConfigureSatellitePath = useRouteMatch([
    editSatelliteRoute.path,
    configureSatelliteTypeRoute.path,
    configureSatellitePositionRoute.path,
    configureSatelliteGatewaysRoute.path,
    configureSatelliteReflectorRoute.path
  ])?.isExact;
  const isMissionsRoutePath = useRouteMatch([missionsRoute.path, missionRoute.path])?.isExact;
  const isAreaOfInterestPath = useRouteMatch([areaOfInteretsRoute.path])?.isExact;
  const isPoiRoute = useRouteMatch([poisRoute.path])?.isExact;
  const isGatewayRoute = useRouteMatch([gatewaysRoute.path])?.isExact;
  const isSatellitesPath = useRouteMatch([satellitesRoute.path])?.isExact;
  const isResultsPath = useRouteMatch([resultsRoute.path, chartResultRoute.path]);

  const items = useMemo(() => {
    const home: IItemDef = {
      key: 'Home',
      content: (
        <div>
          <FontAwesomeIcon icon="map-marker-alt" className="mr-1" />
          <span>{t('translation:projects')}</span>
        </div>
      ),
      active: false,
      to: '/'
    };

    const result: IItemDef[] = [];
    result.push(home);

    if (selectedProject) {
      const projectSection: IItemDef = {
        key: `project_${selectedProject.id}`,
        content: selectedProject.name,
        active: false,
        to: projectPageRoute.getPath(selectedProject.id)
      };
      result.push(projectSection);

      if (isConfigureSatellitePath) {
        const item: IItemDef = {
          key: `project_${selectedProject.id}_configureSat`,
          content: t('satellite.satellites'),
          active: true
        };
        result.push(item);
      } else if (isMissionsRoutePath) {
        const item: IItemDef = {
          key: `project_${selectedProject.id}_mission`,
          content: t('mission.missions'),
          active: true
        };
        result.push(item);
      } else if (isAreaOfInterestPath) {
        const item: IItemDef = {
          key: `project_${selectedProject.id}_areaOfInterest`,
          content: t('areaOfInteret.areaOfInterets'),
          active: true
        };
        result.push(item);
      } else if (isPoiRoute) {
        const item: IItemDef = {
          key: `project_${selectedProject.id}_poi`,
          content: t('pois.poi'),
          active: true
        };
        result.push(item);
      } else if (isGatewayRoute) {
        const item: IItemDef = {
          key: `project_${selectedProject.id}_gateway`,
          content: t('gateways.gateways'),
          active: true
        };
        result.push(item);
      } else if (isSatellitesPath) {
        const item: IItemDef = {
          key: `project_${selectedProject.id}_satellites`,
          content: t('satellite.satellites'),
          active: true
        };
        result.push(item);
      } else if (isResultsPath) {
        const item: IItemDef = {
          key: `project_${selectedProject.id}_results`,
          content: t('results.title'),
          active: true,
          to: resultsRoute.getPath(selectedProject.id)
        };
        result.push(item);
      } else {
        projectSection.active = true;
      }
    }
    return result;
  }, [
    selectedProject,
    t,
    isConfigureSatellitePath,
    isMissionsRoutePath,
    isAreaOfInterestPath,
    isPoiRoute,
    isGatewayRoute,
    isSatellitesPath,
    isResultsPath
  ]);

  return (
    <Breadcrumb className={classnames('skyt-breadcrumb', { map: mapIsDisplayed })}>
      {items.map(item => (
        <BreadcrumbItem
          key={item.key}
          as={item.to ? Link : undefined}
          linkAs="span"
          to={item.to}
          active={item.active}
          className={classnames({ 'text-info': !item.active })}
        >
          {item.content}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default SkyTBreadcrumb;
