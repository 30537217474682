import classnames from 'classnames';
import { IRootState } from 'config/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Coverage, CoverageType } from 'shared/model/coverage.model';
import { ProjectDetails } from 'shared/model/project.model';
import { saveCoverage, setCoverage } from 'shared/reducers/coverageSlice';
import './coverageTypeSelector.scss';

const availableTypes = [CoverageType.MULTI_BEAM, CoverageType.STEERABLE_BEAM, CoverageType.REGIONAL];

const CoverageTypeSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const satelliteModels = useSelector(({ referential }: IRootState) => referential.satelliteModels);

  const { projectId, satelliteId, configurationId } = useParams<{ projectId: string; satelliteId: string; configurationId: string }>();

  const satellite = project?.satellites.find(sat => sat.id === Number(satelliteId));

  const satelliteModel = satelliteModels.find(model => model.id === satellite?.modelId);

  const filetredTypes = availableTypes.filter(available => satelliteModel?.supportedCoverages.some(supported => supported === available));

  const onSelect = (type: CoverageType) => () => {
    const newCoverage: Partial<Coverage> = {
      ...coverage,
      coverageType: type
    };
    dispatch(setCoverage(newCoverage));
    dispatch(saveCoverage(projectId, satelliteId, configurationId, newCoverage));
  };

  return (
    <div className="coverage-type-selector">
      {filetredTypes.map(type => (
        <div
          key={`coverageType_${type}`}
          className={classnames('coverage-type-item', { selected: coverage?.coverageType === type })}
          onClick={onSelect(type)}
        >
          {t(`coverage.types.${type}`)}
        </div>
      ))}
    </div>
  );
};

export default CoverageTypeSelector;
