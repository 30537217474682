import { IRootState } from 'config/store';
import meanBy from 'lodash/meanBy';
import { skyTScichartTheme } from 'modules/results/commons/scichart.def';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RolloverModifier } from 'scichart/Charting/ChartModifiers/RolloverModifier';
import { ZoomPanModifier } from 'scichart/Charting/ChartModifiers/ZoomPanModifier';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { TextLabelProvider } from 'scichart/Charting/Visuals/Axis/LabelProvider/TextLabelProvider';
import { NumericAxis } from 'scichart/Charting/Visuals/Axis/NumericAxis';
import { FastColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastColumnRenderableSeries';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import { Thickness } from 'scichart/Core/Thickness';
import { EAutoRange } from 'scichart/types/AutoRange';
import { EAxisAlignment } from 'scichart/types/AxisAlignment';
import { EXyDirection } from 'scichart/types/XyDirection';
import { usePrevious } from 'shared/utils/hook-utils';

const id = 'result-bar-chart';

export const seriesColors = ['#53B8D9', '#1B1866', '#51A293', '#F5A030', '#B42573'];

const ResultBarChart = () => {
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);
  const partialMission = useSelector(({ exporter }: IRootState) => exporter.mission);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);

  const [chartSurface, setChartSurface] = useState<SciChartSurface>();
  const previousModel = usePrevious(currentChart);
  const previousMission = usePrevious(partialMission);

  useEffect(() => {
    if (currentChart?.type !== 'BAR_CHART' || currentChart?.series.length === 0 || !partialMission) {
      return;
    }

    const initChart = async (missionLetter: string) => {
      const labels = currentChart.series[0].values.map(value => value.label);

      const { sciChartSurface, wasmContext } = await SciChartSurface.create(id);

      const meanLabelLength = meanBy(labels, label => label.length);

      const xAxis = new NumericAxis(wasmContext, {
        autoRange: meanLabelLength > 15 ? EAutoRange.Never : EAutoRange.Once,
        labelProvider: new TextLabelProvider({
          labels,
          maxLength: 12
        }),
        labelStyle: {
          padding: new Thickness(10, 1, 2, 1)
        }
      });

      sciChartSurface.xAxes.add(xAxis);

      const units = currentChart.series.map(serie => serie.unit).join(',');
      const axisTitle = units.length > 0 ? `${currentChart.yAxisLabel} (${units})` : currentChart.yAxisLabel;

      const yAxis = new NumericAxis(wasmContext, {
        autoRange: EAutoRange.Once,
        axisAlignment: EAxisAlignment.Left,
        axisTitle,
        axisTitleStyle: {
          fontSize: 14
        }
      });
      sciChartSurface.yAxes.add(yAxis);

      currentChart.series.forEach((serie, i) => {
        const dataSeries = new XyDataSeries(wasmContext);
        serie.values.forEach((value, index) => {
          if (value.label.startsWith(missionLetter)) {
            dataSeries.append(index, value.value);
          }
        });

        const columnSeries = new FastColumnRenderableSeries(wasmContext, {
          strokeThickness: 0,
          dataPointWidth: 0.4,
          opacity: 0.7,
          fill: seriesColors[i],
          stroke: seriesColors[i],
          dataSeries
        });
        columnSeries.rolloverModifierProps.tooltipLabelX = serie.tooltipLabelX ? serie.tooltipLabelX : '';
        columnSeries.rolloverModifierProps.tooltipLabelY = serie.name;

        /*columnSeries.rolloverModifierProps.tooltipTemplate = (
          id: string,
          seriesInfo: SeriesInfo,
          rolloverTooltip: RolloverTooltipSvgAnnotation
        ) => {
          return getSkyTCharToolTip(seriesInfo, rolloverTooltip);
        };*/
        sciChartSurface.renderableSeries.add(columnSeries);
      });

      sciChartSurface.chartModifiers.add(
        new ZoomPanModifier({
          xyDirection: EXyDirection.XDirection
        })
      );
      sciChartSurface.chartModifiers.add(
        new RolloverModifier({
          showRolloverLine: false
        })
      );

      sciChartSurface.applyTheme(skyTScichartTheme);
      setChartSurface(sciChartSurface);
    };

    if (currentChart !== previousModel || partialMission !== previousMission) {
      const mission = missions.find(mission => mission.id === partialMission.id);
      const missionLetter = mission ? mission.missionLetter : '';
      initChart(missionLetter);
    }

    return () => {
      chartSurface?.delete();
    };
  }, [chartSurface, currentChart, previousModel, missions, partialMission, previousMission]);

  return (
    <div className="skyT-chart-wrapper">
      <div id={id}></div>
    </div>
  );
};

export default ResultBarChart;
