import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { hexToRgb } from 'shared/utils/color-utils';

export const polygonColor = hexToRgb('#FFFFFF');

export const underEditionPolygonSymbol = new SimpleFillSymbol({
  color: [...polygonColor, 0.3],
  outline: {
    color: polygonColor,
    width: 3
  }
});
