import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdatingBeamsSelection } from 'shared/reducers/areaSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';

const SelectAllBeamsButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const area = useSelector(({ area }: IRootState) => area.area);
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);

  const areaBeamsLength = area?.beamList ? area.beamList.length : 0;

  const allSelected = underEditionBeams.every(item => item.selected === true);
  const allUnselected = underEditionBeams.every(item => item.selected === false);

  const onSelectAll = () => {
    const newUnderEdition = underEditionBeams.map(item => ({ ...item, selected: true }));
    dispatch(setUpdatingBeamsSelection(newUnderEdition));
  };

  const onUnselectAll = () => {
    const newUnderEdition = underEditionBeams.map(item => ({ ...item, selected: false }));
    dispatch(setUpdatingBeamsSelection(newUnderEdition));
  };

  const selected = underEditionBeams.filter(item => item.selected).length;

  return (
    <div className="text-center">
      <div className="my-2 font-weight-bold">{t('area.beamSelected', { selected, all: areaBeamsLength })}</div>
      <div className="d-flex justify-content-around align-items-center">
        <SkyTButton variant="light" className="text-info" onClick={onSelectAll} disabled={allSelected}>
          {t('area.selectAll')}
        </SkyTButton>
        <SkyTButton variant="light" className="text-info" onClick={onUnselectAll} disabled={allUnselected}>
          {t('area.unselectAll')}
        </SkyTButton>
      </div>
    </div>
  );
};

export default SelectAllBeamsButton;
