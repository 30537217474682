import { useHistory } from 'react-router-dom';
import './chartCard.scss';

interface IChartCardProps {
  title: string;
  icon: any;
  to?: string;
  onClick?: () => void;
}

const ChartCard = (props: IChartCardProps) => {
  const history = useHistory();
  const { title, icon, to } = props;

  const onClick = () => {
    if (to) {
      history.push(to);
    } else if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className="chart-card hover-white" onClick={onClick}>
      <div className="chart-card-title" title={title}>
        {title}
      </div>
      <div className="chart-card-icon">{icon}</div>
    </div>
  );
};

export default ChartCard;
