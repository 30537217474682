import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import { IRootState } from 'config/store';
import { getGraphicsFromAreaOfInterest } from 'modules/map/layers/areaOfInterets/areaOfInterestLayer-def';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { AreaOfInterest } from 'shared/model/areaOfInterest.model';
import { saveAreaOfInterest, setTypeOfArea, setUnderEditionFeatures } from 'shared/reducers/areaOfInterestSlice';
import { showMap } from 'shared/reducers/mapSlice';
import { areaOfInteretsRoute } from 'shared/routes/routes';
import { hexToRgb } from 'shared/utils/color-utils';
import { usePrevious } from 'shared/utils/hook-utils';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import './configure-areaOfInterests.scss';
import DrawAreaOfInterestToolbar from './drawToolbar/DrawAreaOfInterestToolbar';

const ConfigureAreaOfInterests = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const project = useSelector(({ project }: IRootState) => project.project);

  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const updating = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.updating);
  const updateSuccess = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.updateSuccess);
  const type = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.typeOfArea);
  const underEditionFeatures = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.underEditionFeatures);

  const previousUpdateSuccess = usePrevious(updateSuccess);

  const { projectId, missionId, areaId } = useParams<{ projectId: string; missionId: string; areaId: string }>();

  const [name, setName] = useState('');

  const isNew = areaId === 'new';

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
    return () => {
      dispatch(setTypeOfArea(null));
      dispatch(setUnderEditionFeatures([]));
    };
  }, [dispatch, mapIsDisplayed]);

  useEffect(() => {
    if (!isNew) {
      const mission = missions.find(missionItem => missionItem.areaOfInterests.find(item => item.id === Number(areaId)));
      if (mission) {
        const areaOfInterest = mission.areaOfInterests.find(item => item.id === Number(areaId));
        if (areaOfInterest) {
          setName(areaOfInterest.name);
          dispatch(setTypeOfArea(areaOfInterest.type));

          const color = hexToRgb(mission.color);
          const features = getGraphicsFromAreaOfInterest(areaOfInterest, color, true);
          dispatch(setUnderEditionFeatures(features));
        }
      }
    }
  }, [areaId, missions, dispatch, isNew]);

  useEffect(() => {
    if (updateSuccess && previousUpdateSuccess === false) {
      dispatch(setTypeOfArea(null));
      // dispatch(fetchMissions(projectId));
      history.push(areaOfInteretsRoute.getPath(projectId, missionId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousUpdateSuccess, updateSuccess, dispatch, projectId, missionId]);

  if (!project) {
    return null;
  }

  const hasWriteAccess = projectHasWriteAccess(project);
  if (!hasWriteAccess) {
    history.push('/');
  }

  const onCancel = () => {
    history.push(areaOfInteretsRoute.getPath(projectId, missionId));
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const canSave = name.length > 0 && underEditionFeatures.length > 0 && !updating;

  const onSave = () => {
    if (type && underEditionFeatures.length > 0) {
      const geometries = underEditionFeatures.map(item => item.geometry);
      const union = geometryEngine.union(geometries);
      let toSave = union;
      if (union.spatialReference.isWebMercator) {
        toSave = webMercatorUtils.webMercatorToGeographic(union);
      }
      const json = toSave.toJSON();

      const latLngs = JSON.stringify(json);

      const toSend: Partial<AreaOfInterest> = {
        name,
        type,
        latLngs
      };
      if (!isNew) {
        toSend.id = Number(areaId);
      }
      dispatch(saveAreaOfInterest(projectId, missionId, toSend));
    }
  };

  return (
    <div className="configure-areaOfInterets">
      <div className="configure-areaOfInterets-title">{t('areaOfInteret.AddAreaOfInterets')}</div>
      <div className="configure-areaOfInterets-content">
        <DrawAreaOfInterestToolbar />
        <Form.Group className="mt-2 mb-1">
          <Form.Label>{t('areaOfInteret.labelName')}</Form.Label>
          <SkyTTextInput onChange={onNameChange} value={name}></SkyTTextInput>
        </Form.Group>
      </div>
      <hr />
      <div className="d-flex justify-content-around">
        <SkyTMapButton variant="outline-info" type="button" onClick={onCancel}>
          {t('cancel')}
        </SkyTMapButton>
        <SkyTMapButton variant="outline-info" type="button" onClick={onSave} disabled={!canSave}>
          {t('save')}
        </SkyTMapButton>
      </div>
    </div>
  );
};

export default ConfigureAreaOfInterests;
