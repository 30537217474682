import classnames from 'classnames';
import { IRootState } from 'config/store';
import Map from 'modules/map/map';
import { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router';
import MapMenu from 'shared/layout/mapmenu/mapMenu';
import { reset as resetConfiguration } from 'shared/reducers/configurationSlice';
import { hideMap } from 'shared/reducers/mapSlice';
import { reset as resetMission, selectMissions } from 'shared/reducers/missionSlice';
import { fetchProject } from 'shared/reducers/projectSlice';
import { missionRoute, missionsRoute } from 'shared/routes/routes';
import { isNumeric } from 'shared/utils/math-utils';
import SkyTBreadcrumb from 'shared/widgets/breadcrumb';
import Loader from 'shared/widgets/loader';
import FullVersion from './fullVersion/fullVersion';
import MissionForm from './missions/form/missionForm';
import './needs.scss';

const scrollBarStyle = {
  height: 'calc(100vh - 115px)'
};

const NeedsPage = () => {
  const dispatch = useDispatch();
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const showMenuPanel = useSelector(({ map }: IRootState) => map.showMenuPanel);
  const project = useSelector(({ project }: IRootState) => project.project);
  const loadingProject = useSelector(({ project }: IRootState) => project.loading);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const loadingMissions = useSelector(({ mission }: IRootState) => mission.loading);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);

  const { projectId, missionId } = useParams<{ projectId: string; missionId?: string }>();

  const isMissionsRoute = useRouteMatch([missionsRoute.path, missionRoute.path])?.isExact;

  const currentMission = selectedMissions.length !== 1 ? null : selectedMissions[0];

  useEffect(() => {
    return () => {
      dispatch(resetMission());
      dispatch(resetConfiguration());
    };
  }, [dispatch]);

  useEffect(() => {
    if (project?.id !== Number(projectId)) {
      dispatch(fetchProject(projectId));
    }
  }, [projectId, project, dispatch]);

  useEffect(() => {
    if (!currentMission && missionId && isNumeric(missionId)) {
      const mission = missions.find(item => item.id === Number(missionId));
      const selection = mission ? [mission] : [];
      dispatch(selectMissions(selection));
    }
  }, [currentMission, dispatch, missionId, projectId, loadingMissions, missions]);

  useEffect(() => {
    return () => {
      if (mapIsDisplayed) {
        dispatch(hideMap());
      }
    };
  }, [mapIsDisplayed, dispatch]);

  const style: React.CSSProperties = mapIsDisplayed ? { ...scrollBarStyle, pointerEvents: 'none' } : scrollBarStyle;

  return (
    <div className="needs">
      <div className={classnames('needs-menu', { 'map-is-displayed': mapIsDisplayed, 'hide-content': !showMenuPanel })}>
        {loadingProject ? (
          <div className="vcenter-loader">
            <Loader />
          </div>
        ) : (
          <>
            <SkyTBreadcrumb />
            <div className="needs-menu-content">
              <Scrollbars autoHide autoHeight autoHeightMax={495} className="skyt-scroll-bar pb-2">
                <FullVersion />
              </Scrollbars>
            </div>
          </>
        )}
      </div>
      {mapIsDisplayed && (
        <>
          <Map />
          <MapMenu />
        </>
      )}
      <Scrollbars autoHide style={style} className="needs-content skyt-scroll-bar">
        {isMissionsRoute && <MissionForm />}
      </Scrollbars>
    </div>
  );
};

export default NeedsPage;
