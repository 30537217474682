import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import {
  BEAM_OVERLAP_FACTOR,
  BEAM_SIZE_GLOBAL_AREA,
  MAX_DEVIATION_METER,
  MAX_LONGITUDE,
  RADIUS_EARTH_EQUATOR,
  RADIUS_EARTH_POLE,
  RADIUS_GEO,
  SATELITTE_HEIGHT
} from 'config/beam-contants';
import flattenDeep from 'lodash/flattenDeep';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import partition from 'lodash/partition';
import { IDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { hexToRgb } from './color-utils';
import { deg2rad, rad2deg } from './math-utils';

export const createBeamSymbol = (colorStr: string, opacity = 0.6, lineOnly = false) => {
  const color = hexToRgb(colorStr);
  return lineOnly
    ? new SimpleLineSymbol({
        color,
        width: 1.25
      })
    : new SimpleFillSymbol({
        color: [...color, opacity],
        outline: {
          color,
          width: 1.25
        }
      });
};

export interface ABPoint {
  a: number;
  b: number;
}

export interface ABEnveloppe {
  minA: number;
  maxA: number;
  minB: number;
  maxB: number;
}

export const makeGlobalAreaBeam = (satelliteLon: number): Polygon => {
  const center = toABPoint(0, satelliteLon, satelliteLon);
  return makeBeam(center, BEAM_SIZE_GLOBAL_AREA, satelliteLon);
};

export const makeBeamFromDatabase = (beam: IDatabaseBeam, satelliteLon: number): Polygon => {
  const center = toABPoint(beam.lt, beam.lg, satelliteLon);
  return makeBeam(center, beam.s, satelliteLon);
};

export const makeBeam = (center: ABPoint, beamSize: number, satelliteLng: number): Polygon => {
  const points = [];

  const distance = beamSize / 2;
  for (let i = 0; i < 360; i++) {
    const moved = movePoint(center, distance, i);
    const lonLat = toLonLat(moved, satelliteLng);
    points.push(lonLat);
  }

  const initialGeometry = new Polygon({
    rings: [points],
    spatialReference: {
      wkid: 4326
    }
  });

  const minLon = points.reduce((min, current) => {
    return current[0] < min[0] ? current : min;
  })[0];
  const maxLon = points.reduce((max, current) => {
    return current[0] > max[0] ? current : max;
  })[0];

  if (Math.abs(minLon - maxLon) < MAX_LONGITUDE) {
    // Beam isn't going through the -180 or 180° longitude axis, nothing to do
    return initialGeometry;
  }

  const twoPoints = partition(points, point => {
    return point[0] < MAX_LONGITUDE && point[0] > 0;
  });

  return new Polygon({
    rings: twoPoints,
    spatialReference: {
      wkid: 4326
    }
  });
};

const movePoint = (point: ABPoint, distance: number, heading: number): ABPoint => {
  const angle = deg2rad(heading);

  const a = point.a + distance * Math.cos(angle);
  const b = point.b + distance * Math.sin(angle);
  return {
    a,
    b
  };
};

export const rotate = (point: ABPoint, angle: number): ABPoint => {
  const sinAngle = Math.sin(deg2rad(angle));
  const cosAngle = Math.cos(deg2rad(angle));

  const a = point.a * cosAngle - point.b * sinAngle;
  const b = point.a * sinAngle + point.b * cosAngle;
  return { a, b };
};

export const offset = (point: ABPoint, a: number, b: number): ABPoint => {
  return {
    a: point.a + a,
    b: point.b + b
  };
};

export const toLonLat = (point: ABPoint, satelliteLngDeg: number): number[] => {
  const satelliteLngRad = deg2rad(satelliteLngDeg);

  const a = point.a;
  const b = point.b;

  const phi = rad2deg(Math.acos(Math.cos(deg2rad(a)) * Math.cos(deg2rad(b))));
  const phiL = Math.min(phi, 8.7);
  const phiLRad = deg2rad(phiL);

  const theta = rad2deg(Math.asin((1 + SATELITTE_HEIGHT / RADIUS_EARTH_EQUATOR) * Math.sin(phiLRad))) - phiL;

  const AmRad = deg2rad((phiL * a) / phi);
  const BmRad = deg2rad((phiL * b) / phi);

  const d = (RADIUS_EARTH_EQUATOR * Math.sin(deg2rad(theta))) / Math.sin(phiLRad);
  const u = d * Math.cos(BmRad) * Math.sin(AmRad);
  const v = d * Math.sin(BmRad);
  const w = -d * Math.cos(BmRad) * Math.cos(AmRad);

  let x =
    -Math.sin(satelliteLngRad) * u + Math.cos(satelliteLngRad) * w + (RADIUS_EARTH_EQUATOR + SATELITTE_HEIGHT) * Math.cos(satelliteLngRad);
  let y =
    Math.cos(satelliteLngRad) * u + Math.sin(satelliteLngRad) * w + (RADIUS_EARTH_EQUATOR + SATELITTE_HEIGHT) * Math.sin(satelliteLngRad);
  let z = v;

  const lat = rad2deg(Math.asin(z / RADIUS_EARTH_EQUATOR));
  const lng = rad2deg(Math.atan2(y, x));

  return [lng, lat];
};

export const toABPoint = (lat: number, lon: number, satelliteLngDeg: number): ABPoint => {
  const latitudeRad = deg2rad(lat);
  const cosLatitude = Math.cos(latitudeRad);
  const sinLatitude = Math.sin(latitudeRad);

  const deltaLngRad = deg2rad(lon - satelliteLngDeg);
  const radiusForPoint =
    (RADIUS_EARTH_EQUATOR * RADIUS_EARTH_POLE) / Math.hypot(RADIUS_EARTH_POLE * cosLatitude, RADIUS_EARTH_EQUATOR * sinLatitude);

  const x = radiusForPoint * cosLatitude * Math.sin(deltaLngRad);
  const y = RADIUS_GEO - radiusForPoint * cosLatitude * Math.cos(deltaLngRad);
  const z = radiusForPoint * sinLatitude;

  const a = rad2deg(Math.asin(x / Math.hypot(x, y)));
  const b = rad2deg(Math.asin(z / Math.hypot(y, z)));
  return {
    a,
    b
  };
};

export const extentAB = (geometry: __esri.Geometry, satelliteLng: number, angle: number): ABEnveloppe | null => {
  const simplified = geometryEngine.generalize(geometry, MAX_DEVIATION_METER, true);
  if (simplified instanceof Polygon) {
    const allPoints = simplified.rings.map(ring => ring.map(point => rotate(toABPoint(point[1], point[0], satelliteLng), angle)));

    const all = flattenDeep(allPoints);

    const minA = minBy(all, 'a')?.a;
    const maxA = maxBy(all, 'a')?.a;
    const minB = minBy(all, 'b')?.b;
    const maxB = maxBy(all, 'b')?.b;

    if (minA && maxA && minB && maxB) {
      const envelop: ABEnveloppe = {
        minA,
        maxA,
        minB,
        maxB
      };
      return envelop;
    }
  }
  return null;
};

export const computeBeamCenter = (extent: ABEnveloppe, beamSize: number) => {
  let shiftA = 0.0;

  const fromB = extent.maxB + beamSize;
  const toB = extent.minB - beamSize;
  const byB = beamSize * BEAM_OVERLAP_FACTOR * BEAM_OVERLAP_FACTOR;

  const beamCenters: ABPoint[] = [];
  for (let b = fromB; b > toB; b -= byB) {
    const fromA = extent.minA - beamSize;
    const toA = extent.maxA + beamSize;
    const byA = beamSize * BEAM_OVERLAP_FACTOR;
    for (let a = fromA; a < toA; a += byA) {
      const aPoint = a + shiftA;
      const point: ABPoint = { a: aPoint, b };
      beamCenters.push(point);
    }
    shiftA = shiftA > 0 ? 0 : 0.5 * beamSize * BEAM_OVERLAP_FACTOR;
  }
  return beamCenters;
};

export const computeBeamAtLonLat = (lat: number, lon: number, orbitalPosition: number, beamSize: number) => {
  const center = toABPoint(lat, lon, orbitalPosition);
  return makeBeam(center, beamSize, orbitalPosition);
};

export const toLatLng = (point: ABPoint, satelliteLngDeg: number) => {
  const satelliteLngRad = deg2rad(satelliteLngDeg);
  const a = point.a;
  const b = point.b;

  const radA = deg2rad(a);
  const radB = deg2rad(b);

  const phi = rad2deg(Math.acos(Math.cos(radA) * Math.cos(radB)));
  const phiL = phi <= 8.7 ? phi : 8.7;
  const phiLRad = deg2rad(phiL);

  const thetARad = Math.asin((1 + SATELITTE_HEIGHT / RADIUS_EARTH_EQUATOR) * Math.sin(phiLRad));
  const thetA = rad2deg(thetARad) - phiL;

  const AmRad = deg2rad((phiL * a) / phi);
  const BmRad = deg2rad((phiL * b) / phi);

  const d = (RADIUS_EARTH_EQUATOR * Math.sin(deg2rad(thetA))) / Math.sin(phiLRad);
  const u = d * Math.cos(BmRad) * Math.sin(AmRad);
  const v = d * Math.sin(BmRad);
  const w = -d * Math.cos(BmRad) * Math.cos(AmRad);

  const x =
    -Math.sin(satelliteLngRad) * u + Math.cos(satelliteLngRad) * w + (RADIUS_EARTH_EQUATOR + SATELITTE_HEIGHT) * Math.cos(satelliteLngRad);
  const y =
    Math.cos(satelliteLngRad) * u + Math.sin(satelliteLngRad) * w + (RADIUS_EARTH_EQUATOR + SATELITTE_HEIGHT) * Math.sin(satelliteLngRad);
  let z = v;

  return {
    lat: rad2deg(Math.asin(z / RADIUS_EARTH_EQUATOR)),
    lng: rad2deg(Math.atan2(y, x))
  };
};
