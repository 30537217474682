import { IRootState } from 'config/store';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveMission } from 'shared/reducers/missionSlice';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTSelect, { ILabelValueOption } from 'shared/widgets/form/skyTSelect';

const flexibityOptions = [
  { value: true, label: 'Flexible Payload' },
  { value: false, label: 'No flexibility', isDisabled: true }
];

const SelectMissionFlexibility = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const project = useSelector(({ project }: IRootState) => project.project);
  const hasWriteAccess = projectHasWriteAccess(project);

  if (selectedMissions.length !== 1) {
    return null;
  }
  const mission = selectedMissions[0];

  const defaultFlexibility = mission.isFlexible ? flexibityOptions[0] : flexibityOptions[1];

  const onChange = (selection: ILabelValueOption<boolean>) => {
    if (selection.value !== mission.isFlexible) {
      const toSend = {
        ...mission,
        isFlexible: selection.value
      };
      dispatch(saveMission(mission.projectId, toSend));
    }
  };

  return (
    <Form.Group className="mb-14">
      <Form.Label>{t('translation:mission.flexibility')}</Form.Label>
      <SkyTSelect options={flexibityOptions} defaultValue={defaultFlexibility} onChange={onChange} isDisabled={!hasWriteAccess} />
    </Form.Group>
  );
};
export default SelectMissionFlexibility;
