import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SkyTButton from './skyTButton';

interface IBackButtonProps {
  size?: 'lg' | 'sm';
}

const BackButton = (props: IBackButtonProps) => {
  const { t } = useTranslation('translation');
  const history = useHistory();

  const { size } = props;

  const goBack = () => {
    history.goBack();
  };

  return (
    <SkyTButton onClick={goBack} size={size}>
      <FontAwesomeIcon icon="arrow-left" className="mr-2" />
      {t('back')}
    </SkyTButton>
  );
};
export default BackButton;
