import axios, { AxiosError } from 'axios';

const axiosUtils = {
  setupInterceptors: (store: any) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          setTimeout(() => {
            // wait for the error message to be displayed and then reload current page
            window.location.reload();
          }, 1000);
        }

        return Promise.reject(error);
      }
    );
  }
};
export default axiosUtils;

export const getRequestErrorMessage = (error: AxiosError) => {
  if (error.response) {
    const data = error.response.data;
    if (data.message) {
      return data.message;
    }
  }
  return error.message;
};

export const setBearerToken = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};
