import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Project } from 'shared/model/project.model';
import { requestAccessAsTeamMember } from 'shared/reducers/requestAccessSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDialog from 'shared/widgets/dialogs/ConfirmDialog';

interface IJoinAsTeamMemberProps {
  project: Project;
}

const JoinAsTeamMember = (props: IJoinAsTeamMemberProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const { project } = props;

  const requestAccessProjectId = useSelector(({ requestAccess }: IRootState) => requestAccess.requestAccessProjectId);
  const requestAccessSuccess = useSelector(({ requestAccess }: IRootState) => requestAccess.requestAccessSuccess);

  const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false);

  const onConfirmRequestAccess = (confirm: boolean) => {
    if (confirm) {
      dispatch(requestAccessAsTeamMember(project));
    }
    setShowRequestAccessDialog(false);
  };

  const handleShow = () => {
    setShowRequestAccessDialog(true);
  };

  useEffect(() => {
    if (requestAccessSuccess && requestAccessProjectId === project.id) {
      toast.success(t('project.requestPartOfTeamMemberSuccess'), { toastId: `requestPartOfTeamMemberSuccess_${requestAccessProjectId}` });
    }
  }, [requestAccessSuccess, requestAccessProjectId, project, t]);

  return (
    <>
      <SkyTButton variant="outline-info" onClick={handleShow}>
        <span>{t('project.joinAsTeamMember')}</span>
        <FontAwesomeIcon icon="plus" className="ml-2" />
      </SkyTButton>
      <ConfirmDialog
        show={showRequestAccessDialog}
        dialogTitle={t('requestAccessDialogTitle')}
        dialogContent={t('project.confirmJoinTeamMember', { name: project.name })}
        onClose={onConfirmRequestAccess}
      />
    </>
  );
};

export default JoinAsTeamMember;
