import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import { createCoverage, deleteCoverage, getCoverageDetails, getCoverages, updateCoverage } from 'shared/api/api';
import { CoverageBeamSettings, defaultCoverageBeamSettings } from 'shared/model/area.model';
import { Coverage } from 'shared/model/coverage.model';

const initialState = {
  loading: false,
  loadingDetails: -1,
  updating: false,
  updateSuccess: false,
  computingBeams: false,
  coverages: [] as Coverage[],
  coverage: null as Partial<Coverage> | null,
  coverageBeamConfig: defaultCoverageBeamSettings,
  error: null as any
};

export type CoverageState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'coverage',
  initialState,
  reducers: {
    setCoverage: (state, action: PayloadAction<Partial<Coverage> | null>) => {
      state.coverage = action.payload;
    },
    fetchEntitiesStart: state => {
      state.loading = true;
      state.updateSuccess = false;
    },
    fetchEntityStart: (state, action: PayloadAction<any>) => {
      state.loadingDetails = action.payload;
      state.updateSuccess = false;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.loadingDetails = -1;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<Coverage[]>) => {
      state.loading = false;
      state.coverages = action.payload;
    },
    fetchEntitySuccess: (state, action: PayloadAction<Coverage>) => {
      state.loadingDetails = -1;
      state.coverage = action.payload;
    },
    updateStart: state => {
      state.loading = false;
      state.updating = true;
      state.updateSuccess = false;
    },
    updateSuccess: (state, action: PayloadAction<Coverage>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.coverage = action.payload;
      const index = state.coverages.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        const newCoverages = [...state.coverages];
        newCoverages.splice(index, 1);
        newCoverages.push(action.payload);
        state.coverages = newCoverages;
      }
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
      state.coverage = null;
    },
    setCoverageBeamConfig: (state, action: PayloadAction<CoverageBeamSettings>) => {
      state.coverageBeamConfig = action.payload;
    },
    setComputingBeams: (state, action: PayloadAction<boolean>) => {
      state.computingBeams = action.payload;
    },
    reset: state => {
      state.loading = false;
      state.loadingDetails = -1;
      state.updateSuccess = false;
      state.updating = false;
      state.computingBeams = false;
      state.coverages = [];
      state.coverageBeamConfig = defaultCoverageBeamSettings;
      state.coverage = null;
      state.error = null;
    }
  }
});

const {
  fetchEntitiesStart,
  fetchEntityStart,
  fetchEntitiesFailed,
  fetchEntitiesSuccess,
  updateStart,
  updateFailed,
  updateSuccess,
  deleteSuccess,
  fetchEntitySuccess
} = slice.actions;

export const { setCoverage, reset, setCoverageBeamConfig, setComputingBeams } = slice.actions;

export const fetchCoverages =
  (projectId: number | string, satelliteId: number | string, configurationId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Coverage[]> = await getCoverages(projectId, satelliteId, configurationId);
      dispatch(fetchEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const fetchCoverage =
  (projectId: number | string, satelliteId: number | string, configurationId: number | string, coverageId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntityStart(coverageId));
      const response = await getCoverageDetails(projectId, satelliteId, configurationId, coverageId);
      dispatch(fetchEntitySuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const saveCoverage =
  (projectId: number | string, satelliteId: number | string, configurationId: number | string, coverage: Partial<Coverage>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = coverage.id
        ? await updateCoverage(projectId, satelliteId, configurationId, coverage)
        : await createCoverage(projectId, satelliteId, configurationId, coverage);
      dispatch(updateSuccess(response.data));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeCoverage =
  (projectId: number | string, satelliteId: string | number, configurationId: string | number, coverageId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteCoverage(projectId, satelliteId, configurationId, coverageId);
      dispatch(deleteSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
