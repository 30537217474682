import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoverageType } from 'shared/model/coverage.model';
import { ProjectDetails } from 'shared/model/project.model';
import { setComputingBeams } from 'shared/reducers/coverageSlice';
import { computeBeamCenter, extentAB, makeBeam, offset, rotate } from 'shared/utils/beam-utils';
import { getGeometriesOfAreaOfInterest } from 'shared/utils/model-utils';
import { getBeamI, getBeamId, getMissionSymbol } from '../beam-layer-def';

interface IUseCreateMultiBeamProps {
  layer: GraphicsLayer;
}

const useCreateMultiBeam = (props: IUseCreateMultiBeamProps) => {
  const dispatch = useDispatch();
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const coverageBeamConfig = useSelector(({ coverage }: IRootState) => coverage.coverageBeamConfig);

  const { layer } = props;

  const satellite = project.satellites.find(sat => sat.id === configuration?.satelliteId);

  const mission = missions.find(item => item.id === coverage?.missionId);
  const coverageId = coverage?.id;

  const isMultiBeamCoverageType = coverage?.coverageType === CoverageType.MULTI_BEAM;

  useEffect(() => {
    if (satellite && mission && isMultiBeamCoverageType) {
      // const begin = measPerf();
      try {
        dispatch(setComputingBeams(true));

        layer.removeAll();

        let beams: Graphic[] = [];
        const areaGeometries = mission.areaOfInterests.reduce((agg: Polygon[], area) => {
          const polygons = getGeometriesOfAreaOfInterest(area);
          agg = agg.concat(polygons);
          return agg;
        }, []);

        if (areaGeometries.length === 0) {
          return;
        }

        const unionGeometry = areaGeometries.length > 1 ? geometryEngine.union(areaGeometries) : areaGeometries[0];

        let unionForExtent = unionGeometry;
        if (!unionForExtent.spatialReference.isWGS84) {
          unionForExtent = webMercatorUtils.webMercatorToGeographic(unionForExtent);
        }

        const extent = extentAB(unionForExtent, satellite.orbitalPosition, coverageBeamConfig.gridOrientation);

        if (extent) {
          const missionSymbol = getMissionSymbol(mission);

          // const start = measPerf();
          const beamCenters = computeBeamCenter(extent, coverageBeamConfig.size);
          // console.log('beamCenters=' + beamCenters.length);
          // const endCenter = measPerf(start, 'ComputeCenters');
          // const unionToCompare = webMercatorUtils.webMercatorToGeographic(unionGeometry);

          beams = beamCenters.reduce((agg: Graphic[], current) => {
            let beamCenter = rotate(current, -coverageBeamConfig.gridOrientation);
            beamCenter = offset(beamCenter, coverageBeamConfig.xOffset, coverageBeamConfig.yOffset);

            //const latLngCenter = toLatLng(beamCenter, satellite.orbitalPosition);
            //const finalCenter = toABPoint(latLngCenter.lat, latLngCenter.lng, satellite.orbitalPosition);
            const polygon = makeBeam(beamCenter, coverageBeamConfig.size, satellite.orbitalPosition);

            if (geometryEngine.intersects(unionForExtent, polygon)) {
              const areaGeom = areaGeometries.find(areaGeom => {
                let geomToCompare = areaGeom;
                if (!geomToCompare.spatialReference.isWGS84) {
                  geomToCompare = webMercatorUtils.webMercatorToGeographic(geomToCompare) as Polygon;
                }

                return geometryEngine.intersects(geomToCompare, polygon);
              });

              const areaOfInterestId = areaGeom?.get('areaOfInterestId');
              const nb = beams.length + agg.length + 1;
              const i = getBeamI(mission, nb.toString());

              const attributes = {
                ObjectID: getBeamId(CoverageType.MULTI_BEAM, Number(areaOfInterestId), i),
                i,
                name: i,
                type: CoverageType.MULTI_BEAM,
                missionId: mission.id,
                coverageId: coverageId,
                areaOfInterestId: areaOfInterestId,
                s: coverageBeamConfig.size
              };
              const graphic = new Graphic({
                geometry: webMercatorUtils.geographicToWebMercator(polygon),
                attributes,
                symbol: missionSymbol
              });
              agg.push(graphic);
            }

            return agg;
          }, []);
          // console.log('AllBeamsNb=' + allBeams.length);
          // measPerf(endCenter, 'endBeams');

          // measPerf(start, 'Total');
        }
        // console.log('nbOfBeams=' + beams.length);
        layer.addMany(beams);
        dispatch(setComputingBeams(false));
        setTimeout(() => {
          currentView?.goTo(beams);
        }, 100);
      } catch (e) {
        dispatch(setComputingBeams(false));
      }
    }
  }, [
    coverageBeamConfig.gridOrientation,
    coverageBeamConfig.size,
    coverageBeamConfig.xOffset,
    coverageBeamConfig.yOffset,
    coverageId,
    isMultiBeamCoverageType,
    layer,
    mission,
    satellite,
    dispatch,
    missions
  ]);
};

export default useCreateMultiBeam;
