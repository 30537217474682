import { IRootState } from 'config/store';
import { gatewayPicUrl } from 'modules/map/layers/gateway/gateway-layer-def';
import { ChangeEvent } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { useDispatch, useSelector } from 'react-redux';
import { Gateway } from 'shared/model/gateway.model';
import { setUnderEditionSatellite } from 'shared/reducers/satelliteSlice';

interface ISatelliteGatewayItemProps {
  item: Gateway;
  disabled: boolean;
}

const SatelliteGatewayItem = (props: ISatelliteGatewayItemProps) => {
  const dispatch = useDispatch();

  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);

  const { item, disabled } = props;

  if (!underEditionSatellite) {
    return null;
  }

  const gatewayIds = underEditionSatellite.gatewayIds ? underEditionSatellite.gatewayIds : [];

  const onGatewaySelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled && underEditionSatellite) {
      const newGatewayIds = [...gatewayIds];
      const index = newGatewayIds.findIndex(gatId => gatId.id === item.id);
      if (e.target.checked && index === -1) {
        newGatewayIds.push({ id: item.id });
      } else if (!e.target.checked && index !== -1) {
        newGatewayIds.splice(index, 1);
      }
      const newUnderEditionSat = {
        ...underEditionSatellite,
        gatewayIds: newGatewayIds
      };
      dispatch(setUnderEditionSatellite(newUnderEditionSat));
    }
  };

  const isChecked = gatewayIds.some(gatId => gatId.id === item.id);

  return (
    <div className="gateway-selection-item">
      <Form.Group className="mb-3" controlId={`gateway_${item.id}_checkbox`}>
        <Form.Check
          type="checkbox"
          onChange={onGatewaySelectionChange}
          checked={isChecked}
          disabled={disabled}
          label={
            <>
              <img src={gatewayPicUrl} height="16" alt="gateway" className="mb-1 mr-1" />
              {item.name}
            </>
          }
        />
      </Form.Group>
    </div>
  );
};

export default SatelliteGatewayItem;
