import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Mission } from 'shared/model/mission.model';
import { fetchMissions, removeMission, selectMissions } from 'shared/reducers/missionSlice';
import { missionsRoute } from 'shared/routes/routes';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeleteMissionBtnProps {
  mission: Mission;
}

const DeleteMissionBtn = (props: IDeleteMissionBtnProps) => {
  const { mission } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const updateSuccess = useSelector(({ mission }: IRootState) => mission.updateSuccess);
  const { projectId } = useParams<{ projectId: string }>();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (updateSuccess && show) {
      setShow(false);
      history.push(missionsRoute.getPath(projectId));
      dispatch(selectMissions([]));
      dispatch(fetchMissions(projectId));
    }
  }, [updateSuccess, show, dispatch, projectId, history]);

  const onClose = (confirm: boolean) => {
    if (confirm) {
      dispatch(removeMission(mission.projectId, mission.id));
    } else {
      setShow(false);
    }
  };
  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} onClose={onClose} />
      <SkyTButton variant="link" onClick={open} size="sm">
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default DeleteMissionBtn;
