import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import { createCustomer, deleteCustomer, getCustomers, updateCustomer } from 'shared/api/api';
import { Customer } from 'shared/model/customer.model';
import { arrayRotate, sortByName } from 'shared/utils/array-utils';

const initialState = {
  loading: false,
  updating: false,
  updateSuccess: false,
  customers: [] as Customer[],
  customer: null as Customer | null,
  error: null as any
};

export type CustomerState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    fetchEntitiesStart: state => {
      state.loading = true;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<Customer[]>) => {
      state.loading = false;
      state.customers = action.payload;
    },
    selectCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.customer = action.payload;
    },
    updateStart: state => {
      state.updating = true;
      state.updateSuccess = false;
    },
    updateSuccess: (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    reset: state => {
      state.customer = null;
      state.customers = [];
      state.error = null;
      state.loading = false;
      state.updateSuccess = false;
      state.updating = false;
    }
  }
});

const { fetchEntitiesStart, fetchEntitiesFailed, updateStart, updateFailed, updateSuccess, deleteSuccess } = slice.actions;

export const { selectCustomer, fetchEntitiesSuccess } = slice.actions;

export const fetchCustomers = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchEntitiesStart());
    const response: AxiosResponse<Customer[]> = await getCustomers();
    const orderedCustomers = response.data.sort(sortByName());
    dispatch(fetchEntitiesSuccess(orderedCustomers));
  } catch (error) {
    dispatch(fetchEntitiesFailed(error));
  }
};

export const rotateCustomers = (reverse: boolean) => (dispatch: any, getState: any) => {
  const items = arrayRotate(getState().customer.customers, reverse);
  dispatch(fetchEntitiesSuccess(items));
};

export const saveCustomer =
  (customer: Customer): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = customer.id ? await updateCustomer(customer) : await createCustomer(customer);
      const savedCustomer = response.data;
      dispatch(updateSuccess(savedCustomer));
      dispatch(fetchCustomers());
      dispatch(selectCustomer(savedCustomer));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeCustomer =
  (customerId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteCustomer(customerId);
      dispatch(deleteSuccess());
      dispatch(fetchCustomers());
      dispatch(selectCustomer(null));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
