import { IRootState } from 'config/store';
import { supportLayers } from 'config/supportLayer';
import { useState } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sortByName } from 'shared/utils/array-utils';
import { layerInMap } from 'shared/utils/map-utils';
import NoResult from '../../no-result';
import EquirectangularSwitcher from './EquirectangularSwitcher/EquirectangularSwitcher';
import './layerSwitcher.scss';
import SupportLayerItem from './supportLayerItem';

const showPills = false;
const showToolBar = false;

const LayerSwitcher = () => {
  const { t } = useTranslation('translation');
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);

  const [counter, setCounter] = useState(0);

  const activesLayers = supportLayers.filter(aLayer => layerInMap(arcgisMap, aLayer.id));
  const inactivesLayers = supportLayers.filter(aLayer => !layerInMap(arcgisMap, aLayer.id));

  supportLayers.sort(sortByName());
  inactivesLayers.sort(sortByName());

  const refresh = () => {
    setCounter(counter + 1);
  };

  return (
    <div className="map-menu-content layer-switcher">
      <div className="mb-2 layer-list active-layer">
        <h5>
          <span>Active</span>
          {showPills && (
            <Badge pill variant="info">
              {activesLayers.length}
            </Badge>
          )}
        </h5>
        <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={380} className="skyt-scroll-bar">
          <ListGroup variant="flush" className="text-info cursor-pointer">
            {activesLayers.map((item, i) => (
              <SupportLayerItem item={item} key={`support_${i}`} onClick={refresh} />
            ))}
            {activesLayers.length === 0 && (
              <ListGroup.Item className="layer-support-item py-0">
                <NoResult className="p-1">{t('translation:layers.noSupportLayers')}</NoResult>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Scrollbars>
      </div>
      <div className="layer-list inactive-layer">
        <h5>
          <span>Inactive</span>
          {showPills && (
            <Badge pill variant="primary">
              {inactivesLayers.length}
            </Badge>
          )}
        </h5>
        <Scrollbars autoHeight autoHeightMin={80} autoHeightMax={380} className="skyt-scroll-bar">
          <ListGroup variant="flush" className="cursor-pointer">
            {inactivesLayers.map((item, i) => (
              <SupportLayerItem item={item} key={`support_${i}`} onClick={refresh} />
            ))}
          </ListGroup>
        </Scrollbars>
      </div>
      {showToolBar && (
        <div className="bottom-toolbar">
          <EquirectangularSwitcher />
        </div>
      )}
    </div>
  );
};

export default LayerSwitcher;
