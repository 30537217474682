import { IRootState } from 'config/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Export, SatelliteExportResults } from 'shared/model/export.model';
import { Mission } from 'shared/model/mission.model';
import NoResult from 'shared/widgets/no-result';
import './ExportMissionItem.scss';
import ResultMenuItem from './ResultMenuItem';

interface IExportMissionItemProps {
  mission: Partial<Mission>;
}

const ExportMissionItem: FC<IExportMissionItemProps> = props => {
  const exportItems = useSelector(({ exporter }: IRootState) => exporter.exports);

  const { mission } = props;

  const satelliteExports = exportItems.reduce((agg: SatelliteExportResults[], current: Export) => {
    if (current.exportResult && current.missions.some(item => item.id === mission.id)) {
      let satResuts = agg.find(item => item.satellite.id === current.configuration.satelliteId);
      if (!satResuts) {
        satResuts = {
          satellite: current.configuration.satellite,
          exportItems: []
        };
        agg.push(satResuts);
      }
      satResuts.exportItems.push(current);
    }
    return agg;
  }, []);

  return (
    <div className="export-mission-item">
      <div className="export-mission-item-title" style={{ color: mission.color, borderBottomColor: mission.color }}>
        {mission.name}
      </div>
      <div className="export-mission-item-content">
        {satelliteExports.map(item => (
          <ResultMenuItem key={`resultMenuItem_${item.satellite.id}`} item={item} mission={mission} />
        ))}
        {satelliteExports.length === 0 && <NoResult />}
      </div>
    </div>
  );
};

export default ExportMissionItem;
