import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { saveArea, setUnderEditionBeams } from 'shared/reducers/areaSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

const DeleteAreaBeamBtn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['translation']);

  const area = useSelector(({ area }: IRootState) => area.area);

  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);

  const { projectId, satelliteId, configurationId, coverageId } = useParams<{
    projectId: string;
    satelliteId: string;
    configurationId: string;
    coverageId: string;
    areaId: string;
  }>();

  const [show, setShow] = useState(false);

  const selected = underEditionBeams.filter(item => item.selected);
  const nb = selected.length;

  if (!area) {
    return null;
  }

  const beamList = area.beamList ? area.beamList : [];

  const onClose = async (confirm: boolean) => {
    if (confirm) {
      const newBeamList = beamList.filter(beam => !selected.some(selectedBeam => selectedBeam.i === beam.i));

      const newArea = { ...area };
      newArea.beamList = newBeamList;
      await dispatch(saveArea(projectId, satelliteId, configurationId, coverageId, newArea));

      const newUnderEditionBeams = underEditionBeams.filter(underEditionBeam => underEditionBeam.selected !== true);

      dispatch(setUnderEditionBeams(newUnderEditionBeams));

      setShow(false);
    } else {
      setShow(false);
    }
  };
  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} dialogContent={t('coverage.confirmDeleteBeam', { nb })} onClose={onClose} waiting={false} />
      <SkyTButton variant="danger" className="mb-1" style={{ height: '38px' }} onClick={open} size="sm" disabled={nb === 0}>
        <span>{t('coverage.deleteBeam', { nb })}</span>
      </SkyTButton>
    </>
  );
};

export default DeleteAreaBeamBtn;
