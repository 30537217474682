import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { POI } from 'shared/model/poi.model';
import { removePoi } from 'shared/reducers/poiSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeletePoiBtnProps {
  poi: POI;
}

const DeletePoiBtn = (props: IDeletePoiBtnProps) => {
  const dispatch = useDispatch();
  const updating = useSelector(({ poi }: IRootState) => poi.updating);

  const { poi } = props;
  const { projectId } = useParams<{ projectId: string }>();

  const [show, setShow] = useState(false);

  const onClose = async (confirm: boolean) => {
    if (confirm) {
      await dispatch(removePoi(projectId, poi.id));
    }
    setShow(false);
  };
  const open = () => setShow(true);

  return (
    <>
      <ConfirmDeleteDialog show={show} onClose={onClose} waiting={updating} />
      <SkyTButton variant="link" onClick={open} size="sm" className="text-primary">
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default DeletePoiBtn;
