import { IRootState } from 'config/store';
import SatelliteCardTitle from 'modules/needs/satellites/satelliteCardTitle';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProjectDetails } from 'shared/model/project.model';
import { showMap } from 'shared/reducers/mapSlice';
import { fetchMissions } from 'shared/reducers/missionSlice';
import './ConfigureSatelliteConfiguration.scss';
import CreateOrEditSatelliteConfiguration from './CreateOrEditSatelliteConfiguration';

const ConfigureSatelliteConfiguration = () => {
  const dispatch = useDispatch();

  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);

  const { projectId, satelliteId } = useParams<{ projectId: string; satelliteId: string; configurationId: string }>();

  const satellite = project ? project.satellites.find(item => item.id === Number(satelliteId)) : undefined;

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
  }, [dispatch, mapIsDisplayed]);

  useEffect(() => {
    if (missions.length === 0) {
      dispatch(fetchMissions(projectId));
    }
  }, [dispatch, missions.length, projectId]);

  if (!satellite) {
    return null;
  }

  return (
    <div className="configure-satellite-configuration">
      <div>
        <SatelliteCardTitle item={satellite} className="border-bottom border-primary pb-2 font-weight-bold" />
      </div>
      <div className="pt-2 flex-grow-1">
        <CreateOrEditSatelliteConfiguration />
      </div>
    </div>
  );
};

export default ConfigureSatelliteConfiguration;
