import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import { DefaultFeatureNamelabelClass } from '../commons/common-layer-def';

const popupTemplate = {
  title: '{name}',
  content: '<div>Lon: {lon}</div><div>Lat: {lat}</div>'
};

export const poiPicUrl = `/assets/img/poi.svg`;

export const poiMarkerSymbol = new PictureMarkerSymbol({
  url: poiPicUrl,
  width: '36px',
  height: '36px',
  yoffset: '18px'
});

export const poiLayerId = 'pois';

const defLayer: any = {
  id: poiLayerId,
  title: 'pois',
  geometryType: 'point',
  source: [],
  objectIdField: 'id',
  labelingInfo: [DefaultFeatureNamelabelClass],
  fields: [
    {
      name: 'name',
      alias: 'PoiName',
      type: 'string'
    },
    {
      name: 'lon',
      alias: 'Lon',
      type: 'double'
    },
    {
      name: 'lat',
      alias: 'Lat',
      type: 'double'
    }
  ],
  renderer: new SimpleRenderer({
    symbol: poiMarkerSymbol
  }),
  popupTemplate
};

export const poiLayer = new FeatureLayer(defLayer);
export const tempPoiLayer = new GraphicsLayer({
  title: 'temp-pois'
});

// @ts-ignore
// window.poiLayer = layer;
