import { Col, Row } from 'react-bootstrap';
import SatelliteRessourceUsage from './SatelliteRessourceUsage';
import './SatSystemOverview.scss';
import SystemPerformance from './SystemPerformance';

const SatSystemOverviewViz = () => {
  return (
    <Row>
      <Col lg={6} className="mb-4">
        <SystemPerformance />
      </Col>
      <Col lg={6}>
        <SatelliteRessourceUsage />
      </Col>
    </Row>
  );
};

export default SatSystemOverviewViz;
