import { IRootState } from 'config/store';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProjectDetails } from 'shared/model/project.model';
import { sortByName } from 'shared/utils/array-utils';
import NoResult from 'shared/widgets/no-result';
import './configure-satellite.scss';
import SatelliteGatewayItem from './satelliteGatewayItem';

const ConfigureSatelliteGateways = () => {
  const { t } = useTranslation('translation');
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const satelliteModels = useSelector(({ referential }: IRootState) => referential.satelliteModels);
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);

  if (!underEditionSatellite) {
    return null;
  }

  const currentSatModel = satelliteModels.find(item => item.id === underEditionSatellite?.modelId);
  const maxGatewayCount = currentSatModel ? currentSatModel.maxGatewayCount : 0;
  const gateways = [...project.gateways].sort(sortByName());
  const gatewayIds = underEditionSatellite.gatewayIds ? underEditionSatellite.gatewayIds : [];

  const canAdd = gatewayIds.length < maxGatewayCount;

  return (
    <div>
      <div className="mb-3">{t('satellite.gateways.description')}</div>
      <div className="font-weight-bold mb-1">{t('satellite.gateways.list')}</div>
      <Scrollbars autoHeight autoHeightMax={295} className="skyt-scroll-bar">
        {gateways.map(item => {
          const isChecked = gatewayIds.some(gatId => gatId.id === item.id);
          const disabled = !isChecked && !canAdd;
          return <SatelliteGatewayItem key={`gateway_${item.id}`} item={item} disabled={disabled} />;
        })}
      </Scrollbars>
      {gateways.length === 0 && <NoResult />}
    </div>
  );
};

export default ConfigureSatelliteGateways;
