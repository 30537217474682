import { useTranslation } from 'react-i18next';
import { ProjectDetails } from 'shared/model/project.model';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import CreateOrEditOrganization from './createorEditOrganization';
import './organization.scss';
import OrganizationItem from './organizationItem';

interface IOrganizationsProps {
  project: ProjectDetails;
}

const Organizations = (props: IOrganizationsProps) => {
  const { t } = useTranslation('translation');
  const { project } = props;

  const hasWriteAccess = projectHasWriteAccess(project);

  return (
    <div>
      <h4 className="font-weight-bold mb-4">{t('organization.organizations')}</h4>
      <div>
        {project.projectOrganizations?.map(item => (
          <OrganizationItem key={item.id} item={item} project={project} />
        ))}
      </div>
      {hasWriteAccess && (
        <div>
          <CreateOrEditOrganization />
        </div>
      )}
    </div>
  );
};

export default Organizations;
