import { IThemeProvider } from 'scichart/Charting/Themes/IThemeProvider';

// const backgroundcolor = 'rgba(83, 184, 217, 0.1)';
const backgroundcolor = 'rgba(14, 17, 45, 0.1)';
//const backgroundcolor = '#0E112D';

export const skyTScichartTheme: IThemeProvider = {
  axisBorder: 'Transparent',
  axisTitleColor: '#6495ED',
  annotationsGripsBackroundBrush: 'white',
  annotationsGripsBorderBrush: 'white',
  axis3DBandsFill: '#1F3D6833',
  axisBandsFill: '#1F3D6833',
  axisPlaneBackgroundFill: 'Transparent',
  columnFillBrush: 'white',
  columnLineColor: 'white',
  cursorLineBrush: '#6495ED99',
  defaultColorMapBrush: [
    { offset: 0, color: 'DarkBlue' },
    { offset: 0.5, color: 'CornflowerBlue' },
    { offset: 1, color: '#FF22AA' }
  ],
  downBandSeriesFillColor: '#52CC5490',
  downBandSeriesLineColor: '#E26565FF',
  downBodyBrush: 'white',
  downWickColor: 'white',
  gridBackgroundBrush: 'white',
  gridBorderBrush: 'white',
  labelBackgroundBrush: '#6495EDAA',
  labelBorderBrush: '#6495ED',
  labelForegroundBrush: '#EEEEEE',
  legendBackgroundBrush: '#1D2C35',
  lineSeriesColor: 'white',
  loadingAnimationBackground: '#0D213A',
  loadingAnimationForeground: '#6495ED',
  majorGridLineBrush: '#1F3D68',
  minorGridLineBrush: '#102A47',
  mountainAreaBrush: 'white',
  mountainLineColor: 'white',
  overviewFillBrush: 'white',
  planeBorderColor: 'white',
  rolloverLineBrush: '#FD9F2533',
  rubberBandFillBrush: '#99999933',
  rubberBandStrokeBrush: '#99999977',
  sciChartBackground: backgroundcolor,
  scrollbarBackgroundBrush: 'white',
  scrollbarBorderBrush: 'white',
  scrollbarGripsBackgroundBrush: 'white',
  scrollbarViewportBackgroundBrush: 'white',
  scrollbarViewportBorderBrush: 'white',
  shadowEffectColor: 'white',
  textAnnotationBackground: '#6495EDAA',
  textAnnotationForeground: '#EEEEEE',
  tickTextBrush: '#6495ED',
  upBandSeriesFillColor: 'white',
  upBandSeriesLineColor: 'white',
  upBodyBrush: '#6495EDA0',
  upWickColor: '#6495ED'
};
