import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetShowBeamNumber, setShowBeamNumber } from 'shared/reducers/mapSlice';
import ToggleSwitch from 'shared/widgets/SkyTToggleSwitch';

const ToggleBeamNumber = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const showBeamNumber = useSelector(({ map }: IRootState) => map.showBeamNumber);

  useEffect(() => {
    return () => {
      dispatch(resetShowBeamNumber());
    };
  }, [dispatch]);

  const onChange = (value: boolean) => {
    dispatch(setShowBeamNumber(value));
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>{t('coverage.displayBeamsNumber')}</div>
      <ToggleSwitch onChange={onChange} checked={showBeamNumber} />
    </div>
  );
};

export default ToggleBeamNumber;
