import Polygon from '@arcgis/core/geometry/Polygon';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import { IRootState } from 'config/store';
import {
  areaOfInterestslayer,
  getGlobalAreaTypeSymbol,
  tempAreaOfInterestsLayer
} from 'modules/map/layers/areaOfInterets/areaOfInterestLayer-def';
import { currentView } from 'modules/map/map';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AreaOfInterestType } from 'shared/model/areaOfInterest.model';
import { setUnderEditionFeatures } from 'shared/reducers/areaOfInterestSlice';
import { makeGlobalAreaBeam } from 'shared/utils/beam-utils';
import { hexToRgb } from 'shared/utils/color-utils';
import AreaTypeIcon from '../icons/AreaTypeIcon';

const DrawGlobalAreaOfInterest = () => {
  const dispatch = useDispatch();
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);

  const missionColor = selectedMissions.length === 1 ? selectedMissions[0].color : null;

  useEffect(() => {
    if (arcgisMap && missionColor) {
      tempAreaOfInterestsLayer.removeAll();
      arcgisMap.add(tempAreaOfInterestsLayer);

      let longitude = 0;

      if (areaOfInterestslayer.graphics.length > 0) {
        let rings: any[] = [];
        areaOfInterestslayer.graphics.forEach(g => {
          const polygon = g.geometry as Polygon;
          rings = rings.concat(polygon.rings);
        });
        const multiPolygons = new Polygon({ rings, spatialReference: SpatialReference.WebMercator });
        longitude = multiPolygons.extent.center.longitude;
      }

      const geometry = makeGlobalAreaBeam(longitude);
      const color = hexToRgb(missionColor);
      const symbol = getGlobalAreaTypeSymbol(color);

      const g = new Graphic({
        geometry,
        symbol
      });

      tempAreaOfInterestsLayer.add(g);
      currentView?.goTo(tempAreaOfInterestsLayer.fullExtent);

      dispatch(setUnderEditionFeatures([g]));
    }
    return () => {
      tempAreaOfInterestsLayer.removeAll();
      arcgisMap?.remove(tempAreaOfInterestsLayer);
    };
  }, [arcgisMap, missionColor, dispatch]);

  return (
    <div className="mb-2">
      <div className="d-flex align-items-center mb-2">
        <div className="mr-2">
          <AreaTypeIcon type={AreaOfInterestType.GLOBAL} />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default DrawGlobalAreaOfInterest;
