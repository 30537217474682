import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IRootState } from 'config/store';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputError from 'shared/form/InputError';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Organization } from 'shared/model/organization.model';
import { removeOrganization, reset as resetReducer, saveOrganization } from 'shared/reducers/organizationSlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from 'shared/widgets/dialogs/ConfirmDeleteDialog';

interface ICreateOrEditOrganizationProps {
  organization?: Organization;
}

const CreateOrEditOrganization = (props: ICreateOrEditOrganizationProps) => {
  const dispatch = useDispatch();
  const currentProject = useSelector(({ project }: IRootState) => project.project);
  const updateSuccess = useSelector(({ organization }: IRootState) => organization.updateSuccess);
  const updating = useSelector(({ organization }: IRootState) => organization.updating);
  const { t } = useTranslation(['translation']);
  const refName = useRef<HTMLInputElement>();

  const { organization } = props;

  const [show, setShow] = useState(false);
  const [showDetele, setShowDelete] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: organization?.name
    }
  });

  const { ref, ...rest } = register('name', { required: true, maxLength: 255 });

  const isNew = organization ? false : true;

  useEffect(() => {
    reset({
      name: organization?.name
    });
  }, [organization, reset]);

  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      if (refName.current) {
        refName?.current?.focus();
      }
    }, 200);
  };

  const handleClose = () => {
    setShow(false);
    reset({
      name: organization?.name
    });
  };

  const tryDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (organization) {
      setShowDelete(true);
    }
  };

  const onSubmit = (data: any) => {
    if (currentProject) {
      if (organization) {
        data.id = organization.id;
      }
      dispatch(saveOrganization(currentProject.id, data));
    }
  };

  const onConfirmOrCancelDeletion = (confirm: boolean) => {
    if (confirm && currentProject && organization) {
      dispatch(removeOrganization(currentProject.id, organization.id));
    } else {
      setShowDelete(false);
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      if (show) {
        setShow(false);
      } else if (showDetele) {
        setShowDelete(false);
      }
      dispatch(resetReducer());
    }
  }, [updateSuccess, show, dispatch, reset, showDetele]);

  const title = isNew ? t('organization.createTitle') : t('organization.editTitle');
  const subTitle = isNew ? t('organization.createSubTitle') : t('organization.editSubTitle');
  const ok = isNew ? t('create') : t('edit');

  return (
    <>
      <Modal className="skyt-modal" show={show} onHide={handleClose}>
        {show && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>
                <h4>{title}</h4>
                <h6>{subTitle}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left">
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={classNames({ 'text-danger': errors.name })}>
                  {t('organization.name')}
                </Form.Label>
                <Col sm="8">
                  <SkyTTextInput
                    placeholder="Tape a name here"
                    ref={(e: any) => {
                      ref(e);
                      refName.current = e;
                    }}
                    {...rest}
                    disabled={updating}
                  />
                  <InputError name="name" errors={errors} maxLength={255} />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <SkyTButton onClick={handleClose} disabled={updating}>
                {t('cancel')}
              </SkyTButton>
              <SkyTButton type="submit" disabled={updating}>
                {ok}
              </SkyTButton>
            </Modal.Footer>
          </form>
        )}
      </Modal>
      <ConfirmDeleteDialog show={showDetele} onClose={onConfirmOrCancelDeletion} />
      {isNew ? (
        <SkyTButton size="sm" variant="outline-info" onClick={handleShow}>
          <span>{t('organization.createBtn')}</span>
          <FontAwesomeIcon icon="plus" className="ml-2" />
        </SkyTButton>
      ) : (
        <Button className="organization-btn mr-2 mb-3" onClick={handleShow}>
          {organization?.name}
          <FontAwesomeIcon icon={['far', 'times-circle']} className="ml-2" onClick={tryDelete} />
        </Button>
      )}
    </>
  );
};

export default CreateOrEditOrganization;
