import classNames from 'classnames';
import numeral from 'numeral';
import { ILabelValueValue } from 'shared/model/results.model';
import './OverviewLabelValue.scss';

interface ILabelValueOverviewProps {
  labelValue: ILabelValueValue;
  icon: any;
  className?: string;
}

const LabelValueOverview = (props: ILabelValueOverviewProps) => {
  const { labelValue, icon, className } = props;

  let value = numeral(labelValue.value).format('0,0.[000]');
  if (labelValue.unit) {
    value += ' ' + labelValue.unit;
  }

  return (
    <div className={classNames('overview-label-value', className)}>
      <div className="label">{labelValue.label}</div>
      <div className="value">
        <div className="icon">{icon}</div>
        <div>{value}</div>
      </div>
    </div>
  );
};

export default LabelValueOverview;
