// import webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { ChangeEvent, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { ProjectDetails } from 'shared/model/project.model';
import { Satellite } from 'shared/model/satellite.model';
import { showMap } from 'shared/reducers/mapSlice';
import { fetchProject } from 'shared/reducers/projectSlice';
import { reset as resetSatellite, saveSatellite, setUnderEditionSatellite, updateFailed } from 'shared/reducers/satelliteSlice';
import {
  configureSatelliteGatewaysRoute,
  configureSatellitePositionRoute,
  configureSatelliteReflectorRoute,
  configureSatelliteTypeRoute,
  satellitesRoute
} from 'shared/routes/routes';
import { canConfigureReflector, projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import SelectReflectorFrequencyBand from '../configure/reflectors/SelectReflectorFrequencyBand';
import './create-satellite.scss';

const CreateOrEditSatellite = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const refName = useRef<HTMLInputElement>();
  const { t } = useTranslation('translation');

  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);
  const models = useSelector(({ referential }: IRootState) => referential.satelliteModels);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);

  const { projectId, satelliteId } = useParams<{ projectId: string; satelliteId: string }>();

  const hasWriteAccess = projectHasWriteAccess(project);

  const isNew = satelliteId === 'new';

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
  }, [dispatch, mapIsDisplayed]);

  useEffect(() => {
    if (satelliteId === 'new') {
      const defaultSatellite: Partial<Satellite> = {
        name: ''
      };
      dispatch(setUnderEditionSatellite(defaultSatellite));
    } else if (project) {
      const sat = project.satellites.find(item => item.id === Number(satelliteId));
      if (sat) {
        const defaultSatellite: Partial<Satellite> = { ...sat };
        dispatch(setUnderEditionSatellite(defaultSatellite));
      }
    }
  }, [dispatch, satelliteId, project]);

  useEffect(() => {
    if (underEditionSatellite) {
      setTimeout(() => {
        if (refName.current) {
          refName.current.focus();
        }
      }, 200);
    }
  }, [underEditionSatellite]);

  if (!hasWriteAccess) {
    return null;
  }

  const goToConfigurePosition = () => {
    if (underEditionSatellite) {
      history.push(configureSatellitePositionRoute.getPath(projectId, satelliteId));
    }
  };

  const gotToConfigureType = () => {
    if (underEditionSatellite) {
      history.push(configureSatelliteTypeRoute.getPath(projectId, satelliteId));
    }
  };

  const gotToConfigureGateways = () => {
    if (underEditionSatellite) {
      history.push(configureSatelliteGatewaysRoute.getPath(projectId, satelliteId));
    }
  };

  const showConfigureReflector = canConfigureReflector(underEditionSatellite, models);

  const gotToConfigureReflector = () => {
    if (underEditionSatellite && showConfigureReflector) {
      history.push(configureSatelliteReflectorRoute.getPath(projectId, satelliteId));
    }
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (underEditionSatellite) {
      dispatch(
        setUnderEditionSatellite({
          ...underEditionSatellite,
          name: e.target.value
        })
      );
    }
  };

  const onCancel = () => {
    history.push(satellitesRoute.getPath(projectId));
    dispatch(resetSatellite());
  };

  const onValid = async () => {
    if (isNew) {
      history.push(configureSatelliteTypeRoute.getPath(projectId, 'new'));
    } else if (underEditionSatellite) {
      try {
        await dispatch(saveSatellite(projectId, underEditionSatellite));
        dispatch(fetchProject(projectId));
        history.push(satellitesRoute.getPath(projectId));
        dispatch(resetSatellite());
      } catch (e) {
        dispatch(updateFailed(e));
      }
    }
  };

  const canSave =
    underEditionSatellite && underEditionSatellite.name && underEditionSatellite.name.length > 0 && underEditionSatellite.reflector?.band;
  const title = isNew ? 'satellite.createSatelliteBtn' : 'satellite.configureSatelliteBtn';

  return (
    <div className="create-sat">
      <div className="create-sat-title unselectable">
        <div>{t(title)}</div>
        <SkyTButton variant="link" className="text-primary" onClick={onCancel}>
          <FontAwesomeIcon icon="times" />
        </SkyTButton>
      </div>
      {underEditionSatellite && (
        <div className="sat-content">
          <div>
            <Form.Group className="mt-2 mb-2">
              <Form.Label>{t('satellite.labelSatelliteName')}</Form.Label>
              <SkyTTextInput type="text" ref={refName} value={underEditionSatellite.name} onChange={onNameChange}></SkyTTextInput>
            </Form.Group>
            <SelectReflectorFrequencyBand />
            {!isNew && (
              <div className="configure-options">
                <SkyTButton variant="outline-info" className="text-primary" onClick={gotToConfigureType}>
                  {t('satellite.configureSatelliteType')}
                </SkyTButton>
                <SkyTButton variant="outline-info" className="text-primary" onClick={goToConfigurePosition}>
                  {t('satellite.configureSatellitePosition')}
                </SkyTButton>
                <SkyTButton variant="outline-info" className="text-primary" onClick={gotToConfigureGateways}>
                  {t('satellite.configureSatelliteGateways')}
                </SkyTButton>
                {showConfigureReflector && (
                  <SkyTButton variant="outline-info" className="text-primary" onClick={gotToConfigureReflector}>
                    {t('satellite.configureSatelliteReflectors')}
                  </SkyTButton>
                )}
              </div>
            )}
          </div>

          <div className="create-sat-bar">
            <SkyTMapButton type="submit" variant="outline-info" onClick={onCancel}>
              {t('cancel')}
            </SkyTMapButton>
            <SkyTMapButton type="submit" variant="outline-info" disabled={!canSave} onClick={onValid}>
              {t(isNew ? 'next' : 'save')}
            </SkyTMapButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateOrEditSatellite;
