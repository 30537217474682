import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { useContext, useEffect, useMemo } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ProjectDetails } from 'shared/model/project.model';
import { Satellite } from 'shared/model/satellite.model';
import { showMap } from 'shared/reducers/mapSlice';
import { selectMissions } from 'shared/reducers/missionSlice';
import { editSatelliteRoute, satellitesRoute } from 'shared/routes/routes';
import { sortByName } from 'shared/utils/array-utils';
import { projectHasWriteAccess, someMissionNotComplete } from 'shared/utils/model-utils';
import SkyTAccordionTab from 'shared/widgets/accordion/skyTAccordionTab';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';
import SatelliteItem from './satellite-item';
import SatellitesTabTitle from './satellitesTabTitle';

const maxNbOfSatellite = 6;

export const satelliteKey = 'satellites';

const SatellitesTab = () => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const history = useHistory();
  const currentEventKey = useContext(AccordionContext);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const project = useSelector(({ project }: IRootState) => project.project);
  const loading = useSelector(({ project }: IRootState) => project.loading);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);

  const { projectId } = useParams<{ projectId: string }>();

  const isActive = currentEventKey === satelliteKey;
  const isDisabled = someMissionNotComplete(missions);
  const hasWriteAccess = projectHasWriteAccess(project);

  const projectDetail = project as ProjectDetails;

  useEffect(() => {
    if (isActive) {
      if (!mapIsDisplayed) {
        dispatch(showMap());
      }
      dispatch(selectMissions([]));
      const newHistory = satellitesRoute.getPath(projectId);
      history.push(newHistory);
    }
  }, [isActive, mapIsDisplayed, dispatch, projectId, history]);

  const sorted = useMemo(() => {
    let result: Satellite[] = [];
    if (projectDetail) {
      result = [...projectDetail.satellites];
      result.sort(sortByName());
    }
    return result;
  }, [projectDetail]);

  const title = <SatellitesTabTitle />;

  const onCreateSatellite = () => {
    history.push(editSatelliteRoute.getPath(projectId, 'new'));
  };

  const canAddSat = hasWriteAccess && sorted.length < maxNbOfSatellite;

  return (
    <SkyTAccordionTab eventKey={satelliteKey} title={title} disabled={isDisabled}>
      {isActive && (
        <div>
          {loading ? (
            <Loader />
          ) : (
            <div>
              {sorted.map(sat => (
                <SatelliteItem key={sat.id} item={sat} />
              ))}
              {canAddSat && (
                <div className="text-right">
                  <SkyTMapButton size="sm" className="add-satellite-btn" variant="outline-info" type="button" onClick={onCreateSatellite}>
                    {t('satellite.createSatelliteBtn')}
                    <FontAwesomeIcon icon="plus" className="ml-1" />
                  </SkyTMapButton>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </SkyTAccordionTab>
  );
};

export default SatellitesTab;
