import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import { GatewayBandWidth } from 'shared/model/gateway.model';
import { DefaultFeatureNamelabelClass } from '../commons/common-layer-def';

const getPopupContent = (feature: any, other: any) => {
  const div = document.createElement('div');
  div.className = 'text-primary';
  const bandwidths = JSON.parse(feature.graphic.attributes.bandwidths) as GatewayBandWidth[];
  const content = bandwidths.map(band => `<div class="py-1">${band.name} : ${band.bandwidth} Mhz</div>`);
  div.innerHTML = '<div>' + content.join('') + '</div>';
  return div;
};

const popupTemplate = {
  title: '<div class="text-primary">{name}</div>',
  content: getPopupContent,
  outFields: ['*']
};

export const gatewayPicUrl = `/assets/img/gateway.svg`;

export const gatewayMarkerSymbol = new PictureMarkerSymbol({
  url: gatewayPicUrl,
  width: '32px',
  height: '32px'
});

export const gatewayLayerId = 'gateways';

const defLayer: any = {
  id: 'gatewayLayerId',
  title: 'Gateways',
  source: [],
  objectIdField: 'id',
  labelingInfo: [DefaultFeatureNamelabelClass],
  geometryType: 'point',
  fields: [
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'bandwidths',
      type: 'string'
    }
  ],
  renderer: new SimpleRenderer({
    symbol: gatewayMarkerSymbol
  }),
  popupTemplate
};

export const gatewayLayer = new FeatureLayer(defLayer);
export const tempGatewayLayer = new GraphicsLayer({
  title: 'temp-gateway'
});

// @ts-ignore
// window.gatewayLayer = layer;
