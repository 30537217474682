import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import './loader.scss';

interface ILoaderProps {
  inline?: boolean;
  role?: string;
  size?: 'sm';
  variant?: Variant;
  delay?: number;
}

const Loader = (props: ILoaderProps) => {
  const { inline = false, delay = 500, ...rest } = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);

    return () => clearTimeout(timer);
  }, [delay]);

  if (!show) {
    return null;
  }

  if (inline) {
    return <Spinner animation="border" {...rest} />;
  }
  return (
    <div className="text-center">
      <Spinner animation="border" {...rest} />
    </div>
  );
};

export default Loader;
