import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'shared/model/user.model';
import { removeTeamMember } from 'shared/reducers/teamMemberSlice';
import SkyTButton from '../../shared/widgets/buttons/skyTButton';
import ConfirmDeleteDialog from '../../shared/widgets/dialogs/ConfirmDeleteDialog';

interface IDeleteTeamMemberBtnProps {
  user: User;
  onModalClose: () => void;
}

const DeleteTeamMember = (props: IDeleteTeamMemberBtnProps) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const project = useSelector(({ project }: IRootState) => project.project);
  const updateSuccess = useSelector(({ teamMember }: IRootState) => teamMember.updateSuccess);

  const { user, onModalClose } = props;

  const [show, setShow] = useState(false);

  const onClose = (confirm: boolean) => {
    if (confirm && project) {
      dispatch(removeTeamMember(project.id, user.id));
    } else {
      setShow(false);
    }
    onModalClose();
  };
  const open = () => setShow(true);

  useEffect(() => {
    setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  const name = user.firstName + ' ' + user.lastName;

  return (
    <>
      <ConfirmDeleteDialog show={show} dialogContent={t('teamMember.deleteConfirm', { name })} onClose={onClose} />
      <SkyTButton variant="outline-info" className="ml-1" onClick={open} size="sm">
        <FontAwesomeIcon icon="times" />
      </SkyTButton>
    </>
  );
};

export default DeleteTeamMember;
