import { useMsal } from '@azure/msal-react';
import Home from 'modules/home/home';
import { areaOfInterestKey } from 'modules/needs/areaOfInterets/areaOfInteretsTab';
import { configurationsKey } from 'modules/needs/configurations/configurationsTab';
import { exportKey } from 'modules/needs/exports/exportsTab';
import { gatewayKey } from 'modules/needs/gateways/GatewaysTab';
import { missionTabKey } from 'modules/needs/missions/missionsTab';
import NeedsPage from 'modules/needs/needsPage';
import { poiKey } from 'modules/needs/pois/PoiTab';
import { satelliteKey } from 'modules/needs/satellites/satellitesTab';
import ProfilPage from 'modules/profil/profilPage';
import ProjectPage from 'modules/projects/ProjectPage';
import ResultsPage from 'modules/results/ResultsPage';
import { Switch, useHistory } from 'react-router-dom';
import ErrorBoundaryRoute from 'shared/error/errorBoundaryRoute';
import { CustomNavigationClient } from 'shared/utils/NavigationClient';
import PrivateRoute from './private-route';

interface IPageRoute {
  path: string;
  component: any;
  getPath: (...args: Array<number | string>) => string;
}

export const profilPageRoute: IPageRoute = {
  path: '/profil',
  component: ProfilPage,
  getPath: () => '/profil'
};

export const projectPageRoute: IPageRoute = {
  path: '/project/:projectId',
  component: ProjectPage,
  getPath: (projectId: number | string) => `/project/${projectId}`
};

export const needsRoute: IPageRoute = {
  path: '/project/:projectId/needs',
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/needs`
};

export const missionsRoute: IPageRoute = {
  path: `/project/:projectId/${missionTabKey}s`,
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/${missionTabKey}s`
};

export const poisRoute: IPageRoute = {
  path: `/project/:projectId/${poiKey}`,
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/${poiKey}`
};

export const poiRoute: IPageRoute = {
  path: `/project/:projectId/${poiKey}/:poiId`,
  component: NeedsPage,
  getPath: (projectId: number | string, poiId: number | string) => `/project/${projectId}/${poiKey}/${poiId}`
};

export const gatewaysRoute: IPageRoute = {
  path: `/project/:projectId/${gatewayKey}`,
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/${gatewayKey}`
};

export const gatewayRoute: IPageRoute = {
  path: `/project/:projectId/${gatewayKey}/:gatewayId`,
  component: NeedsPage,
  getPath: (projectId: number | string, gatewayId: number | string) => `/project/${projectId}/${gatewayKey}/${gatewayId}`
};

export const configureGatewayRoute = {
  path: `/project/:projectId/${gatewayKey}/configure/:gatewayId`,
  component: NeedsPage,
  getPath: (projectId: number | string, gatewayId: number | string) => `/project/${projectId}/${gatewayKey}/configure/${gatewayId}`
};

export const missionRoute = {
  path: `/project/:projectId/${missionTabKey}/:missionId`,
  component: NeedsPage,
  getPath: (projectId: number | string, missionId: number | string) => `/project/${projectId}/${missionTabKey}/${missionId}`
};

export const allAreaOfInteretsRoute = {
  path: `/project/:projectId/${missionTabKey}/all/${areaOfInterestKey}`,
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/${missionTabKey}/all/${areaOfInterestKey}`
};

export const areaOfInteretsRoute = {
  path: `/project/:projectId/${missionTabKey}/:missionId/${areaOfInterestKey}`,
  component: NeedsPage,
  getPath: (projectId: number | string, missionId: number | string) =>
    `/project/${projectId}/${missionTabKey}/${missionId}/${areaOfInterestKey}`
};

export const configureAreaOfInteretsRoute = {
  path: `/project/:projectId/${missionTabKey}/:missionId/${areaOfInterestKey}/:areaId`,
  component: NeedsPage,
  getPath: (projectId: number | string, missionId: number | string, areaId: number | string) =>
    `/project/${projectId}/${missionTabKey}/${missionId}/${areaOfInterestKey}/${areaId}`
};

export const satellitesRoute = {
  path: `/project/:projectId/${satelliteKey}`,
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/${satelliteKey}`
};

export const editSatelliteRoute = {
  path: `/project/:projectId/${satelliteKey}/edit/:satelliteId/`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) => `/project/${projectId}/${satelliteKey}/edit/${satelliteId}`
};

export const configureSatelliteTypeRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/type`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) => `/project/${projectId}/${satelliteKey}/${satelliteId}/type`
};

export const configureSatellitePositionRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/position`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) => `/project/${projectId}/${satelliteKey}/${satelliteId}/position`
};

export const configureSatelliteGatewaysRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/gateways`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) => `/project/${projectId}/${satelliteKey}/${satelliteId}/gateways`
};

export const configureSatelliteReflectorRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/reflector`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) => `/project/${projectId}/${satelliteKey}/${satelliteId}/reflector`
};

export const configurationsRoute = {
  path: `/project/:projectId/${configurationsKey}`,
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/${configurationsKey}`
};

export const allconfigurationsSatellitesRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/${configurationsKey}/`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) =>
    `/project/${projectId}/${satelliteKey}/${satelliteId}/${configurationsKey}/`
};

export const configurationsSatelliteRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/${configurationsKey}/`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) =>
    `/project/${projectId}/${satelliteKey}/${satelliteId}/${configurationsKey}/`
};

export const configureConfigurationSatelliteRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/${configurationsKey}/:configurationId`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string, configurationId: number | string) =>
    `/project/${projectId}/${satelliteKey}/${satelliteId}/${configurationsKey}/${configurationId}`
};

export const coveragesRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/${configurationsKey}/:configurationId/coverages`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string, configurationId: number | string) =>
    `/project/${projectId}/${satelliteKey}/${satelliteId}/${configurationsKey}/${configurationId}/coverages`
};

export const coverageRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/${configurationsKey}/:configurationId/coverage/:coverageId`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string, configurationId: number | string, coverageId: number | string) =>
    `/project/${projectId}/${satelliteKey}/${satelliteId}/${configurationsKey}/${configurationId}/coverage/${coverageId}`
};

export const areaRoute = {
  path: `/project/:projectId/${satelliteKey}/:satelliteId/${configurationsKey}/:configurationId/coverage/:coverageId/area/:areaId`,
  component: NeedsPage,
  getPath: (
    projectId: number | string,
    satelliteId: number | string,
    configurationId: number | string,
    coverageId: number | string,
    areaId: number | string
  ) => `/project/${projectId}/${satelliteKey}/${satelliteId}/${configurationsKey}/${configurationId}/coverage/${coverageId}/area/${areaId}`
};

export const exportsRoute = {
  path: `/project/:projectId/${exportKey}`,
  component: NeedsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/${exportKey}`
};

export const exportsSatelliteRoute = {
  path: `/project/:projectId/${exportKey}/satellite/:satelliteId`,
  component: NeedsPage,
  getPath: (projectId: number | string, satelliteId: number | string) => `/project/${projectId}/${exportKey}/satellite/${satelliteId}`
};

export const resultsRoute = {
  path: `/project/:projectId/results`,
  component: ResultsPage,
  getPath: (projectId: number | string) => `/project/${projectId}/results`
};

export const chartResultRoute = {
  path: `/project/:projectId/result/:resultId`,
  component: ResultsPage,
  getPath: (projectId: number | string, exportId: number | string) => `/project/${projectId}/result/${exportId}`
};

const Routes = () => {
  const history = useHistory();
  const { instance } = useMsal();

  const navigationClient = new CustomNavigationClient(history);
  instance.setNavigationClient(navigationClient);

  return (
    <Switch>
      <ErrorBoundaryRoute path={needsRoute.path} component={needsRoute.component} />
      <ErrorBoundaryRoute path={areaRoute.path} component={areaRoute.component} />
      <ErrorBoundaryRoute path={coverageRoute.path} component={coverageRoute.component} />
      <ErrorBoundaryRoute path={coveragesRoute.path} component={coveragesRoute.component} />
      <ErrorBoundaryRoute path={configureConfigurationSatelliteRoute.path} component={configureConfigurationSatelliteRoute.component} />
      <ErrorBoundaryRoute path={editSatelliteRoute.path} component={editSatelliteRoute.component} />
      <ErrorBoundaryRoute path={configurationsSatelliteRoute.path} component={configurationsSatelliteRoute.component} />
      <ErrorBoundaryRoute path={configureGatewayRoute.path} component={configureGatewayRoute.component} />
      <ErrorBoundaryRoute path={configurationsRoute.path} component={configurationsRoute.component} />
      <ErrorBoundaryRoute path={configureSatelliteTypeRoute.path} component={configureSatelliteTypeRoute.component} />
      <ErrorBoundaryRoute path={configureSatellitePositionRoute.path} component={configureSatellitePositionRoute.component} />
      <ErrorBoundaryRoute path={configureSatelliteGatewaysRoute.path} component={configureSatelliteGatewaysRoute.component} />
      <ErrorBoundaryRoute path={configureSatelliteReflectorRoute.path} component={configureSatelliteReflectorRoute.component} />
      <ErrorBoundaryRoute path={exportsSatelliteRoute.path} component={exportsSatelliteRoute.component} />
      <ErrorBoundaryRoute path={exportsRoute.path} component={exportsRoute.component} />
      <ErrorBoundaryRoute path={poiRoute.path} component={poiRoute.component} />
      <ErrorBoundaryRoute path={poisRoute.path} component={poisRoute.component} />
      <ErrorBoundaryRoute path={gatewayRoute.path} component={gatewayRoute.component} />
      <ErrorBoundaryRoute path={gatewaysRoute.path} component={gatewaysRoute.component} />
      <ErrorBoundaryRoute path={missionsRoute.path} component={missionsRoute.component} />
      <ErrorBoundaryRoute path={configureAreaOfInteretsRoute.path} component={configureAreaOfInteretsRoute.component} />
      <ErrorBoundaryRoute path={allAreaOfInteretsRoute.path} component={areaOfInteretsRoute.component} />
      <ErrorBoundaryRoute path={areaOfInteretsRoute.path} component={areaOfInteretsRoute.component} />
      <ErrorBoundaryRoute path={missionRoute.path} component={missionRoute.component} />
      <ErrorBoundaryRoute path={satellitesRoute.path} component={satellitesRoute.component} />
      <PrivateRoute path={chartResultRoute.path} component={chartResultRoute.component} />
      <PrivateRoute path={resultsRoute.path} component={resultsRoute.component} />
      <PrivateRoute path={projectPageRoute.path} component={projectPageRoute.component} />
      <PrivateRoute path={profilPageRoute.path} component={profilPageRoute.component} />
      <PrivateRoute path="/" exact component={Home} />
    </Switch>
  );
};

export default Routes;
