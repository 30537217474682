import classnames from 'classnames';
import { IRootState } from 'config/store';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Export } from 'shared/model/export.model';
import { Mission } from 'shared/model/mission.model';
import { selectExport, selectMission } from 'shared/reducers/exportSlice';
import { resultsRoute } from 'shared/routes/routes';
import './exportResultItem.scss';

interface IExportResultItemProps {
  item: Export;
  mission: Partial<Mission>;
}

const ExportResultItem = (props: IExportResultItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const exportItem = useSelector(({ exporter }: IRootState) => exporter.export);
  const selectedMission = useSelector(({ exporter }: IRootState) => exporter.mission);

  const { projectId } = useParams<{ projectId: string }>();

  const { item, mission } = props;

  const selected = item.id === exportItem?.id && mission.id === selectedMission?.id;

  const onClick = async () => {
    if (!selected) {
      await dispatch(selectMission(mission));
      dispatch(selectExport(item));
    } else {
      dispatch(selectMission(null));
      dispatch(selectExport(null));
      history.push(resultsRoute.getPath(projectId));
    }
  };

  const dateStr = moment(item.date).format('DD/MM/YYYY HH:mm');

  return (
    <div className={classnames('export-result-item', { selected })} onClick={onClick}>
      <div>{item.configuration.name}</div>
      <div className="text-muted small">{dateStr}</div>
    </div>
  );
};

export default ExportResultItem;
