import { ChangeEvent, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { MissionUserTerminal } from 'shared/model/mission.model';
import './EditTerminalPercent.scss';

interface IEditTerminalPercentProps {
  item: MissionUserTerminal;
  onChange: (value: number) => void;
}

const EditTerminalPercent = (props: IEditTerminalPercentProps) => {
  const { t } = useTranslation('translation');

  const { item } = props;

  const [value, setValue] = useState<number>(item.repartitionPercentage);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.length > 0 ? Number(e.target.value) : 0;
    setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <InputGroup className="d-flex align-items-center text-info">
      <SkyTTextInput
        className="terminal-percent-input"
        size="sm"
        type="number"
        placeholder={t('mission.terminalPercent')}
        value={value}
        onChange={onChange}
        min={0}
        max={100}
      />
      <span>&nbsp;%</span>
    </InputGroup>
  );
};

export default EditTerminalPercent;
