import { IRootState } from 'config/store';
import { useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { saveMission } from 'shared/reducers/missionSlice';
import { sortByLabel, sortByName } from 'shared/utils/array-utils';
import { getAvailableUserFrequencyBands, projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTSelect, { ICategoryLabelOptions, ILabelValueOption } from 'shared/widgets/form/skyTSelect';
import NoResult from 'shared/widgets/no-result';
import FrequencyItem from '../FrequencyItem';
import MissionFrequencyBandWarningIcon from '../shared/missionFrequencyBandWarningIcon';
import FrequencyBandFilters from './frequencyBandFilter/FrequencyBandFilters';

const SelectMissionFrequencyBand = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const bandFilter = useSelector(({ mission }: IRootState) => mission.bandFilter);
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const project = useSelector(({ project }: IRootState) => project.project);
  const hasWriteAccess = projectHasWriteAccess(project);

  const [value, setValue] = useState<any>();

  if (selectedMissions.length !== 1) {
    return null;
  }

  const mission = selectedMissions[0];

  const usersFrequencyBand = getAvailableUserFrequencyBands(referential, mission.isCivil)
    .filter(item => !mission.userFrequencies.some(ut => ut.name === item.name))
    .filter(item => !bandFilter || item.category === bandFilter);

  const userFrequencyOptions = usersFrequencyBand.reduce((agg: ICategoryLabelOptions<string>[], current) => {
    const currentCategory = current.category;
    let existingCategory = agg.find(item => item.label === currentCategory);
    if (!existingCategory) {
      existingCategory = {
        label: currentCategory,
        options: [] as ILabelValueOption<string>[]
      };
      agg.push(existingCategory);
    }
    existingCategory.options.push({
      label: current.name,
      value: current.name
    });
    return agg;
  }, []);

  userFrequencyOptions.sort(sortByLabel());

  const onChange = (selection: ILabelValueOption<string>) => {
    const selected = usersFrequencyBand.find(item => item.name === selection.value);

    const newUserFrequencies = [...mission.userFrequencies];
    if (selected) {
      newUserFrequencies.push({
        name: selected.name,
        isCivil: selected.isCivil
      });

      const toSend: Mission = {
        ...mission,
        userFrequencies: newUserFrequencies
      };
      dispatch(saveMission(mission.projectId, toSend));
      setValue(null);
    }
  };

  const ordered = [...mission.userFrequencies].sort(sortByName());

  return (
    <Form.Group className="mb-4">
      <Form.Label className="mb-2">
        {t('translation:mission.frequencies')}
        <Badge pill variant="info" className="ml-2">
          {mission.userFrequencies.length}
        </Badge>
        <MissionFrequencyBandWarningIcon mission={mission as MissionDetails} />
      </Form.Label>
      {hasWriteAccess && <FrequencyBandFilters mission={mission as MissionDetails} />}
      {mission.userFrequencies.length === 0 ? (
        <>{!hasWriteAccess && <NoResult />}</>
      ) : (
        <Scrollbars autoHeight autoHeightMax={200} className="skyt-scroll-bar mb-2">
          {ordered.map(item => (
            <FrequencyItem key={item.name} item={item} readonly={!hasWriteAccess} />
          ))}
        </Scrollbars>
      )}
      {hasWriteAccess && userFrequencyOptions.length > 0 && (
        <SkyTSelect
          value={value}
          options={userFrequencyOptions}
          placeholder="Add a frequency band"
          onChange={onChange}
          isDisabled={!bandFilter}
        />
      )}
    </Form.Group>
  );
};
export default SelectMissionFrequencyBand;
