import ArcGisMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import { IRootState } from 'config/store';
import ResultMap from 'modules/results/commons/ResultMap';
import { useSelector } from 'react-redux';
import { IBooleanBeamMapModel } from 'shared/model/results.model';
import initBooleanBeamMap from './booleanBeamMap-utils';

const ResultBooleanBeamMap = () => {
  const currentExport = useSelector(({ exporter }: IRootState) => exporter.export);
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);

  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const partialMission = useSelector(({ exporter }: IRootState) => exporter.mission);

  const satLng = currentExport ? currentExport.configuration.satellite.orbitalPosition : 0;

  const initLayer = (map: ArcGisMap, view: MapView | SceneView) => {
    if (partialMission) {
      const mission = missions.find(mission => mission.id === partialMission.id);
      if (mission) {
        const layer = initBooleanBeamMap(currentChart as IBooleanBeamMapModel, satLng, mission.missionLetter);
        map.add(layer);
        setTimeout(() => {
          view.goTo(layer.source);
        }, 500);
      }
    }
  };

  return <ResultMap onMapCreation={initLayer} showLegend></ResultMap>;
};

export default ResultBooleanBeamMap;
