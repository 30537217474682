import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { EventKey } from 'react-bootstrap/esm/types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import useAuthentication from 'shared/landing/useAuthentication';
import BackButton from 'shared/widgets/buttons/backButton';
import ContactForm from './ContactForm';
import LegalInformation from './LegalInformation';
import './profilPage.scss';
import Settings from './Settings';

const tabQueryParam = 'tab';

const scrollBarStyle = {
  height: 'calc(100vh - 25rem)'
};

let itemsDisplayed: string[] = [];

const ProfilPage = () => {
  const { t } = useTranslation('translation');
  const { userInfo } = useAuthentication();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const tabParam = params.get(tabQueryParam);
  const defaultKey = tabParam ? tabParam : 'contact';
  const [key, setKey] = useState<string | null>(defaultKey);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get(tabQueryParam);
    if (tabParam) {
      setKey(tabParam);
    }
  }, [location]);

  const items = useMemo(() => {
    return [
      {
        text: t('profil.contactInfo'),
        key: 'contact',
        content: <ContactForm />
      },
      {
        text: t('profil.settings'),
        key: 'settings',
        content: <Settings />
      },
      {
        text: t('profil.legal'),
        key: 'legal',
        content: <LegalInformation />
      }
    ];
  }, [t]);

  useEffect(() => {
    itemsDisplayed = ['contact'];
    return () => {
      itemsDisplayed = [];
    };
  }, []);

  if (!userInfo) {
    return null;
  }

  const onSelect = (i: string | null) => {
    setKey(i);
    if (i) {
      itemsDisplayed.push(i);

      const params = new URLSearchParams(location.search);
      params.set(tabQueryParam, i);

      history.push({ search: '?' + params });
    }
  };

  return (
    <div className="profil-page">
      <Tab.Container activeKey={key as EventKey} onSelect={onSelect}>
        <Nav>
          {items.map(item => (
            <Nav.Item key={item.key}>
              <Nav.Link eventKey={item.key}>
                <h3 className="display-3">{item.text}</h3>
                <hr
                  className={classnames('animate__animated', {
                    'd-none': !itemsDisplayed.includes(item.key) && key !== item.key,
                    animate__fadeOut: key !== item.key,
                    animate__fadeIn: key === item.key
                  })}
                />
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Tab.Content>
          {items.map(item => (
            <Tab.Pane eventKey={item.key} key={item.key}>
              <Scrollbars autoHide style={scrollBarStyle} className="skyt-scroll-bar">
                {item.content}
              </Scrollbars>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
      <div className="text-center mt-2">
        <BackButton />
      </div>
    </div>
  );
};

export default ProfilPage;
