import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faComment, faCompass, faEyeSlash, faStar as farStar, faTimesCircle, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faBars,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronUp,
  faClone,
  faCog,
  faCopy,
  faCrosshairs,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faGlobeAfrica,
  faInfo,
  faInfoCircle,
  faLayerGroup,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faPencilAlt,
  faPlus,
  faPowerOff,
  faRedo,
  faSatellite,
  faSearch,
  faSignal,
  faStar,
  faTimes,
  faUndo,
  faUpload,
  faWater
} from '@fortawesome/free-solid-svg-icons';

export const loadIcons = () => {
  library.add(faSatellite);
  library.add(faSignal);
  library.add(faClone);
  library.add(faCalendar);
  library.add(faInfo);
  library.add(faMapMarkerAlt);
  library.add(faAngleRight);
  library.add(faAngleLeft);
  library.add(faArrowRight);
  library.add(faStar);
  library.add(faSearch);
  library.add(farStar);
  library.add(faPlus);
  library.add(faPencilAlt);
  library.add(faTimesCircle);
  library.add(faClock);
  library.add(faComment);
  library.add(faBars);
  library.add(faMinus);
  library.add(faCompass);
  library.add(faLayerGroup);
  library.add(faCrosshairs);
  library.add(faMapMarkedAlt);
  library.add(faChevronUp);
  library.add(faChevronDown);
  library.add(faTimes);
  library.add(faExclamationTriangle);
  library.add(faUser);
  library.add(faCog);
  library.add(faPowerOff);
  library.add(faInfoCircle);
  library.add(faUpload);
  library.add(faCheck);
  library.add(faGlobeAfrica);
  library.add(faEye);
  library.add(faEyeSlash);
  library.add(faWater);
  library.add(faArrowsAlt);
  library.add(faUndo);
  library.add(faRedo);
  library.add(faCog);
  library.add(faArrowLeft);
  library.add(faExclamation);
  library.add(faCopy);
};
