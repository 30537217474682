import ArcGisMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import { IRootState } from 'config/store';
import { addAndOrderLayer } from 'modules/map/layers/layerOrder';
import ResultMap from 'modules/results/commons/ResultMap';
import { useSelector } from 'react-redux';
import { IBeamMapModel, IHeatMapModel } from 'shared/model/results.model';
import HeatMapLegend from '../heatMap/heatMap-legend';
import initBeamMap from './beamMap-utils';

const ResultBeamMap = () => {
  const currentExport = useSelector(({ exporter }: IRootState) => exporter.export);
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);

  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const partialMission = useSelector(({ exporter }: IRootState) => exporter.mission);

  const satLng = currentExport ? currentExport.configuration.satellite.orbitalPosition : 0;

  const initLayer = (map: ArcGisMap, view: MapView | SceneView) => {
    if (partialMission) {
      const mission = missions.find(mission => mission.id === partialMission.id);
      if (mission) {
        const layer = initBeamMap(currentChart as IBeamMapModel, satLng, mission.missionLetter);
        addAndOrderLayer(map, layer);
        setTimeout(() => {
          view.goTo(layer.source);
        }, 500);
      }
    }
  };

  return (
    <ResultMap onMapCreation={initLayer}>
      <HeatMapLegend model={currentChart as IHeatMapModel} />
    </ResultMap>
  );
};

export default ResultBeamMap;
