import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import { DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IInputError {
  name: string;
  errors: DeepMap<any, FieldError>;
  className?: string;
  maxLength?: number;
}

const InputError = (props: IInputError) => {
  const { t } = useTranslation('translation');
  const { name, errors, maxLength = 255, className } = props;
  const error = errors[name];

  if (!error) {
    return null;
  }

  return (
    <Form.Text className={classnames('text-danger', 'mt-2', className)}>
      {error.type === 'required' && t('fieldRequired')}
      {error.type === 'maxLength' && t('maxlength', { nb: maxLength })}
      {error.type === 'pattern' && <>{error.message}</>}
    </Form.Text>
  );
};

export default InputError;
