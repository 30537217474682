// import webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { POI } from 'shared/model/poi.model';
import { poiRoute } from 'shared/routes/routes';
import SkyTButton from 'shared/widgets/buttons/skyTButton';

interface IEditPoiProps {
  poi: POI;
}

const EditPoiBtn = (props: IEditPoiProps) => {
  const history = useHistory();

  const { projectId } = useParams<{ projectId: string }>();

  const { poi } = props;

  const onEdit = () => {
    history.push(poiRoute.getPath(projectId, poi.id));
  };

  return (
    <SkyTButton variant="link" onClick={onEdit} size="sm" className="text-primary">
      <FontAwesomeIcon icon="pencil-alt" />
    </SkyTButton>
  );
};

export default EditPoiBtn;
