import classnames from 'classnames';
import SatelliteCardTitle from 'modules/needs/satellites/satelliteCardTitle';
import { default as React, useContext } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { SatelliteExportResults } from 'shared/model/export.model';
import { Mission } from 'shared/model/mission.model';
import ExportResultItem from './ExportResultItem';
import './ResultMenuItem.scss';

export const resultSatItemPrefix = 'resultSatItem';

interface IResultMenuItemProps {
  item: SatelliteExportResults;
  mission: Partial<Mission>;
}

const ResultMenuItem = (props: IResultMenuItemProps) => {
  const { item, mission } = props;

  const satellite = item.satellite;

  const eventKey = `${resultSatItemPrefix}_${satellite.id}`;
  const currentEventKey = useContext(AccordionContext);
  const isCurrentTab = currentEventKey === eventKey;

  const ordered = [...item.exportItems].sort((e1, e2) => {
    const d1 = new Date(e1.date);
    const d2 = new Date(e2.date);
    return d2.getTime() - d1.getTime();
  });

  return (
    <div className="result-menu-item hover-white unselectable">
      <div className={classnames('result-satellite-item-title', { 'is-open': isCurrentTab })}>
        <SatelliteCardTitle item={satellite} />
      </div>
      <Scrollbars autoHeight autoHeightMax={200} className="skyt-scroll-bar">
        <div className="p-1">
          {ordered.map(exportItem => (
            <ExportResultItem key={exportItem.id + '_exportITem'} item={exportItem} mission={mission} />
          ))}
        </div>
      </Scrollbars>
    </div>
  );
};

export default ResultMenuItem;
