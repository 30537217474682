import classnames from 'classnames';
import { IRootState } from 'config/store';
import Navbar from 'react-bootstrap/Navbar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './header.scss';
import logo from './Logo.svg';
import ProfilMenu from './profilMenu';

const Header = () => {
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);

  return (
    <Navbar className="skyt-header">
      <Navbar.Brand as={Link} to="/" className="flex-grow-1">
        <img src={logo} height="30" className={classnames('d-inline-block align-top logo', { map: mapIsDisplayed })} alt="SkyT" />
      </Navbar.Brand>

      <ProfilMenu />
    </Navbar>
  );
};

export default Header;
