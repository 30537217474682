import classnames from 'classnames';
import React, { FC, useContext, useEffect } from 'react';
import { Accordion, AccordionContext, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'shared/utils/hook-utils';
import './skyTAccordionTab.scss';
import SkyTAccordionToggle from './skyTAccordionToggle';

interface ISkyTAccordionTabProps {
  eventKey: string;
  title: React.ReactNode;
  className?: string;
  bodyClassName?: string;
  queryParam?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

const SkyTAccordionTab: FC<ISkyTAccordionTabProps> = props => {
  const history = useHistory();
  const params = useQueryParams();

  const currentEventKey = useContext(AccordionContext);

  const { eventKey, title, className, queryParam, bodyClassName, disabled } = props;

  useEffect(() => {
    if (queryParam && currentEventKey === eventKey) {
      params.set(queryParam, eventKey);
      history.push({
        search: '?' + params.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventKey, currentEventKey]);

  return (
    <Card className={classnames('skyT-accordion-tab', className)}>
      <SkyTAccordionToggle eventKey={eventKey} title={title} disabled={disabled} />
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className={bodyClassName}>{props.children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default SkyTAccordionTab;
