import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../loader';
import ConfirmDialog from './ConfirmDialog';

interface IConfirmDeleteDialogProps {
  show: boolean;
  waiting?: boolean;
  className?: string;
  dialogTitle?: string | React.ReactNode;
  dialogContent?: string | React.ReactNode;
  dialogCancel?: string | React.ReactNode;
  dialogConfirm?: string | React.ReactNode;
  onClose: (confirm: boolean) => void;
}

const ConfirmDeleteDialog = (props: IConfirmDeleteDialogProps) => {
  const { t } = useTranslation('translation');
  const { dialogTitle = t('confirmDeleteTitle'), dialogContent = t('confirmDelete'), waiting = false, ...rest } = props;

  const content = waiting ? <Loader /> : dialogContent;

  return <ConfirmDialog dialogTitle={dialogTitle} dialogContent={content} disabled={waiting} {...rest} />;
};

export default ConfirmDeleteDialog;
