import Point from '@arcgis/core/geometry/Point';
import classNames from 'classnames';
import { IRootState } from 'config/store';
import isNil from 'lodash/isNil';
import { tempGatewayLayer } from 'modules/map/layers/gateway/gateway-layer-def';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { GatewayModel } from 'shared/model/gateway-model.model';
import { Gateway, GatewayBandWidth } from 'shared/model/gateway.model';
import { reset as resetGatewayEdition, saveGateway, setGatewayModel } from 'shared/reducers/GatewaySlice';
import { fetchProject } from 'shared/reducers/projectSlice';
import { gatewaysRoute } from 'shared/routes/routes';
import { sortByName } from 'shared/utils/array-utils';
import { getAvailableGatewaysFrequencyBands } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import ImageFromUrl from 'shared/widgets/images/ImageFromUrl';
import EditGatewayFrequencyBandwidth from './EditGatewayFrequencyBandwidth';
import './gatewaymodel-item.scss';
import GatewayPolarization from './gatewayPolarization';

interface IGatewayModelItemProps {
  item: GatewayModel;
}

const GatewayModelItem = (props: IGatewayModelItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const selectedGatewayModel = useSelector(({ gateway }: IRootState) => gateway.selectedGatewayModel);
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const underEditionFeature = useSelector(({ gateway }: IRootState) => gateway.underEditionFeature);
  const selectedGatewayBandwidth = useSelector(({ gateway }: IRootState) => gateway.selectedGatewayBandwidth);
  const selectedPolorization = useSelector(({ gateway }: IRootState) => gateway.selectedPolorization);
  const updateSuccess = useSelector(({ gateway }: IRootState) => gateway.updateSuccess);
  const { projectId } = useParams<{ projectId: string }>();

  const { item } = props;

  const selected = selectedGatewayModel?.id === item.id;

  const onClick = () => {
    dispatch(setGatewayModel(item));
  };

  const onCancel = () => {
    tempGatewayLayer.removeAll();
    const newHistory = gatewaysRoute.getPath(projectId);
    history.push(newHistory);
  };

  const onSave = async () => {
    if (underEditionFeature && !isNil(selectedPolorization)) {
      const name = underEditionFeature.getAttribute('name');
      const geometry = underEditionFeature.geometry as Point;
      if (geometry) {
        const bandwidths = selectedGatewayBandwidth ? [selectedGatewayBandwidth] : [];
        const toSave: Partial<Gateway> = {
          name,
          longitude: geometry.longitude,
          latitude: geometry.latitude,
          modelId: item.id,
          bandwidths,
          polarization: selectedPolorization
        };
        if (underEditionFeature.getAttribute('id')) {
          toSave.id = underEditionFeature.getAttribute('id');
        }
        await dispatch(saveGateway(projectId, toSave));
      }
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      tempGatewayLayer.removeAll();
      dispatch(resetGatewayEdition());
      dispatch(fetchProject(projectId));
      const newHistory = gatewaysRoute.getPath(projectId);
      history.push(newHistory);
    }
  }, [dispatch, history, projectId, updateSuccess]);

  const bands = selected ? [...getAvailableGatewaysFrequencyBands(referential)] : [];

  bands.sort(sortByName());

  return (
    <div className={classNames('gateway-model-item hover-info', { selected })}>
      <div className="gateway-model-item-title" onClick={onClick}>
        <div className="mr-2">{item.image && <ImageFromUrl url={item.image} height="32px" />}</div>
        <div>{item.name}</div>
      </div>
      {selected && bands && (
        <div>
          <div className="mt-2">
            {bands.map(band => {
              const item: GatewayBandWidth = {
                ...band,
                bandwidth: 0
              };

              return <EditGatewayFrequencyBandwidth key={`edit-gateway-${item.name}`} item={item} />;
            })}
          </div>
          <div>
            <GatewayPolarization />
          </div>
          <div className="text-right mt-2">
            <SkyTButton type="button" size="sm" variant="outline-info" onClick={onCancel} className="mr-2">
              {t('cancel')}
            </SkyTButton>
            <SkyTButton type="button" size="sm" variant="outline-info" onClick={onSave} disabled={selectedPolorization === null}>
              {t('save')}
            </SkyTButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default GatewayModelItem;
