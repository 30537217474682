import Polygon from '@arcgis/core/geometry/Polygon';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import ColorVariable from '@arcgis/core/renderers/visualVariables/ColorVariable';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { resultLayerId, resultMapColors } from 'modules/results/commons/resultConstants';
import numeral from 'numeral';
import { IHeatMapModel } from 'shared/model/results.model';

export const LEGEND_FORMAT = '0,0.[00]';

const SPACING_DEGREES = 0.25;

const defaultSym = new SimpleFillSymbol({
  outline: {
    color: [128, 128, 128, 0.2],
    width: '0.5px'
  }
});

export const initHeatMap = (model: IHeatMapModel) => {
  const step = (model.maxValue - model.minValue) / resultMapColors.length;

  const stops = resultMapColors.map((color, i) => {
    const edge = i === 0 ? model.minValue : model.minValue + (i + 1) * step;
    const val = numeral(edge);
    const label = i === 0 ? '>= ' + val.format(LEGEND_FORMAT) : '<= ' + val.format(LEGEND_FORMAT);

    return {
      color,
      label,
      value: val.value() as number
    };
  });

  const colorVariable = new ColorVariable({
    field: 'val',
    stops
  });

  const renderer = new SimpleRenderer({
    symbol: defaultSym,
    label: model.name,
    visualVariables: [colorVariable]
  });

  const graphics = makeGeometries(model);

  const layer = new FeatureLayer({
    id: resultLayerId,
    spatialReference: SpatialReference.WGS84,
    title: resultLayerId,
    renderer,
    source: graphics,
    geometryType: 'polygon',
    objectIdField: 'id',
    opacity: 0.7,
    fields: [
      {
        name: 'id',
        type: 'string'
      },
      {
        name: 'val',
        type: 'double'
      },
      {
        name: 'max',
        type: 'double'
      }
    ],
    popupTemplate: {
      title: '',
      content: '{val}',
      outFields: ['*']
    }
  });

  return layer;
};

const makeGeometries = (model: IHeatMapModel) => {
  const spacing = SPACING_DEGREES / 2.0;

  return model.values.map((value, i) => {
    const a = [value.lng - spacing, value.lat - spacing];
    const b = [value.lng - spacing, value.lat + spacing];
    const c = [value.lng + spacing, value.lat + spacing];
    const d = [value.lng + spacing, value.lat - spacing];

    const polygon = new Polygon({
      rings: [[a, b, c, d]],
      spatialReference: SpatialReference.WGS84
    });

    return new Graphic({
      geometry: polygon,
      attributes: {
        id: i.toString(),
        val: value.val,
        max: model.maxValue
      }
    });
  });
};
