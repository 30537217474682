import { SearchedUser, User } from 'shared/model/user.model';
import './user-avatar.scss';

interface IUserAvatarProps {
  user: User | SearchedUser;
}

const UserAvatar = (props: IUserAvatarProps) => {
  const { user } = props;
  const trigramme = user.firstName.charAt(0) + user.lastName.charAt(0) + user.lastName.charAt(1);

  return <div className="user-avatar">{trigramme}</div>;
};

export default UserAvatar;
