import { IDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';

export interface Area {
  id: number;
  coverageId: number;
  areaOfInterestId: number;
  beamList?: IDatabaseBeam[];
  createdAt?: Date;
}

export interface CoverageBeamSettings {
  size: number;
  gridOrientation: number;
  xOffset: number;
  yOffset: number;
  steerableBeamNumber: number;
}

export const defaultCoverageBeamSettings: CoverageBeamSettings = {
  size: 1,
  gridOrientation: 0,
  xOffset: 0,
  yOffset: 0,
  steerableBeamNumber: 2
};
