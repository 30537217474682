import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { useDispatch, useSelector } from 'react-redux';
import { Mission } from 'shared/model/mission.model';
import { selectMissions } from 'shared/reducers/missionSlice';

interface IMissionPanelItemProps {
  mission: Mission;
}

const MissionPanelItem = (props: IMissionPanelItemProps) => {
  const dispatch = useDispatch();
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);

  const { mission } = props;

  const isSelected = selectedMissions.some(item => item.id === mission.id);

  const icon: IconProp = isSelected ? 'eye' : ['far', 'eye-slash'];

  const onSelectMission = () => {
    if (isSelected) {
      const newSelection = selectedMissions.filter(item => item.id !== mission.id);
      dispatch(selectMissions(newSelection));
    } else {
      const newSelection = [...selectedMissions, mission];
      dispatch(selectMissions(newSelection));
    }
  };

  return (
    <div className="mission-item hover-white py-1" onClick={onSelectMission}>
      <div className="d-flex align-items-center" style={{ color: mission.color }}>
        <div>
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {mission.name}
        </div>
      </div>
    </div>
  );
};

export default MissionPanelItem;
