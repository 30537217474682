import { isDebug } from 'config/env';
import PerformanceInfo from './performanceInfo';

const DebugInfo = () => {
  if (!isDebug()) {
    return null;
  }

  return <PerformanceInfo />;
};

export default DebugInfo;
