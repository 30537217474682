import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk } from 'config/store';
import { IChartModel, IExportResult } from 'shared/model/results.model';

const initialState = {
  loading: false,
  charts: [] as IChartModel[],
  currentChart: null as IChartModel | null,
  error: null as any
};

export type ResultsState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    fetchChartsStart: state => {
      state.loading = true;
      state.currentChart = null;
    },
    fetchChartsFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchChartsSuccess: (state, action: PayloadAction<IChartModel[]>) => {
      state.loading = false;
      state.charts = action.payload;
    },
    selectCharts: (state, action: PayloadAction<IChartModel | null>) => {
      state.currentChart = action.payload;
    },
    reset: state => {
      state.loading = false;
      state.charts = [];
      state.currentChart = null;
      state.error = null;
    }
  }
});

const { fetchChartsStart, fetchChartsFailed, fetchChartsSuccess } = slice.actions;

export const { reset, selectCharts } = slice.actions;

export const fetchCharts =
  (exportResult: IExportResult): AppThunk =>
  async (dispatch, getState) => {
    try {
      const currentChartName = getState().charts.currentChart ? getState().charts.currentChart?.name : '';

      dispatch(fetchChartsStart());
      const requests = exportResult.graphList.map(graph => axios.get<IChartModel>(graph.blobPath));
      const responses = await Promise.all(requests);
      const data = responses.map(response => {
        const graphLoc = exportResult.graphList.find(aGraph => aGraph.blobPath === response.config.url);
        const name = graphLoc ? graphLoc.name : response.data.name;
        return {
          ...response.data,
          name,
          blobPath: graphLoc ? graphLoc.blobPath : ''
        };
      });

      if (data.length > 0) {
        // select previous if exists else the first one
        let toSelect = data.find(item => item.name === currentChartName);
        if (!toSelect) {
          toSelect = data[0];
        }
        dispatch(selectCharts(toSelect));
      }

      dispatch(fetchChartsSuccess(data));
    } catch (error) {
      dispatch(fetchChartsFailed(error));
    }
  };

export default slice.reducer;
