import { IRootState } from 'config/store';
import { useSelector } from 'react-redux';
import FwdRtnRatioSlider from '../sliders/FwdRtnRatioSlider';
import TargetCapacityPerBeamSlider from '../sliders/TargetCapacityPerBeamSlider';

const EditTargetSystem = () => {
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);

  const disabled = underEditionBeams.every(item => !item.selected);

  // defaults values
  let defaultCapacity = undefined;
  let defaultRatio = undefined;
  // check same value
  const beamsToCheck = underEditionBeams.filter(beam => beam.selected);

  if (beamsToCheck.length > 0 && beamsToCheck[0].sys) {
    if (beamsToCheck.every(underEdition => underEdition.sys?.c === beamsToCheck[0].sys?.c)) {
      defaultCapacity = beamsToCheck[0].sys.c;
    }
    if (beamsToCheck.every(underEdition => underEdition.sys?.r === beamsToCheck[0].sys?.r)) {
      defaultRatio = beamsToCheck[0].sys.r;
    }
  }

  return (
    <div className="h-100 d-flex flex-column justify-content-around ">
      <TargetCapacityPerBeamSlider disabled={disabled} defaultValue={defaultCapacity} />
      <FwdRtnRatioSlider disabled={disabled} defaultValue={defaultRatio} />
    </div>
  );
};

export default EditTargetSystem;
