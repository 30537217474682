import LabelClass from '@arcgis/core/layers/support/LabelClass';
import { Font } from '@arcgis/core/symbols';

export const DefaultFont = new Font({
  family: 'Montserrat',
  size: 12,
  style: 'normal',
  weight: 'normal'
});

export const DefaultFeatureNamelabelClass = new LabelClass({
  symbol: {
    type: 'text',
    color: '#ffffff',
    haloColor: '#1b2a6f',
    haloSize: '1px',
    font: DefaultFont
  },
  labelPlacement: 'above-center',
  labelExpressionInfo: {
    expression: '$feature.name'
  }
});
