import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Gateway } from 'shared/model/gateway.model';
import { saveGateway } from 'shared/reducers/GatewaySlice';
import SkyTButton from 'shared/widgets/buttons/skyTButton';

interface ICopyGatewayBtnProps {
  gateway: Gateway;
}

const CopyGatewayBtn = (props: ICopyGatewayBtnProps) => {
  const dispatch = useDispatch();

  const { projectId } = useParams<{ projectId: string }>();

  const { gateway } = props;

  const onCopy = (e: SyntheticEvent) => {
    e.stopPropagation();

    const copy: Partial<Gateway> = {
      ...gateway,
      id: undefined,
      name: gateway.name + '_copy',
      longitude: gateway.longitude + 1,
      latitude: gateway.latitude - 1
    };
    dispatch(saveGateway(projectId, copy));
  };

  return (
    <SkyTButton variant="link" onClick={onCopy} size="sm" className="text-primary">
      <FontAwesomeIcon icon="copy" />
    </SkyTButton>
  );
};

export default CopyGatewayBtn;
