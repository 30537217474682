import classnames from 'classnames';
import Slider, { SliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import './skyTSlider.scss';

const handleStyle = {
  borderColor: '#CCCCCC',
  height: 24,
  width: 24,
  backgroundColor: 'white',
  marginTop: -10
};

const enabletrackStyle = { backgroundColor: '#53b8d9' };
const disabledTrackStyle = { backgroundColor: '#3a8097' };
const railStyle = { backgroundColor: '#CCCCCC' };
const enableDotStyle = {
  width: '1px',
  border: '1px solid #53b8d9',
  // height: '10px',
  borderRadius: 0
};
const disabedDotStyle = {
  ...enableDotStyle,
  border: '1px solid #CCCCCC'
};

interface ISkyTslider extends SliderProps {
  showInputField?: boolean;
  mandatory?: boolean;
}

const SkyTSlider = (props: ISkyTslider) => {
  const { className, showInputField = true, min, max, value, onChange, disabled, mandatory = false, ...rest } = props;

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const newValue = e.target.value ? Number(e.target.value) : 0;
      let valid = true;
      if (min || min === 0) {
        valid = newValue >= min;
      }
      if (valid && (max || max === 0)) {
        valid = newValue <= max;
      }
      if (valid) {
        props.onChange(newValue);
      }
    }
  };

  const trackStyle = disabled ? disabledTrackStyle : enabletrackStyle;
  const dotStyle = disabled ? disabedDotStyle : enableDotStyle;

  const showMandatoryError = mandatory && typeof value === 'undefined' && !disabled;

  return (
    <div className={classnames('skyT-slider', className, { invalid: showMandatoryError })}>
      <Slider
        className="flex-grow-1"
        handleStyle={handleStyle}
        trackStyle={trackStyle}
        railStyle={railStyle}
        dotStyle={dotStyle}
        min={min}
        max={max}
        value={value}
        disabled={disabled}
        onChange={onChange}
        {...rest}
      />
      {showInputField && (
        <Form.Control
          size="sm"
          type="number"
          className="input-slider"
          isInvalid={showMandatoryError}
          min={min}
          max={max}
          value={value}
          onChange={onInputChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default SkyTSlider;
