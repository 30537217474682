import Graphic from '@arcgis/core/Graphic';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { createAreaOfInterest, deleteAreaOfInterest, updateAreaOfInterest } from 'shared/api/api';
import { AreaOfInterest, AreaOfInterestType } from 'shared/model/areaOfInterest.model';

const initialState = {
  underEdition: false,
  typeOfArea: null as AreaOfInterestType | null,
  underEditionFeatures: [] as Graphic[],
  updating: false,
  updateSuccess: false,
  error: null as any
};

export type AreaOfInterestState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'areaOfInterest',
  initialState,
  reducers: {
    setUnderEdition: (state, action: PayloadAction<boolean>) => {
      state.underEditionFeatures = [];
      state.underEdition = action.payload;
    },
    setUnderEditionFeatures: (state, action: PayloadAction<Graphic[]>) => {
      state.underEditionFeatures = action.payload;
    },
    setTypeOfArea: (state, action: PayloadAction<AreaOfInterestType | null>) => {
      state.typeOfArea = action.payload;
    },
    updateStart: state => {
      state.updating = true;
      state.error = null;
      state.updateSuccess = false;
    },
    updateSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    reset: state => {
      state.underEdition = false;
      state.updating = false;
      state.updateSuccess = false;
      state.error = null;
    }
  }
});

export const { setUnderEdition, reset, setUnderEditionFeatures, setTypeOfArea } = slice.actions;

const { updateStart, updateSuccess, updateFailed } = slice.actions;

export const saveAreaOfInterest =
  (projectId: number | string, missionId: number | string, areaOfInterest: Partial<AreaOfInterest>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      if (areaOfInterest.id) {
        await updateAreaOfInterest(projectId, missionId, areaOfInterest);
      } else {
        await createAreaOfInterest(projectId, missionId, areaOfInterest);
      }
      dispatch(updateSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeAreaOfInterest =
  (projectId: number | string, missionId: number | string, areaOfInterestId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteAreaOfInterest(projectId, missionId, areaOfInterestId);
      dispatch(updateSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
