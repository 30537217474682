import Lottie from 'shared/widgets/lottie/Lottie';
import data from '../lotties/availability.json';

const frameNb = 100;
const maxValue = 100;

interface IAvailabilityProps {
  value: number;
}

const AvailabilityIcon = (props: IAvailabilityProps) => {
  const { value } = props;

  const end = value <= maxValue ? (value * frameNb) / maxValue : frameNb;

  return <Lottie animationData={data} initialSegment={[0, end]} loop={false} />;
};

export default AvailabilityIcon;
