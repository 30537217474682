import classnames from 'classnames';
import { resultMapColors } from 'modules/results/commons/resultConstants';
import numeral from 'numeral';
import { IBeamMapModel, IHeatMapModel } from 'shared/model/results.model';
import ColorGradient from 'shared/widgets/legend/ColorGradient';
import { verticalLegend } from '../../commons/resultConstants';
import './heatMap-legend.scss';
import { LEGEND_FORMAT } from './heatMap-utils';

interface IHeatMapLegendProps {
  model: IHeatMapModel | IBeamMapModel;
}

const HeatMapLegend = (props: IHeatMapLegendProps) => {
  const { model } = props;

  const { minValue, maxValue, name } = model;

  const unit = (model as IBeamMapModel).unit;

  const sameValue = model.minValue === model.maxValue;

  const min = numeral(minValue).format(LEGEND_FORMAT);
  const max = numeral(maxValue).format(LEGEND_FORMAT);

  const first = verticalLegend ? max : min;
  const last = verticalLegend ? min : max;

  const colors = sameValue ? resultMapColors.map(() => resultMapColors[0]) : resultMapColors;

  return (
    <div className={classnames('heatmap-legend', { verticalLegend })}>
      <div className="units">
        <div className="text">{first}</div>
        <div className="text">
          {name}
          {unit && <span>&nbsp;({unit})</span>}
        </div>
        <div className="text">{last}</div>
      </div>
      <ColorGradient className="gradient" colors={colors} vertical={verticalLegend} />
    </div>
  );
};
export default HeatMapLegend;
