import { AuthenticationResult, InteractionRequiredAuthError, InteractionStatus, InteractionType, SilentRequest } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { ROLE_APP } from 'config/constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getCurrentUserInfo } from 'shared/api/api';
import { User } from 'shared/model/user.model';
import { IUserInfo } from 'shared/model/userInfo.model';
import { setBearerToken } from 'shared/utils/axios-utils';

// const scopes = ['openid', 'profile', 'User.Read', 'User.ReadBasic.All'];
const scopes = ['User.Read'];

let currentUserInfo = null as IUserInfo | null;
let databaseUser = null as User | null;

// gat in milliseconds before refreshing token expiration
const gapBeforeExpiration = moment.duration({ minutes: 5 });

const useAuthentication = () => {
  useMsalAuthentication(InteractionType.Redirect);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

  const [userInfo, setUserInfo] = useState<IUserInfo | null>(currentUserInfo);
  const [forceRefresh, setForceRefresh] = useState(false);

  const isAuthorized = userInfo?.roles.includes(ROLE_APP);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0 && !currentUserInfo) {
      const accessTokenRequest: SilentRequest = {
        scopes,
        forceRefresh,
        // @ts-ignore
        account: accounts[0]
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse: AuthenticationResult) => {
          const expiresOn = moment(accessTokenResponse.expiresOn);
          const dateToRefresh = expiresOn.subtract(gapBeforeExpiration);
          console.log('Token will be refresh', dateToRefresh.fromNow());

          const expiratationDelay = dateToRefresh.diff(moment(), 'milliseconds');

          if (expiratationDelay > 0) {
            console.log('Program refresh');
            setTimeout(() => {
              console.log('Force Refresh');
              currentUserInfo = null;
              setForceRefresh(true);
            }, expiratationDelay);
          }

          // Acquire token silent success
          // const token = accessTokenResponse.accessToken;
          const token = accessTokenResponse.idToken;
          setBearerToken(token);
          currentUserInfo = accessTokenResponse.idTokenClaims as IUserInfo;
          setUserInfo(currentUserInfo);
          setForceRefresh(false);
        })
        .then(getCurrentUserInfo)
        .then(response => {
          databaseUser = response.data;
        })
        .catch((error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error('Error not handled in msal', error);
        });
    }
  }, [accounts, inProgress, instance, forceRefresh]);

  return {
    isAuthenticated,
    inProgress,
    userInfo,
    accounts,
    msalInstance: instance,
    isAuthorized,
    databaseUser
  };
};

export default useAuthentication;
