import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { Area } from 'shared/model/area.model';
import { AreaOfInterest } from 'shared/model/areaOfInterest.model';
import { Configuration } from 'shared/model/configuration.model';
import { Coverage } from 'shared/model/coverage.model';
import { Customer } from 'shared/model/customer.model';
import { Export } from 'shared/model/export.model';
import { GatewayModel } from 'shared/model/gateway-model.model';
import { Gateway } from 'shared/model/gateway.model';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { Notification } from 'shared/model/notification.model';
import { Organization } from 'shared/model/organization.model';
import { POI } from 'shared/model/poi.model';
import { Project, ProjectDetails } from 'shared/model/project.model';
import { ReferentialData } from 'shared/model/referential.model';
import { SatelliteModel } from 'shared/model/satellite-model.model';
import { ICreateSatellite, Satellite } from 'shared/model/satellite.model';
import { SearchedUser, User } from 'shared/model/user.model';

const apiUrl = getEnvApiUrl();

// ping
export const ping = () => axios.get(`${apiUrl}/`);

// Coverage
export const getCoverageDetails = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverageId: number | string
) =>
  axios.get<Coverage>(
    `${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverageId}`
  );

export const getCoverages = (projectId: number | string, satelliteId: number | string, configurationId: number | string) =>
  axios.get<Coverage[]>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages`);

export const createCoverage = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverage: Partial<Coverage>
) =>
  axios.post<Coverage>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages`, coverage);

export const updateCoverage = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverage: Partial<Coverage>
) => {
  const body = {
    missionId: coverage.missionId,
    coverageType: coverage.coverageType,
    details: coverage.details
  };

  return axios.put<Coverage>(
    `${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverage.id}`,
    body
  );
};

export const deleteCoverage = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverageId: number | string
) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverageId}`);

// Coverage area
export const getAreas = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverageId: number | string
) =>
  axios.get<Area[]>(
    `${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverageId}/areas`
  );

export const getArea = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverageId: number | string,
  areaId: number | string
) =>
  axios.get<Area>(
    `${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverageId}/areas/${areaId}`
  );

export const createArea = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverageId: number | string,
  area: Partial<Area>
) => {
  const body = {
    beamList: JSON.stringify(area.beamList),
    areaOfInterestId: area.areaOfInterestId
  };

  return axios.post<Area>(
    `${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverageId}/areas`,
    body
  );
};

export const updateArea = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverageId: number | string,
  area: Partial<Area>
) => {
  const body = {
    areaOfInterestId: area.areaOfInterestId,
    beamList: JSON.stringify(area.beamList)
  };

  return axios.put<Area>(
    `${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverageId}/areas/${area.id}`,
    body
  );
};

export const deleteArea = (
  projectId: number | string,
  satelliteId: number | string,
  configurationId: number | string,
  coverageId: number | string,
  areaId: number | string
) =>
  axios.delete<void>(
    `${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}/coverages/${coverageId}/areas/${areaId}`
  );

// Configurations
export const getGonfigurations = (projectId: number | string, satelliteId: number | string) =>
  axios.get<Configuration[]>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations`);
export const getGonfigurationDetails = (projectId: number | string, satelliteId: number | string, configurationId: number | string) =>
  axios.get<Configuration>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}`);
export const createConfiguration = (projectId: number | string, satelliteId: number | string, name: string) =>
  axios.post<Configuration>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations`, { name });
export const updateConfiguration = (projectId: number | string, satelliteId: number | string, configuration: Partial<Configuration>) => {
  const body = {
    name: configuration.name
  };

  return axios.put<Configuration>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configuration.id}`, body);
};
export const deleteConfiguration = (projectId: number | string, satelliteId: number | string, configurationId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}/configurations/${configurationId}`);

// TODO attendee

// Area of interets
export const createAreaOfInterest = (projectId: number | string, missionId: number | string, areaOfInterest: Partial<AreaOfInterest>) =>
  axios.post<AreaOfInterest>(`${apiUrl}/projects/${projectId}/missions/${missionId}/area-of-interests`, areaOfInterest);
export const updateAreaOfInterest = (projectId: number | string, missionId: number | string, areaOfInterest: Partial<AreaOfInterest>) => {
  const toSend = { ...areaOfInterest };
  delete toSend.id;
  return axios.put<AreaOfInterest>(`${apiUrl}/projects/${projectId}/missions/${missionId}/area-of-interests/${areaOfInterest.id}`, toSend);
};
export const deleteAreaOfInterest = (projectId: number | string, missionId: number | string, areaOfInterestId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/missions/${missionId}/area-of-interests/${areaOfInterestId}`);

// Customers
export const getCustomers = () => axios.get<Customer[]>(`${apiUrl}/customers`);
export const createCustomer = (customer: Customer) => axios.post<Customer>(`${apiUrl}/customers`, customer);
export const updateCustomer = (customer: Customer) =>
  axios.put<Customer>(`${apiUrl}/customers/${customer.id}`, {
    name: customer.name,
    isOperator: customer.isOperator
  });
export const deleteCustomer = (customerId: number | string) => axios.delete<void>(`${apiUrl}/customers/${customerId}`);

// export project
export const getExports = (projectId: number | string) => axios.get<Export[]>(`${apiUrl}/projects/${projectId}/exports`);
export const createExport = (projectId: number | string, configurationId: number | string) =>
  axios.post<Export>(`${apiUrl}/projects/${projectId}/configurations/${configurationId}/exports`);

// Gateway model
export const getGatewayModels = () => axios.get<GatewayModel[]>(`${apiUrl}/catalogs/gateway-models`);

// Satellite Models
export const getSatelliteModels = () => axios.get<SatelliteModel[]>(`${apiUrl}/catalogs/satellite-models`);

// Gateway
export const createGateway = (projectId: number | string, gateway: Partial<Gateway>) =>
  axios.post<Gateway>(`${apiUrl}/projects/${projectId}/gateways`, gateway);
export const updateGateway = (projectId: number | string, gateway: Partial<Gateway>) => {
  const body = {
    ...gateway
  };
  delete body.id;
  return axios.put<Gateway>(`${apiUrl}/projects/${projectId}/gateways/${gateway.id}`, body);
};
export const deleteGateway = (projectId: number | string, gatewayId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/gateways/${gatewayId}`);

// TODO Media

// TODO Meeting

// Mission
export const getMissions = (projectId: number | string) => axios.get<Mission[]>(`${apiUrl}/projects/${projectId}/missions`);
export const getMissionDetails = (projectId: number | string, missionId: number | string) =>
  axios.get<MissionDetails>(`${apiUrl}/projects/${projectId}/missions/${missionId}`);

export const getMissionsDetailsFromConfig = (projectId: number | string, configurationId: number | string) =>
  axios.get<MissionDetails[]>(`${apiUrl}/projects/${projectId}/missions-configuration/${configurationId}`);
export const createMission = (projectId: number | string, mission: Mission) =>
  axios.post<Mission>(`${apiUrl}/projects/${projectId}/missions`, mission);
export const updateMission = (projectId: number | string, mission: Mission) =>
  axios.put<Mission, AxiosResponse<MissionDetails>>(`${apiUrl}/projects/${projectId}/missions/${mission.id}`, {
    isCivil: mission.isCivil,
    isFlexible: mission.isFlexible,
    name: mission.name,
    services: mission.services,
    userFrequencies: mission.userFrequencies,
    userTerminals: mission.userTerminals,
    color: mission.color,
    missionLetter: mission.missionLetter
  });
export const deleteMission = (projectId: number | string, missionId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/missions/${missionId}`);

// Organization
export const createOrganization = (projectId: number | string, organization: Organization) =>
  axios.post<Organization>(`${apiUrl}/projects/${projectId}/organizations`, organization);
export const updateOrganization = (projectId: number | string, organization: Organization) =>
  axios.put<Organization>(`${apiUrl}/projects/${projectId}/organizations/${organization.id}`, {
    name: organization.name
  });
export const deleteOrganization = (projectId: number | string, organizationId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/organizations/${organizationId}`);

// TODO Planning

// POI
export const createPoi = (projectId: number | string, poi: Partial<POI>) => axios.post<POI>(`${apiUrl}/projects/${projectId}/pois`, poi);
export const updatePoi = (projectId: number | string, poi: Partial<POI>) => {
  const body = { ...poi };
  delete body.id;
  return axios.put<POI>(`${apiUrl}/projects/${projectId}/pois/${poi.id}`, body);
};
export const deletePoi = (projectId: number | string, poiId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/pois/${poiId}`);

// Projects
export const getFavoritesProjects = () => axios.get<Project[]>(`${apiUrl}/projects/favorites`);
export const getProjectsOfCustomer = (customerId: number) => axios.get<Project[]>(`${apiUrl}/customers/${customerId}/projects/`);
export const updateFavoriteProject = (projectId: number | string) => axios.post<ProjectDetails>(`${apiUrl}/projects/${projectId}/favorite`);
export const getProject = (projectId: number | string) => axios.get<ProjectDetails>(`${apiUrl}/projects/${projectId}`);
export const createProject = (customerId: number | string, project: Project) =>
  axios.post<Project>(`${apiUrl}/customers/${customerId}/projects`, project);
export const updateProject = (project: Project) =>
  axios.put<Project>(`${apiUrl}/projects/${project.id}`, {
    name: project.name,
    description: project.description,
    isConfidential: project.isConfidential
  });
export const deleteProject = (projectId: number | string) => axios.delete<void>(`${apiUrl}/projects/${projectId}`);

export const requestAccessToProject = (projectId: number | string) => axios.post<void>(`${apiUrl}/projects/${projectId}/request-access`);
export const requestMembershipToProject = (projectId: number | string) =>
  axios.post<void>(`${apiUrl}/projects/${projectId}/request-membership`);

// Access request
export const acceptAccessRequest = (requestId: number | string) => axios.post<void>(`${apiUrl}/requests/${requestId}/accept`);
export const denyAccessRequest = (requestId: number | string) => axios.post<void>(`${apiUrl}/requests/${requestId}/deny`);

// Satellite
export const addSatellite = (projectId: number | string, satellite: ICreateSatellite) =>
  axios.post<Satellite>(`${apiUrl}/projects/${projectId}/satellites`, satellite);
export const editSatellite = (projectId: number | string, satellite: Partial<Satellite>) => {
  const body = {
    ...satellite
  };
  delete body.id;
  delete body.createdAt;

  return axios.put<Satellite>(`${apiUrl}/projects/${projectId}/satellites/${satellite.id}`, body);
};
export const deleteSatellite = (projectId: number | string, satelliteId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/satellites/${satelliteId}`);

// Team member
export const addTeamMember = (projectId: number | string, teamMember: User) =>
  axios.post<User>(`${apiUrl}/projects/${projectId}/team-members`, teamMember);
export const editTeamMember = (projectId: number | string, teamMember: Partial<User>) => {
  const toSend = {
    ...teamMember
  };
  delete toSend.id;

  return axios.put<User>(`${apiUrl}/projects/${projectId}/team-members/${teamMember.id}`, toSend);
};

export const deleteTeamMember = (projectId: number | string, teamMemberId: number | string) =>
  axios.delete<void>(`${apiUrl}/projects/${projectId}/team-members/${teamMemberId}`);

// Notifications
export const getMyNotifications = (from: number) => axios.get<Notification[]>(`${apiUrl}/users/notifications?timestamp=${from}`);
export const getProjectNotifications = (projectId: number) => axios.get<Notification[]>(`${apiUrl}/projects/${projectId}/notifications`);

// referential
export const getReferential = () => axios.get<ReferentialData>(`${apiUrl}/referential/data`);

// user
export const getCurrentUserInfo = () => axios.get<User>(`${apiUrl}/users/info`);

// customer lock
export const getCustomerLock = () => axios.get(`${apiUrl}/settings/lock`);
export const lockCustomer = (customerId: number) => axios.post(`${apiUrl}/settings/lock/${customerId}`);
export const unlockCustomer = () => axios.post(`${apiUrl}/settings/unlock`);

export const searchUserByString = (str: string, limit = 20) =>
  axios.get<SearchedUser[]>(`${apiUrl}/users/search?searchString=${str}&limit=${limit}`);

export const searchUserByMail = (mail: string, limit = 10) =>
  axios.get<SearchedUser[]>(`${apiUrl}/users/search?mail=${mail}&limit=${limit}`);
