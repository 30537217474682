import classnames from 'classnames';
import { IRootState } from 'config/store';
import { IRxDatabaseBeam, ITxDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from './common-slider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    0: <span className={className}>0</span>,
    25: <span className={className}>25</span>,
    50: <span className={className}>50</span>,
    75: <span className={className}>75</span>,
    100: <span className={className}>100</span>
  };
};

interface IFreqPlanOccRatioSliderProps {
  forward: boolean;
  defaultValue?: number;
  disabled?: boolean;
}

const FreqPlanOccRatioSlider = (props: IFreqPlanOccRatioSliderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const { defaultValue, disabled = false, forward } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue ? debounceValue : 0);

  useEffect(() => {
    if (!disabled) {
      setValue(defaultValue);
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (
      previousDebounceValue !== debounceValue &&
      typeof previousDebounceValue !== 'undefined' &&
      typeof debounceValue !== 'undefined' &&
      !disabled
    ) {
      const newUnderEditionBeams = underEditionBeams.map(underEditionBeam => {
        if (underEditionBeam.selected) {
          const initialTx = underEditionBeam.tx;
          const initialRx = underEditionBeam.rx;

          if (forward) {
            const tx: ITxDatabaseBeam = {
              ...initialTx,
              r: debounceValue
            };
            return {
              ...underEditionBeam,
              tx
            };
          } else {
            const rx: IRxDatabaseBeam = {
              ...initialRx,
              r: debounceValue
            };
            return {
              ...underEditionBeam,
              rx
            };
          }
        }
        return { ...underEditionBeam };
      });
      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }
  }, [debounceValue, dispatch, forward, underEditionBeams, previousDebounceValue, disabled]);

  const onChange = (e: number) => {
    setValue(e);
  };

  const marks = getMarks(disabled);

  const showMandatoryError = typeof value === 'undefined' && !disabled;

  return (
    <Form.Group className="common-slider">
      <Form.Label className={classnames('d-flex justify-content-center mb-0', { 'text-danger': showMandatoryError })}>
        {t(forward ? 'area.targets.FreqPlanOccupancyRatio' : 'area.targets.FreqPlanOccupancyRatio')}
      </Form.Label>
      <SkyTSlider onChange={onChange} marks={marks} min={0} max={100} value={value} step={1} disabled={disabled} mandatory />
    </Form.Group>
  );
};

export default FreqPlanOccRatioSlider;
