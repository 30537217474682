import classNames from 'classnames';
import { IRootState } from 'config/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProjectDetails } from 'shared/model/project.model';
import { SatelliteModel } from 'shared/model/satellite-model.model';
import { GatewayId } from 'shared/model/satellite.model';
import { setUnderEditionSatellite } from 'shared/reducers/satelliteSlice';
import ImageFromUrl from 'shared/widgets/images/ImageFromUrl';
import './satellite-motel-item.scss';

interface ISatelliteModelItemProps {
  item: SatelliteModel;
}

const SatelliteModelItem = (props: ISatelliteModelItemProps) => {
  const dispatch = useDispatch();
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;

  const { satelliteId } = useParams<{ satelliteId: string }>();

  const isNew = satelliteId === 'new';

  const { item } = props;

  const selected = underEditionSatellite?.modelId === item.id;

  const isActive = item.isActive;

  const onClick = () => {
    if (isActive) {
      if (isNew && project.gateways.length <= item.maxGatewayCount) {
        // In creation mode, select all gateways if the number of available gateways
        // is less or equals than the supported number by the satelli{te model
        const gatewayIds: GatewayId[] = project.gateways.map(gat => ({ id: gat.id }));

        dispatch(
          setUnderEditionSatellite({
            ...underEditionSatellite,
            modelId: item.id,
            gatewayIds
          })
        );
      } else {
        dispatch(
          setUnderEditionSatellite({
            ...underEditionSatellite,
            modelId: item.id
          })
        );
      }
    }
  };

  return (
    <div className={classNames('satellite-model-item', { selected, disabled: !isActive, 'hover-info': isActive })} onClick={onClick}>
      <div>{item.logo && <ImageFromUrl url={item.logo} height="32px" className="mr-2" />}</div>
      <div>{item.name}</div>
    </div>
  );
};

export default SatelliteModelItem;
