import classnames from 'classnames';
import { IRootState } from 'config/store';
import CreateOrEditCustomer from 'modules/customers/createorEditCustomer';
import CustomerList from 'modules/customers/customers-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProjectsList from './projects-list';
import './projects.scss';

const ProjectsPage = () => {
  const { t } = useTranslation('translation');
  const selectedCustomer = useSelector(({ customer }: IRootState) => customer.customer);

  const [visible, setVisible] = useState(false);

  const title = selectedCustomer?.name || t('projects');

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  return (
    <div className="projects-page">
      <div>
        <div className="d-flex align-items-center" onMouseEnter={showActions} onMouseLeave={hideActions}>
          <h1 className="display-1">{title}</h1>

          {selectedCustomer?.name && visible && (
            <div
              className={classnames('ml-4 animate__animated', {
                animate__fadeIn: visible
              })}
            >
              <CreateOrEditCustomer customer={selectedCustomer} onClose={hideActions} />
            </div>
          )}
        </div>
        <hr className="title-underline" />
      </div>
      <CustomerList />
      <ProjectsList />
    </div>
  );
};

export default ProjectsPage;
