// import webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { Gateway } from 'shared/model/gateway.model';
import { gatewayRoute } from 'shared/routes/routes';
import SkyTButton from 'shared/widgets/buttons/skyTButton';

interface Props {
  gateway: Gateway;
}

const EditGatewayBtn = (props: Props) => {
  const history = useHistory();

  const { projectId } = useParams<{ projectId: string }>();

  const { gateway } = props;

  const onEdit = () => {
    history.push(gatewayRoute.getPath(projectId, gateway.id));
  };

  return (
    <SkyTButton variant="link" onClick={onEdit} size="sm" className="text-primary">
      <FontAwesomeIcon icon="pencil-alt" />
    </SkyTButton>
  );
};

export default EditGatewayBtn;
