import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultBaseLayer, hide3DMap, setBaseLayer, setMapMenuPanel, show3DMap } from 'shared/reducers/mapSlice';
import './mapMenu.scss';
import MapMenuPanel from './mapMenuPanel';
import { ReactComponent as SatPlanIcon } from './sat-plan.svg';
import { ReactComponent as TargetIcon } from './target.svg';

interface IMenuItem {
  onClick?: () => void;
  text?: string;
  icon?: IconProp;
  svg?: any;
  match?: boolean;
}

interface IMapMenuProps {
  onCompassClick?: () => void;
}

const MapMenu = (props: IMapMenuProps) => {
  const dispatch = useDispatch();
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const flat = useSelector(({ map }: IRootState) => map.flat);
  const mapMenuPanel = useSelector(({ map }: IRootState) => map.mapMenuPanel);
  const activeBaseLayer = useSelector(({ map }: IRootState) => map.baseLayer);

  const { onCompassClick } = props;

  const items = useMemo(() => {
    const toggleViz = flat ? show3DMap : hide3DMap;

    const missionViewerItem: IMenuItem = {
      svg: mapMenuPanel !== 'missions' ? <TargetIcon height="26px" /> : undefined,
      text: mapMenuPanel === 'missions' ? 'Missions' : undefined,
      onClick: () => {
        if (mapMenuPanel === 'missions') {
          dispatch(setMapMenuPanel(null));
        } else {
          dispatch(setMapMenuPanel('missions'));
        }
      },
      match: mapMenuPanel === 'missions'
    };

    const compassItem: IMenuItem = {
      svg: <FontAwesomeIcon icon={['far', 'compass']} />,
      onClick: onCompassClick
    };

    const result: IMenuItem[] = [
      {
        text: flat ? '3D' : '2D',
        onClick: () => dispatch(toggleViz())
      },
      {
        svg: <SatPlanIcon />,
        onClick: () => {
          if (activeBaseLayer === defaultBaseLayer) {
            dispatch(setBaseLayer('arcgis-navigation'));
          } else {
            dispatch(setBaseLayer(defaultBaseLayer));
          }
        }
      },
      {
        icon: mapMenuPanel !== 'layer-group' ? 'layer-group' : undefined,
        text: mapMenuPanel === 'layer-group' ? 'Layers' : undefined,
        onClick: () => {
          if (mapMenuPanel === 'layer-group') {
            dispatch(setMapMenuPanel(null));
          } else {
            dispatch(setMapMenuPanel('layer-group'));
          }
        },
        match: mapMenuPanel === 'layer-group'
      }
    ];

    if (onCompassClick) {
      result.push(compassItem);
    } else {
      result.push(missionViewerItem);
    }

    return [...result].reverse();
  }, [flat, dispatch, mapMenuPanel, activeBaseLayer, onCompassClick]);

  useEffect(() => {
    if (arcgisMap) {
      arcgisMap.set('basemap', activeBaseLayer);
    }
  }, [activeBaseLayer, arcgisMap]);

  let active = -1;
  let previousActive = -1;
  let nextActive = -1;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (item.match) {
      active = i;
      previousActive = i - 1;
      nextActive = i + 1;
    }
  }

  const execute = (item: IMenuItem) => () => {
    item.onClick && item.onClick();
  };

  return (
    <div id="right-menu">
      <div className="map-menu">
        <div className="map-menu-container">
          <div className={classnames('first', { 'border-radius': active === 0 })}>
            <div></div>
          </div>
          {items.map((item, i) => {
            const isActive = item.match;

            return (
              <div
                className={classnames({
                  'item-active': isActive,
                  'item-inactive': !isActive,
                  'item-previous': i === previousActive,
                  'item-next': i === nextActive,
                  'map-is-displayed': mapIsDisplayed
                })}
                key={`menu-item_${i}`}
                onClick={execute(item)}
              >
                {item.text && (
                  <div>
                    <span className="map-menu-item-rotate">{item.text}</span>
                  </div>
                )}
                {item.icon && (
                  <div>
                    <span className="map-menu-item-rotate">
                      <FontAwesomeIcon icon={item.icon} />
                    </span>
                  </div>
                )}
                {item.svg && <div className="map-menu-icon-rotate">{item.svg}</div>}
              </div>
            );
          })}
          <div className={classnames('last', { 'border-radius': active === items.length - 1 })}>
            <div></div>
          </div>
        </div>
      </div>
      <MapMenuPanel />
    </div>
  );
};

export default MapMenu;
