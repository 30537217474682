import ArcGisMap from '@arcgis/core/Map';
import { baseLayers } from 'config/baseLayer';
import { resultLayerId } from 'modules/results/commons/resultConstants';
import { areaOfInterestLayerId } from './areaOfInterets/areaOfInterestLayer-def';
import { gatewayLayerId } from './gateway/gateway-layer-def';
import { poiLayerId } from './poi/poi-layer-def';
import { satelliteLayerId } from './satellite/useSatelliteLayer';

let orderedLayerIds = baseLayers.map(baseLayer => baseLayer.id);

orderedLayerIds = orderedLayerIds.concat([areaOfInterestLayerId, satelliteLayerId, gatewayLayerId, poiLayerId, resultLayerId]);

export const addAndOrderLayers = (map: ArcGisMap | null, layers: __esri.Layer[]) => {
  if (map) {
    map.addMany(layers);
    orderedLayerIds.forEach((layerId, index) => {
      const layer = map.findLayerById(layerId);
      map.reorder(layer, index);
    });
  }
};

export const addAndOrderLayer = (map: ArcGisMap | null, layer: __esri.Layer) => {
  addAndOrderLayers(map, [layer]);
};
