import classnames from 'classnames';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Mission, MissionDetails, MissionUserTerminal } from 'shared/model/mission.model';
import { saveMission } from 'shared/reducers/missionSlice';
import { getMissionTotalTerminalsRepartition } from 'shared/utils/model-utils';
import DeleteMissionUserTerminal from './DeleteMissionUserTerminal';
import EditTerminalPercent from './EditTerminalPercent';
import './missionUserTerminalItem.scss';

interface IMissionUserTerminalItemProps {
  item: MissionUserTerminal;
  readonly: boolean;
}

const MissionUserTerminalItem = (props: IMissionUserTerminalItemProps) => {
  const dispatch = useDispatch();
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const { item, readonly } = props;

  const [visible, setVisible] = useState(false);

  if (selectedMissions.length !== 1) {
    return null;
  }
  const currentMission = selectedMissions[0] as MissionDetails;

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  const onEdit = (value: number) => {
    if (currentMission) {
      const newTerminals = [...currentMission.userTerminals];
      const index = newTerminals.findIndex(t => t.name === item.name);
      if (index !== -1) {
        const editedTerminal = { ...newTerminals[index] };
        editedTerminal.repartitionPercentage = value;

        newTerminals.splice(index, 1);
        newTerminals.push(editedTerminal);

        const toSend: Mission = {
          ...currentMission,
          userTerminals: newTerminals
        };
        dispatch(saveMission(currentMission.projectId, toSend));
      }
    }
  };

  const bigramme = item.name.charAt(0) + item.name.charAt(1);

  const percent = item.repartitionPercentage ? item.repartitionPercentage : 0;

  const totalPercent = getMissionTotalTerminalsRepartition(currentMission);

  return (
    <div className="mission-user-terminal-item  mb-4" onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div className="frequency-avatar mr-2">{bigramme}</div>
      <div className="flex-grow-1 mx-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mission-form-item-name">{item.name}</div>
          <div className="mission-form-item-action">
            {visible && !readonly && (
              <div
                className={classnames('animate__animated text-nowrap', {
                  animate__fadeIn: visible
                })}
              >
                <DeleteMissionUserTerminal item={item} />
              </div>
            )}
          </div>
        </div>
        <hr className="my-1" />
        <div className="terminal-percent">
          <div className={classnames({ 'text-info': totalPercent <= 100, 'text-danger': totalPercent > 100 })}>
            {readonly ? <span>{percent + ' %'}</span> : <EditTerminalPercent item={item} onChange={onEdit} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionUserTerminalItem;
