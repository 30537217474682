import round from 'lodash/round';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from '../../coverage/area/sliders/common-slider';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    0: <span className={className}>0°</span>,
    1: <span className={className}>1°</span>,
    2: <span className={className}>2°</span>,
    3: <span className={className}>3°</span>,
    4: <span className={className}>4°</span>,
    5: <span className={className}>5°</span>
  };
};

interface IBeamSizeSliderProps {
  onChange: (beamSize: number) => void;
  defaultValue?: number;
  disabled?: boolean;
}

const BeamSizeSlider = (props: IBeamSizeSliderProps) => {
  const { t } = useTranslation('translation');

  const { defaultValue = 1, disabled = false } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue(1);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (previousDebounceValue !== debounceValue) {
      props.onChange(debounceValue);
    }
  }, [debounceValue, previousDebounceValue, props]);

  const onChange = (e: number) => {
    const newValue = e === 0 ? 0.1 : e;
    setValue(newValue);
  };

  const marks = getMarks(disabled);

  const roundedValue = round(value, 2);

  return (
    <Form.Group>
      <Form.Label className="d-flex justify-content-center mb-0">
        {t('coverage.MULTI_BEAM.geometry.beamSize', { value: `${roundedValue}°` })}
      </Form.Label>
      <SkyTSlider onChange={onChange} marks={marks} min={0} max={5} value={value} step={0.1} disabled={disabled} />
    </Form.Group>
  );
};

export default BeamSizeSlider;
