import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AreaOfInterestType } from 'shared/model/areaOfInterest.model';
import { setTypeOfArea, setUnderEditionFeatures } from 'shared/reducers/areaOfInterestSlice';
import SkyTToggleButton from 'shared/widgets/buttons/skyTToggleButton';
import AreaTypeIcon from '../icons/AreaTypeIcon';
import './DrawAreaOfInterestToolbar.scss';
import DrawGlobalAreaOfInterest from './DrawGlobalAreaOfInterest';
import DrawPolygonAreaBtn from './DrawPolygonAreaBtn';
import SelectCountriesAsAreaOfInterest from './SelectCountriesAsAreaOfInterest';

const availableTypes = Object.keys(AreaOfInterestType).filter(item => item !== AreaOfInterestType.FREEDRAW);

const DrawAreaOfInterestToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const typeOfArea = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.typeOfArea);

  const onSelectTypeOfArea = (typeStr: string) => () => {
    const type: AreaOfInterestType = AreaOfInterestType[typeStr as keyof typeof AreaOfInterestType];
    dispatch(setUnderEditionFeatures([]));
    dispatch(setTypeOfArea(type));
  };

  return (
    <div className="draw-areaOfInterest-toolbar">
      {typeOfArea ? (
        <div>
          {typeOfArea === AreaOfInterestType.POLYGON ? (
            <DrawPolygonAreaBtn />
          ) : typeOfArea === AreaOfInterestType.COUNTRY ? (
            <SelectCountriesAsAreaOfInterest />
          ) : typeOfArea === AreaOfInterestType.GLOBAL ? (
            <DrawGlobalAreaOfInterest />
          ) : null}
        </div>
      ) : (
        <div>
          <div className="mb-2">{t('areaOfInteret.chooseType')}</div>
          {availableTypes.map(item => (
            <SkyTToggleButton
              key={`select_${item}`}
              variant="outline-map"
              type="checkbox"
              value={item}
              checked={typeOfArea === item}
              className="mr-2"
              onClick={onSelectTypeOfArea(item)}
            >
              <AreaTypeIcon type={item} className="configure-areaOfInterets-type-icon" />
            </SkyTToggleButton>
          ))}
        </div>
      )}
    </div>
  );
};
export default DrawAreaOfInterestToolbar;
