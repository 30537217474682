import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SkyTSlider from 'shared/widgets/form/skyTSlider';

interface IGridOrientationSliderProps {
  onChange: (orientation: number) => void;
  defaultValue?: number;
  disabled?: boolean;
}

const GridOrientationSlider = (props: IGridOrientationSliderProps) => {
  const { t } = useTranslation('translation');

  const { defaultValue = 0, disabled = false } = props;

  const [value, setValue] = useState(defaultValue);

  const gridOrientationMarks = {
    '-60': <span className="text-primary">-60°</span>,
    '0': <span className="text-primary">0°</span>,
    '60': <span className="text-primary">60°</span>
  };

  const onChange = (e: number) => {
    props.onChange(e);
    setValue(e);
  };

  return (
    <Form.Group>
      <Form.Label className="d-flex justify-content-center mb-0">
        {t('coverage.MULTI_BEAM.geometry.gridOrientation', { value: `${value}°` })}
      </Form.Label>
      <SkyTSlider onChange={onChange} marks={gridOrientationMarks} min={-60} max={60} value={value} disabled={disabled} />
    </Form.Group>
  );
};

export default GridOrientationSlider;
