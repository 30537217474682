import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { IRootState } from 'config/store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CoverageType } from 'shared/model/coverage.model';
import { setShowGlobalBeam } from 'shared/reducers/satelliteSlice';
import './beams.scss';
import useCreateMultiBeam from './multibeams/useCreateMultiBeam';
import useCreateRegionalBeam from './regional/useCreateRegionalBeam';
import useCreateSteerableBeam from './steerable/useCreateSteerableBeam';

export const createBeamsLayerId = 'create-beams';

const createBeamsLayer = new GraphicsLayer({
  id: createBeamsLayerId,
  title: createBeamsLayerId
});

const useCreateBeamLayer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);

  const isSteerableCoverateType = coverage?.coverageType === CoverageType.STEERABLE_BEAM;

  useCreateMultiBeam({ layer: createBeamsLayer });
  useCreateSteerableBeam({ layer: createBeamsLayer });
  useCreateRegionalBeam({ layer: createBeamsLayer });

  useEffect(() => {
    if (!coverage || !coverage.coverageType) {
      createBeamsLayer.removeAll();
    }
  }, [coverage]);

  useEffect(() => {
    dispatch(setShowGlobalBeam(isSteerableCoverateType));
  }, [dispatch, isSteerableCoverateType]);

  useEffect(() => {
    if (arcgisMap) {
      if (missions.length > 0) {
        arcgisMap.add(createBeamsLayer);
      }
    }
    return () => {
      if (arcgisMap) {
        createBeamsLayer.removeAll();
        arcgisMap.remove(createBeamsLayer);
      }
    };
  }, [arcgisMap, missions, t]);
  return createBeamsLayer;
};

export default useCreateBeamLayer;
