import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IExportResult } from 'shared/model/results.model';
import { fetchCharts } from 'shared/reducers/chartSlice';
import { fetchMissions } from 'shared/reducers/missionSlice';
import { useQueryParams } from 'shared/utils/hook-utils';
import Loader from 'shared/widgets/loader';
import ResultChartBar from '../commons/ResultChartBar';
import SatSystemOverviewViz from '../viz/overview/SatSystemOverviewViz';
import ResultBarChart from './barChart/ResultBarChart';
import ResultBeamMap from './beamMap/ResultBeamMap';
import ResultBooleanBeamMap from './booleanBeamMap/ResultBooleanBeamMap';
import ResultHeatMap from './heatMap/ResultHeatMap';
import ResultLineChart from './lineChart/ResultLineChart';

const ResultChart = () => {
  const dispatch = useDispatch();
  const exportItem = useSelector(({ exporter }: IRootState) => exporter.export);
  const loadingCharts = useSelector(({ charts }: IRootState) => charts.loading);
  const loadingMissions = useSelector(({ mission }: IRootState) => mission.loading);

  const query = useQueryParams();
  const name = query.get('name');
  const type = query.get('type');

  const [currentResult, setCurrentResult] = useState<IExportResult>();

  const exportResult = exportItem?.exportResult;

  useEffect(() => {
    if (exportItem?.projectId) {
      dispatch(fetchMissions(exportItem.projectId));
    }
  }, [exportItem, dispatch]);

  useEffect(() => {
    if (exportResult?.results && name) {
      const found = exportResult.results.find(item => item.name === name && item.resultType === type);
      setCurrentResult(found);
      if (found) {
        dispatch(fetchCharts(found));
      }
    }
  }, [exportResult, name, type, dispatch]);

  if (!exportResult || !currentResult) {
    return null;
  }

  const loading = loadingMissions || loadingCharts;

  return (
    <div>
      <ResultChartBar loading={loading} exportResult={currentResult} />
      <div>
        {loading ? (
          <div className="vcenter-loader">
            <Loader />
          </div>
        ) : (
          <div>
            {currentResult.resultType === 'BAR_CHART' && <ResultBarChart />}
            {currentResult.resultType === 'CURVE_LINE' && <ResultLineChart />}
            {currentResult.resultType === 'HEAT_MAP' && <ResultHeatMap />}
            {currentResult.resultType === 'BEAM_MAP' && <ResultBeamMap />}
            {currentResult.resultType === 'BOOLEAN_BEAM_MAP' && <ResultBooleanBeamMap />}
            {currentResult.resultType === 'OVERVIEW' && <SatSystemOverviewViz />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultChart;
