import { IRootState } from 'config/store';
import { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setMapMenuPanel } from 'shared/reducers/mapSlice';
import Loader from 'shared/widgets/loader';
import BeamCriteria from './beamCriteria';
import MissionPanelItem from './missionPanelItem';

const MissionsPanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);
  const loading = useSelector(({ configuration }: IRootState) => configuration.loadingMissions);

  useEffect(() => {
    if (missions.length === 0) {
      dispatch(setMapMenuPanel(null));
    }
  }, [dispatch, missions.length]);

  return (
    <div className="map-menu-content missions-panel">
      {loading !== -1 ? (
        <div className="vcenter-loader">
          <Loader />
        </div>
      ) : (
        <Scrollbars autoHeight autoHeightMax={525} className="skyt-scroll-bar">
          <div className="mb-3">
            <h5 className="font-weight-bold">{t('configuration.missionCoverageTitle')}</h5>
            {missions.map(mission => (
              <MissionPanelItem key={mission.id} mission={mission} />
            ))}
          </div>
          {missions.length > 0 && <BeamCriteria />}
        </Scrollbars>
      )}
    </div>
  );
};

export default MissionsPanel;
