import { IRootState } from 'config/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from 'shared/widgets/loader';

const ExportsTabTitle = () => {
  const { t } = useTranslation('translation');
  const loading = useSelector(({ configuration }: IRootState) => configuration.loading);

  return (
    <div className="df-jb-ac unselectable pr-2">
      {t('export.exports')}
      {loading && (
        <span className="ml-2">
          <Loader size="sm" inline />
        </span>
      )}
    </div>
  );
};
export default ExportsTabTitle;
