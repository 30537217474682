import { IRootState } from 'config/store';
import { useContext, useEffect } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { hideMap } from 'shared/reducers/mapSlice';
import { fetchMissions } from 'shared/reducers/missionSlice';
import { missionRoute } from 'shared/routes/routes';
import { sortByName } from 'shared/utils/array-utils';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTAccordionTab from 'shared/widgets/accordion/skyTAccordionTab';
import Loader from 'shared/widgets/loader';
import CreateOrEditMission from './createorEditMission';
import MissionItem from './missionItem';
import './missions.scss';
import MissionsTabTitle from './missionsTabTitle';

export const missionTabKey = 'mission';

const title = <MissionsTabTitle />;

const MissionsTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const currentProject = useSelector(({ project }: IRootState) => project.project);
  const loading = useSelector(({ mission }: IRootState) => mission.loading);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const currentEventKey = useContext(AccordionContext);

  const { projectId } = useParams<{ projectId: string }>();

  const isCurrentTab = currentEventKey === missionTabKey;

  const hasWriteAccess = projectHasWriteAccess(currentProject);

  useEffect(() => {
    dispatch(fetchMissions(projectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCurrentTab) {
      if (mapIsDisplayed) {
        dispatch(hideMap());
      }
      if (missions.length > 0) {
        const id = selectedMissions.length === 1 ? selectedMissions[0].id : missions[0].id;
        const newHistory = missionRoute.getPath(projectId, id);
        history.push(newHistory);
      }
    }
  }, [selectedMissions, dispatch, history, isCurrentTab, mapIsDisplayed, missions, projectId]);

  const onCreate = () => {
    dispatch(fetchMissions(projectId));
  };

  const orderedMission = [...missions].sort(sortByName());

  return (
    <SkyTAccordionTab eventKey={missionTabKey} title={title} className="missions">
      {loading ? (
        <Loader />
      ) : (
        <>
          {isCurrentTab && (
            <>
              <div className="mb-3">
                {orderedMission.map(item => (
                  <MissionItem key={`missionTab-mission-item-${item.id}`} projectId={projectId} mission={item} />
                ))}
              </div>
              {hasWriteAccess && (
                <div className="text-right">
                  <CreateOrEditMission onCreateOrEdit={onCreate} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </SkyTAccordionTab>
  );
};

export default MissionsTab;
