import Polygon from '@arcgis/core/geometry/Polygon';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import isArray from 'lodash/isArray';
import { AreaOfInterest, AreaOfInterestType } from 'shared/model/areaOfInterest.model';
import { Mission } from 'shared/model/mission.model';
import { hexToRgb } from 'shared/utils/color-utils';

export const areaOfInterestLayerId = 'areaOfInterests';

export const areaOfInterestslayer = new GraphicsLayer({ id: areaOfInterestLayerId, title: 'area of interets' });

export const tempAreaOfInterestsLayer = new GraphicsLayer({
  title: 'temp-areaOfInterests'
});

export const underEditionTempColor = hexToRgb('#1f39ad');
export const hoverEditionTempColor = hexToRgb('#ffffff');

export const getGlobalAreaTypeSymbol = (color: number[]) => {
  return new SimpleLineSymbol({
    color,
    width: 2,
    style: 'dash'
  });
};

export const getGlobalAreaTypeSymbolFromMission = (mission: Mission) => {
  const color = hexToRgb(mission.color);
  return new SimpleLineSymbol({
    color,
    width: 2,
    style: 'dash'
  });
};

export const getDefaultAreaTypeSymbol = (color: number[]) => {
  const inColor = [...color, 0.3];
  return new SimpleFillSymbol({
    color: inColor,
    outline: {
      width: 2,
      color
    }
  });
};

export const getUnderEditionAreaSymbol = (color = underEditionTempColor) => {
  const inColor = [...color, 0.3];
  return new SimpleFillSymbol({
    color: inColor,
    outline: {
      width: 3,
      color
    }
  });
};

export const getAreaSymbol = (type: AreaOfInterestType, color: number[], isUnderEdition = false) => {
  if (isUnderEdition) {
    return getUnderEditionAreaSymbol();
  }
  return type === AreaOfInterestType.GLOBAL ? getGlobalAreaTypeSymbol(color) : getDefaultAreaTypeSymbol(color);
};

export const getGraphicsFromAreaOfInterest = (
  areaOfInterest: AreaOfInterest,
  color: number[],
  isUnderEdition = false,
  onlyOutline = false
) => {
  let confArray = JSON.parse(areaOfInterest.latLngs);
  if (!isArray(confArray)) {
    confArray = [confArray];
  }
  const simpleLineSymbol = getAreaSymbol(areaOfInterest.type, color, isUnderEdition) as SimpleFillSymbol;

  if (onlyOutline) {
    simpleLineSymbol.set('color', undefined);
  }

  return confArray.map((conf: any) => {
    const polygon = new Polygon(conf);

    return new Graphic({
      geometry: polygon,
      symbol: simpleLineSymbol
    });
  });
};
