import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AreaOfInterestType } from 'shared/model/areaOfInterest.model';
import { CoverageType } from 'shared/model/coverage.model';
import { Mission } from 'shared/model/mission.model';
import { ProjectDetails } from 'shared/model/project.model';
import { computeBeamAtLonLat } from 'shared/utils/beam-utils';
import { getBeamI, getBeamId, getMissionSymbol } from '../beam-layer-def';

let sketchViewModelSteerableBeams: SketchViewModel | undefined;

export const IS_DIRTY_ATT = 'isDirty';

interface IUseCreateSteerableBeamProps {
  layer: GraphicsLayer;
}

const useCreateSteerableBeam = (props: IUseCreateSteerableBeamProps) => {
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const configuration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const coverageBeamConfig = useSelector(({ coverage }: IRootState) => coverage.coverageBeamConfig);
  const missions = useSelector(({ configuration }: IRootState) => configuration.configurationMissions);

  const { layer } = props;

  const satellite = project.satellites.find(sat => sat.id === configuration?.satelliteId);
  const orbitalPosition = satellite?.orbitalPosition;
  const beamSize = coverageBeamConfig.size;

  const mission = missions.find(item => item.id === coverage?.missionId);
  const coverageId = coverage?.id;

  const isSteerableCoverateType = coverage?.coverageType === CoverageType.STEERABLE_BEAM;

  useEffect(() => {
    if (currentView && isSteerableCoverateType) {
      sketchViewModelSteerableBeams = new SketchViewModel({
        layer,
        view: currentView,
        defaultUpdateOptions: {
          tool: 'move',
          multipleSelectionEnabled: false
        }
      });

      const updateSteerableBeams = (e: any) => {
        const toolType = e.toolEventInfo?.type;
        if (toolType === 'move-stop' && orbitalPosition) {
          const movedBeam = e.graphics[0] as Graphic;

          // change geom
          const geom = movedBeam.geometry as Polygon;
          const centroid = geom.centroid;
          const newGeom = computeBeamAtLonLat(centroid.latitude, centroid.longitude, orbitalPosition, beamSize);
          movedBeam.geometry = webMercatorUtils.geographicToWebMercator(newGeom);
          sketchViewModelSteerableBeams?.update(movedBeam);

          movedBeam.setAttribute(IS_DIRTY_ATT, true);
        }
      };

      sketchViewModelSteerableBeams.on('update', updateSteerableBeams);
    }
  }, [beamSize, isSteerableCoverateType, layer, orbitalPosition]);

  useEffect(() => {
    if (satellite && mission && isSteerableCoverateType) {
      const beams: Graphic[] = [];
      layer.removeAll();

      const areaOfInterests = mission.areaOfInterests;
      const globalAreaOfInterest = areaOfInterests.find(item => item.type === AreaOfInterestType.GLOBAL);
      if (!globalAreaOfInterest) {
        return;
      }

      const dirtyGraphics = layer.graphics.filter(g => g.getAttribute(IS_DIRTY_ATT) === true);

      const nbOfBeamsToCreate = coverageBeamConfig.steerableBeamNumber - dirtyGraphics.length;
      const nbOfBeamsToRemove = dirtyGraphics.length - coverageBeamConfig.steerableBeamNumber;

      if (nbOfBeamsToCreate > 0) {
        const positions = createRandomBeamPositions(
          nbOfBeamsToCreate,
          coverageBeamConfig.size,
          satellite.orbitalPosition,
          dirtyGraphics.length
        );

        positions.forEach(pos => {
          const i = getBeamI(mission, pos.i.toString());
          const beam = createSteerableGraphic(
            pos.lat,
            pos.lon,
            satellite.orbitalPosition,
            coverageBeamConfig.size,
            i,
            globalAreaOfInterest.id,
            mission,
            coverageId as number
          );
          beams.push(beam);
        });

        layer.addMany(beams);
      } else if (nbOfBeamsToRemove > 0) {
        for (let i = 0; i < nbOfBeamsToRemove; i++) {
          dirtyGraphics.pop();
        }
      }

      dirtyGraphics.forEach(g => {
        const currentGeom = g.geometry as Polygon;
        const centroid = currentGeom.centroid;
        const computedGeom = computeBeamAtLonLat(centroid.latitude, centroid.longitude, satellite.orbitalPosition, coverageBeamConfig.size);
        const newGeom = webMercatorUtils.geographicToWebMercator(computedGeom);
        if (!geometryEngine.equals(currentGeom, newGeom)) {
          g.geometry = newGeom;
          g.setAttribute('s', coverageBeamConfig.size);
          sketchViewModelSteerableBeams?.update(g);
        }
      });

      layer.addMany(dirtyGraphics.toArray());

      setTimeout(() => {
        currentView?.goTo(beams);
      }, 200);
    }
  }, [
    coverageBeamConfig.size,
    coverageBeamConfig.steerableBeamNumber,
    isSteerableCoverateType,
    layer,
    mission,
    satellite,
    coverageId,
    missions
  ]);
};

export default useCreateSteerableBeam;

const createSteerableGraphic = (
  lat: number,
  lon: number,
  orbitalPosition: number,
  size: number,
  i: string,
  areaOfInterestId: number,
  mission: Mission,
  coverageId: number
) => {
  const geom = computeBeamAtLonLat(lat, lon, orbitalPosition, size);
  const geometry = webMercatorUtils.geographicToWebMercator(geom);

  const attributes = {
    ObjectID: getBeamId(CoverageType.STEERABLE_BEAM, areaOfInterestId, i),
    i,
    name: i,
    type: CoverageType.STEERABLE_BEAM,
    missionId: mission.id,
    coverageId: coverageId,
    areaOfInterestId: areaOfInterestId,
    s: size
  };

  const beam = new Graphic({
    geometry,
    symbol: getMissionSymbol(mission),
    attributes
  });
  return beam;
};

export const createRandomBeamPositions = (nbOfBeamsToCreate: number, size: number, orbitalPosition: number, nbOfExistingBeams: number) => {
  const delta = 2 * size;
  const positions = [];
  for (let i = 0; i < nbOfBeamsToCreate; i++) {
    const nb = i + 1;
    const beamNumber = nbOfExistingBeams + nb;
    const lat = nb % 2 ? nb * delta : -nb * delta;
    const lon = nb % 3 ? orbitalPosition + lat : orbitalPosition - lat;
    positions.push({ lat, lon, i: beamNumber });
  }
  return positions;
};
