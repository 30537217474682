import { IRootState } from 'config/store';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from 'shared/widgets/loader';
import SelectMissionApplication from './selectMissionApplication';
import SelectMissionFlexibility from './selectMissionFlexibility';
import SelectMissionFrequencyBand from './selectMissionFrequencyBand';
import SelectMissionServices from './selectMissionServices';
import SelectMissionUserTerminals from './selectMissionUserTerminals';

const MissionForm = () => {
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const loadingDetails = useSelector(({ mission }: IRootState) => mission.loadingDetails);

  const { missionId } = useParams<{ missionId: string }>();

  if (selectedMissions.length !== 1 || selectedMissions[0].id !== Number(missionId)) {
    return null;
  }

  if (loadingDetails !== -1) {
    return <Loader />;
  }

  return (
    <div className="mission-form">
      <Row className="justify-content-around">
        <Col lg="6" xl="3" className="p-xl-0">
          <SelectMissionApplication />
          <SelectMissionFlexibility />
        </Col>
        <Col lg="6" xl="3" className="p-xl-0">
          <SelectMissionFrequencyBand />
        </Col>
      </Row>
      <Row className="justify-content-around">
        <Col lg="6" xl="3" className="p-xl-0">
          <SelectMissionServices />
        </Col>
        <Col lg="6" xl="3" className="p-xl-0">
          <SelectMissionUserTerminals />
        </Col>
      </Row>
    </div>
  );
};
export default MissionForm;
