import { IRootState } from 'config/store';
import { skyTScichartTheme } from 'modules/results/commons/scichart.def';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TAxisDefinition } from 'scichart/Builder/buildAxis';
import { TXySeriesData } from 'scichart/Builder/buildDataSeries';
import { TSeriesDefinition } from 'scichart/Builder/buildSeries';
import { ISciChart2DDefinition } from 'scichart/Builder/buildSurface';
import { chartBuilder } from 'scichart/Builder/chartBuilder';
import { MouseWheelZoomModifier } from 'scichart/Charting/ChartModifiers/MouseWheelZoomModifier';
import { RolloverModifier } from 'scichart/Charting/ChartModifiers/RolloverModifier';
import { RubberBandXyZoomModifier } from 'scichart/Charting/ChartModifiers/RubberBandXyZoomModifier';
import { ZoomExtentsModifier } from 'scichart/Charting/ChartModifiers/ZoomExtentsModifier';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import { EAutoRange } from 'scichart/types/AutoRange';
import { EAxisAlignment } from 'scichart/types/AxisAlignment';
import { EAxisType } from 'scichart/types/AxisType';
import { ESeriesType } from 'scichart/types/SeriesType';
import { usePrevious } from 'shared/utils/hook-utils';
import '../../commons/chart.scss';
import { seriesColors } from '../barChart/ResultBarChart';

const id = 'result-line-chart';

const ResultLineChart = () => {
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);
  const [chartSurface, setChartSurface] = useState<SciChartSurface>();
  const previousModel = usePrevious(currentChart);

  useEffect(() => {
    if (currentChart?.type !== 'CURVE_LINE' || currentChart?.series.length === 0) {
      return;
    }

    const initChart = async () => {
      const unit = currentChart.series.length > 0 ? currentChart.series[0].unit : '';

      const xAxes: TAxisDefinition = {
        type: EAxisType.NumericAxis,
        options: {
          drawMajorGridLines: true,
          drawMinorGridLines: true,
          axisAlignment: EAxisAlignment.Bottom,
          autoRange: EAutoRange.Once
        }
      };

      const yAxes: TAxisDefinition = {
        type: EAxisType.NumericAxis,
        options: {
          drawMajorGridLines: true,
          drawMinorGridLines: true,
          axisTitle: `${currentChart.name} (${unit})`,
          axisAlignment: EAxisAlignment.Left
        }
      };

      const series = currentChart.series.map((serieDef, i) => {
        const xValues: number[] = [];
        const yValues: number[] = [];
        if (serieDef.values) {
          serieDef.values.forEach(value => {
            xValues.push(value.x);
            yValues.push(value.y);
          });
        } else if (serieDef.verticalValue) {
          // if (serieDef.unit === '%') {
          for (let y = 0; y <= 100; y++) {
            xValues.push(serieDef.verticalValue);
            yValues.push(y);
          }
          // }
        }
        const xyData: TXySeriesData = {
          xValues,
          yValues
        };

        const strokeDashArray = serieDef.style === 'DOTTED' ? [10, 3] : undefined;

        const serie: TSeriesDefinition = {
          type: ESeriesType.LineSeries,
          xyData,
          options: {
            stroke: seriesColors[i],
            strokeDashArray: strokeDashArray
          }
        };

        return serie;
      });

      const def: ISciChart2DDefinition = {
        series,
        xAxes,
        yAxes
      };

      const { sciChartSurface } = await chartBuilder.build2DChart(id, def);
      const mouseWheelZoomModifier = new MouseWheelZoomModifier();
      sciChartSurface.chartModifiers.add(mouseWheelZoomModifier);

      const rubberBandZoomModifier = new RubberBandXyZoomModifier();
      sciChartSurface.chartModifiers.add(rubberBandZoomModifier);
      const zoomExtentsModifier = new ZoomExtentsModifier();
      sciChartSurface.chartModifiers.add(zoomExtentsModifier);

      const rolloverModifier = new RolloverModifier({
        showRolloverLine: true,
        rolloverLineStroke: '#53b8d9'
      });
      sciChartSurface.chartModifiers.add(rolloverModifier);

      sciChartSurface.applyTheme(skyTScichartTheme);
      setChartSurface(sciChartSurface);
    };

    if (currentChart !== previousModel) {
      initChart();
    }

    return () => {
      chartSurface?.delete();
    };
  }, [chartSurface, currentChart, previousModel]);

  return (
    <div className="skyT-chart-wrapper">
      <div id={id}></div>
    </div>
  );
};

export default ResultLineChart;
