import ArcGisMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import { IRootState } from 'config/store';
import ResultMap from 'modules/results/commons/ResultMap';
import { useSelector } from 'react-redux';
import { IHeatMapModel } from 'shared/model/results.model';
import HeatMapLegend from './heatMap-legend';
import { initHeatMap } from './heatMap-utils';

const ResultHeatMap = () => {
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);

  const initLayer = (map: ArcGisMap, view: MapView | SceneView) => {
    const layer = initHeatMap(currentChart as IHeatMapModel);
    map.add(layer);
    setTimeout(() => {
      view.goTo(layer.source);
    }, 500);
  };

  return (
    <ResultMap onMapCreation={initLayer}>
      <HeatMapLegend model={currentChart as IHeatMapModel} />
    </ResultMap>
  );
};

export default ResultHeatMap;
