import { IRootState } from 'config/store';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const MissionsTabTitle = () => {
  const { t } = useTranslation('translation');
  const loading = useSelector(({ mission }: IRootState) => mission.loading);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);

  return (
    <div className="df-jb-ac unselectable pr-2">
      <>{t('mission.missions')}</>
      {!loading && (
        <Badge pill variant="info" className="ml-2">
          {missions.length}
        </Badge>
      )}
    </div>
  );
};
export default MissionsTabTitle;
