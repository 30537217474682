import classNames from 'classnames';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import SelectAllBeamsButton from '../selectAllBeamsButton';
import EditTargetPayload from './EditTargetPayload';
import './EditTargetsArea.scss';
import EditTargetSystem from './EditTargetSystem';

const contentScrollBarStyle = {
  height: '200px'
};

type targetSubMenu = 'system' | 'payload';

const EditTargetsArea = () => {
  const { t } = useTranslation('translation');
  const [subMenu, setSubMenu] = useState<targetSubMenu>('system');

  const onClick = (newSubmenu: targetSubMenu) => () => {
    setSubMenu(newSubmenu);
  };

  return (
    <div className="edit-targets-area">
      <div className="system-or-payload-menu">
        <div className={classNames({ selected: subMenu === 'system' })} onClick={onClick('system')}>
          {t('area.targets.system')}
        </div>
        <div className={classNames({ selected: subMenu === 'payload' })} onClick={onClick('payload')}>
          {t('area.targets.payload')}
        </div>
      </div>
      <Scrollbars autoHide style={contentScrollBarStyle} className="skyt-scroll-bar mt-2">
        {subMenu === 'system' ? <EditTargetSystem /> : <EditTargetPayload />}
      </Scrollbars>
      <div>
        <SelectAllBeamsButton />
      </div>
    </div>
  );
};

export default EditTargetsArea;
