import classnames from 'classnames';
import { IRootState } from 'config/store';
import { poiPicUrl } from 'modules/map/layers/poi/poi-layer-def';
import { currentView } from 'modules/map/map';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { POI } from 'shared/model/poi.model';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import DeletePoiBtn from './deletePoiBtn';
import EditPoiBtn from './editPoiBtn';
import './poi-gateway-item.scss';

interface IPoitItemProps {
  poi: POI;
}

const PoiItem = (props: IPoitItemProps) => {
  const { poi } = props;
  const project = useSelector(({ project }: IRootState) => project.project);

  const hasWriteAccess = projectHasWriteAccess(project);

  const [visible, setVisible] = useState(false);

  const onClick = () => {
    currentView?.goTo({
      center: [poi.longitude, poi.latitude],
      zoom: 8
    });
  };

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  return (
    <div className="poi-item hover-white" onClick={onClick} onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div>
        <img src={poiPicUrl} height="18" alt="poi" className="mb-1 mr-1" />
        {poi.name}
      </div>
      {hasWriteAccess && visible && (
        <div
          className={classnames('d-flex', 'align-items-center', 'animate__animated', {
            animate__fadeIn: visible
          })}
        >
          <EditPoiBtn poi={poi} />
          <DeletePoiBtn poi={poi} />
        </div>
      )}
    </div>
  );
};

export default PoiItem;
