import classnames from 'classnames';
import { IRootState } from 'config/store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { areaMenuType, setCurrentMenu } from 'shared/reducers/areaSlice';
import './AreaMenuSelector.scss';

const items: areaMenuType[] = ['geometry', 'services', 'targets'];

const AreaMenuSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const currentMenu = useSelector(({ area }: IRootState) => area.currentMenu);

  useEffect(() => {
    return () => {
      dispatch(setCurrentMenu('geometry'));
    };
  }, [dispatch]);

  const onClick = (menu: areaMenuType) => () => {
    dispatch(setCurrentMenu(menu));
  };

  return (
    <div className="area-menu-selector">
      {items.map(item => (
        <div key={item} className={classnames('area-menu-selector-item', { selected: currentMenu === item })} onClick={onClick(item)}>
          {t(`area.menu.${item}`)}
        </div>
      ))}
    </div>
  );
};

export default AreaMenuSelector;
