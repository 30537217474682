import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import {
  getUnderEditionAreaSymbol,
  hoverEditionTempColor,
  tempAreaOfInterestsLayer,
  underEditionTempColor
} from 'modules/map/layers/areaOfInterets/areaOfInterestLayer-def';
import { currentView } from 'modules/map/map';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AreaOfInterestType } from 'shared/model/areaOfInterest.model';
import { setTypeOfArea, setUnderEditionFeatures } from 'shared/reducers/areaOfInterestSlice';
import { hexToRgb } from 'shared/utils/color-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import AreaTypeIcon from '../icons/AreaTypeIcon';

let clickHandle: IHandle | undefined;
let pointerMouveHandle: IHandle | undefined;

const countriesLayer = new FeatureLayer({
  title: 'countries',
  url: 'https://services.arcgis.com/d3voDfTFbHOCRwVR/arcgis/rest/services/Monde_contours_pays/FeatureServer',
  // title: 'World_Countries',
  // url: 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/World_Countries/FeatureServer/0',
  opacity: 0.1
});

const SelectCountriesAsAreaOfInterest = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const underEditionFeatures = useSelector(({ areaOfInterest }: IRootState) => areaOfInterest.underEditionFeatures);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);

  const { missionId } = useParams<{ missionId: string }>();

  const mission = missions.find(item => item.id === Number(missionId));
  const selectionColor = mission ? hexToRgb(mission.color) : underEditionTempColor;

  const queryFeatures = async (screenPoint: __esri.ViewClickEvent) => {
    if (currentView) {
      const point = currentView.toMap(screenPoint);
      const featureSet = await countriesLayer.queryFeatures({
        geometry: point,
        spatialRelationship: 'intersects',
        returnGeometry: true,
        outFields: ['*']
      });

      let features = [...tempAreaOfInterestsLayer.graphics.toArray()];

      featureSet.features.forEach(feature => {
        const geometry = feature.geometry;
        const alreadyThereIndex = tempAreaOfInterestsLayer.graphics.findIndex(g => {
          return geometryEngine.contains(g.geometry, geometry);
        });
        if (alreadyThereIndex !== -1) {
          features.splice(alreadyThereIndex, 1);
        } else {
          const graphic = new Graphic({
            geometry: feature.geometry,
            symbol: getUnderEditionAreaSymbol(selectionColor)
          });
          features.push(graphic);
        }
      });

      dispatch(setUnderEditionFeatures(features));
    }
  };

  const onPointerMove = (event: __esri.ViewPointerMoveEvent) => {
    if (currentView) {
      const opts = {
        include: countriesLayer
      };
      currentView.hitTest(event, opts).then(response => {
        let graphic: Graphic | null = null;
        if (response.results.length > 1) {
          // @ts-ignore
          graphic = response.results[0].graphic.clone() as Graphic;
          graphic.set('hover', true);
          graphic.set('symbol', getUnderEditionAreaSymbol(hoverEditionTempColor));
          tempAreaOfInterestsLayer.add(graphic);
        }

        tempAreaOfInterestsLayer.graphics.forEach(item => {
          if (item.get('hover') === true && item !== graphic) {
            tempAreaOfInterestsLayer.remove(item);
          }
        });
      });
    }
  };

  useEffect(() => {
    if (arcgisMap) {
      tempAreaOfInterestsLayer.removeAll();
      arcgisMap.add(tempAreaOfInterestsLayer);
      arcgisMap.add(countriesLayer);
    }
    if (currentView) {
      if (clickHandle) {
        clickHandle.remove();
      }
      clickHandle = currentView.on('click', queryFeatures);
      if (pointerMouveHandle) {
        pointerMouveHandle.remove();
      }
      pointerMouveHandle = currentView.on('pointer-move', onPointerMove);
    }
    return () => {
      tempAreaOfInterestsLayer.removeAll();
      arcgisMap?.remove(countriesLayer);
      arcgisMap?.remove(tempAreaOfInterestsLayer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arcgisMap]);

  useEffect(() => {
    tempAreaOfInterestsLayer.removeAll();
    tempAreaOfInterestsLayer.addMany(underEditionFeatures);
  }, [underEditionFeatures]);

  const onCancel = () => {
    dispatch(setTypeOfArea(null));
  };

  return (
    <div className="mb-2">
      <div className="d-flex align-items-center mb-2">
        <div className="mr-2">
          <AreaTypeIcon type={AreaOfInterestType.COUNTRY} />
        </div>
        <div className="mt-1">{t('areaOfInteret.selectCountry')}</div>
      </div>
      <div>
        <SkyTButton variant="outline-map" className="mr-2" onClick={onCancel}>
          <FontAwesomeIcon icon="times" />
        </SkyTButton>
      </div>
    </div>
  );
};

export default SelectCountriesAsAreaOfInterest;
