import Select from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';
import './skyTSelect.scss';

export interface ILabelValueOption<T> {
  label: string;
  value: T;
  other?: any;
}

export interface ICategoryLabelOptions<T> {
  label: string;
  options: ILabelValueOption<T>[];
}

interface ISkyTSelectProps extends SelectComponentsProps {
  dark?: boolean;
}

const SkyTSelect = (props: ISkyTSelectProps) => {
  const { dark = false, ...rest } = props;
  return <Select classNamePrefix="skyT" className={dark ? 'dark' : 'light'} {...rest} />;
};

export default SkyTSelect;
