import SceneViewPerformanceInfo from '@arcgis/core/views/3d/support/SceneViewPerformanceInfo';
import SceneView from '@arcgis/core/views/SceneView';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect, useRef, useState } from 'react';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { getMB } from 'shared/utils/math-utils';
import './perfromanceinfo.scss';

const updateTime = 2000;

const scrollBarStyle = {
  height: '30rem'
};

const PerformanceInfo = () => {
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const flat = useSelector(({ map }: IRootState) => map.flat);
  const ref = useRef<HTMLDivElement>(null);

  const [performanceInfo, setPerformanceInfo] = useState<SceneViewPerformanceInfo>();

  const [show, setShow] = useState(false);
  useHotkeys('ctrl+k', () => setShow(prevShow => !prevShow));

  useEffect(() => {
    const updatePerformanceInfo = () => {
      if (currentView) {
        const sceneView = currentView as SceneView;

        setPerformanceInfo(sceneView.performanceInfo);
        setTimeout(updatePerformanceInfo, updateTime);
      }
    };

    if (arcgisMap && currentView && !flat && ref?.current) {
      currentView.when(() => {
        if (ref.current) {
          currentView?.ui.add(ref.current, 'bottom-right');
          updatePerformanceInfo();
        }
      });
    }
  }, [arcgisMap, flat]);

  const usedMem = performanceInfo ? getMB(performanceInfo.usedMemory) : 0;
  const totalMem = performanceInfo ? getMB(performanceInfo.totalMemory) : 0;
  const quality = performanceInfo ? Math.round(100 * performanceInfo.quality) : 0;

  const layersInfo = performanceInfo ? (performanceInfo.layerPerformanceInfos as any[]) : [];

  return (
    <div id="performanceInfo" ref={ref}>
      {performanceInfo && show && (
        <>
          <div className="memory">
            <div>
              <span className="font-weight-bold">Memory:&nbsp;</span>
              <span>
                {usedMem}MB / {totalMem}MB
              </span>
            </div>
            <div>
              <span className="font-weight-bold">Quality:&nbsp;</span>
              <span>{quality} %</span>
            </div>
          </div>
          <Scrollbars autoHide style={scrollBarStyle} className="skyt-scroll-bar">
            <div className="d-flex justify-content-between font-weight-bold p-2">
              <div>Layers</div>
              <div>Memory (MB)</div>
            </div>
            <ListGroup className="layers">
              {layersInfo.map(layerInfo => (
                <ListGroup.Item key={layerInfo.layer.id} className="d-flex justify-content-between">
                  <div className="layer-name">{layerInfo.layer.title}</div>
                  <div>{getMB(layerInfo.memory)}</div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="d-flex justify-content-between font-weight-bold p-2">
              <div>Layers Features</div>
              <div>Displayed/Max (count)</div>
              <div>Total (count)</div>
            </div>
            <ListGroup className="layers">
              {layersInfo
                .filter(layer => layer.displayedNumberOfFeatures)
                .map(layerInfo => (
                  <ListGroup.Item key={layerInfo.layer.id} className="d-flex justify-content-between">
                    <div className="layer-name">{layerInfo.layer.title}</div>
                    <div>
                      {layerInfo.displayedNumberOfFeatures ? layerInfo.displayedNumberOfFeatures : '-'}
                      &nbsp;/&nbsp;
                      {layerInfo.maximumNumberOfFeatures ? layerInfo.maximumNumberOfFeatures : '-'}
                    </div>
                    <div>{layerInfo.totalNumberOfFeatures ? layerInfo.totalNumberOfFeatures : '-'}</div>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Scrollbars>
        </>
      )}
    </div>
  );
};

export default PerformanceInfo;
