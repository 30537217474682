import classnames from 'classnames';
import { AreaOfInterestType } from 'shared/model/areaOfInterest.model';
import './AreaTypeIcon.scss';
import { ReactComponent as CountryIcon } from './COUNTRY.svg';
import { ReactComponent as FreeDrawIcon } from './FREEDRAW.svg';
import { ReactComponent as GlobalIcon } from './GLOBAL.svg';
import { ReactComponent as PolygonIcon } from './POLYGON.svg';

interface IAreaTypeIconProps {
  type: AreaOfInterestType | string;
  className?: string;
}

const AreaTypeIcon = (props: IAreaTypeIconProps) => {
  const { type, className } = props;

  switch (type) {
    case AreaOfInterestType.COUNTRY:
      return <CountryIcon className={classnames('area-type', className)} />;
    case AreaOfInterestType.GLOBAL:
      return <GlobalIcon className={classnames('area-type', className)} />;
    case AreaOfInterestType.FREEDRAW:
      return <FreeDrawIcon className={classnames('area-type', className)} />;
    case AreaOfInterestType.POLYGON:
      return <PolygonIcon className={classnames('area-type', className)} />;
  }

  return null;
};

export default AreaTypeIcon;
