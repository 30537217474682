import classnames from 'classnames';
import React, { FC } from 'react';
import { ButtonProps } from 'react-bootstrap';
import SkyTButton from './skyTButton';
import './SkyTMapButton.scss';

const SkyTMapButton: FC<ButtonProps> = props => {
  const { children, className, ...rest } = props;

  return (
    <SkyTButton className={classnames('skyt-map-button', className)} {...rest}>
      {children}
    </SkyTButton>
  );
};

export default SkyTMapButton;
