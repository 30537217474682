import Point from '@arcgis/core/geometry/Point';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import ImageryLayer from '@arcgis/core/layers/ImageryLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import TileLayer from '@arcgis/core/layers/TileLayer';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import WMSLayer from '@arcgis/core/layers/WMSLayer';
import ArcGisMap from '@arcgis/core/Map';
import { ISupportLayer, ISupportLayerType } from 'config/supportLayer';
import { beamLayerId, editBeamLayerId } from 'modules/map/layers/beams/beam-layer-def';
import { createBeamsLayerId } from 'modules/map/layers/beams/useCreateBeamLayer';
import { gatewayMarkerSymbol } from 'modules/map/layers/gateway/gateway-layer-def';
import { poiMarkerSymbol } from 'modules/map/layers/poi/poi-layer-def';
import { GatewayBandWidth, GatewayPolarizationType } from 'shared/model/gateway.model';

export const layerInMap = (map: ArcGisMap | null, layerId: string) => {
  if (map) {
    return map.layers.some(aLayer => aLayer.id === layerId);
  }
  return false;
};

export const cleanAllAndAddFeatures = async (layer: FeatureLayer, addFeatures: Graphic[]) => {
  const result = await layer.queryFeatures();
  layer.applyEdits({
    addFeatures,
    deleteFeatures: result.features
  });
};

export const cleanAllFeatures = (layer: FeatureLayer) => {
  cleanAllAndAddFeatures(layer, []);
};

export const createPoiGraphic = (lon: number, lat: number, name: string, id: number, toWebMercator?: boolean, withSymbol?: boolean) => {
  let point = new Point({
    longitude: lon,
    latitude: lat
  });

  if (toWebMercator) {
    point = webMercatorUtils.geographicToWebMercator(point) as Point;
  }
  let symbol = withSymbol ? poiMarkerSymbol : undefined;

  const graphic = new Graphic({
    geometry: point,
    attributes: {
      id,
      name,
      lon: lon,
      lat: lat
    },
    symbol
  });
  return graphic;
};

export const createGatewayGraphic = (
  lon: number,
  lat: number,
  name: string,
  bandwidths: GatewayBandWidth[],
  polarization: GatewayPolarizationType,
  modelId: number,
  id: number,
  toWebMercator?: boolean,
  withSymbol?: boolean
) => {
  let point = new Point({
    longitude: lon,
    latitude: lat
  });

  if (toWebMercator) {
    point = webMercatorUtils.geographicToWebMercator(point) as Point;
  }
  let symbol = withSymbol ? gatewayMarkerSymbol : undefined;

  const bandwidthsStr = JSON.stringify(bandwidths);
  const graphic = new Graphic({
    geometry: point,
    attributes: {
      id,
      name,
      modelId,
      bandwidths: bandwidthsStr,
      polarization
    },
    symbol
  });
  return graphic;
};

export const createSupportLayer = (item: ISupportLayer) => {
  const legendEnabled = item.showInLegend ? true : false;
  switch (item.type) {
    case ISupportLayerType.TILE:
      return new TileLayer({
        url: item.url,
        id: item.id,
        legendEnabled
      });
    case ISupportLayerType.IMAGERY:
      return new ImageryLayer({
        url: item.url,
        id: item.id,
        legendEnabled
      });
    case ISupportLayerType.FEATURE:
      return new FeatureLayer({
        url: item.url,
        id: item.id,
        legendEnabled
      });
    case ISupportLayerType.WMS:
      return new WMSLayer({
        url: item.url,
        id: item.id,
        legendEnabled
      });
    case ISupportLayerType.VECTOR_TILE:
      return new VectorTileLayer({
        id: item.id,
        url: item.url
      });
    case ISupportLayerType.MAP_IMAGE:
      return new MapImageLayer({
        id: item.id,
        url: item.url,
        legendEnabled
      });
    default:
      return null;
  }
};

export const cleanFeatureLayer = async (featureLayer: FeatureLayer) => {
  const result = await featureLayer.queryFeatures();
  featureLayer.applyEdits({
    deleteFeatures: result.features
  });
};

export const cleanFeatureLayerAndRemove = async (featureLayer: FeatureLayer, map: __esri.Map) => {
  const result = await featureLayer.queryFeatures();

  await featureLayer.applyEdits({
    deleteFeatures: result.features
  });
  map.remove(featureLayer);
};

export const hasAnyBeamLayer = (map: ArcGisMap | null) =>
  layerInMap(map, editBeamLayerId) || layerInMap(map, createBeamsLayerId) || layerInMap(map, beamLayerId);
