import axios from 'axios';
import { useEffect, useState } from 'react';
import Loader from '../loader';

interface ImageAlreadyLoaded {
  url: string;
  src: string;
}

const imagesAllreadyLoaded: ImageAlreadyLoaded[] = [];

interface IImageFromUrlProps {
  url: string;
  alt?: string;
  title?: string;
  height?: string;
  className?: string;
  forceLoad?: boolean;
}

export const loadImage = async (url: string) => {
  const res = await axios.get(url, {
    responseType: 'arraybuffer'
  });
  const blob = new Blob([res.data], { type: res.headers['content-type'] });
  const src = URL.createObjectURL(blob);
  imagesAllreadyLoaded.push({
    src,
    url
  });
  return src;
};

const ImageFromUrl = (props: IImageFromUrlProps) => {
  const { url, alt = '', height, className, forceLoad = false, title } = props;

  const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState('');

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const imageSrc = await loadImage(url);
        setSrc(imageSrc);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    const exist = imagesAllreadyLoaded.find(item => item.url === url);
    if (exist && !forceLoad) {
      setSrc(exist.src);
    } else {
      if (forceLoad && exist) {
        //remove item
        const index = imagesAllreadyLoaded.findIndex(item => item.url === url);
        imagesAllreadyLoaded.splice(index, 1);
      }
      load();
    }
  }, [forceLoad, src, url]);

  if (loading) {
    return (
      <span className={className}>
        <Loader size="sm" inline />
      </span>
    );
  }

  if (!src) {
    return null;
  }

  return <img src={src} alt={alt} height={height} className={className} title={title} />;
};

export const getAllReadyLoadImage = (url: string) => {
  return imagesAllreadyLoaded.find(item => item.url === url);
};

export default ImageFromUrl;
