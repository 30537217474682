import { IRootState } from 'config/store';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AvailabilityIcon from './AvailabilityIcon';
import GaugeIcon from './GaugeIcon';
import LabelValueOverview from './OverviewLabelValue';

const SystemPerformance = () => {
  const { t } = useTranslation('translation');
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);

  if (currentChart?.type !== 'OVERVIEW') {
    return null;
  }

  return (
    <Card className="sat-system-card">
      <div className="text-center h4 mb-4">{t('results.viz.overview.systemPerformance')}</div>
      <div className="text-center">
        <LabelValueOverview labelValue={currentChart.forwardCapacity} icon={<GaugeIcon value={currentChart.forwardCapacity.value} />} />
        <LabelValueOverview
          labelValue={currentChart.forwardAvailability}
          icon={<AvailabilityIcon value={currentChart.forwardAvailability.value} />}
        />
        <LabelValueOverview labelValue={currentChart.returnCapacity} icon={<GaugeIcon value={currentChart.returnCapacity.value} />} />
        <LabelValueOverview
          labelValue={currentChart.returnAvailability}
          icon={<AvailabilityIcon value={currentChart.returnAvailability.value} />}
          className="mb-0"
        />
      </div>
    </Card>
  );
};

export default SystemPerformance;
