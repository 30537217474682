import classNames from 'classnames';
import { useState } from 'react';
import { MissionService } from 'shared/model/mission.model';
import ImageFromBase64 from 'shared/widgets/images/imageFromBase64';
import DeleteMissionUserService from './DeleteMissionUserService';
import './missionServiceItem.scss';

interface IMissionServiceItemProps {
  item: MissionService;
  readonly: boolean;
}

const MissionServiceItem = (props: IMissionServiceItemProps) => {
  const { item, readonly } = props;

  const [visible, setVisible] = useState(false);

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  return (
    <div className="mission-service-item mb-4" onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div className="frequency-avatar mr-2">
        <ImageFromBase64 data={item.image} height="24px" />
      </div>
      <div className="flex-grow-1 mx-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mission-form-item-name">{item.name}</div>
          <div className="mission-form-item-action">
            {visible && !readonly && (
              <div
                className={classNames('animate__animated text-nowrap', {
                  animate__fadeIn: visible
                })}
              >
                <DeleteMissionUserService item={item} />
              </div>
            )}
          </div>
        </div>
        <hr className="my-1" />
        <div className="text-info">{item.category}</div>
      </div>
    </div>
  );
};

export default MissionServiceItem;
