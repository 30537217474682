import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import { Moment } from 'moment';
import { acceptAccessRequest, denyAccessRequest, getMyNotifications, getProjectNotifications } from 'shared/api/api';
import { sortByDate } from 'shared/utils/array-utils';
import { Notification } from '../model/notification.model';

const initialState = {
  loading: false,
  updating: false,
  requestId: -1,
  updateSuccess: false,
  notifications: [] as Notification[],
  error: null as any
};

export type NotificationState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchEntitiesStart: state => {
      state.loading = true;
      state.updateSuccess = false;
      state.error = null;
      state.requestId = -1;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<Notification[]>) => {
      state.loading = false;
      state.notifications = action.payload;
    },
    updateRequestStart: (state, action: PayloadAction<number>) => {
      state.updating = true;
      state.requestId = action.payload;
      state.error = null;
    },
    updateRequestFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.requestId = action.payload;
      state.updateSuccess = false;
    },
    updateRequestSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
    },
    reset: state => {
      state.loading = false;
      state.updateSuccess = false;
      state.updating = false;
      state.notifications = [];
      state.error = null;
      state.requestId = -1;
    }
  }
});

const { fetchEntitiesStart, fetchEntitiesFailed, fetchEntitiesSuccess, updateRequestStart, updateRequestFailed, updateRequestSuccess } =
  slice.actions;

export const { reset } = slice.actions;

export const fetchMyNotifications =
  (from: Moment): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Notification[]> = await getMyNotifications(from.toDate().getTime());
      response.data.sort(sortByDate('date'));
      dispatch(fetchEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const fetchProjectNotifications =
  (projectId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Notification[]> = await getProjectNotifications(projectId);
      response.data.sort(sortByDate('date'));
      dispatch(fetchEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const updateAcceptAccessRequest =
  (requestId: number, accept: boolean): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateRequestStart(requestId));
      accept ? await acceptAccessRequest(requestId) : await denyAccessRequest(requestId);
      dispatch(updateRequestSuccess());
    } catch (error) {
      dispatch(updateRequestFailed(error));
    }
  };

export default slice.reducer;
