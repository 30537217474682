import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { Card, NavItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useAuthentication from 'shared/landing/useAuthentication';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import './profilMenu.scss';

const ProfilMenu = () => {
  const { t } = useTranslation('translation');
  const { userInfo, msalInstance, isAuthorized } = useAuthentication();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const items = useMemo(() => {
    return [
      {
        text: t('profil.contactInfo'),
        key: 'contact',
        icon: <FontAwesomeIcon icon={['far', 'user']} />,
        show: isAuthorized
      },
      {
        text: t('profil.settings'),
        key: 'settings',
        icon: <FontAwesomeIcon icon="cog" />,
        show: isAuthorized
      },
      {
        text: t('profil.logout'),
        key: 'logout',
        icon: <FontAwesomeIcon icon="power-off" />,
        show: true
      }
    ];
  }, [t, isAuthorized]);

  const showMenu = () => {
    setShow(true);
  };

  const hideMenu = () => {
    setShow(false);
  };

  const onClickTo = (to: string) => () => {
    setShow(false);
    if (to.includes('logout')) {
      msalInstance.logoutRedirect();
    } else {
      history.push(to);
    }
  };

  return (
    <NavItem className="profil-nav-item">
      {show ? (
        <Card className="profil-menu" onMouseLeave={hideMenu}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="profil-name" onClick={onClickTo('/profil')}>
              {userInfo?.name}
            </div>
            <div onClick={hideMenu}>
              <FontAwesomeIcon icon="bars" size="2x" />
            </div>
          </div>

          <div className="profil-items">
            {items
              .filter(item => item.show)
              .map(item => (
                <div key={item.key} className="profil-item" onClick={onClickTo(`/profil?tab=${item.key}`)}>
                  <div className="profil-item-text">{item.text}</div>
                  <div className="profil-item-icon">{item.icon}</div>
                </div>
              ))}
          </div>
        </Card>
      ) : (
        <SkyTButton variant="outline-primary" onClick={showMenu} className="profil-btn">
          <FontAwesomeIcon icon="bars" size="2x" />
        </SkyTButton>
      )}
    </NavItem>
  );
};

export default ProfilMenu;
