import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import { IRootState } from 'config/store';
import useTempSatelliteLayer from 'modules/map/layers/satellite/useTempSatelliteLayer';
import { currentView } from 'modules/map/map';
import React, { ChangeEvent, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { getGeometriesOfAreaOfInterest } from 'shared/utils/model-utils';
import { roundOrbitalPosition } from 'shared/utils/string-utils';
import './configure-satellite.scss';

const ConfigureSatellitePosition = () => {
  const { t } = useTranslation('translation');

  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);

  useTempSatelliteLayer();

  const defaultPosition = useMemo(() => {
    let areaGeoms: Polygon[] = [];

    missions.forEach(mission => {
      mission.areaOfInterests.forEach(area => {
        const polygons = getGeometriesOfAreaOfInterest(area);
        areaGeoms = areaGeoms.concat(polygons);
      });
    });
    if (areaGeoms.length === 0) {
      return 0;
    }

    const all = geometryEngine.union(areaGeoms);
    return all.extent.center.longitude;
  }, [missions]);

  const onLongitudeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (currentView && e.target.value.length > 0) {
      const center = currentView.center;
      center.longitude = Number(e.target.value);
      currentView.goTo(
        {
          center
        },
        {
          animate: false
        }
      );
    }
  };

  const lon = roundOrbitalPosition(underEditionSatellite?.orbitalPosition ? underEditionSatellite.orbitalPosition : defaultPosition);

  return (
    <>
      <Form.Group>
        <Form.Label>{t('satellite.orbitalPositionLabel')}</Form.Label>
        <SkyTTextInput type="number" min={-180} max={180} step={0.0001} value={lon} onChange={onLongitudeChange}></SkyTTextInput>
      </Form.Group>
      <div className="text-center mt-4">{t('satellite.orbitalPositionDescription')}</div>
    </>
  );
};

export default ConfigureSatellitePosition;
