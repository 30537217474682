import { IRootState } from 'config/store';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BatteryIcon from './BatteryIcon';
import LabelValueOverview from './OverviewLabelValue';
import TemperatureIcon from './TemperatureIcon';
import WaveIcon from './WaveIcon';

const SatelliteRessourceUsage = () => {
  const { t } = useTranslation('translation');
  const currentChart = useSelector(({ charts }: IRootState) => charts.currentChart);

  if (currentChart?.type !== 'OVERVIEW') {
    return null;
  }

  return (
    <Card className="sat-system-card">
      <div className="text-center h4 mb-4">{t('results.viz.overview.SatelliteRessourceUsage')}</div>
      <div className="text-center">
        <LabelValueOverview
          labelValue={currentChart.forwardBandwidthGatewayUsage}
          icon={<WaveIcon value={currentChart.forwardBandwidthGatewayUsage.value} />}
        />
        <LabelValueOverview
          labelValue={currentChart.returnBandwidthGatewayUsage}
          icon={<WaveIcon value={currentChart.returnBandwidthGatewayUsage.value} />}
        />
        <LabelValueOverview
          labelValue={currentChart.dissipationIn65Zone}
          icon={<TemperatureIcon value={currentChart.dissipationIn65Zone.value} />}
        />
        <LabelValueOverview
          labelValue={currentChart.dissipationIn85Zone}
          icon={<TemperatureIcon value={currentChart.dissipationIn85Zone.value} />}
        />
        <LabelValueOverview
          labelValue={currentChart.totalConsumption}
          icon={<BatteryIcon value={currentChart.totalConsumption.value} />}
          className="mb-0"
        />
      </div>
    </Card>
  );
};

export default SatelliteRessourceUsage;
