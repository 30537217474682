import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import {
  createExport,
  createProject,
  deleteProject,
  getFavoritesProjects,
  getProject,
  getProjectsOfCustomer,
  updateFavoriteProject,
  updateProject
} from 'shared/api/api';
import { Export } from 'shared/model/export.model';
import { sortByDate } from 'shared/utils/array-utils';
import { Project, ProjectDetails } from '../model/project.model';
import { reset as resetAreaOfInterest } from './areaOfInterestSlice';

const initialState = {
  loading: false,
  updating: false,
  updateSuccess: false,
  exporting: false,
  exportSuccess: false,
  projects: [] as Project[],
  project: null as Project | ProjectDetails | null,
  export: null as Export | null,
  exports: [] as Export[],
  error: null as any
};

export type ProjectState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    fetchEntitiesStart: state => {
      state.loading = true;
      state.updateSuccess = false;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<Project[]>) => {
      state.loading = false;
      state.projects = action.payload;
    },
    selectProject: (state, action: PayloadAction<Project | ProjectDetails>) => {
      state.loading = false;
      state.project = action.payload;
    },
    updateStart: state => {
      state.loading = false;
      state.updating = true;
      state.updateSuccess = false;
    },
    updateSuccess: (state, action: PayloadAction<Project>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.project = action.payload;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
      state.project = null;
    },
    toggleFavoriteSuccess: (state, action: PayloadAction<Project>) => {
      state.updating = false;
      const updatedProject = action.payload;
      if (state.project?.id === updatedProject.id) {
        state.project = updatedProject;
      }
      state.projects = state.projects.map(item => (item.id === updatedProject.id ? updatedProject : item));
    },
    exportStart: state => {
      state.exporting = true;
      state.exportSuccess = false;
      state.export = null;
    },
    exportFailed: (state, action: PayloadAction<any>) => {
      state.exporting = false;
      state.exportSuccess = false;
      state.error = action.payload;
    },
    exportSuccess: (state, action: PayloadAction<Export>) => {
      state.exporting = false;
      state.export = action.payload;
      state.exportSuccess = true;
    },
    resetUpdateSuccess: state => {
      state.updateSuccess = false;
    },
    reset: state => {
      state.loading = false;
      state.updateSuccess = false;
      state.updating = false;
      state.projects = [];
      state.project = null;
      state.export = null;
      state.exportSuccess = false;
      state.exporting = false;
      state.exports = [];
      state.error = null;
    }
  }
});

const {
  fetchEntitiesStart,
  fetchEntitiesFailed,
  fetchEntitiesSuccess,
  updateStart,
  updateFailed,
  updateSuccess,
  deleteSuccess,
  exportStart,
  exportFailed,
  exportSuccess,
  toggleFavoriteSuccess
} = slice.actions;

export const { reset, selectProject, resetUpdateSuccess } = slice.actions;

export const fetchFavoritesProjects = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchEntitiesStart());
    const response: AxiosResponse<Project[]> = await getFavoritesProjects();
    response.data.sort(sortByDate('createdAt'));
    dispatch(fetchEntitiesSuccess(response.data));
  } catch (error) {
    dispatch(fetchEntitiesFailed(error));
  }
};

export const fetchProjects =
  (customerId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Project[]> = await getProjectsOfCustomer(customerId);
      response.data.sort(sortByDate('createdAt'));
      dispatch(fetchEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const fetchProject =
  (projectId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(resetAreaOfInterest());
      dispatch(fetchEntitiesStart());
      const response = await getProject(projectId);
      dispatch(selectProject(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const saveProject =
  (customerId: number | string, project: Project): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = project.id ? await updateProject(project) : await createProject(customerId, project);
      dispatch(updateSuccess(response.data));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeProject =
  (project: Project): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const idCustomer = project.idCustomer;
      await deleteProject(project.id);
      await dispatch(fetchProjects(idCustomer));
      dispatch(deleteSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const exportProjectConfiguration =
  (projectId: string | number, configurationId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(exportStart());
      const response = await createExport(projectId, configurationId);
      dispatch(exportSuccess(response.data));
    } catch (error) {
      dispatch(exportFailed(error));
    }
  };

export const toggleFavoriteProject =
  (projectId: string | number, fetchFavorites = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = await updateFavoriteProject(projectId);

      dispatch(toggleFavoriteSuccess(response.data));
      if (fetchFavorites) {
        dispatch(fetchFavoritesProjects());
      }
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export default slice.reducer;
