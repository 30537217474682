import Polygon from '@arcgis/core/geometry/Polygon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IRootState } from 'config/store';
import isArray from 'lodash/isArray';
import { currentView } from 'modules/map/map';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AreaOfInterest } from 'shared/model/areaOfInterest.model';
import { configureAreaOfInteretsRoute } from 'shared/routes/routes';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import './areaOfInterets.scss';
import DeleteAreaOfInterestBtn from './deleteAreaOfInterestBtn';
import AreaTypeIcon from './icons/AreaTypeIcon';

interface IAreaOfInteretItemProps {
  area: AreaOfInterest;
}

const AreaOfInteretItem = (props: IAreaOfInteretItemProps) => {
  const history = useHistory();
  const project = useSelector(({ project }: IRootState) => project.project);
  const hasWriteAccess = projectHasWriteAccess(project);

  const { projectId, missionId } = useParams<{ projectId: string; missionId: string }>();

  const { area } = props;

  const [visible, setVisible] = useState(false);

  const onClick = () => {
    let geomJson = JSON.parse(area.latLngs);

    let geometries: any[] = [];
    if (isArray(geomJson)) {
      geometries = geometries.concat(geomJson);
    } else {
      geometries.push(geomJson);
    }

    const polygons = geometries.map(item => new Polygon(item));
    currentView?.goTo(polygons);
  };

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  const onUpdate = () => {
    const newHistory = configureAreaOfInteretsRoute.getPath(projectId, missionId, area.id);
    history.push(newHistory);
  };

  return (
    <div className="area-of-interet-item hover-white" onClick={onClick} onMouseEnter={showActions} onMouseLeave={hideActions}>
      <div>
        <AreaTypeIcon type={area.type} className="mr-1" />
        {area.name}
      </div>
      {hasWriteAccess && visible && (
        <div
          className={classnames('animate__animated', {
            animate__fadeIn: visible
          })}
        >
          <SkyTButton variant="link" onClick={onUpdate} size="sm" className="text-primary">
            <FontAwesomeIcon icon="pencil-alt" />
          </SkyTButton>
          <DeleteAreaOfInterestBtn areOfInterest={area} />
        </div>
      )}
    </div>
  );
};

export default AreaOfInteretItem;
