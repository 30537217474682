import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Mission, MissionDetails, MissionUserTerminal } from 'shared/model/mission.model';
import { saveMission } from 'shared/reducers/missionSlice';
import { sortByLabel, sortByName } from 'shared/utils/array-utils';
import { getAvailableUserTerminals, projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTSelect, { ILabelValueOption } from 'shared/widgets/form/skyTSelect';
import NoResult from 'shared/widgets/no-result';
import MissionTerminamWarningIcon from '../shared/missionTerminalsWarningIcon';
import MissionUserTerminalItem from './missionUserTerminalItem';

const SelectMissionUserTerminals = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions);
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const bandFilter = useSelector(({ mission }: IRootState) => mission.bandFilter);
  const project = useSelector(({ project }: IRootState) => project.project);
  const hasWriteAccess = projectHasWriteAccess(project);
  const [value, setValue] = useState<any>();

  if (selectedMissions.length !== 1) {
    return null;
  }

  const mission = selectedMissions[0];

  const userTerminals = getAvailableUserTerminals(referential, mission.isCivil)
    .filter(item => !mission.userTerminals.some(ut => ut.name === item.name))
    .filter(item => !bandFilter || item.category === bandFilter);

  const terminalsOptions = userTerminals.reduce((agg: any[], current) => {
    const currentCategory = current.category;
    let existingCategory = agg.find(item => item.label === currentCategory);
    if (!existingCategory) {
      existingCategory = {
        label: currentCategory,
        options: []
      };
      agg.push(existingCategory);
    }
    existingCategory.options.push({ label: current.name, value: current.name });

    return agg;
  }, []);

  terminalsOptions.sort(sortByLabel());

  const onChange = (selection: ILabelValueOption<string>) => {
    const selectedTerminal = userTerminals.find(item => item.name === selection.value);

    const newUserTerminals = [...mission.userTerminals];
    if (selectedTerminal) {
      const missionTerminal: MissionUserTerminal = {
        ...selectedTerminal,
        repartitionPercentage: 0
      };
      newUserTerminals.push(missionTerminal);

      const toSend: Mission = {
        ...mission,
        userTerminals: newUserTerminals
      };
      dispatch(saveMission(mission.projectId, toSend));
      setValue(null);
    }
  };

  const ordered = [...mission.userTerminals].sort(sortByName());

  return (
    <Form.Group className="">
      <Form.Label className="mb-4">
        {t('translation:mission.terminals')}
        <Badge pill variant="info" className="ml-2">
          {mission.userTerminals.length}
        </Badge>
        <MissionTerminamWarningIcon mission={mission as MissionDetails} />
      </Form.Label>
      {mission.userTerminals.length === 0 ? (
        <>{!hasWriteAccess && <NoResult />}</>
      ) : (
        <Scrollbars autoHeight autoHeightMax={200} className="skyt-scroll-bar mb-2">
          {ordered.map(item => (
            <MissionUserTerminalItem item={item} readonly={!hasWriteAccess} key={item.name} />
          ))}
        </Scrollbars>
      )}
      {hasWriteAccess && terminalsOptions.length > 0 && (
        <SkyTSelect
          value={value}
          options={terminalsOptions}
          placeholder={t('translation:mission.terminalsPlaceholder')}
          onChange={onChange}
          isDisabled={!bandFilter}
        />
      )}
    </Form.Group>
  );
};
export default SelectMissionUserTerminals;
