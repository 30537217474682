import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { isProd } from './env';

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProd(),
    fallbackLng: 'en',
    whitelist: ['en'],
    load: 'languageOnly',
    ns: ['translation'],
    defaultNS: 'translation',
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  });
export default i18n;
