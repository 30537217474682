// import webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Point from '@arcgis/core/geometry/Point';
import { lngLatToXY } from '@arcgis/core/geometry/support/webMercatorUtils';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { IRootState } from 'config/store';
import debounce from 'lodash/debounce';
import { gatewayMarkerSymbol, tempGatewayLayer } from 'modules/map/layers/gateway/gateway-layer-def';
import { currentView } from 'modules/map/map';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { ProjectDetails } from 'shared/model/project.model';
import { setUnderEditionFeature } from 'shared/reducers/GatewaySlice';
import { showMap } from 'shared/reducers/mapSlice';
import { configureGatewayRoute, gatewaysRoute, projectPageRoute } from 'shared/routes/routes';
import { createGatewayGraphic } from 'shared/utils/map-utils';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import Loader from 'shared/widgets/loader';

let sketchViewModel: SketchViewModel | undefined;

const EditGateway = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');

  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const loading = useSelector(({ project }: IRootState) => project.loading);
  const underEditionFeature = useSelector(({ gateway }: IRootState) => gateway.underEditionFeature);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const updating = useSelector(({ gateway }: IRootState) => gateway.updating);
  const gatewayModels = useSelector(({ referential }: IRootState) => referential.gatewayModels);

  const { projectId, gatewayId } = useParams<{ projectId: string; gatewayId: string }>();

  const hasWriteAccess = projectHasWriteAccess(project);

  const gateways = project?.gateways ?? [];
  const gateway = gateways.find(item => item.id === Number(gatewayId));

  const isNew = gatewayId === 'new';

  const [name, setName] = useState('');

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
    return () => {
      arcgisMap?.remove(tempGatewayLayer);
    };
  }, [dispatch, mapIsDisplayed, arcgisMap]);

  useEffect(() => {
    tempGatewayLayer.removeAll();
    arcgisMap?.add(tempGatewayLayer);
    if (!currentView) {
      return;
    }

    if (gateway) {
      setName(gateway.name);

      const graphic = createGatewayGraphic(
        gateway.longitude,
        gateway.latitude,
        gateway.name,
        gateway.bandwidths,
        gateway.polarization,
        gateway.modelId,
        gateway.id,
        true,
        true
      );

      tempGatewayLayer.add(graphic);
      dispatch(setUnderEditionFeature(graphic));

      sketchViewModel = new SketchViewModel({
        layer: tempGatewayLayer,
        view: currentView,
        defaultUpdateOptions: {
          enableZ: false
        }
      });

      const updateUnderEditionFeature = debounce((e: any) => {
        if (e.state === 'active') {
          const moved = e.graphics[0];
          dispatch(setUnderEditionFeature(moved));
        }
      }, 200);

      sketchViewModel.on('update', updateUnderEditionFeature);
      sketchViewModel.update(graphic);
    } else if (isNew) {
      sketchViewModel = new SketchViewModel({
        layer: tempGatewayLayer,
        view: currentView,
        defaultCreateOptions: {
          hasZ: false
        }
      });

      sketchViewModel.on('create', (event: __esri.SketchViewModelCreateEvent) => {
        const graphic = event.graphic;
        if (graphic) {
          graphic.symbol = gatewayMarkerSymbol;
          dispatch(setUnderEditionFeature(graphic));
        }
      });

      sketchViewModel.create('point');
    }
  }, [arcgisMap, dispatch, gateway, isNew, gatewayModels]);

  if (!project) {
    return null;
  }
  if (loading) {
    return <Loader />;
  }

  if (!hasWriteAccess) {
    history.push(projectPageRoute.getPath(projectId));
    return null;
  }

  const onNameChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (underEditionFeature) {
      const newFeature = underEditionFeature.clone();
      newFeature.setAttribute('name', name);
      dispatch(setUnderEditionFeature(newFeature));
    }
  };

  const onCancel = () => {
    history.push(gatewaysRoute.getPath(projectId));
  };

  const onSave = async () => {
    const feature = tempGatewayLayer.graphics.getItemAt(0);
    if (feature) {
      feature.setAttribute('name', name);
      dispatch(setUnderEditionFeature(feature.clone()));
      const id = feature.getAttribute('id') ? feature.getAttribute('id') : 'new';
      history.push(configureGatewayRoute.getPath(project.id, id));
    }
  };

  const onLongitudeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newLongitude = Number(e.target.value);

    const feature = tempGatewayLayer.graphics.getItemAt(0);
    if (feature) {
      const geom = feature.geometry as Point;
      const pos = lngLatToXY(newLongitude, geom.latitude);
      const newGeom = geom.clone();
      newGeom.x = pos[0];
      feature.geometry = newGeom;
      dispatch(setUnderEditionFeature(feature.clone()));
    }
  };

  const onLatitudeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newLatitude = Number(e.target.value);

    const feature = tempGatewayLayer.graphics.getItemAt(0);
    if (feature) {
      const geom = feature.geometry as Point;
      const pos = lngLatToXY(geom.longitude, newLatitude);
      const newGeom = geom.clone();
      newGeom.y = pos[1];
      feature.geometry = newGeom;
      dispatch(setUnderEditionFeature(feature.clone()));
    }
  };

  const canSave = name.length > 0 && underEditionFeature && !updating;

  const point = underEditionFeature ? (underEditionFeature.geometry as Point) : undefined;

  const longitude = point ? point.longitude : 0;
  const latitude = point ? point.latitude : 0;

  return (
    <div className="create-gateway">
      <div className="create-gateway-title">{t(isNew ? 'gateways.createGatewayBtn' : 'gateways.editGateway')}</div>
      <div className="form">
        <Form.Group className="mt-2 mb-1">
          <Form.Label>{t('gateways.labelGatewayName')}</Form.Label>
          <SkyTTextInput type="text" onChange={onNameChange} value={name} disabled={updating}></SkyTTextInput>
        </Form.Group>
        {isNew && <div className="mt-4 text-center">{t('gateways.createGatewayHelp')}</div>}
        {underEditionFeature && (
          <div className="mt-4">
            <Form.Group className="mt-2 mb-1">
              <Form.Label>{t('longitude')}</Form.Label>
              <SkyTTextInput type="number" step={0.1} onChange={onLongitudeChange} value={longitude} disabled={updating}></SkyTTextInput>
            </Form.Group>

            <Form.Group className="mt-2 mb-1">
              <Form.Label>{t('latitude')}</Form.Label>
              <SkyTTextInput type="number" step={0.1} onChange={onLatitudeChange} value={latitude} disabled={updating}></SkyTTextInput>
            </Form.Group>
          </div>
        )}
      </div>
      <div className="actions">
        <SkyTMapButton variant="outline-info" type="button" onClick={onCancel}>
          {t('cancel')}
        </SkyTMapButton>
        <SkyTMapButton variant="outline-info" type="button" onClick={onSave} disabled={!canSave}>
          {t('next')}
        </SkyTMapButton>
      </div>
    </div>
  );
};

export default EditGateway;
