import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getEnv } from 'config/env';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/fr';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';
import Landing from 'shared/landing/landing';
import DebugInfo from 'shared/widgets/map/debug/debugInfo';
import './App.scss';

interface IAppProps {
  pca: IPublicClientApplication;
}

const App = (props: IAppProps) => {
  const { i18n } = useTranslation();
  const { pca } = props;

  const language = i18n.language;
  moment.locale(language);

  useEffect(() => {
    console.log(`SkyT, Version v${getEnv().version}`);
    numeral.locale('fr');
  }, []);

  return (
    <Router>
      <MsalProvider instance={pca}>
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          autoClose={3000}
          transition={Slide}
          className="toastify-container"
          toastClassName="toastify-toast"
        />
        <Landing />
        <DebugInfo />
      </MsalProvider>
    </Router>
  );
};

export default App;
