import classnames from 'classnames';
import React, { ForwardedRef, forwardRef } from 'react';
import ReactSwitch, { ReactSwitchProps } from 'react-switch';

type htmlInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type excludedHTMLInputProps = 'onFocus' | 'onBlur' | 'onKeyUp' | 'onChange' | 'ref' | keyof ReactSwitchProps;

type allowedHTMLinputProps = Omit<htmlInputProps, excludedHTMLInputProps>;

const SkyTToggleSwitch = forwardRef((props: ReactSwitchProps & allowedHTMLinputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { className, ...rest } = props;
  return (
    <ReactSwitch
      className={classnames('skyy-toggle-switch', className)}
      onColor="#53b8d9"
      checkedIcon={false}
      uncheckedIcon={false}
      width={50}
      {...rest}
    />
  );
});

export default SkyTToggleSwitch;
