import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IRootState } from 'config/store';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Project } from 'shared/model/project.model';
import { resetUpdateSuccess, saveProject } from 'shared/reducers/projectSlice';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import Loader from 'shared/widgets/loader';
import './project-description.scss';

interface IProjectDescriptionProps {
  project: Project;
}

const ProjectDescription = (props: IProjectDescriptionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const updateSuccess = useSelector(({ project }: IRootState) => project.updateSuccess);
  const updating = useSelector(({ project }: IRootState) => project.updating);

  const { project } = props;

  const [edition, setEdition] = useState(false);
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      description: project.description
    }
  });

  const onSubmit = (data: any) => {
    if (edition) {
      const toUpdate = {
        ...project,
        ...data
      };

      dispatch(saveProject(project.idCustomer, toUpdate));
    }
  };

  const toggleEdition = () => {
    reset({
      description: project.description
    });

    setEdition(!edition);
  };

  useEffect(() => {
    if (updateSuccess && edition) {
      setEdition(false);
      dispatch(resetUpdateSuccess());
      reset({
        description: project.description
      });
    }
  }, [updateSuccess, edition, dispatch, reset, project.description]);

  const showActions = () => {
    setVisible(true);
  };

  const hideActions = () => {
    setVisible(false);
  };

  const description = project.description ? DOMPurify.sanitize(project.description) : '';

  return (
    <div className="project-description" onMouseEnter={showActions} onMouseLeave={hideActions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex align-items-center mb-2">
          <h3 className="mb-2 font-weight-bold">Description</h3>
          {!edition && projectHasWriteAccess(project) && visible && (
            <div
              className={classNames('ml-2 animate__animated text-nowrap', {
                animate__fadeIn: visible
              })}
            >
              <SkyTButton variant="outline-info" size="sm" type="button" onClick={toggleEdition}>
                <FontAwesomeIcon icon="pencil-alt" />
              </SkyTButton>
            </div>
          )}
        </div>
        <Scrollbars autoHide autoHeight autoHeightMax={160} className="skyt-scroll-bar">
          {edition ? (
            <div className="mb-1">
              <SkyTTextInput as="textarea" {...register('description')} rows={5} disabled={updating} />
            </div>
          ) : (
            <div className="description-content html" dangerouslySetInnerHTML={{ __html: description }}></div>
          )}
        </Scrollbars>

        {edition && (
          <div className="mt-2 text-right">
            {updating && (
              <span className="mr-2">
                <Loader inline size="sm" />
              </span>
            )}
            <SkyTButton size="sm" type="button" variant="outline-info" onClick={toggleEdition} className="mr-2" disabled={updating}>
              {t('cancel')}
            </SkyTButton>
            <SkyTButton size="sm" type="submit" variant="outline-info" disabled={updating}>
              {t('save')}
            </SkyTButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default ProjectDescription;
