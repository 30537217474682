import { Accordion } from 'react-bootstrap';
import { useRouteMatch } from 'react-router';
import {
  areaOfInteretsRoute,
  areaRoute,
  configurationsSatelliteRoute,
  configureAreaOfInteretsRoute,
  configureConfigurationSatelliteRoute,
  configureGatewayRoute,
  configureSatelliteGatewaysRoute,
  configureSatellitePositionRoute,
  configureSatelliteReflectorRoute,
  configureSatelliteTypeRoute,
  coverageRoute,
  coveragesRoute,
  editSatelliteRoute,
  exportsRoute,
  exportsSatelliteRoute,
  gatewayRoute,
  gatewaysRoute,
  poiRoute,
  poisRoute,
  satellitesRoute
} from 'shared/routes/routes';
import AreaOfInteretsTab, { areaOfInterestKey } from '../areaOfInterets/areaOfInteretsTab';
import ConfigureAreaOfInterests from '../areaOfInterets/configureAreaOfInterests';
import ConfigurationsTab, { configurationsKey } from '../configurations/configurationsTab';
import ConfigureSatelliteConfiguration from '../configurations/configure/ConfigureSatelliteConfiguration';
import EditCoverage from '../configurations/configure/coverage/EditCoverage';
import ExportsTab, { exportKey } from '../exports/exportsTab';
import ConfigureGateway from '../gateways/configureGateway';
import EditGateway from '../gateways/editGateway';
import GatewaysTab, { gatewayKey } from '../gateways/GatewaysTab';
import MissionsTab, { missionTabKey } from '../missions/missionsTab';
import EditPoi from '../pois/editPoi';
import PoiTab, { poiKey } from '../pois/PoiTab';
import ConfigureSatellite from '../satellites/configure/configureSatellite';
import CreateOrEditSatellite from '../satellites/edit/createOrEditSatellite';
import SatellitesTab, { satelliteKey } from '../satellites/satellitesTab';

const FullVersion = () => {
  // const isMissionsRoute = useRouteMatch([missionsRoute.path, missionRoute.path])?.isExact;
  const isAreaOfInteretsRoute = useRouteMatch([areaOfInteretsRoute.path])?.isExact;
  const isPoisRoute = useRouteMatch([poisRoute.path])?.isExact;
  const isGatewaysRoute = useRouteMatch([gatewaysRoute.path])?.isExact;
  const isConfigureGatewayPath = useRouteMatch([configureGatewayRoute.path])?.isExact;
  const isSatellitesRoutePath = useRouteMatch([satellitesRoute.path])?.isExact;
  const isEditSatellitePath = useRouteMatch(editSatelliteRoute.path)?.isExact;
  const isConfigureSatellitePath = useRouteMatch([
    configureSatelliteTypeRoute.path,
    configureSatellitePositionRoute.path,
    configureSatelliteGatewaysRoute.path,
    configureSatelliteReflectorRoute.path
  ])?.isExact;
  const isExportPath = useRouteMatch([exportsRoute.path, exportsSatelliteRoute.path])?.isExact;
  const isconfigureAreaOfInteretsPath = useRouteMatch([configureAreaOfInteretsRoute.path])?.isExact;
  const isConfigurationsSatelliteRoute = useRouteMatch([configurationsSatelliteRoute.path])?.isExact;
  const isConfigureConfigurationSatelliteRoute = useRouteMatch([configureConfigurationSatelliteRoute.path])?.isExact;
  const isCoveragesRoute = useRouteMatch([coveragesRoute.path, coverageRoute.path])?.isExact;
  const isAreaRoute = useRouteMatch([areaRoute.path])?.isExact;
  const isEditPoiRoute = useRouteMatch(poiRoute.path)?.isExact;
  const isEditGateWayRoute = useRouteMatch(gatewayRoute.path)?.isExact;

  if (isConfigureGatewayPath) {
    return <ConfigureGateway />;
  } else if (isEditSatellitePath) {
    return <CreateOrEditSatellite />;
  } else if (isConfigureSatellitePath) {
    return <ConfigureSatellite />;
  } else if (isconfigureAreaOfInteretsPath) {
    return <ConfigureAreaOfInterests />;
  } else if (isConfigureConfigurationSatelliteRoute) {
    return <ConfigureSatelliteConfiguration />;
  } else if (isCoveragesRoute || isAreaRoute) {
    return <EditCoverage />;
  } else if (isEditPoiRoute) {
    return <EditPoi />;
  } else if (isEditGateWayRoute) {
    return <EditGateway />;
  }

  let defaultActiveKey = missionTabKey;
  if (isAreaOfInteretsRoute) {
    defaultActiveKey = areaOfInterestKey;
  } else if (isPoisRoute) {
    defaultActiveKey = poiKey;
  } else if (isGatewaysRoute) {
    defaultActiveKey = gatewayKey;
  } else if (isSatellitesRoutePath) {
    defaultActiveKey = satelliteKey;
  } else if (isConfigurationsSatelliteRoute) {
    defaultActiveKey = configurationsKey;
  } else if (isExportPath) {
    defaultActiveKey = exportKey;
  }

  return (
    <Accordion defaultActiveKey={defaultActiveKey}>
      <MissionsTab />
      <AreaOfInteretsTab />
      <PoiTab />
      <GatewaysTab />
      <SatellitesTab />
      <ConfigurationsTab />
      <ExportsTab />
    </Accordion>
  );
};

export default FullVersion;
