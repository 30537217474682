// FOR BEAM COMPUTATION

export const MAX_LONGITUDE = 180;

/// A geostationary equatorial orbit (GEO) is a circular geosynchronous orbit in the plane of the Earth's equator
/// with a radius of approximately `42 164` km (measured from the center of the Earth)
export const RADIUS_GEO = 42164.137;
export const RADIUS_EARTH_EQUATOR = 6378.137;
export const RADIUS_EARTH_POLE = 6356.752;

export const SATELITTE_HEIGHT = RADIUS_GEO - RADIUS_EARTH_EQUATOR;
export const SATELITTE_HEIGHT_METERS = SATELITTE_HEIGHT * 1000;

export const BEAM_OVERLAP_FACTOR = Math.sqrt(3) / 2;
export const BEAM_SIZE_GLOBAL_AREA = 17.2;

/// Used to simplify polygon, corresponds to the deviation maximum from
/// the original path, in meters
export const MAX_DEVIATION_METER = 5_000.0;

// FOR BEAM LAYOUT

export const MULTI_BEAM_COLOR = [70, 180, 220];
export const STEERABLE_BEAM_COLOR = [255, 140, 0];
