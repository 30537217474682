import { IRootState } from 'config/store';
import { useSelector } from 'react-redux';
import LayerSwitcher from 'shared/widgets/map/layerSwitcher/layerSwitcher';
import MissionsPanel from './panel/missionsPanel';

const MapMenuPanel = () => {
  const mapMenuPanel = useSelector(({ map }: IRootState) => map.mapMenuPanel);

  switch (mapMenuPanel) {
    case 'layer-group':
      return <LayerSwitcher />;
    case 'missions':
      return <MissionsPanel />;
    default:
      return null;
  }
};

export default MapMenuPanel;
