import classnames from 'classnames';
import { IRootState } from 'config/store';
import { beamEirpDensityDef, defaultTx, ITxDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from './common-slider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    '-50': <span className={className}>-50</span>,
    '-45': <span className={className}>-45</span>,
    '-40': <span className={className}>-40</span>,
    '-35': <span className={className}>-35</span>,
    '-30': <span className={className}>-30</span>,
    '-25': <span className={className}>-25</span>,
    '-20': <span className={className}>-20</span>
  };
};

interface IEIRPDensitySliderProps {
  defaultValue?: number;
  disabled?: boolean;
}

const EIRPDensitySlider = (props: IEIRPDensitySliderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);

  const { defaultValue, disabled = false } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue ? debounceValue : 0);

  useEffect(() => {
    if (!disabled) {
      setValue(defaultValue);
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (
      previousDebounceValue !== debounceValue &&
      typeof previousDebounceValue !== 'undefined' &&
      typeof debounceValue !== 'undefined' &&
      !disabled
    ) {
      const newUnderEditionBeams = underEditionBeams.map(underEditionBeam => {
        if (underEditionBeam.selected) {
          const initialTx = underEditionBeam.tx ? underEditionBeam.tx : defaultTx;
          const tx: ITxDatabaseBeam = {
            ...initialTx,
            d: debounceValue
          };
          return {
            ...underEditionBeam,
            tx
          };
        }
        return { ...underEditionBeam };
      });
      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }
  }, [debounceValue, disabled, dispatch, previousDebounceValue, underEditionBeams]);

  const onChange = (e: number) => {
    setValue(e);
  };

  const marks = getMarks(disabled);

  const showMandatoryError = typeof value === 'undefined' && !disabled;

  return (
    <Form.Group className={classnames('common-slider', { 'text-danger': showMandatoryError })}>
      <Form.Label className="d-flex justify-content-center mb-0">{t('area.targets.EIRPDensity')}&nbsp;*</Form.Label>
      <SkyTSlider
        onChange={onChange}
        marks={marks}
        min={beamEirpDensityDef.min}
        max={beamEirpDensityDef.max}
        value={value}
        step={1}
        disabled={disabled}
        mandatory
      />
    </Form.Group>
  );
};

export default EIRPDensitySlider;
