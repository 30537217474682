import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { graphicToDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import useCreateBeamLayer from 'modules/map/layers/beams/useCreateBeamLayer';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Area } from 'shared/model/area.model';
import { saveAreas } from 'shared/reducers/areaSlice';
import { setCoverage } from 'shared/reducers/coverageSlice';
import { configurationsSatelliteRoute, coveragesRoute } from 'shared/routes/routes';
import { useWindowDimensions } from 'shared/utils/hook-utils';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import CoverageAreaEditor from '../../coverageArea/CoverageAreaEditor';
import CoverageTypeSelector from '../coverageTypeSelector';

const CreateAreas = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const updating = useSelector(({ configuration }: IRootState) => configuration.updating);
  const multipleUpdating = useSelector(({ area }: IRootState) => area.multipleUpdating);

  const { projectId, satelliteId, configurationId } = useParams<{ projectId: string; satelliteId: string; configurationId: string }>();

  const tempBeamLayer = useCreateBeamLayer();
  const { width } = useWindowDimensions();

  let scrollBarHeight = '260px';
  if (width && width > 1300) {
    scrollBarHeight = '285px';
  }
  const contentScrollBarStyle = {
    height: scrollBarHeight
  };

  const onCancel = () => {
    dispatch(setCoverage(null));
    history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
  };

  const onSave = async () => {
    if (tempBeamLayer) {
      const areas = tempBeamLayer.graphics.reduce((agg: Partial<Area>[], feature) => {
        const att = feature.attributes;

        const coverageId = att.coverageId as number;
        const areaOfInterestId = att.areaOfInterestId;

        const mission = missions.find(item => item.areaOfInterests.some(aoi => aoi.id === areaOfInterestId));

        const dbBeam = graphicToDatabaseBeam(feature, mission);

        let area = agg.find(area => area.coverageId === coverageId && area.areaOfInterestId === areaOfInterestId);
        if (!area) {
          area = {
            coverageId,
            areaOfInterestId,
            beamList: []
          };
          agg.push(area);
        }
        area.beamList?.push(dbBeam);
        return agg;
      }, []);

      if (areas.length > 0) {
        const coverageId = areas[0].coverageId as number;
        await dispatch(saveAreas(projectId, satelliteId, configurationId, coverageId, areas, true));
        history.push(coveragesRoute.getPath(projectId, satelliteId, configurationId));
      }
    }
  };

  return (
    <>
      <div className="mb-2">
        <CoverageTypeSelector />
      </div>
      <hr />
      <Scrollbars autoHide style={contentScrollBarStyle} className="skyt-scroll-bar">
        <CoverageAreaEditor />
      </Scrollbars>

      <hr />
      <div className="d-flex justify-content-around">
        <SkyTMapButton variant="outline-info" type="button" onClick={onCancel} disabled={updating || multipleUpdating}>
          {t('cancel')}
          <FontAwesomeIcon icon="times" className="ml-1" />
        </SkyTMapButton>
        <SkyTMapButton variant="outline-info" type="button" onClick={onSave}>
          {t('done')}
          <FontAwesomeIcon icon="check" className="ml-1" />
        </SkyTMapButton>
      </div>
    </>
  );
};

export default CreateAreas;
