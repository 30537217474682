import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import DOMPurify from 'dompurify';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Project } from 'shared/model/project.model';
import { toggleFavoriteProject } from 'shared/reducers/projectSlice';
import { requestAccessForProject } from 'shared/reducers/requestAccessSlice';
import { projectIsForbidden } from 'shared/utils/model-utils';
import ConfirmDialog from 'shared/widgets/dialogs/ConfirmDialog';
import './project-card.scss';

interface IProjectCardProps {
  project: Project;
}

const ProjectCard = (props: IProjectCardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');

  const currentCustomer = useSelector(({ customer }: IRootState) => customer.customer);
  const updating = useSelector(({ project }: IRootState) => project.updating);
  const requestAccessProjectId = useSelector(({ requestAccess }: IRootState) => requestAccess.requestAccessProjectId);
  const requestAccessSuccess = useSelector(({ requestAccess }: IRootState) => requestAccess.requestAccessSuccess);

  const { project } = props;

  const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false);

  const onClick = () => {
    if (projectIsForbidden(project)) {
      setShowRequestAccessDialog(true);
    } else {
      history.push(`/project/${project.id}`);
    }
  };

  const toggleFavorite = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!updating) {
      dispatch(toggleFavoriteProject(project.id, !currentCustomer));
    }
  };

  const onConfirmRequestAccess = (confirm: boolean) => {
    if (confirm) {
      dispatch(requestAccessForProject(project));
    }
    setShowRequestAccessDialog(false);
  };

  useEffect(() => {
    if (requestAccessSuccess && requestAccessProjectId === project.id) {
      toast.success(t('requestAccessSuccess'), { toastId: `requestAccessSuccess_${requestAccessProjectId}` });
    }
  }, [requestAccessSuccess, requestAccessProjectId, project, t]);

  const description = project.description ? DOMPurify.sanitize(project.description) : '';

  return (
    <Card className="project-card hover-white" onClick={onClick}>
      <Card.Body className="">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="title" title={project.name}>
            {project.name}
          </div>
          <div onClick={toggleFavorite}>
            <FontAwesomeIcon icon={project?.isFavorite ? 'star' : ['far', 'star']} />
          </div>
        </div>
        {projectIsForbidden(project) && project.isConfidential ? (
          <div className="confidential">{t('confidential')}</div>
        ) : (
          <>
            <div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
            <div className="text-right">
              <Link to={`/project/${project.id}`}>
                <FontAwesomeIcon icon="arrow-right" />
              </Link>
            </div>
          </>
        )}

        <ConfirmDialog
          show={showRequestAccessDialog}
          dialogTitle={t('requestAccessDialogTitle')}
          dialogContent={t('requestAccessDialogContent', { name: project.name })}
          onClose={onConfirmRequestAccess}
        />
      </Card.Body>
    </Card>
  );
};

export default ProjectCard;
