import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useSelector } from 'react-redux';
import { MissionDetails } from 'shared/model/mission.model';
import { ProjectDetails } from 'shared/model/project.model';
import SkyTButton from 'shared/widgets/buttons/skyTButton';

// see https://developers.arcgis.com/documentation/mapping-apis-and-services/reference/zoom-levels-and-scale/
const defaultScale = 591657527.591555;

const CompassTool = () => {
  const currentConfiguration = useSelector(({ configuration }: IRootState) => configuration.configuration);
  const selectedMissions = useSelector(({ mission }: IRootState) => mission.selectedMissions) as MissionDetails[];
  const selectedSatellite = useSelector(({ satellite }: IRootState) => satellite.selectedSatellite);
  const currentProject = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;

  const currentMission = selectedMissions.length !== 1 ? null : selectedMissions[0];

  const onCompass = () => {
    if (currentView) {
      if (currentConfiguration) {
        const sat = currentProject.satellites.find(item => item.id === currentConfiguration.satelliteId);
        if (sat) {
          currentView.goTo({
            heading: 0,
            rotation: 0,
            center: new Point({ latitude: 0, longitude: sat.orbitalPosition }),
            scale: defaultScale
          });
        }
      } else if (selectedSatellite) {
        currentView.goTo({
          heading: 0,
          rotation: 0,
          center: new Point({ latitude: 0, longitude: selectedSatellite.orbitalPosition }),
          scale: defaultScale
        });
      } else if (currentMission) {
        const areaGeoms = currentMission.areaOfInterests.map(item => {
          const parsed = JSON.parse(item.latLngs);
          return new Polygon(parsed);
        });
        const union = geometryEngine.union(areaGeoms) as Polygon;
        currentView.goTo(union.extent).then(() => {
          currentView?.goTo({
            heading: 0,
            rotation: 0
          });
        });
      } else {
        currentView.goTo({
          heading: 0,
          rotation: 0,
          center: new Point({ latitude: 0, longitude: 0 }),
          scale: defaultScale
        });
      }
    }
  };
  return (
    <SkyTButton variant="info" className="map-tool-bar-button" onClick={onCompass}>
      <FontAwesomeIcon icon={['far', 'compass']} />
    </SkyTButton>
  );
};

export default CompassTool;
