import { IRootState } from 'config/store';
import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { GatewayBandWidth } from 'shared/model/gateway.model';
import { setSelectedGatewayBandwidth } from 'shared/reducers/GatewaySlice';
import './EditGatewayFrequencyBandwidth.scss';

interface IEditGatewayFrequencyBandwidthProps {
  item: GatewayBandWidth;
}

const EditGatewayFrequencyBandwidth = (props: IEditGatewayFrequencyBandwidthProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const selectedGatewayBandwidth = useSelector(({ gateway }: IRootState) => gateway.selectedGatewayBandwidth);
  const { item } = props;

  const [value, setValue] = useState<number>(item.bandwidth);

  const active = selectedGatewayBandwidth?.name === item.name;

  useEffect(() => {
    if (active) {
      setValue(selectedGatewayBandwidth ? selectedGatewayBandwidth.bandwidth : 0);
    }
  }, [active, selectedGatewayBandwidth]);

  const toggleCheck = () => {
    const editedBandwidth: GatewayBandWidth = {
      ...item,
      bandwidth: value
    };
    dispatch(setSelectedGatewayBandwidth(editedBandwidth));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.length > 0 ? Number(e.target.value) : 0;
    setValue(newValue);

    const editedBandwidth: GatewayBandWidth = {
      ...item,
      bandwidth: newValue
    };

    dispatch(setSelectedGatewayBandwidth(editedBandwidth));
  };

  return (
    <div className="edit-gateway-frequency-band">
      <Form.Group className="edit-gateway-frequency-band-title" controlId={`gateway-frequency-band-${item.name}`}>
        <Form.Check type="radio" label={item.name} checked={active} onChange={toggleCheck} />
      </Form.Group>
      {active && (
        <Form.Group className="edit-gateway-frequency-band-content">
          <SkyTTextInput
            className="bandwidth-input"
            size="sm"
            type="number"
            min={0}
            placeholder={t('mission.bandwidth')}
            value={value}
            onChange={onChange}
          />
          <span className="ml-2">Mhz</span>
        </Form.Group>
      )}
    </div>
  );
};

export default EditGatewayFrequencyBandwidth;
