import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import { currentView } from 'modules/map/map';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import { underEditionPolygonSymbol } from './common-polygon';

let sketchViewModel: SketchViewModel | undefined;

interface IEditPolygonProps {
  layer: GraphicsLayer;
  graphics: Graphic[];
  onCancel: () => void;
  onComplete: (g: Graphic) => void;
  symbol?: SimpleFillSymbol;
}

const EditPolygon = (props: IEditPolygonProps) => {
  const arcgisMap = useSelector(({ map }: IRootState) => map.arcgisMap);

  const [underEdition, setUnderEdition] = useState(false);

  const { layer, graphics, symbol = underEditionPolygonSymbol } = props;

  useEffect(() => {
    if (currentView) {
      arcgisMap?.add(layer);
      graphics.forEach(g => {
        if (g.geometry.spatialReference.isGeographic) {
          g.geometry = webMercatorUtils.geographicToWebMercator(g.geometry);
        }
      });
      layer.addMany(graphics);
      sketchViewModel = new SketchViewModel({
        layer,
        view: currentView,
        polygonSymbol: symbol,
        defaultUpdateOptions: {
          tool: 'transform',
          multipleSelectionEnabled: false
        }
      });

      const handleModification = (e: any) => {
        setUnderEdition(e.state === 'active');
        if (e.state === 'complete') {
          const graphic = e.graphic ? e.graphic : e.graphics ? e.graphics[0] : null;
          if (graphic) {
            const newGraphic = graphic.clone();
            if (newGraphic.geometry.spatialReference.isWebMercator) {
              newGraphic.geometry = webMercatorUtils.geographicToWebMercator(newGraphic.geometry);
            }
            props.onComplete(graphic);
            sketchViewModel?.update(graphic);
          }
        }
      };
      sketchViewModel.on('update', handleModification);
      sketchViewModel.update(graphics);
    }
    return () => {
      if (sketchViewModel) {
        sketchViewModel.destroy();
        sketchViewModel = undefined;
      }

      layer.removeAll();
      arcgisMap?.remove(layer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUndo = () => {
    sketchViewModel?.undo();
  };

  const onRedo = () => {
    sketchViewModel?.redo();
  };

  return (
    <div>
      <SkyTButton variant="outline-map" className="mr-2" onClick={props.onCancel}>
        <FontAwesomeIcon icon="times" />
      </SkyTButton>

      <SkyTButton variant="outline-map" className="mr-2" onClick={onUndo} disabled={!underEdition}>
        <FontAwesomeIcon icon="undo" />
      </SkyTButton>
      <SkyTButton variant="outline-map" onClick={onRedo} disabled={!underEdition}>
        <FontAwesomeIcon icon="redo" />
      </SkyTButton>
    </div>
  );
};

export default EditPolygon;
