interface IColorGradientProps {
  colors: string[];
  className: string;
  vertical?: boolean;
}

const ColorGradient = (props: IColorGradientProps) => {
  const { colors, className, vertical = false } = props;

  const deg = vertical ? '0' : '90';

  const style: React.CSSProperties = {
    background: `linear-gradient(${deg}deg, ${colors.join(',')})`
  };

  return <div className={className} style={style}></div>;
};
export default ColorGradient;
