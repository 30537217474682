import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AppThunk } from 'config/store';
import {
  createConfiguration,
  deleteConfiguration,
  getGonfigurationDetails,
  getGonfigurations,
  getMissionsDetailsFromConfig,
  updateConfiguration
} from 'shared/api/api';
import { Configuration } from 'shared/model/configuration.model';
import { Coverage } from 'shared/model/coverage.model';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { reset as resetArea } from './areaSlice';
import { removeCoverage, saveCoverage } from './coverageSlice';

const initialState = {
  loading: false,
  loadingDetails: -1 as number,
  loadingMissions: -1 as number,
  updating: false,
  updateSuccess: false,
  configurations: [] as Configuration[],
  configuration: null as Configuration | null,
  configurationMissions: [] as MissionDetails[],
  error: null as any
};

export type ConfigurationState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    fetchEntitiesStart: state => {
      state.loading = true;
      state.updateSuccess = false;
    },
    fetchEntityStart: (state, action: PayloadAction<any>) => {
      state.loadingDetails = action.payload;
      state.updateSuccess = false;
    },
    fetchMissionsStart: (state, action: PayloadAction<any>) => {
      state.loadingMissions = action.payload;
      state.updateSuccess = false;
    },
    fetchEntitiesFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.loadingDetails = -1;
      state.error = action.payload;
    },
    fetchEntityFailed: (state, action: PayloadAction<any>) => {
      state.loadingDetails = -1;
      state.error = action.payload;
    },
    fetchMissionsFailed: (state, action: PayloadAction<any>) => {
      state.loadingMissions = -1;
      state.error = action.payload;
    },
    fetchEntitiesSuccess: (state, action: PayloadAction<Configuration[]>) => {
      state.loading = false;
      state.configurations = action.payload;
    },
    fetchEntitySuccess: (state, action: PayloadAction<Configuration | null>) => {
      state.loadingDetails = -1;
      state.configuration = action.payload;
    },
    fetchMissionsSuccess: (state, action: PayloadAction<MissionDetails[]>) => {
      state.loadingMissions = -1;
      state.configurationMissions = action.payload;
    },
    updateStart: state => {
      state.loading = false;
      state.updating = true;
      state.updateSuccess = false;
      state.configuration = null;
    },
    updateSuccess: (state, action: PayloadAction<Configuration>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.configuration = action.payload;
    },
    updateFailed: (state, action: PayloadAction<any>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.error = action.payload;
    },
    deleteSuccess: state => {
      state.updating = false;
      state.updateSuccess = true;
      state.configuration = null;
      state.configurationMissions = [];
    },
    reset: state => {
      state.loading = false;
      state.loadingDetails = -1;
      state.loadingMissions = -1;
      state.updateSuccess = false;
      state.updating = false;
      state.configurations = [];
      state.configurationMissions = [];
      state.configuration = null;
      state.error = null;
    }
  }
});

const {
  fetchEntitiesStart,
  fetchEntityStart,
  fetchMissionsStart,
  fetchEntitiesFailed,
  fetchEntityFailed,
  fetchMissionsFailed,
  fetchEntitiesSuccess,
  fetchMissionsSuccess,
  updateStart,
  updateFailed,
  updateSuccess,
  deleteSuccess
} = slice.actions;

export const { reset, fetchEntitySuccess } = slice.actions;

export const fetchConfigurations =
  (projectId: number | string, satelliteId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntitiesStart());
      const response: AxiosResponse<Configuration[]> = await getGonfigurations(projectId, satelliteId);
      dispatch(fetchEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesFailed(error));
    }
  };

export const fetchConfiguration =
  (projectId: number | string, satelliteId: number | string, configurationId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchEntityStart(configurationId));
      const response = await getGonfigurationDetails(projectId, satelliteId, configurationId);
      dispatch(fetchEntitySuccess(response.data));
    } catch (error) {
      dispatch(fetchEntityFailed(error));
    }
  };

export const saveConfiguration =
  (projectId: number | string, satelliteId: number | string, configuration: Configuration): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = configuration.id
        ? await updateConfiguration(projectId, satelliteId, configuration)
        : await createConfiguration(projectId, satelliteId, configuration.name);
      dispatch(updateSuccess(response.data));
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const createConfigurationAndAddCoverage =
  (projectId: number | string, satelliteId: number | string, name: string, selectedMissions: Mission[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = await createConfiguration(projectId, satelliteId, name);
      const createdConfiguration = response.data;
      dispatch(updateSuccess(createdConfiguration));

      for (let i = 0; i < selectedMissions.length; i++) {
        const coverage: Partial<Coverage> = {
          missionId: selectedMissions[i].id
        };
        await dispatch(saveCoverage(projectId, satelliteId, createdConfiguration.id, coverage as Coverage));
      }
      return createdConfiguration.id;
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const updateConfigurationAndCoverages =
  (
    projectId: number | string,
    satelliteId: number | string,
    configuration: Configuration,
    oldCoverages: Coverage[],
    selectedMissions: Mission[]
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response = await updateConfiguration(projectId, satelliteId, configuration);
      const updatedConfiguration = response.data;
      dispatch(updateSuccess(updatedConfiguration));

      const toDelete = oldCoverages.filter(coverage => !selectedMissions.some(item => item.id === coverage.missionId));
      const toAdd = selectedMissions.filter(mission => !oldCoverages.some(coverage => coverage.missionId === mission.id));

      for (let i = 0; i < toDelete.length; i++) {
        const item = toDelete[i];
        await dispatch(removeCoverage(projectId, satelliteId, configuration.id, item.id));
      }

      for (let i = 0; i < toAdd.length; i++) {
        const item = toAdd[i];
        const coverage: Partial<Coverage> = {
          missionId: item.id
        };
        await dispatch(saveCoverage(projectId, satelliteId, updatedConfiguration.id, coverage as Coverage));
      }
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const removeConfiguration =
  (projectId: number | string, satelliteId: string | number, configurationId: string | number): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await deleteConfiguration(projectId, satelliteId, configurationId);

      dispatch(resetArea());

      dispatch(deleteSuccess());
    } catch (error) {
      dispatch(updateFailed(error));
    }
  };

export const getMissionsFromConfiguration =
  (projectId: number | string, configurationId: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchMissionsStart(configurationId));
      const response = await getMissionsDetailsFromConfig(projectId, configurationId);
      dispatch(fetchMissionsSuccess(response.data));
    } catch (error) {
      dispatch(fetchMissionsFailed(error));
    }
  };

export default slice.reducer;
