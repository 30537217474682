import classnames from 'classnames';
import { FormatOptionLabelMeta } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import './skyTSelect.scss';

interface ISkyTAsyncSelectProps extends AsyncProps<any, any> {
  dark?: boolean;
  placeholder?: string;
  disabled?: boolean;
  maxMenuHeight?: number;
  className?: string;
  onChange?: any;
  defaultInputValue?: string | undefined;
  formatOptionLabel?: ((option: any, labelMeta: FormatOptionLabelMeta<any, any>) => React.ReactNode) | undefined;
}

const SkyTAsyncSelect = (props: ISkyTAsyncSelectProps) => {
  const { dark = false, disabled = false, className, ...rest } = props;
  return (
    <AsyncSelect
      classNamePrefix="skyT"
      className={classnames(className, 'async-select', dark ? 'dark' : 'light')}
      isDisabled={disabled}
      {...rest}
    />
  );
};

export default SkyTAsyncSelect;
