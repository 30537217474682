import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { ProjectDetails } from 'shared/model/project.model';
import { showMap } from 'shared/reducers/mapSlice';
import { fetchProject } from 'shared/reducers/projectSlice';
import { reset, saveSatellite, setUnderEditionSatellite } from 'shared/reducers/satelliteSlice';
import {
  configureSatelliteGatewaysRoute,
  configureSatellitePositionRoute,
  configureSatelliteReflectorRoute,
  configureSatelliteTypeRoute,
  satellitesRoute
} from 'shared/routes/routes';
import { projectHasWriteAccess } from 'shared/utils/model-utils';
import SkyTButton from 'shared/widgets/buttons/skyTButton';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import './configure-satellite.scss';
import ConfigureSatelliteGateways from './configureSatelliteGateways';
import ConfigureSatellitePosition from './configureSatellitePosition';
import ConfigureSatelliteReflectors from './configureSatelliteReflectors';
import ConfigureSatelliteType from './configureSatelliteType';

const ConfigureSatellite = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const project = useSelector(({ project }: IRootState) => project.project);

  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);
  const updateSuccess = useSelector(({ satellite }: IRootState) => satellite.updateSuccess);

  const { projectId, satelliteId } = useParams<{ projectId: string; satelliteId: string }>();

  const isNew = satelliteId === 'new';

  const isConfigureSatelliteTypePath = useRouteMatch([configureSatelliteTypeRoute.path])?.isExact;
  const isConfigureSatellitePositionPath = useRouteMatch([configureSatellitePositionRoute.path])?.isExact;
  const isConfigureSatelliteGatewaysPath = useRouteMatch([configureSatelliteGatewaysRoute.path])?.isExact;
  const isConfigureSatelliteReflectorPath = useRouteMatch([configureSatelliteReflectorRoute.path])?.isExact;

  useEffect(() => {
    if (!isNew && !underEditionSatellite && project) {
      // for edition purpose
      const projectDetails = project as ProjectDetails;
      if (projectDetails.satellites) {
        const currentSat = projectDetails.satellites.find(item => item.id === Number(satelliteId));
        if (currentSat) {
          dispatch(setUnderEditionSatellite(currentSat));
        }
      }
    }
  }, [dispatch, isNew, project, satelliteId, underEditionSatellite]);

  useEffect(() => {
    if (!mapIsDisplayed) {
      dispatch(showMap());
    }
  }, [dispatch, mapIsDisplayed]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(reset());
      dispatch(fetchProject(projectId));
      history.push(satellitesRoute.getPath(projectId));
    }
  }, [updateSuccess, project, dispatch, projectId, history]);

  if (!project) {
    return null;
  }

  const onCancel = () => {
    history.push(satellitesRoute.getPath(projectId));
    dispatch(reset());
  };

  const hasWriteAccess = projectHasWriteAccess(project);
  if (!hasWriteAccess) {
    onCancel();
  }

  const onSave = () => {
    if (isNew) {
      if (isConfigureSatelliteTypePath) {
        history.push(configureSatellitePositionRoute.getPath(projectId, 'new'));
      } else if (isConfigureSatellitePositionPath) {
        history.push(configureSatelliteGatewaysRoute.getPath(projectId, 'new'));
      } else if (isConfigureSatelliteGatewaysPath) {
        history.push(configureSatelliteReflectorRoute.getPath(projectId, 'new'));
      } else if (isConfigureSatelliteReflectorPath && underEditionSatellite) {
        dispatch(saveSatellite(projectId, underEditionSatellite));
      }
    } else if (underEditionSatellite) {
      dispatch(saveSatellite(projectId, underEditionSatellite));
    }
  };

  let canSave = false;
  if (isNew) {
    if (underEditionSatellite) {
      if (isConfigureSatelliteTypePath) {
        canSave = !isEmpty(underEditionSatellite.name) && isNumber(underEditionSatellite.modelId);
      } else if (isConfigureSatellitePositionPath) {
        canSave =
          !isEmpty(underEditionSatellite.name) &&
          isNumber(underEditionSatellite.modelId) &&
          isNumber(underEditionSatellite.orbitalPosition);
      } else if (isConfigureSatelliteGatewaysPath) {
        canSave =
          !isEmpty(underEditionSatellite.name) &&
          isNumber(underEditionSatellite.modelId) &&
          isNumber(underEditionSatellite.orbitalPosition) &&
          typeof underEditionSatellite.gatewayIds !== 'undefined' &&
          underEditionSatellite.gatewayIds.length > 0;
      } else if (isConfigureSatelliteReflectorPath) {
        canSave =
          !isEmpty(underEditionSatellite.name) &&
          isNumber(underEditionSatellite.modelId) &&
          isNumber(underEditionSatellite.orbitalPosition) &&
          typeof underEditionSatellite.gatewayIds !== 'undefined' &&
          underEditionSatellite.gatewayIds.length > 0 &&
          typeof underEditionSatellite.reflector?.zooming !== 'undefined' &&
          isNumber(underEditionSatellite.reflector?.fwdBandwidth) &&
          underEditionSatellite.reflector?.fwdBandwidth > 0 &&
          isNumber(underEditionSatellite.reflector?.rtnBandwidth) &&
          underEditionSatellite.reflector?.rtnBandwidth > 0;
      }
    }
  } else {
    canSave =
      !isEmpty(underEditionSatellite?.name) && isNumber(underEditionSatellite?.modelId) && isNumber(underEditionSatellite?.orbitalPosition);
  }

  let okBtnKey = 'save';
  if (isNew) {
    okBtnKey = 'next';
    if (isConfigureSatelliteReflectorPath) {
      okBtnKey = 'save';
    }
  }

  const title = isConfigureSatelliteTypePath
    ? 'satellite.configureSatelliteType'
    : isConfigureSatellitePositionPath
    ? 'satellite.configureSatellitePosition'
    : isConfigureSatelliteGatewaysPath
    ? 'satellite.configureSatelliteGateways'
    : isConfigureSatelliteReflectorPath
    ? 'satellite.configureSatelliteReflectors'
    : 'satellite.configureSatelliteBtn';

  return (
    <div className="configure-satellite">
      <div className="configure-satellite-title">
        <div>{t(title)}</div>
        <div>
          <SkyTButton variant="link" className="text-primary" onClick={onCancel}>
            <FontAwesomeIcon icon="times" />
          </SkyTButton>
        </div>
      </div>
      <div className="flex-grow-1">
        {isConfigureSatellitePositionPath ? (
          <ConfigureSatellitePosition />
        ) : isConfigureSatelliteTypePath ? (
          <ConfigureSatelliteType />
        ) : isConfigureSatelliteGatewaysPath ? (
          <ConfigureSatelliteGateways />
        ) : isConfigureSatelliteReflectorPath ? (
          <ConfigureSatelliteReflectors />
        ) : null}
      </div>
      <div className="configure-satellite-toolbar">
        <SkyTMapButton variant="outline-info" type="button" onClick={onCancel}>
          {t('cancel')}
        </SkyTMapButton>
        <SkyTMapButton variant="outline-info" type="button" onClick={onSave} disabled={!canSave}>
          {t(okBtnKey)}
        </SkyTMapButton>
      </div>
    </div>
  );
};

export default ConfigureSatellite;
