import { IRootState } from 'config/store';
import React, { useContext, useEffect } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { showMap } from 'shared/reducers/mapSlice';
import { configurationsRoute } from 'shared/routes/routes';
import { someMissionNotComplete } from 'shared/utils/model-utils';
import SkyTAccordionTab from 'shared/widgets/accordion/skyTAccordionTab';
import { confSatItemPrefix } from './configurationSatellliteItem';
import ConfigurationTabContent from './configurationsTabContent';
import ConfigurationsTabTitle from './configurationsTabTitle';

export const configurationsKey = 'configurations';

const ConfigurationsTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentEventKey = useContext(AccordionContext);
  const missions = useSelector(({ mission }: IRootState) => mission.missions);
  const mapIsDisplayed = useSelector(({ map }: IRootState) => map.mapIsDisplayed);

  const { projectId, satelliteId } = useParams<{ projectId: string; satelliteId: string }>();

  const isActive = currentEventKey === configurationsKey;
  const isDisabled = someMissionNotComplete(missions);

  const defaultActiveKey = satelliteId ? confSatItemPrefix + '_' + satelliteId : undefined;

  useEffect(() => {
    if (isActive) {
      if (!mapIsDisplayed) {
        dispatch(showMap());
      }

      if (projectId && !defaultActiveKey) {
        const newHistory = configurationsRoute.getPath(projectId);
        history.push(newHistory);
      }
    }
  }, [isActive, dispatch, mapIsDisplayed, history, defaultActiveKey, projectId]);

  return (
    <SkyTAccordionTab eventKey={configurationsKey} title={<ConfigurationsTabTitle />} disabled={isDisabled}>
      {isActive && <ConfigurationTabContent />}
    </SkyTAccordionTab>
  );
};

export default ConfigurationsTab;
