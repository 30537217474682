import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MissionDetails } from 'shared/model/mission.model';
import { getMissionTotalTerminalsRepartition } from 'shared/utils/model-utils';
import './missionWarningIcon.scss';

interface IMissionTerminamWarningIconProps {
  mission: MissionDetails;
  size?: SizeProp;
}

const MissionTerminamWarningIcon = (props: IMissionTerminamWarningIconProps) => {
  const { t } = useTranslation('translation');
  const { mission, size = 'sm' } = props;

  const total = getMissionTotalTerminalsRepartition(mission);

  if (total !== 100) {
    const message = mission.userTerminals.length === 0 ? 'mission.atLeastOneTerminal' : 'mission.terminalsNotComplete';
    return (
      <span title={t(message)} className="mission-warning-icon">
        <FontAwesomeIcon icon="exclamation" size={size} color="#f5a030" />
      </span>
    );
  }
  return null;
};

export default MissionTerminamWarningIcon;
