import { IRootState } from 'config/store';
import useSatelliteReflectorLayer from 'modules/map/layers/satellite/useSatelliteReflectorLayer';
import EditFrequencyBandwidth from 'modules/needs/missions/form/EditFrequencyBandwidth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ProjectDetails } from 'shared/model/project.model';
import { setUnderEditionSatellite } from 'shared/reducers/satelliteSlice';
import { satellitesRoute } from 'shared/routes/routes';
import { canConfigureReflector } from 'shared/utils/model-utils';
import './configure-satellite.scss';
import SelectReflectorZoomFactor from './reflectors/SelectReflectorZoomFactor';

const ConfigureSatelliteReflectors = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const project = useSelector(({ project }: IRootState) => project.project) as ProjectDetails;
  const satelliteModels = useSelector(({ referential }: IRootState) => referential.satelliteModels);
  const referential = useSelector(({ referential }: IRootState) => referential.referential);
  const underEditionSatellite = useSelector(({ satellite }: IRootState) => satellite.underEditionSatellite);

  const allowed = canConfigureReflector(underEditionSatellite, satelliteModels);

  useSatelliteReflectorLayer();

  if (!allowed) {
    const to = satellitesRoute.getPath(project.id);
    return <Redirect to={to} />;
  }

  const onZoomingChange = (selectedZooming: number) => {
    if (underEditionSatellite) {
      const reflector = underEditionSatellite.reflector;

      const found = referential?.satelliteZooming.find(item => item.band === reflector?.band && item.zooming === selectedZooming);
      if (found) {
        const newReflector = {
          ...reflector,
          ...found
        };
        const centerLat = reflector?.centerLat ? reflector.centerLat : 0;
        const centerLng = reflector?.centerLng ? reflector.centerLng : underEditionSatellite.orbitalPosition;

        const underEdition = {
          ...underEditionSatellite,
          reflector: {
            ...newReflector,
            zooming: found.zooming,
            centerLat,
            centerLng
          }
        };
        dispatch(setUnderEditionSatellite(underEdition));
      }
    }
  };

  const onFwdChange = (value: number) => {
    const newEdition = {
      ...underEditionSatellite
    };
    if (newEdition?.reflector) {
      const newReflector = {
        ...newEdition.reflector
      };
      newReflector.fwdBandwidth = value;
      newEdition.reflector = newReflector;
      dispatch(setUnderEditionSatellite(newEdition));
    }
  };

  const onRtnChange = (value: number) => {
    const newEdition = {
      ...underEditionSatellite
    };
    if (newEdition?.reflector) {
      const newReflector = {
        ...newEdition.reflector
      };
      newReflector.rtnBandwidth = value;
      newEdition.reflector = newReflector;
      dispatch(setUnderEditionSatellite(newEdition));
    }
  };

  return (
    <div className="configure-reflectors">
      <SelectReflectorZoomFactor onChange={onZoomingChange} />

      {underEditionSatellite?.reflector && (
        <div className="mt-4">
          <div className="font-weight-bold mb-2">{t('mission.frequencies')}</div>
          <EditFrequencyBandwidth item={underEditionSatellite.reflector} onFwdChange={onFwdChange} onRtnChange={onRtnChange} />
        </div>
      )}
    </div>
  );
};

export default ConfigureSatelliteReflectors;
