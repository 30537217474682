import { IRootState } from 'config/store';
import { beamCIDef, IRxDatabaseBeam, ITxDatabaseBeam } from 'modules/map/layers/beams/databaseBeam.model';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setUnderEditionBeams } from 'shared/reducers/areaSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import SkyTSlider from 'shared/widgets/form/skyTSlider';
import { useDebounce } from 'use-debounce';
import { refreshSliderDelay } from './common-slider';
import './common-slider.scss';

const getMarks = (disabled: boolean) => {
  const className = disabled ? 'text-muted' : 'text-primary';
  return {
    0: <span className={className}>0</span>,
    5: <span className={className}>5</span>,
    10: <span className={className}>10</span>,
    15: <span className={className}>15</span>,
    20: <span className={className}>20</span>,
    25: <span className={className}>25</span>,
    30: <span className={className}>30</span>
  };
};

interface ICISliderProps {
  forward: boolean;
  defaultValue?: number;
  disabled?: boolean;
}

const CISlider = (props: ICISliderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const underEditionBeams = useSelector(({ area }: IRootState) => area.underEditionBeams);
  const { defaultValue = 0, disabled = false, forward } = props;

  const [value, setValue] = useState(defaultValue);
  const [debounceValue] = useDebounce(value, refreshSliderDelay);
  const previousDebounceValue = usePrevious(debounceValue);

  useEffect(() => {
    if (!disabled) {
      if (defaultValue) {
        setValue(defaultValue);
      } else {
        setValue(0);
      }
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (
      previousDebounceValue !== debounceValue &&
      typeof previousDebounceValue !== 'undefined' &&
      typeof debounceValue !== 'undefined' &&
      !disabled
    ) {
      const newUnderEditionBeams = underEditionBeams.map(underEditionBeam => {
        if (underEditionBeam.selected) {
          const initialTx = underEditionBeam.tx;
          const initialRx = underEditionBeam.rx;

          if (forward) {
            const tx: ITxDatabaseBeam = {
              ...initialTx,
              c: debounceValue
            };
            return {
              ...underEditionBeam,
              tx
            };
          } else {
            const rx: IRxDatabaseBeam = {
              ...initialRx,
              c: debounceValue
            };
            return {
              ...underEditionBeam,
              rx
            };
          }
        }
        return { ...underEditionBeam };
      });
      dispatch(setUnderEditionBeams(newUnderEditionBeams));
    }
  }, [debounceValue, disabled, dispatch, forward, previousDebounceValue, underEditionBeams]);

  const onChange = (e: number) => {
    setValue(e);
  };

  const marks = getMarks(disabled);

  return (
    <Form.Group className="common-slider">
      <Form.Label className="d-flex justify-content-center mb-0">{t('area.targets.CI')}</Form.Label>
      <SkyTSlider onChange={onChange} marks={marks} min={beamCIDef.min} max={beamCIDef.max} value={value} step={1} disabled={disabled} />
    </Form.Group>
  );
};

export default CISlider;
