import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'config/store';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SkyTTextInput from 'shared/form/skyTTextInput';
import { Coverage } from 'shared/model/coverage.model';
import { saveCoverage, setCoverage } from 'shared/reducers/coverageSlice';
import { configurationsSatelliteRoute } from 'shared/routes/routes';
import SkyTMapButton from 'shared/widgets/buttons/SkyTMapButton';
import './EditCoverage.scss';
import ResetCoverage from './ResetCoverage';
import ToggleBeamNumber from './ToggleBeamNumber';

const EditCoverageDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');

  const updating = useSelector(({ coverage }: IRootState) => coverage.updating);
  const coverage = useSelector(({ coverage }: IRootState) => coverage.coverage);

  const { projectId, satelliteId, configurationId } = useParams<{ projectId: string; satelliteId: string; configurationId: string }>();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      details: coverage?.details
    }
  });

  const onCancel = () => {
    dispatch(setCoverage(null));
    history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
  };

  const onSubmit = (data: any) => {
    const newCoverage: Coverage = {
      ...coverage,
      ...data
    };
    dispatch(saveCoverage(projectId, satelliteId, configurationId, newCoverage));
    history.push(configurationsSatelliteRoute.getPath(projectId, satelliteId));
  };

  return (
    <div>
      <hr className="my-3" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="edit-coverage-details-content">
          <div>
            <div className="mb-2">{t('coverage.details.title')}</div>
            <div className="mb-4">
              <SkyTTextInput as="textarea" {...register('details')} rows={6} disabled={updating} />
            </div>
          </div>
          <div className="text-center">
            <div className="mb-2">
              <ToggleBeamNumber />
            </div>
            <ResetCoverage />
          </div>
        </div>
        <hr className="my-3" />
        <div className="d-flex justify-content-around">
          <SkyTMapButton type="button" variant="outline-info" onClick={onCancel} disabled={updating}>
            {t('cancel')}
            <FontAwesomeIcon icon="times" className="ml-1" />
          </SkyTMapButton>
          <SkyTMapButton variant="outline-info" type="submit">
            {t('done')}
            <FontAwesomeIcon icon="check" className="ml-1" />
          </SkyTMapButton>
        </div>
      </form>
    </div>
  );
};

export default EditCoverageDetails;
