import ArcGisMap from '@arcgis/core/Map';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QUALITY_PROFILE_TYPE_KEY } from 'config/constants';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import { BeamCriterion } from 'modules/map/layers/beams/databaseBeam.model';
import Storage from 'shared/utils/storage-utils';

type mapMenuPanelType = 'layer-group' | 'missions';
export type qualityProfileType = 'low' | 'medium' | 'high';

export const defaultBaseLayer = 'satellite';
// export const defaultBaseLayer = 'arcgis-imagery';

const defaultQualityProfil = Storage.local.get(QUALITY_PROFILE_TYPE_KEY) ? Storage.local.get(QUALITY_PROFILE_TYPE_KEY) : 'medium';

const initialState = {
  mapIsDisplayed: false,
  showMenuPanel: true,
  flat: true,
  view: null as MapView | SceneView | null,
  arcgisMap: null as ArcGisMap | null,
  baseLayer: defaultBaseLayer as string,
  mapMenuPanel: null as mapMenuPanelType | null,
  qualityProfile: defaultQualityProfil as qualityProfileType,
  showBeamLayer: true,
  showBeamNumber: true,
  beamCriterion: null as BeamCriterion | null
};

export type MapState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMap: (state, action: PayloadAction<ArcGisMap | null>) => {
      state.arcgisMap = action.payload;
      state.showMenuPanel = true;
    },
    showMap: state => {
      state.mapIsDisplayed = true;
      state.showMenuPanel = true;
    },
    hideMap: state => {
      state.mapIsDisplayed = false;
      state.showMenuPanel = true;
    },
    show3DMap: state => {
      state.flat = false;
    },
    hide3DMap: state => {
      state.flat = true;
    },
    setShowBeamNumber: (state, action: PayloadAction<boolean>) => {
      state.showBeamNumber = action.payload;
    },
    resetShowBeamNumber: state => {
      state.showBeamNumber = true;
    },
    setBaseLayer: (state, action: PayloadAction<string>) => {
      state.baseLayer = action.payload;
    },
    toggleMenuPanel: state => {
      state.showMenuPanel = !state.showMenuPanel;
    },
    setMapMenuPanel: (state, action: PayloadAction<mapMenuPanelType | null>) => {
      state.mapMenuPanel = action.payload;
    },
    setQualityProfile: (state, action: PayloadAction<qualityProfileType>) => {
      state.qualityProfile = action.payload;
      Storage.local.set(QUALITY_PROFILE_TYPE_KEY, action.payload);
    },
    setBeamCriterion: (state, action: PayloadAction<BeamCriterion | null>) => {
      state.beamCriterion = action.payload;
    },
    setShowBeamLayer: (state, action: PayloadAction<boolean>) => {
      state.showBeamLayer = action.payload;
    }
  }
});

export const {
  show3DMap,
  hide3DMap,
  showMap,
  hideMap,
  setMap,
  setBaseLayer,
  setMapMenuPanel,
  setQualityProfile,
  setBeamCriterion,
  setShowBeamLayer,
  toggleMenuPanel,
  setShowBeamNumber,
  resetShowBeamNumber
} = slice.actions;

export default slice.reducer;
