import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { TFunction } from 'react-i18next';
import { AreaOfInterest } from 'shared/model/areaOfInterest.model';
import { CoverageType } from 'shared/model/coverage.model';
import { Mission, MissionDetails } from 'shared/model/mission.model';
import { createBeamSymbol } from 'shared/utils/beam-utils';
import { getGlobalAreaTypeSymbolFromMission } from '../areaOfInterets/areaOfInterestLayer-def';
import { DefaultFont } from '../commons/common-layer-def';
import { getBeamPopupContent } from './beam-popup';
import { BeamCriterion, getBeamCriterionDef, IDatabaseBeam, IFeatureBeam } from './databaseBeam.model';

const MISSION_COLOR_KEY = 'missionColor';
const MISSION_COLOR_TO_COMPLETE_KEY = 'missionColorToComplete';

const noDataSymbol = createBeamSymbol('#FFFFFF', 0.3);
const selectedSymbol = createBeamSymbol('#FFFFFF', 0.3);

const missionColorsSymbols: any = {};

export const getMissionSymbol = (mission: Mission, toComplete = false) => {
  const key = toComplete ? mission.color + '_toComplete' : mission.color;

  let symbol = missionColorsSymbols[key];
  if (!symbol) {
    symbol = createBeamSymbol(mission.color, 0.3, toComplete);
    missionColorsSymbols[key] = symbol;
  }
  return symbol;
};
export const getNoDataSymbol = () => noDataSymbol;
export const getSelectedSymbol = () => selectedSymbol;
export const getMissionSymbolFromGraphic = (graphic: Graphic, toComplete = false) => {
  const key = toComplete ? MISSION_COLOR_TO_COMPLETE_KEY : MISSION_COLOR_KEY;
  return graphic.get(key) as __esri.SimpleFillSymbol;
};

export const saveMissionSymbol = (graphic: Graphic, mission: Mission) => {
  const symbol = getMissionSymbol(mission);
  graphic.symbol = symbol;
  graphic.set(MISSION_COLOR_KEY, getMissionSymbol(mission));
  graphic.set(MISSION_COLOR_TO_COMPLETE_KEY, getMissionSymbol(mission, true));
};

export const beamNumberLabelClass = new LabelClass({
  symbol: {
    type: 'text',
    color: 'white',
    font: DefaultFont
  },
  labelExpressionInfo: {
    expression: '$feature.name'
  }
});

const getPopupContent = (feature: any, other: any) => {
  const div = document.createElement('div');
  div.className = 'text-primary';
  const atts = feature.graphic.attributes;

  const content = getBeamPopupContent(atts as IFeatureBeam);

  div.innerHTML = '<div>' + content + '</div>';
  return div;
};

const popupTemplate = {
  title: '<div class="text-primary">Beam {name}</div>',
  content: getPopupContent,
  outFields: ['*']
};

export const beamsLayerPosition = 20;

export const beamLayerId = 'beams';

export const getBeamsLayer = (missions: Mission[], beamCriterion: BeamCriterion | null, t: TFunction, graphics: Graphic[]) => {
  let renderer = null;
  if (beamCriterion) {
    renderer = new ClassBreaksRenderer({
      field: beamCriterion,
      defaultSymbol: getNoDataSymbol(),
      defaultLabel: 'no data',
      classBreakInfos: getBeamCriterionDef(beamCriterion).classBreakInfos
    });
  } else {
    const uniqueValueInfos = missions.reduce((agg: __esri.UniqueValueInfoProperties[], mission) => {
      agg.push({
        value: mission.id,
        symbol: getMissionSymbol(mission)
      });
      // add global area style (global area graphics have negative mission id)
      agg.push({
        value: -mission.id,
        symbol: getGlobalAreaTypeSymbolFromMission(mission)
      });
      return agg;
    }, []);

    renderer = new UniqueValueRenderer({
      field: 'missionId',
      uniqueValueInfos
    });
  }

  let fields: any[] = [
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'lt',
      type: 'double'
    },
    {
      name: 'lg',
      type: 'double'
    },
    {
      name: 's',
      type: 'double'
    },
    {
      name: 'missionId',
      type: 'double'
    },
    {
      name: 'type',
      type: 'string'
    },
    {
      name: 'id',
      type: 'string'
    },
    {
      name: 'i',
      type: 'string'
    },
    {
      name: 'ObjectID',
      alias: 'ObjectID',
      type: 'oid'
    },
    {
      name: 'sysc',
      alias: t('beam.targetedCapacity'),
      type: 'double'
    },
    {
      name: 'sysr',
      alias: t('beam.ratio'),
      type: 'double'
    },
    {
      name: 'txb',
      alias: t('beam.bandForward'),
      type: 'double'
    },
    {
      name: 'txe',
      alias: t('beam.eirp'),
      type: 'double'
    },
    {
      name: 'txc',
      alias: t('beam.ci'),
      type: 'double'
    },
    {
      name: 'txd',
      alias: t('beam.eirpDensity'),
      type: 'double'
    },
    {
      name: 'txr',
      alias: t('beam.freqPlanOccRatio'),
      type: 'double'
    },
    {
      name: 'rxc',
      alias: t('beam.ci'),
      type: 'double'
    },
    {
      name: 'rxg',
      alias: t('beam.gt'),
      type: 'double'
    },
    {
      name: 'rxb',
      alias: t('beam.bandReturn'),
      type: 'double'
    },
    {
      name: 'rxr',
      alias: t('beam.freqPlanOccRatio'),
      type: 'double'
    }
  ];

  return new FeatureLayer({
    id: beamLayerId,
    title: beamLayerId,
    source: graphics,
    objectIdField: 'ObjectID',
    spatialReference: SpatialReference.WGS84,
    labelingInfo: [beamNumberLabelClass],
    geometryType: 'polygon',
    fields,
    renderer,
    popupTemplate: popupTemplate
  });
};

export const getBeamId = (coverageType: CoverageType, areaId: number, id: string | number) => {
  return `area_${areaId}_${coverageType}_${id}`;
};

export const getBeamI = (mission: Mission, suffix: string) => {
  return mission.missionLetter + suffix;
};

export const getRegionalBeamI = (mission: Mission, missions: MissionDetails[], area: AreaOfInterest) => {
  const suffix = area.id.toString();
  return getBeamI(mission, suffix);
};

export const getGraphicBeamId = (g: Graphic) => g.getAttribute('ObjectID');

export const toBeamAttribute = (beam: IDatabaseBeam): Partial<IFeatureBeam> => {
  return {
    i: beam.i,
    lt: beam.lt,
    lg: beam.lg,
    s: beam.s,
    sysc: beam.sys ? beam.sys.c : null,
    sysr: beam.sys ? beam.sys.r : null,
    txb: beam.tx ? beam.tx.b : null,
    txe: beam.tx ? beam.tx.e : null,
    txc: beam.tx ? beam.tx.c : null,
    txr: beam.tx ? beam.tx.r : null,
    txd: beam.tx ? beam.tx.d : null,
    rxc: beam.rx ? beam.rx.c : null,
    rxg: beam.rx ? beam.rx.g : null,
    rxb: beam.rx ? beam.rx.b : null,
    rxr: beam.rx ? beam.rx.r : null
  };
};

export const beamIsSelected = (graphic: Graphic) => {
  const selected = graphic.getAttribute('selected');
  return selected === 'true' || selected === true;
};

export const editBeamLayerId = 'edit-beams';
